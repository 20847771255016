import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ExcelService } from './excel.service';
import { LoadProfileService } from './load-profiles.service';
import { ResourceService } from './resource.service';
import {
  BillCharges,
  LoadProfileJson,
  LoadProfileStats,
  Result,
  ResultExport,
  Scenario,
} from '@model';

class ResultsSheetData {
  resultTabData: Record<string, unknown>[];
  drTabData: object[];
  cpTabData: object[];
  cpReductionTabData: object[];

  constructor() {
    this.resultTabData = [];
    this.drTabData = [];
    this.cpTabData = [];
    this.cpReductionTabData = [];
  }
}

@Injectable()
export class ResultService {
  private readonly selectedResultEmitter = new Subject<
    [
      Observable<LoadProfileJson[]>,
      string,
      Observable<LoadProfileJson[]>,
      boolean,
    ]
  >();
  selectedResult$ = this.selectedResultEmitter.asObservable();

  private readonly selectedResultStatEmitter = new Subject<
    [Observable<LoadProfileStats>, string]
  >();
  selectedResultStats$ = this.selectedResultStatEmitter.asObservable();
  runEnable: boolean;
  showRunningSpinner = false;
  showForecastedRunningSpinner = false;
  showRerunningSpinner = false;
  private resultsChargesCache = new Map<string, Observable<BillCharges[]>>();

  constructor(
    private resultService: ResourceService<Result>,
    private chargesService: ResourceService<BillCharges>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private exportService: ResourceService<any[]>,
    private loadProfileService: LoadProfileService,
    private excelService: ExcelService,
    private translateService: TranslateService,
  ) {}

  getResults$(scenarioId: string): Observable<Result[]> {
    return this.resultService.getList(`scenarios/${scenarioId}/results`);
  }

  getForecastResults$(scenarioId: string, idx: number): Observable<Result> {
    return this.resultService.get(
      `scenarios/${scenarioId}/forecast-results/${idx}`,
    );
  }

  getResultExport$(
    scenarioId: string,
    projectLabel: string,
    scenarioLabel: string,
    date: string,
    timezone: string,
    fbdScenario: boolean,
  ): Observable<{ filename: string; buffer: BlobPart }> {
    let allResults = new ResultsSheetData();

    const actualResults$ = this.exportService.get(
      `scenarios/${scenarioId}/results/export`,
    );
    const forecastedResults$ = this.exportService.get(
      `scenarios/${scenarioId}/results/export?is_forecast=true`,
    );
    const results$ = fbdScenario
      ? [actualResults$, forecastedResults$]
      : [actualResults$];

    return forkJoin(results$).pipe(
      map(data => {
        allResults = this.buildSheetData(
          allResults,
          data[0], // actual
          timezone,
          fbdScenario,
          'scenario.results.optimization_type.actual',
        );
        if (data.length > 1) {
          allResults = this.buildSheetData(
            allResults,
            data[1], // forecasted
            timezone,
            fbdScenario,
            'scenario.results.optimization_type.forecasted',
          );
        }

        const sheets = this.buildSheets(allResults);
        return {
          filename: `OPT_${projectLabel}_${scenarioLabel}_Results_${date}`
            .replaceAll(' ', '_')
            .replaceAll(/[\[\]\\\/?*:+,]/g, ''),
          buffer: this.excelService.createExcel(sheets),
        };
      }),
    );
  }

  getResultsCharges$(
    scenario: Scenario,
    idx: number,
    forecasted = false,
  ): Observable<BillCharges[]> {
    let KEY = `${scenario.id}${idx}${scenario.lastUpdatedDate}${scenario.runCompletedOn}`;
    if (forecasted) {
      KEY += 'forecasted';
    }
    if (!this.resultsChargesCache.has(KEY)) {
      this.resultsChargesCache.set(
        KEY,
        this.resultsCharges$(scenario.id, idx, forecasted).pipe(shareReplay(1)),
      );
    }
    return this.resultsChargesCache.get(KEY);
  }

  private resultsCharges$(
    scenarioId: string,
    idx: number,
    forecasted: boolean,
  ): Observable<BillCharges[]> {
    let path = `scenarios/${scenarioId}/results/${idx}/bill-charges`;
    if (forecasted) {
      path += '?is_forecast=true';
    }
    return this.chargesService.getList(path);
  }

  formatSheetName(name: string): string {
    return name.replaceAll(/[\[\]\\\/?*]/g, '').substring(0, 30);
  }

  selectResult(loadProfileId: string, forecast = false): void {
    const loadProfile$ = loadProfileId
      ? this.loadProfileService.getLoadProfileJson(loadProfileId)
      : of(null);
    const selectedResultStats$ = loadProfileId
      ? this.loadProfileService
          .loadProfile$(loadProfileId)
          .pipe(map(loadProfile => loadProfile.stats))
      : of(null);
    const stateOfCharge$ = loadProfileId
      ? this.loadProfileService.loadSOC$(loadProfileId)
      : of(null);
    this.selectedResultEmitter.next([
      loadProfile$,
      loadProfileId,
      stateOfCharge$,
      forecast,
    ]);
    this.selectedResultStatEmitter.next([selectedResultStats$, loadProfileId]);
  }

  private convertResultExportKeys(
    resultsExport: ResultExport[],
    gridStats: { nomination: number; programName: string }[][],
    programNames: string[],
    fbdScenario: boolean,
    optType: string,
  ): Record<string, unknown>[] {
    const resultLocalizedLabel = {};
    if (fbdScenario === true) {
      resultLocalizedLabel['scenario.results.optimization_type'] =
        this.translateService.instant('scenario.results.optimization_type');
    }
    resultLocalizedLabel['scenario.results.config_number'] =
      this.translateService.instant('scenario.results.config_number');
    resultLocalizedLabel['scenario.results.solar_pv_nameplate'] =
      this.translateService.instant('scenario.results.solar_pv_nameplate');
    resultLocalizedLabel['scenario.results.storage_power'] =
      this.translateService.instant('scenario.results.storage_power');
    resultLocalizedLabel['scenario.results.storage_capacity'] =
      this.translateService.instant('scenario.results.storage_capacity');
    resultLocalizedLabel['scenario.results.solar_savings'] =
      this.translateService.instant('scenario.results.solar_savings');
    resultLocalizedLabel['scenario.results.storage_savings'] =
      this.translateService.instant('scenario.results.storage_savings');
    resultLocalizedLabel['scenario.results.tariff_switch_savings'] =
      this.translateService.instant('scenario.results.tariff_switch_savings');
    resultLocalizedLabel['scenario.results.grid_services_revenues'] =
      this.translateService.instant('scenario.results.grid_services_revenues');
    resultLocalizedLabel['scenario.results.total_savings_and_revenues'] =
      this.translateService.instant(
        'scenario.results.total_savings_and_revenues',
      );
    resultLocalizedLabel['scenario.results.ghg_baseline'] =
      this.translateService.instant('scenario.results.ghg_baseline');
    resultLocalizedLabel['scenario.results.ghg_pv'] =
      this.translateService.instant('scenario.results.ghg_pv');
    resultLocalizedLabel['scenario.results.ghg_storage'] =
      this.translateService.instant('scenario.results.ghg_storage');
    resultLocalizedLabel['scenario.results.storage_cycles'] =
      this.translateService.instant('scenario.results.storage_cycles');
    resultLocalizedLabel['scenario.results.total_storage_savings'] =
      this.translateService.instant('scenario.results.total_storage_savings');
    resultLocalizedLabel['scenario.results.storage_energy_charge'] =
      this.translateService.instant('scenario.results.storage_energy_charge');
    resultLocalizedLabel['scenario.results.storage_demand_charge'] =
      this.translateService.instant('scenario.results.storage_demand_charge');
    resultLocalizedLabel['scenario.results.solar_energy_charge'] =
      this.translateService.instant('scenario.results.solar_energy_charge');
    resultLocalizedLabel['scenario.results.solar_demand_charge'] =
      this.translateService.instant('scenario.results.solar_demand_charge');
    resultLocalizedLabel['scenario.results.aux_loss'] =
      this.translateService.instant('scenario.results.aux_loss');
    if (programNames?.length) {
      programNames
        .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
        .forEach(programName => {
          resultLocalizedLabel[
            `${programName}scenario.results.grid.avg.nomination`
          ] =
            `${programName} ${this.translateService.instant('scenario.results.grid.avg.nomination')}`;
        });
    }
    resultLocalizedLabel['scenario.results.exported_pv'] =
      this.translateService.instant('scenario.results.exported_pv');
    resultLocalizedLabel['scenario.results.exported'] =
      this.translateService.instant('scenario.results.exported');
    resultLocalizedLabel['scenario.results.curtailed_pv'] =
      this.translateService.instant('scenario.results.curtailed_pv');
    resultLocalizedLabel['scenario.results.curtailed'] =
      this.translateService.instant('scenario.results.curtailed');
    let mappedResult = resultsExport.map((result, i) => {
      const localizedResults = {};
      if (fbdScenario === true) {
        localizedResults[
          resultLocalizedLabel['scenario.results.optimization_type']
        ] = optType;
      }
      localizedResults[resultLocalizedLabel['scenario.results.config_number']] =
        result.configNumber;
      localizedResults[
        resultLocalizedLabel['scenario.results.solar_pv_nameplate']
      ] = result.solarPvNameplate;
      localizedResults[resultLocalizedLabel['scenario.results.storage_power']] =
        result.storagePower;
      localizedResults[
        resultLocalizedLabel['scenario.results.storage_capacity']
      ] = result.storageCapacity;
      localizedResults[resultLocalizedLabel['scenario.results.solar_savings']] =
        result.solarSavings;
      localizedResults[
        resultLocalizedLabel['scenario.results.storage_savings']
      ] = result.storageSavings;
      localizedResults[
        resultLocalizedLabel['scenario.results.tariff_switch_savings']
      ] = result.tariffSwitchSavings;
      localizedResults[
        resultLocalizedLabel['scenario.results.grid_services_revenues']
      ] = result.gridServiceRevenues;
      localizedResults[
        resultLocalizedLabel['scenario.results.total_savings_and_revenues']
      ] = result.totalSavingsAndRevenues;
      localizedResults[resultLocalizedLabel['scenario.results.ghg_baseline']] =
        result.ghgBaseline;
      localizedResults[resultLocalizedLabel['scenario.results.ghg_pv']] =
        result.ghgAfterPv;
      localizedResults[resultLocalizedLabel['scenario.results.ghg_storage']] =
        result.ghgAfterEss;
      localizedResults[
        resultLocalizedLabel['scenario.results.storage_cycles']
      ] = result.storageCycles;
      localizedResults[
        resultLocalizedLabel['scenario.results.total_storage_savings']
      ] = result.storageSavingsPerKwh;
      localizedResults[
        resultLocalizedLabel['scenario.results.solar_energy_charge']
      ] = result.solarEnergyChargesSavings;
      if (result.dynamicSolarSavings?.length) {
        if (result.dynamicSolarSavings.length === 1) {
          localizedResults[
            this.translateService.instant(
              'scenario.results.savings.solar.dynamic_solar_savings',
            )
          ] = result.dynamicSolarSavings[0].value;
        } else {
          if (
            result.dynamicSolarSavings[0].name.toLowerCase().includes('export')
          ) {
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_solar_import',
              )
            ] = result.dynamicSolarSavings[1].value;
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_solar_export',
              )
            ] = result.dynamicSolarSavings[0].value;
          } else {
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_solar_import',
              )
            ] = result.dynamicSolarSavings[0].value;
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_solar_export',
              )
            ] = result.dynamicSolarSavings[1].value;
          }
        }
      }
      localizedResults[
        resultLocalizedLabel['scenario.results.solar_demand_charge']
      ] = result.solarDemandChargesSavings;
      localizedResults[
        resultLocalizedLabel['scenario.results.storage_energy_charge']
      ] = result.storageEnergyChargesSavings;
      if (result.dynamicStorageSavings?.length) {
        if (result.dynamicStorageSavings.length === 1) {
          localizedResults[
            this.translateService.instant(
              'scenario.results.savings.storage.dynamic_storage_savings',
            )
          ] = result.dynamicStorageSavings[0].value;
        } else {
          if (
            result.dynamicStorageSavings[0].name
              .toLowerCase()
              .includes('export')
          ) {
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_storage_import',
              )
            ] = result.dynamicStorageSavings[1].value;
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_storage_export',
              )
            ] = result.dynamicStorageSavings[0].value;
          } else {
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_storage_import',
              )
            ] = result.dynamicStorageSavings[0].value;
            localizedResults[
              this.translateService.instant(
                'scenario.results.energy_savings_storage_export',
              )
            ] = result.dynamicStorageSavings[1].value;
          }
        }
      }
      localizedResults[
        resultLocalizedLabel['scenario.results.storage_demand_charge']
      ] = result.storageDemandChargesSavings;
      localizedResults[resultLocalizedLabel['scenario.results.aux_loss']] =
        result.auxLosses;
      localizedResults[resultLocalizedLabel['scenario.results.exported_pv']] =
        Math.round(result.exportedPv);
      localizedResults[resultLocalizedLabel['scenario.results.exported']] =
        Math.round(result.exported);
      localizedResults[resultLocalizedLabel['scenario.results.curtailed_pv']] =
        Math.round(result.curtailedPv);
      localizedResults[resultLocalizedLabel['scenario.results.curtailed']] =
        Math.round(result.curtailed);

      if (result.gridServicesProgramResults?.length) {
        result.gridServicesProgramResults
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .forEach(gsp => {
            const key = `${gsp.name} ${this.translateService.instant('scenario.export.results.revenue')}`;
            localizedResults[key] = gsp.value;
          });
      }
      // Nominations are added
      if (programNames?.length) {
        programNames
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
          .forEach(programName => {
            localizedResults[
              resultLocalizedLabel[
                `${programName}scenario.results.grid.avg.nomination`
              ]
            ] = gridStats[i]?.find(
              i => i.programName === programName,
            )?.nomination;
          });
      }
      const nominationKey = 'PlaceholderNomination';

      Object.keys(result).forEach(key => {
        let propertyName = '';
        if (key.includes(nominationKey)) {
          propertyName = `${key.replace(nominationKey, '')} ${this.translateService.instant('scenario.export.results.nomination')}`;
        }
        if (propertyName !== '') {
          if (propertyName.includes('Energy')) {
            const n = propertyName.lastIndexOf('Energy');
            const newPropertyName =
              propertyName.slice(0, n) +
              propertyName
                .slice(n)
                .replace(
                  'Energy',
                  ` ${this.translateService.instant(
                    'scenario.export.results.energy',
                  )}`,
                );
            localizedResults[newPropertyName] = result[key];
            delete localizedResults[propertyName];
          }
          if (propertyName.includes('Capacity')) {
            const n = propertyName.lastIndexOf('Capacity');
            const newPropertyName =
              propertyName.slice(0, n) +
              propertyName
                .slice(n)
                .replace(
                  'Capacity',
                  ` ${this.translateService.instant(
                    'scenario.export.results.capacity',
                  )}`,
                );
            localizedResults[newPropertyName] = result[key];
            delete localizedResults[propertyName];
          }
        }
      });
      return localizedResults;
    });

    // If the nomination column for a certain program doesn't contain any values,
    // that column is removed using array destructuration
    programNames
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
      .forEach(programName => {
        const areThereValues = mappedResult
          .map(
            row =>
              row[
                `${programName} ${this.translateService.instant('scenario.results.grid.avg.nomination')}`
              ],
          )
          .some(values => values !== null && values !== undefined);
        if (!areThereValues) {
          mappedResult = JSON.parse(JSON.stringify(mappedResult)).map(
            ({
              [`${programName} ${this.translateService.instant('scenario.results.grid.avg.nomination')}`]:
                _,
              ...item
            }) => item,
          );
        }
      });
    return mappedResult;
  }

  private changeDRKeys(
    data: object[],
    params = {},
    fbdScenario: boolean,
    optType: string,
  ): object[] {
    return data.map(result => {
      if (fbdScenario === true) {
        const optTypeHeading = this.translateService.instant(
          'scenario.results.optimization_type',
        );
        result[optTypeHeading] = optType;
      }
      this.changeKey(result, 'config', 'scenario.export.results.dr.config');
      result['mc_iteration'] = 1;
      this.changeKey(
        result,
        'mc_iteration',
        'scenario.export.results.dr.mc_iteration',
      );
      this.changeKey(
        result,
        'energy',
        'scenario.export.results.cp_reduction.energy',
      );
      this.changeKey(
        result,
        'power',
        'scenario.export.results.cp_reduction.power',
      );
      this.changeKey(
        result,
        'pvSize',
        'scenario.export.results.cp_reduction.pv_size',
      );
      this.changeKey(result, 'date', 'scenario.export.results.dr.date', params);
      this.changeKey(
        result,
        'gridServiceProgram',
        'scenario.export.results.dr.grid_service_program',
      );
      this.changeKey(
        result,
        'simulatedDispatchStart',
        'scenario.export.results.dr.simulated_dispatch_start',
        params,
      );
      this.changeKey(
        result,
        'simulatedDispatchEnd',
        'scenario.export.results.dr.simulated_dispatch_end',
        params,
      );
      this.changeKey(
        result,
        'energyRate',
        'scenario.export.results.dr.energy_rate',
      );
      this.changeKey(
        result,
        'drProgramExport',
        'scenario.export.results.dr.dr_program_export',
      );
      this.changeKey(
        result,
        'avgLoadBeforeDers',
        'scenario.export.results.dr.avg_load_before_ders',
      );
      this.changeKey(
        result,
        'totalConsumptionBeforeDers',
        'scenario.export.results.dr.total_consumption_before_ders',
      );
      this.changeKey(
        result,
        'avgLoadAfterPv',
        'scenario.export.results.dr.avg_load_after_pv',
      );
      this.changeKey(
        result,
        'avgBessOutput',
        'scenario.export.results.dr.avg_bess_output',
      );
      this.changeKey(
        result,
        'avgLoadAfterDers',
        'scenario.export.results.dr.avg_load_after_ders',
      );
      this.changeKey(
        result,
        'totalConsumptionAfterDers',
        'scenario.export.results.dr.total_consumption_after_ders',
      );
      this.changeKey(
        result,
        'estimatedBaselineCbl',
        'scenario.export.results.dr.estimated_baseline_cbl',
      );
      this.changeKey(
        result,
        'estimatedBaselineFsl',
        'scenario.export.results.dr.estimated_baseline_fsl',
      );
      this.changeKey(
        result,
        'estimatedDayOfAdjustmentCbl',
        'scenario.export.results.dr.estimated_day_of_adjustment_cbl',
      );
      this.changeKey(result, 'duration', 'scenario.export.results.dr.duration');
      this.changeKey(
        result,
        'curtailment',
        'scenario.export.results.dr.curtailment',
      );
      this.changeKey(
        result,
        'performance',
        'scenario.export.results.dr.performance',
      );
      return result;
    });
  }

  private changeCPKeys(
    data: object[],
    params = {},
    fbdScenario: boolean,
    optType: string,
  ): object[] {
    return data.map(result => {
      if (fbdScenario === true) {
        const optTypeHeading = this.translateService.instant(
          'scenario.results.optimization_type',
        );
        result[optTypeHeading] = optType;
      }
      this.changeKey(result, 'config', 'scenario.export.results.cpp.config');
      result['mc_iteration'] = 1;
      this.changeKey(
        result,
        'mc_iteration',
        'scenario.export.results.cpp.mc_iteration',
      );
      this.changeKey(
        result,
        'date',
        'scenario.export.results.cpp.date',
        params,
      );
      this.changeKey(
        result,
        'gridServiceProgram',
        'scenario.export.results.cpp.grid_service_program',
      );
      this.changeKey(
        result,
        'energy',
        'scenario.export.results.cp_reduction.energy',
      );
      this.changeKey(
        result,
        'power',
        'scenario.export.results.cp_reduction.power',
      );
      this.changeKey(
        result,
        'pvSize',
        'scenario.export.results.cp_reduction.pv_size',
      );
      this.changeKey(
        result,
        'simulatedDispatchStart',
        'scenario.export.results.cpp.simulated_dispatch_start',
        params,
      );
      this.changeKey(
        result,
        'simulatedDispatchEnd',
        'scenario.export.results.cpp.simulated_dispatch_end',
        params,
      );
      this.changeKey(
        result,
        'energyRate',
        'scenario.export.results.cpp.energy_rate',
      );
      this.changeKey(
        result,
        'nrevenueBasisHours',
        'scenario.export.results.cpp.n_revenue_basis_hours',
      );
      this.changeKey(
        result,
        'dispatchHour',
        'scenario.export.results.cpp.dispatch_hour',
        params,
      );
      this.changeKey(
        result,
        'avgLoadBeforeDers',
        'scenario.export.results.cpp.avg_load_before_ders',
      );
      this.changeKey(
        result,
        'totalConsumptionBeforeDers',
        'scenario.export.results.cpp.total_consumption_before_ders',
      );
      this.changeKey(
        result,
        'avgLoadAfterPv',
        'scenario.export.results.cpp.avg_load_after_pv',
      );
      this.changeKey(
        result,
        'avgBessOutput',
        'scenario.export.results.cpp.avg_bess_output',
      );
      this.changeKey(
        result,
        'avgLoadAfterDers',
        'scenario.export.results.cpp.avg_load_after_ders',
      );
      this.changeKey(
        result,
        'totalConsumptionAfterDers',
        'scenario.export.results.cpp.total_consumption_after_ders',
      );
      this.changeKey(
        result,
        'curtailment',
        'scenario.export.results.cpp.curtailment',
      );
      this.changeKey(
        result,
        'selectedAsRevenueBasisHour',
        'scenario.export.results.cpp.selected_as_revenue_basis_hour',
      );
      return result;
    });
  }

  private changeCPReductionKeys(
    data: object[],
    fbdScenario: boolean,
    optType: string,
  ): object[] {
    return data.map(result => {
      if (fbdScenario === true) {
        const optTypeHeading = this.translateService.instant(
          'scenario.results.optimization_type',
        );
        result[optTypeHeading] = optType;
      }
      this.changeKey(
        result,
        'gridServiceProgram',
        'scenario.export.results.cp_reduction.grid_service_program',
      );
      this.changeKey(
        result,
        'config',
        'scenario.export.results.cp_reduction.config',
      );
      this.changeKey(
        result,
        'energy',
        'scenario.export.results.cp_reduction.energy',
      );
      this.changeKey(
        result,
        'power',
        'scenario.export.results.cp_reduction.power',
      );
      this.changeKey(
        result,
        'pvSize',
        'scenario.export.results.cp_reduction.pv_size',
      );
      this.changeKey(
        result,
        'chargePv',
        'scenario.export.results.cp_reduction.charge_pv',
      );
      this.changeKey(
        result,
        'export',
        'scenario.export.results.cp_reduction.export',
      );
      this.changeKey(
        result,
        'exportEss',
        'scenario.export.results.cp_reduction.export_ess',
      );
      this.changeKey(
        result,
        'costCycle',
        'scenario.export.results.cp_reduction.cycling_cost',
      );
      this.changeKey(
        result,
        'costSoc',
        'scenario.export.results.cp_reduction.soc_cost',
      );
      this.changeKey(
        result,
        'costGhg',
        'scenario.export.results.cp_reduction.ghg_cost',
      );
      this.changeKey(
        result,
        'prePvpreEsscp',
        'scenario.export.results.cp_reduction.pre_pv_pre_ess_cp',
      );
      this.changeKey(
        result,
        'postPvpreEsscp',
        'scenario.export.results.cp_reduction.post_pv_pre_ess_cp',
      );
      this.changeKey(
        result,
        'postPvpostEsscp',
        'scenario.export.results.cp_reduction.post_pv_post_ess_cp',
      );
      this.changeKey(
        result,
        'pvCpreduction',
        'scenario.export.results.cp_reduction.pv_cp_reduction',
      );
      this.changeKey(
        result,
        'essCpreduction',
        'scenario.export.results.cp_reduction.ess_cp_reduction',
      );
      result['cpUtilization'] = `${result['cpUtilization'] * 100}`;
      this.changeKey(
        result,
        'cpUtilization',
        'scenario.export.results.cp_reduction.cp_utilization',
      );
      return result;
    });
  }

  private changeKey(
    data: object,
    columnName: string,
    translationKey: string,
    params = {},
  ): void {
    const value = data[columnName];
    delete data[columnName];
    data[this.translateService.instant(translationKey, params)] = value;
  }

  private buildSheetData(
    allResults: ResultsSheetData,
    data: any,
    timezone: string,
    fbdScenario: boolean,
    optTypeKey: string,
  ): ResultsSheetData {
    if (data.length === 0) return allResults;
    const resultsExport = data[0];
    const resultsCpExport = data[1];
    const resultsDrExport = data[2];
    const resultsCpReductionExport = data[3];
    const gridStatNominations = data[4];
    const programNames = data[5];
    const optType = this.translateService.instant(optTypeKey);

    allResults.resultTabData = allResults.resultTabData.concat(
      this.convertResultExportKeys(
        resultsExport,
        gridStatNominations,
        programNames,
        fbdScenario,
        optType,
      ),
    );
    allResults.drTabData = allResults.drTabData.concat(
      this.changeDRKeys(resultsDrExport, { timezone }, fbdScenario, optType),
    );
    allResults.cpTabData = allResults.cpTabData.concat(
      this.changeCPKeys(resultsCpExport, { timezone }, fbdScenario, optType),
    );
    allResults.cpReductionTabData = allResults.cpReductionTabData.concat(
      this.changeCPReductionKeys(
        resultsCpReductionExport,
        fbdScenario,
        optType,
      ),
    );

    return allResults;
  }

  private buildSheets(allResults: ResultsSheetData) {
    const sheets = new Map();
    sheets.set(
      this.formatSheetName(
        this.translateService.instant('scenario.export.results'),
      ),
      allResults.resultTabData,
    );
    sheets.set(
      this.formatSheetName(
        this.translateService.instant('scenario.export.simulated_events_dr'),
      ),
      allResults.drTabData,
    );
    sheets.set(
      this.formatSheetName(
        this.translateService.instant('scenario.export.simulated_events_cp'),
      ),
      allResults.cpTabData,
    );
    sheets.set(
      this.formatSheetName(
        this.translateService.instant(
          'scenario.export.simulated_events_cp_reduction',
        ),
      ),
      allResults.cpReductionTabData,
    );
    return sheets;
  }
}
