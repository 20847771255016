<div>
  <div class="w-full flex justify-center items-center title-heading">
    <div
      class="flex justify-start items-center justify-self-start margin-right-auto icon-header"
    >
      <div class="icon-container">
        <mat-icon svgIcon="building1" class="header-icon"></mat-icon>
      </div>
      <div>
        <optima-inline-edit
          ngDefaultControl
          htmlTag="h1"
          inputType="input"
          [(formControl)]="projectNameFormControl"
          [label]="project.displayLabel"
          [fieldToUpdate]="'displayLabel'"
          [callback]="updateProject"
        ></optima-inline-edit>
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex items-center last-save-container">
        <mat-icon svgIcon="reset" class="refresh-icon"></mat-icon>
        <span class="text-warn last-save">
          {{ 'project.last_saved' | translate }}:
        </span>
        <span class="text-warn">{{ projectLastSaved | async }}</span>
      </div>
      <div class="flex items-center justify-end button-container ml-6">
        <button
          id="delete-project-button"
          (click)="openDeleteProjectDialog()"
          data-automation="delete-project-button"
          class="x-btn x-btn-secondary delete-project-button flex items-center"
          matRipple
        >
          <mat-icon svgIcon="delete"></mat-icon>
          <span class="ml-2">{{ 'project.button.delete' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div data-automation="project-page-container" class="page-container">
    <optima-project-details
      [project]="project"
      [updateProject]="updateProject"
    ></optima-project-details>

    <optima-project-scenario-table
      [project]="project"
      [scenarios]="scenarios"
      [projectInvalid]="projectInvalid"
    ></optima-project-scenario-table>

    <optima-project-load-profile-table
      [loadProfiles]="loadProfiles"
      [scenarios]="scenarios"
    ></optima-project-load-profile-table>
  </div>
</div>
