import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { TariffTableComponent } from './tariff-table.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [TariffTableComponent],
  exports: [TariffTableComponent],
  imports: [CommonModule, TranslateModule, MatTableModule, PipeModule],
})
export class TariffTableModule {}
