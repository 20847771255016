import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { InlineEditComponent } from '@component';
import { isError } from '@helper';
import { Project, Timezone } from '@model';
import { GoogleService, InlineEditService, TimezonesService } from '@service';
import { noWhitespaceValidator } from '@validator';

@Component({
  selector: 'optima-project-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class DetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterViewChecked {
  @Input() project: Project;
  @Input() updateProject: (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof Project,
  ) => Observable<Project>;
  @ViewChild('timezoneSelect') timezoneSelect: InlineEditComponent<Timezone>;
  @ViewChild('mapComponent') mapComponent: InlineEditComponent<Location>;
  timezoneFormControl: UntypedFormControl;
  locationFormControl: UntypedFormControl;
  projectStartDateFormControl: UntypedFormControl;
  projectLifetimeFormControl: UntypedFormControl;
  projectLifetimeList: { label: string; value: number }[];
  projectFormattedStartDate: string;
  apiKey: string;
  subscriptions = [];
  projectCreated: Promise<string>;

  isFormError = isError;

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    event.preventDefault();
  }

  constructor(
    private cd: ChangeDetectorRef,
    private inlineEditService: InlineEditService<unknown>,
    private translateService: TranslateService,
    private googleService: GoogleService,
    public timezoneService: TimezonesService,
  ) {}

  ngOnInit(): void {
    const defaultString = this.translateService.instant('default.label');
    this.projectLifetimeList = Array.from({ length: 25 }, (_, index) => {
      const num = index + 1;
      return {
        label: num === 20 ? `${num} ` + defaultString : `${num}`,
        value: num,
      };
    });
    this.apiKey = this.googleService.apiKey;
    this.projectCreated = this.timezoneService.getUserTimezoneLastUpdateDate(
      this.project.createdDate,
    );
  }

  ngAfterViewInit(): void {
    this.checkLocation();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.projectCreated = this.timezoneService.getUserTimezoneLastUpdateDate(
      this.project.createdDate,
    );
    this.projectFormattedStartDate = this.project.startDate
      ? moment(this.project.startDate, 'MM-DD-YYYY').format('MMM DD, YYYY')
      : '';
    if (!changes['project'].firstChange) {
      Array.from(this.inlineEditService.existingEdits.keys()).forEach(
        component => this.inlineEditService.unregisterEdit(component),
      );
    }
    this.setUpForm();
    this.setUpFormLogic();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private checkLocation(): ValidatorFn {
    return () => {
      const errors = {} as ValidationErrors;

      if (!this.project.location?.address) {
        errors['location is required'] = 'missing location';
      } else {
        this.mapComponent.isEditing = false;
      }
      return errors;
    };
  }

  timezoneListener(event: string): void {
    this.project.timezone = event;
    this.timezoneFormControl.setValue(this.project.timezone);
  }

  private setUpForm(): void {
    this.timezoneFormControl = new UntypedFormControl(
      !this.project.location?.address || !this.project.timezone
        ? null
        : this.project.timezone,
      [Validators.required, this.checkLocation],
    );
    this.projectStartDateFormControl = new UntypedFormControl(
      this.project.startDate
        ? moment(this.project.startDate, 'MM-DD-YYYY').toDate()
        : new Date(moment().year() + 1, 0),
      [Validators.required],
    );
    this.projectLifetimeFormControl = new UntypedFormControl(
      this.project.projectLifetime,
      [Validators.required],
    );
    this.locationFormControl = new UntypedFormControl(
      this.project.location || null,
      [Validators.required, noWhitespaceValidator('address')],
    );
  }

  private setUpFormLogic(): void {
    this.locationFormControl.valueChanges.subscribe(value => {
      if (!value || value === '') {
        this.timezoneFormControl.patchValue(null);
      }
    });
  }
}
