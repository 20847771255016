import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { inOut } from '@helper';
import { Scenario, ScenarioPrograms, Program } from '@model';
import { GridProgramService, LoaderService, ScenarioService } from "@service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'optima-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  animations: [inOut({ height: 10, time: 0.1 })],
})
export class GridComponent implements OnInit{
  @Input() scenario: Scenario;
  @Output() scenarioChange = new EventEmitter<Scenario>();
  showAdvancedParameters = false;
  toggle: 'standard' | 'custom' = 'standard';
  scenarioServiceUrl = '';
  customPrograms: Program[] = [];

  constructor(
    private scenarioService: ScenarioService,
    private loaderService: LoaderService,
    private programService: GridProgramService,
    private route: ActivatedRoute
  ) {
    this.scenarioServiceUrl = this.scenarioService.apiUrl;
  }

  ngOnInit(): void {
    this.customPrograms = this.route.snapshot.data['scenarioView'][1];
  }

  scenarioEmitter(scenario: Scenario): void {
    this.scenario = { ...this.scenario, programs: scenario.programs };
    this.scenarioChange.emit(this.scenario);
  }

  async updatePrograms(): Promise<void> {
    this.loaderService.showLoader('grid-service-loader');
    this.programService.customPrograms$(this.scenario.id).subscribe((customPrograms) => {
      this.scenario.programs.custom = customPrograms?.map((program) => { return program.id });
      this.customPrograms = customPrograms;
    });

    this.scenarioService.getScenario$(this.scenario.id).subscribe((scenario) => {
      this.scenario.customProgramFilename = scenario.customProgramFilename;
    });
  }

  hasCustomPrograms(): boolean {
    return !!this.scenario?.customProgramFilename;
  }

  removeCustomProgramFile() {
    this.programService.deleteCustomPrograms$(this.scenario.id).subscribe(() => {
      this.scenario.programs.custom = [];
      this.scenario.customProgramFilename = '';
      this.scenarioEmitter(this.scenario);
    });
  }
}
