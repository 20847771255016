import { Component, Input } from '@angular/core';
import { Scenario } from '@model';
import { LoaderService, TariffService } from '@service';

@Component({
  selector: 'optima-tariff-advanced-options',
  templateUrl: './tariff-advanced-options.component.html',
  styleUrls: ['./tariff-advanced-options.component.scss'],
})
export class TariffAdvancedOptionsComponent {
  @Input() scenario: Scenario;

  constructor(
    private tariffService: TariffService,
    private loaderService: LoaderService,
  ) {}

  update(): void {
    this.loaderService.changeLabel('');
    this.tariffService
      .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
      .subscribe(() => {
        this.loaderService.changeLabel(null);
      });
  }
}
