import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class ExcelService {
  createExcel(sheets: Map<string, Record<string, unknown>[]>): BlobPart {
    const book = XLSX.utils.book_new();
    Array.from(sheets.keys()).forEach(sheet => {
      XLSX.utils.book_append_sheet(
        book,
        XLSX.utils.json_to_sheet(sheets.get(sheet)),
        sheet,
      );
    });
    return XLSX.write(book, { bookType: 'xlsx', type: 'buffer' });
  }
}
