import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UploaderModule } from '../../../global/uploader/uploader.module';
import { DynamicRatesComponent } from './dynamic-rates.component';

@NgModule({
  declarations: [DynamicRatesComponent],
  exports: [DynamicRatesComponent],
  imports: [
    CommonModule,
    TranslateModule,
    UploaderModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSelectModule,
    FormsModule,
    MatTooltipModule,
  ],
})
export class DynamicRatesModule {}
