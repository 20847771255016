<span class="info" data-automation="forecast-message">
  {{ 'scenario.form.forecast.info.part_1' | translate }}
  <a href="https://forecast-based-dispatch.enel.com/" target="_blank">
    {{ 'scenario.form.forecast.info.part_2' | translate }}
  </a>
</span>

<optima-loader
  id="forecast-loader"
  [translateString]="'scenario.form.forecast.updating'"
  [translateStringSubtext]="'scenario.form.forecast.updating.info'"
></optima-loader>
<optima-loader-no-http
  id="chunk-loader"
  [translateString]="chunkLoaderTranslateString"
  [translateStringSubtext]="chunkLoaderTranslateStringSubtext"
></optima-loader-no-http>
<optima-forecasted-energy
  [scenario]="scenario"
  (forecastChangedChunk)="forecastChangedChunk($event)"
  (forecastChanged)="forecastChanged($event)"
  [energyForecasts]="energyForecasts"
></optima-forecasted-energy>

<optima-forecasted-solar
  *ngIf="scenario.pvloadId && scenario.options.solarPv"
  [scenario]="scenario"
  (solarForecastChangedChunk)="solarForecastChangedChunk($event)"
  (solarForecastChanged)="solarForecastChanged($event)"
  [solarForecasts]="solarForecasts"
></optima-forecasted-solar>
