export interface SiteParameters {
  id: number;
  value: number;
}

export class MinSiteParameters implements SiteParameters {
  id: number;
  value: number;

  constructor() {
    this.id = 2;
    this.value = null;
  }
}

export class MaxSiteParameters implements SiteParameters {
  id: number;
  value: number;

  constructor() {
    this.id = 0;
    this.value = null;
  }
}
