import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { LoadProfileTableModule } from '../../shared/component/scenario/load-profile-table/load-profile-table.module';
import { ScenarioResultModule } from './scenario-result/scenario-result.module';
import { ScenarioSummaryModule } from './scenario-summary/scenario-summary.module';
import { ScenarioComponent } from './scenario.component';
import { InlineEditModule } from '@component';

@NgModule({
  declarations: [ScenarioComponent],
  exports: [ScenarioComponent],
  imports: [
    CommonModule,
    ScenarioSummaryModule,
    MatIconModule,
    MatProgressSpinnerModule,
    InlineEditModule,
    ReactiveFormsModule,
    MatRippleModule,
    TranslateModule,
    ScenarioResultModule,
    MatTooltipModule,
    NgxPopperjsModule,
    LoadProfileTableModule,
  ],
})
export class ScenarioModule {}
