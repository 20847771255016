export interface EssParameters {
  climate: string;
  costCycle: number;
  costSoc: number;
  distance: number;
  dod: number;
  export: boolean;
  reserve: number;
  pvCharge: boolean;
}

export class EssParameters {
  constructor() {
    this.climate = 'EXTREME';
    this.costCycle = 0.04;
    this.costSoc = 0;
    this.distance = 200;
    this.dod = 1;
    this.export = false;
    this.reserve = 0;
    this.pvCharge = true;
  }
}
