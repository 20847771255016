<div class="flex flex-col">
  <!--  HEADER  -->
  <div class="header">
    <h5 class="mb-2" data-automation="select-programs-heading">
      {{ 'scenario.form.grid.programs.select' | translate }}
    </h5>
    <div class="flex">
      <span class="description">
        {{ 'scenario.grid.programs.link.description' | translate }}
      </span>
      <span class="ml-1">
        <a
          class="pointer"
          target="_blank"
          href="https://enelcom.sharepoint.com/:x:/r/sites/EXNAFlexibilityLaunchandEM/_layouts/15/guestaccess.aspx?e=4%3AOprP6L&at=9&share=EQ-xQK3IsEFDsKX-UBfocHsBbLjaDRAt4uphGl-7E6FJ3A"
        >
          {{ 'scenario.grid.programs.link.definition' | translate }}
        </a>
      </span>
    </div>
  </div>

  <!--  COMPONENTS  -->
  <div class="flex flex-col">
    <!--  UPPER PART  -->
    <div class="mb-8">
      <optima-programs-search [custom]="toggle"></optima-programs-search>
    </div>
    <div class="flex justify-between grid-toggle-container" data-automation="grid-service-toggle">
        <div>
            <mat-button-toggle-group [(value)]="toggle" class="mt-2" appearance="legacy" attr.aria-label="{{ 'scenario.grid.programs.program_type' | translate }}">
                <mat-button-toggle value="standard" class="button-toggle" data-automation="standard-toggle-option">{{ 'scenario.grid.programs.standard' | translate }}</mat-button-toggle>
                <mat-button-toggle value="custom" class="button-toggle" data-automation="custom-toggle-option">{{ 'scenario.grid.programs.custom' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
       <div *ngIf="toggle === 'custom' && hasCustomPrograms()">
           <div class="flex file-label-container">
                <span data-automation="grid-uploader-file-label" class="file-label">{{ scenario.customProgramFilename }}</span>
               <mat-icon
                   data-automation="grid-uploader-remove-file"
                   svgIcon="clear-pink"
                   class="pointer"
                   (click)="removeCustomProgramFile()"
               ></mat-icon>
           </div>
       </div>
    </div>
      <optima-loader
          id="grid-service-loader"
          [translateString]="'loader.message.custom_programs'"
          data-automation="grid-service-loader"
      ></optima-loader>
    <div class="components-container">
        <ng-container [ngSwitch]="toggle">
            <div *ngSwitchCase="'standard'" class="program-list-container">
                <optima-programs-list [scenario]="scenario" [custom]="false" (scenarioEmitter)="scenarioEmitter($event)"></optima-programs-list>
            </div>
            <div *ngSwitchCase="'custom'" class="program-list-container">
                <ng-container *ngIf="hasCustomPrograms()">
                    <optima-programs-list [scenario]="scenario" [custom]="true" [customPrograms]="customPrograms" (scenarioEmitter)="scenarioEmitter($event)"
                    ></optima-programs-list>
                </ng-container>
                <div class="custom-grid-container" *ngIf="hasCustomPrograms() === false">
                    <div class="mb-4">
                        <span>{{ 'scenario.grid.programs.instructions' | translate }}</span>
                        <a title="Link to custom grid service instructions" target="_blank" aria-label="Link to custom grid service instructions"  href="https://confluence.springlab.enel.com/display/DERPM/Optima+User+Manual#OptimaUserManual-Customgridservicesprograms">here</a>
                        <span>{{ 'scenario.grid.programs.download' | translate }}</span>
                        <a title="Custom grid service template download" target="_blank" download="true" aria-label="Custom grid service template download" href="https://enelcom.sharepoint.com/sites/EnergyStorageSalesEngineering/Documents/Forms/AllItems.aspx?csf=1&web=1&e=JbpXxT&CID=acee02ea%2D68ba%2D4b4b%2Dbb1a%2D75df9ac4b117&FolderCTID=0x0120005DE31C26E60A1B479BCDB343FC09380F&isAscending=true&id=%2Fsites%2FEnergyStorageSalesEngineering%2FDocuments%2F04%5FModeling%2FOptima%20custom%20grid%20service%20template&sortField=LinkFilename&viewid=c2cf2cd8%2D1a3d%2D4d2d%2Da877%2Df8a80fcae80f">here</a>
                    </div>
                    <div class="upload-custom-program-container" data-automation="optima-file-upload-grid">
                        <optima-uploader
                          #uploaderPrograms
                          title="{{ 'uploader.programs.title' | translate }}"
                          subTitle="{{ 'uploader.programs.subtitle' | translate }}"
                          fileLabel="{{ 'uploader.programs.file_label' | translate }}"
                          [url]="scenarioServiceUrl + '/scenarios/' +  scenario.id + '/files/programs/custom-file'"
                          [createMethod]="'post'"
                          [accept]="'.xlsx'"
                          [object]="customPrograms"
                          (objectChange)="updatePrograms()"
                          [idField]="'id'"
                          [labelField]="'filename'"
                        ></optima-uploader>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="side-container">
            <div class="program-selected-container">
                <optima-programs-selected
                    [scenario]="scenario"
                    [customPrograms]="customPrograms"
                    (scenarioEmitter)="scenarioEmitter($event)"
                ></optima-programs-selected>
            </div>
            <div class="monte-carlo-container">
                <optima-programs-monte-carlo
                    [scenario]="scenario"
                ></optima-programs-monte-carlo>
            </div>
        </div>
    </div>

    <!--  ADVANCED PARAMETERS  -->
    <div class="flex flex-col w-full">
      <div
        data-automation="grid-service-adv-parameters"
        class="advanced-parameters-header"
        (click)="showAdvancedParameters = !showAdvancedParameters"
      >
        <img
          src="../../../../../../assets/svg-icons/arrow.svg"
          alt="arrow"
          class="mr-4"
          [class]="
            !showAdvancedParameters
              ? 'advanced-parameters-arrow-90'
              : 'advanced-parameters-arrow-180'
          "
        />
        <h5>{{ 'scenario.grid.advanced_parameters' | translate }}</h5>
      </div>

      <!--    FORM    -->
      <div *ngIf="showAdvancedParameters" [@inOut]>
        <optima-programs-parameters
          [scenario]="scenario"
        ></optima-programs-parameters>
      </div>
    </div>
  </div>
</div>
