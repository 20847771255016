import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectComponent } from './project.component';
import {
  DetailsModule,
  DialogModule,
  InlineEditModule,
  ProjectLoadProfileTableModule,
  ScenarioTableModule,
} from '@component';

@NgModule({
  declarations: [ProjectComponent],
  exports: [ProjectComponent],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    InlineEditModule,
    ReactiveFormsModule,
    TranslateModule,
    MatRippleModule,
    DetailsModule,
    ScenarioTableModule,
    ProjectLoadProfileTableModule,
    MatIconModule,
  ],
})
export class ProjectModule {}
