import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, publishReplay, refCount } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import {
  BillCharges,
  PvWatts,
  PvWattsArrayType,
  PvWattsModuleType,
  Scenario,
  SolarLoadProfile,
} from '@model';

@Injectable()
export class SolarPvService {
  private solarChargesCache = new Map<string, Observable<BillCharges[]>>();

  constructor(
    private alertService: GlobalAlertService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private resourceSolarLoadProfileService: ResourceService<SolarLoadProfile>,
    private resourceArrayTypeService: ResourceService<PvWattsArrayType>,
    private resourceModuleTypeService: ResourceService<PvWattsModuleType>,
    private chargesResourceService: ResourceService<BillCharges>,
  ) {}

  pvLoadProfiles$(projectId: string): Observable<SolarLoadProfile[]> {
    return this.resourceSolarLoadProfileService.getList(
      `projects/${projectId}/pv-loads`,
    );
  }

  pvLoadProfile$(pvLoadId: string): Observable<SolarLoadProfile> {
    return this.resourceSolarLoadProfileService.getById(
      'load-profiles',
      pvLoadId,
    );
  }

  getPvWattsArrayType$(): Observable<PvWattsArrayType[]> {
    return this.resourceArrayTypeService.getList(
      'reference/pv-watts-array-types',
    );
  }

  getPvWattsModuleType$(): Observable<PvWattsModuleType[]> {
    return this.resourceModuleTypeService.getList(
      'reference/pv-watts-module-types',
    );
  }

  createSolar(
    scenarioId: string,
    pvWatts: PvWatts,
  ): Observable<SolarLoadProfile> {
    return this.resourceSolarLoadProfileService.add(
      `scenarios/${scenarioId}/pv-watts`,
      pvWatts,
    );
  }

  getSolarCharges$(scenario: Scenario): Observable<BillCharges[]> {
    const KEY = `${scenario.id}${scenario.lastUpdatedDate}${scenario.runCompletedOn}`;
    if (!this.solarChargesCache.has(KEY)) {
      this.solarChargesCache.set(
        KEY,
        this.solarCharges$(scenario.id).pipe(shareReplay(1)),
      );
    }
    return this.solarChargesCache.get(KEY);
  }

  private solarCharges$(scenarioId: string): Observable<BillCharges[]> {
    return this.chargesResourceService.getList(
      `scenarios/${scenarioId}/after-pv-charges`,
    );
  }
}
