import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { CreateSolarComponent } from './create-solar.component';
import { DirectiveModule } from '@directive';

@NgModule({
  declarations: [CreateSolarComponent],
  exports: [CreateSolarComponent],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    DirectiveModule,
    MatCheckboxModule,
    FormsModule,
  ],
})
export class CreateSolarModule {}
