<ng-container *ngIf="!loaderService.isLoading">
  <div
    class="loader-overlay"
    data-automation="loader-overlay"
    [style.opacity]="opacity"
  ></div>
  <div
    class="flex flex-col justify-start items-center loader-container"
    data-automation="loader"
  >
    <mat-spinner diameter="60"></mat-spinner>
    <h2 id="loader-title">{{ translateString | translate }}</h2>
    <h3 id="loader-sub-title">
      {{ translateStringSubtext | translate }}
    </h3>
  </div>
</ng-container>
