import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'printUnit',
})
export class PrintUnitPipe implements PipeTransform {
  constructor(private translationService: TranslateService) {}

  transform(value: string, translationKey: string, print: boolean): string {
    if (value === null) {
      return '-';
    }
    if (!print) {
      return value;
    }
    const label: string = this.translationService.instant(translationKey);
    const unit = label.slice(label.indexOf('(') + 1, label.indexOf(')'));
    return `${value} ${unit}`;
  }
}
