import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NoCommaPipe } from '../../../pipe/no-comma.pipe';
import { SiteConstraintComponent } from './site-constraint.component';

@NgModule({
  declarations: [SiteConstraintComponent],
  exports: [SiteConstraintComponent],
  imports: [TranslateModule],
  providers: [DecimalPipe, NoCommaPipe],
})
export class SiteConstraintModule {}
