import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderNoHttpComponent } from './loader-no-http.component';

@NgModule({
  declarations: [LoaderNoHttpComponent],
  exports: [LoaderNoHttpComponent],
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
})
export class LoaderNoHttpModule {}
