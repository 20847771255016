import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UploaderService } from './uploader.service';
import { GlobalAlertService } from '@service';

@Component({
  selector: 'optima-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent<T> implements OnChanges {
  @Input() title: string;
  @Input() fileLabel: string;
  @Input() subTitle: string;
  @Input() multiple = false;
  @Input() url: string;
  @Input() createMethod: 'post' | 'put';
  @Input() accept: string;
  @Input() formDataParameters: Map<string, string>;
  @Input() object: T | T[];
  @Output() objectChange = new EventEmitter<T | T[]>();
  @Input() idField: string;
  @Input() labelField: string;
  @Input() errorMessageTranslation?: string;
  @Input() disabled: boolean;
  @Input() fileJustify: 'justify-start' | 'justify-end' = 'justify-end';
  @Input() chunk = false;
  @ViewChild('file') file: ElementRef;
  @ViewChild('uploader') uploader: ElementRef;
  showFiles = false;
  showError = false;
  showLoader = false;
  uploading = false;

  constructor(
    private globalAlertService: GlobalAlertService,
    public uploadService: UploaderService<T>,
    private renderer: Renderer2,
    private translateService: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.checkForExist();
  }

  addFiles(): void {
    if (this.disabled) {
      return;
    }
    this.renderer.addClass(this.uploader.nativeElement, 'uploader-drag');
    setTimeout(
      () =>
        this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag'),
      200,
    );
    this.file.nativeElement.click();
  }

  onFilesAdded(droppedFile?: File): void {
    if (!droppedFile) {
      droppedFile = this.file.nativeElement.files[0];
    }
    if (droppedFile && !this.validateFile(droppedFile)) {
      const errorMessage = `${this.translateService.instant('uploader.files_not_accepted')}: ${droppedFile.name}`;
      this.globalAlertService.setError(errorMessage);
      return;
    }
    void this.uploadFile(droppedFile);
  }

  async uploadFile(droppedFile: File): Promise<void> {
    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    const progress = await this.uploadService.upload(
      droppedFile,
      this.url,
      this.createMethod,
      this.chunk,
      this.formDataParameters,
    );

    progress.subscribe({
      next: () => {
        this.uploading = false;
        this.showLoader = true;
        if (this.chunk) {
          this.object = this.uploadService.object;
          this.objectChange.emit(this.object);
        }
      },
      error: (err: unknown) => {
        const error = this.errorMessageTranslation
          ? this.translateService.instant(this.errorMessageTranslation)
          : err.toString();
        this.globalAlertService.setError(error);
        this.showError = true;
      },
      complete: () => {
        this.clear();
        this.object = this.uploadService.object;
        this.objectChange.emit(this.object);
      },
    });
  }

  /**
   * Clear file input and checks for files.
   */
  clear(): void {
    this.showError = false;
    this.showLoader = false;
    this.file.nativeElement.value = '';
  }

  remove(id: string): void {
    if (this.multiple) {
      this.object = (<T[]>this.object).map(o => {
        if (o[this.idField] === id) {
          return null;
        }
        return o;
      });
      this.object = this.object.filter(o => o);
      if (!this.object.length) {
        this.clear();
        this.showFiles = false;
      }
    } else {
      this.clear();
      this.object = null;
      this.showFiles = false;
    }
    this.objectChange.emit(this.object);
  }

  checkForExist(): void {
    if (!this.object) {
      this.showFiles = false;
    }
    if (this.multiple) {
      this.object = this.object as T[];
      this.object = this.object?.filter(o => o[this.idField]);
      if (this.object?.length) {
        this.showFiles = true;
        this.showLoader = false;
      }
    } else {
      this.object = this.object as T;
      if (this.object && this.object[this.idField]) {
        this.showFiles = true;
        this.showLoader = false;
      }
    }
  }

  dragOver(dragEvent: DragEvent): void {
    if (this.disabled) {
      return;
    }
    dragEvent.preventDefault();
  }

  dragEnter(dragEvent: DragEvent): void {
    if (this.disabled) {
      return;
    }
    dragEvent.preventDefault();
    this.renderer.addClass(this.uploader.nativeElement, 'uploader-drag');
    dragEvent.dataTransfer.dropEffect = 'copy';
  }

  dragLeave(dragEvent: DragEvent): void {
    if (this.disabled) {
      return;
    }
    dragEvent.preventDefault();
    this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag');
  }

  filesDrop(dragEvent: DragEvent): void {
    if (this.disabled) {
      return;
    }
    dragEvent.preventDefault();
    this.renderer.removeClass(this.uploader.nativeElement, 'uploader-drag');
    this.showLoader = true;
    const files = dragEvent.dataTransfer.files;
    if (files.length > 0) {
      this.onFilesAdded(files[0]);
    }
  }

  validateFile(file: File): boolean {
    const fileExtension = file.name.split('.').pop();
    return this.accept.includes(fileExtension);
  }
}
