import { TranslateService } from '@ngx-translate/core';
import { Username } from '@model';

export const fullname = (
  translateService: TranslateService,
  username: Username,
): string => {
  if (!username) {
    return '';
  }
  return translateService.instant('user.full.name', {
    first: username.firstName ?? username['first_name'],
    last: username.lastSystemName ?? username['last_system_name'],
  });
};
