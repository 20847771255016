import { formatCurrency } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';
import { Result } from '@model';

@Component({
  selector: 'optima-savings-revenues-treemap',
  templateUrl: './result-treemap.component.html',
  styleUrls: ['./result-treemap.component.scss'],
})
export class ResultTreemapComponent implements AfterViewInit, OnChanges {
  @Input() result: Result;
  chart: Highcharts.Chart;
  chartOptions: Highcharts.Options = {};
  @Input() chartId: string;
  constructor(private translateService: TranslateService) {
    HighchartsTreemap(Highcharts);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.renderChart();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.renderChart();
  }

  private setData(result: Result) {
    const data = [
      {
        id: 'solarSavings',
        name: this.translateService.instant('scenario.results.solar_savings'),
        color: '#EB5D19',
      },
      {
        id: 'storageSavings',
        name: this.translateService.instant('scenario.results.storage_savings'),
        color: '#003768',
      },
      {
        id: 'tariffSwitch',
        name: this.translateService.instant(
          'scenario.results.tariff_switch_savings',
        ),
        color: '#127679',
      },
      {
        id: 'programs',
        name: this.translateService.instant(
          'scenario.results.revenue.grid_services',
        ),
        color: '#AB186C',
      },
      // STORAGE SAVINGS
      {
        id: 'storageTariffSavings',
        parent: 'storageSavings',
        name: this.translateService.instant(
          'scenario.results.savings.storage.energy',
        ),
        value: result.storageEnergyChargesSavings
          ? Math.abs(result.storageEnergyChargesSavings)
          : 0,
        text: result.storageEnergyChargesSavings < 0 ? '-' : '',
      },
      {
        id: 'storageDynamicSavings',
        parent: 'storageSavings',
        name: this.translateService.instant(
          'scenario.results.savings.storage.dynamic_storage_savings',
        ),
        value: result.dynamicStorageSavings
          ? Math.abs(result.dynamicStorageSavings)
          : 0,
        text: result.dynamicStorageSavings < 0 ? '-' : '',
      },
      {
        id: 'storageDemandSavings',
        parent: 'storageSavings',
        name: this.translateService.instant(
          'scenario.results.savings.storage.demand',
        ),
        value: result.storageDemandChargesSavings
          ? Math.abs(result.storageDemandChargesSavings)
          : 0,
        text: result.storageDemandChargesSavings < 0 ? '-' : '',
      },
      // SOLAR SAVINGS
      {
        id: 'solarDemandSavings',
        parent: 'solarSavings',
        name: this.translateService.instant(
          'scenario.results.savings.solar.demand',
        ),
        value: result.solarDemandChargesSavings
          ? Math.abs(result.solarDemandChargesSavings)
          : 0,
        text: result.solarDemandChargesSavings < 0 ? '-' : '',
      },
      {
        id: 'solarDynamicSavings',
        parent: 'solarSavings',
        name: this.translateService.instant(
          'scenario.results.savings.solar.dynamic_solar_savings',
        ),
        value: result.dynamicSolarSavings
          ? Math.abs(result.dynamicSolarSavings)
          : 0,
        text: result.dynamicSolarSavings < 0 ? '-' : '',
      },
      {
        id: 'solarEnergySavings',
        parent: 'solarSavings',
        name: this.translateService.instant(
          'scenario.results.savings.solar.energy',
        ),
        value: result.solarEnergyChargesSavings
          ? Math.abs(result.solarEnergyChargesSavings)
          : 0,
        text: result.solarEnergyChargesSavings < 0 ? '-' : '',
      },
      // TARIFF SWITCH SAVINGS
      {
        id: 'tariffSwitchSavings',
        parent: 'tariffSwitch',
        name: this.translateService.instant(
          'scenario.results.savings.tariff_switch',
        ),
        value: result.tariffSwitchSavings
          ? Math.abs(result.tariffSwitchSavings)
          : 0,
        text: result.tariffSwitchSavings < 0 ? '-' : '',
      },
    ];

    this.result.gridServicesRevenuesFromPrograms?.forEach(program => {
      if (program.revenue) {
        data.push({
          id: program.id,
          parent: 'programs',
          name: program.programName,
          value: Math.abs(program.revenue),
          text: program.revenue < 0 ? '-' : '',
        });
      }
    });

    return data;
  }

  renderChart(): void {
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        renderTo: 'chart',
        margin: 0,
        width: 525,
        height: 300,
      },
      title: {
        text: null,
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject): string {
          return this.point.options.value
            ? `<b> ${this.point.name}</b>: 
            <b> ${this.point.options.text}${formatCurrency(
              this.point.options.value,
              'en-US',
              '$',
              'USD',
            )}</b>`
            : undefined;
        },
        useHTML: true,
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'stripes',
          alternateStartingDirection: true,
          // borderColor: '#fff',
          borderRadius: 0,
          // borderWidth: 0,
          dataLabels: {
            style: {
              textOutline: 'none',
            },
          },
          levels: [
            {
              level: 1,
              layoutAlgorithm: 'sliceAndDice',
              dataLabels: {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                },
              },
            },
            {
              level: 2,
              borderColor: '#fff',
              borderWidth: 0.5,
            },
          ],
          data: this.setData(this.result),
        },
      ],
    };

    this.chart = Highcharts.chart(this.chartId, this.chartOptions);
  }
}
