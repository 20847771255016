<div
  class="table-container"
  data-automation="results-savings-revenue-table"
  [ngStyle]="{ 'overflow-y': isFirefox ? 'auto' : 'overlay' }"
>
  <table>
    <thead *ngIf="showForecastResult">
      <tr>
        <th class="color-column"></th>
        <th></th>
        <th class="position-relative">
          {{ 'scenario.results.forecast.historical' | translate }}
          <mat-icon
            class="pop-icon"
            matTooltip="{{
              'scenario.results.forecast.historical.tooltip' | translate
            }}"
            svgIcon="general-info"
            alt="general-info"
          ></mat-icon>
          <mat-icon
            class="arrow-icon"
            svgIcon="arrow-down"
            alt="sort-arrow"
            (click)="
              showHistoricalSort = !showHistoricalSort;
              showForecastedSort = false
            "
            [style.transform]="
              showHistoricalSort ? 'rotate(180deg)' : 'rotate(0deg)'
            "
          ></mat-icon>
          <div *ngIf="showHistoricalSort" class="sort-super-container">
            <ng-container
              *ngTemplateOutlet="
                sorting;
                context: {
                  who: 'historical',
                }
              "
            ></ng-container>
          </div>
        </th>
        <th
          class="position-relative"
          *ngIf="forecastResult?.runState === RunState.COMPLETED"
        >
          {{ 'scenario.results.forecast.forecasted' | translate }}
          <mat-icon
            class="pop-icon"
            matTooltip="{{
              'scenario.results.forecast.forecasted.tooltip' | translate
            }}"
            svgIcon="general-info"
            alt="general-info"
          ></mat-icon>
          <mat-icon
            class="arrow-icon"
            svgIcon="arrow-down"
            alt="sort-arrow"
            (click)="
              showForecastedSort = !showForecastedSort;
              showHistoricalSort = false
            "
            [style.transform]="
              showForecastedSort ? 'rotate(180deg)' : 'rotate(0deg)'
            "
          ></mat-icon>
          <div *ngIf="showForecastedSort" class="sort-super-container">
            <ng-container
              *ngTemplateOutlet="
                sorting;
                context: {
                  who: 'forecasted',
                }
              "
            ></ng-container>
          </div>
        </th>
        <th *ngIf="showForecastResult">
          % {{ 'scenario.results.forecast.change' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let property of getResultProperties()">
        <tr
          *ngIf="
            (result && isNumber(result[property])) ||
            (forecastResult && isNumber(forecastResult[property])) ||
            propertiesMap.get(property)[2] === 'true'
          "
        >
          <ng-container *ngIf="propertiesMap.get(property)[2] !== 'true'">
            <td
              [attr.data-automation]="propertiesMap.get(property)[5]"
              class="colored-tab colored-tab-{{
                propertiesMap.get(property)[0]
              }}"
            ></td>
            <td class="saving-revenue-label">
              {{ propertiesMap.get(property)[1] | translate }}
            </td>
            <td
              [style.text-align]="showForecastResult ? 'left' : 'right'"
              data-automation="results-saving-revenue-value"
            >
              {{ result[property] | currency }}
            </td>
            <td
              *ngIf="showForecastResult"
              data-automation="forecast-results-saving-revenue-value"
            >
              {{ forecastResult[property] | currency }}
            </td>
            <td
              *ngIf="showForecastResult"
              class="change-column"
              data-automation="change-results-saving-revenue-value"
            >
              <ng-container
                *ngTemplateOutlet="
                  changeTemplate;
                  context: {
                    resultVal: result[property],
                    forecastVal: forecastResult[property],
                  }
                "
              ></ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="propertiesMap.get(property)[2] === 'true'">
            <td
              [attr.data-automation]="propertiesMap.get(property)[5]"
              class="colored-tab colored-tab-{{
                propertiesMap.get(property)[0]
              }}"
            ></td>
            <td
              data-automation="results-grid-name"
              class="saving-revenue-label"
            >
              {{ propertiesMap.get(property)[1] | translate }}
              <br />
              {{
                getProgramById(
                  result?.gridServicesRevenuesFromPrograms,
                  property
                )?.programName
              }}
            </td>
            <td
              [style.text-align]="showForecastResult ? 'left' : 'right'"
              data-automation="results-saving-revenue-value"
            >
              {{
                getProgramById(
                  result?.gridServicesRevenuesFromPrograms,
                  property
                )?.revenue | currency
              }}
            </td>
            <td
              *ngIf="showForecastResult"
              data-automation="forecast-results-saving-revenue-value"
            >
              {{
                getProgramById(
                  forecastResult?.gridServicesRevenuesFromPrograms,
                  property
                )?.revenue | currency
              }}
            </td>
            <td
              *ngIf="showForecastResult"
              data-automation="change-results-saving-revenue-value"
            >
              <ng-container
                *ngTemplateOutlet="
                  changeTemplate;
                  context: {
                    resultVal: getProgramById(
                      result?.gridServicesRevenuesFromPrograms,
                      property
                    )?.revenue,
                    forecastVal: getProgramById(
                      forecastResult?.gridServicesRevenuesFromPrograms,
                      property
                    )?.revenue,
                  }
                "
              ></ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td class="footer"></td>
        <td class="footer">{{ 'scenario.results.total' | translate }}</td>
        <td
          class="footer"
          [style.text-align]="showForecastResult ? 'left' : 'right'"
          data-automation="results-saving-revenue-value"
        >
          {{ result?.totalSavingsAndRevenues | currency }}
        </td>
        <td
          *ngIf="showForecastResult"
          class="footer"
          data-automation="forecast-results-saving-revenue-value"
        >
          {{ forecastResult?.totalSavingsAndRevenues | currency }}
        </td>
        <td
          *ngIf="showForecastResult"
          class="footer"
          data-automation="total-change"
        >
          <ng-container
            *ngTemplateOutlet="
              changeTemplate;
              context: {
                resultVal: result?.totalSavingsAndRevenues,
                forecastVal: forecastResult?.totalSavingsAndRevenues,
              }
            "
          ></ng-container>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template
  #changeTemplate
  let-resultVal="resultVal"
  let-forecastVal="forecastVal"
>
  <span
    [ngClass]="{
      green: !getChange(resultVal, forecastVal).includes('-'),
      red: getChange(resultVal, forecastVal).includes('-'),
    }"
    class="flex items-center"
  >
    <mat-icon
      [svgIcon]="
        getChange(resultVal, forecastVal).includes('-')
          ? 'arrow-red'
          : 'arrow-green'
      "
      class="arrow mr-2"
    ></mat-icon>
    {{ getChange(resultVal, forecastVal) }}
  </span>
</ng-template>

<ng-template #sorting let-who="who">
  <div class="sorting-container">
    {{ 'scenario.results.forecast.sort' | translate }}
    <mat-radio-group
      (ngModelChange)="sort(who, $event)"
      [ngModel]="who === 'historical' ? historicalSort : forecastedSort"
    >
      <mat-radio-button class="flex flex-col mb-2" [value]="0">
        {{ 'scenario.results.forecast.default' | translate }}
      </mat-radio-button>
      <mat-radio-button class="flex flex-col mb-2" [value]="1">
        {{ 'scenario.results.forecast.low_high' | translate }}
      </mat-radio-button>
      <mat-radio-button class="flex flex-col" [value]="2">
        {{ 'scenario.results.forecast.high_low' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</ng-template>
