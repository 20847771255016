import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ForecastedSolarComponent } from './forecasted-solar.component';
import { UploaderModule } from '@component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [ForecastedSolarComponent],
  exports: [ForecastedSolarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyButtonModule,
    MatIconModule,
    PipeModule,
    UploaderModule,
    NgOptimizedImage,
    MatTooltipModule,
  ],
})
export class ForecastedSolarModule {}
