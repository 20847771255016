<div class="dynamics-energy-rate-container">
  <mat-button-toggle-group
    appearance="legacy"
    name="dynamic-rate-tabs"
    aria-label="tabs"
    [(ngModel)]="activeMode"
  >
    <mat-button-toggle
      data-automation="dynamic-rate-upload-button"
      class="button-toggle"
      [ngClass]="{ active: activeMode === 'upload' }"
      [matTooltip]="disabledButtonUploadTooltipMessage"
      value="upload"
      [disabled]="hasStoredRate()"
      [matTooltipDisabled]="!hasStoredRate()"
    >
      {{ 'scenario.dynamic_rates.tab.upload' | translate }}
    </mat-button-toggle>
    <mat-button-toggle
      data-automation="dynamic-rate-stored-button"
      class="button-toggle"
      [ngClass]="{ active: activeMode === 'stored' }"
      [matTooltip]="disabledButtonStoredTooltipMessage"
      [matTooltipDisabled]="!hasDynamicUploadFile()"
      value="stored"
      [disabled]="hasDynamicUploadFile()"
    >
      {{ 'scenario.dynamic_rates.tab.stored' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="line"></div>
  <div
    id="dynamic-upload-section"
    class="tab-content"
    *ngIf="activeMode === 'upload'"
  >
    <div class="import-rate-upload">
      <h5 class="dynamic-rate-label">
        {{ 'scenario.tariff.dynamic.import' | translate }}
      </h5>
      <optima-uploader
        title="{{ 'uploader.tariff.dynamic.import.title' | translate }}"
        fileLabel="{{
          'uploader.tariff.dynamic.import.file_label' | translate
        }}"
        [url]="scenarioService.apiUrl + '/tariffs'"
        [createMethod]="'put'"
        [accept]="'.csv'"
        [formDataParameters]="importFormDataKeyValue"
        [object]="importRate"
        (objectChange)="updateDynamicRate($event, true)"
        [idField]="'id'"
        [labelField]="'filename'"
      ></optima-uploader>
    </div>
    <div class="export-rate-upload">
      <h5 class="dynamic-rate-label">
        {{ 'scenario.tariff.dynamic.export' | translate }}
      </h5>
      <optima-uploader
        title="{{ 'uploader.tariff.dynamic.export.title' | translate }}"
        fileLabel="{{
          'uploader.tariff.dynamic.export.file_label' | translate
        }}"
        [url]="scenarioService.apiUrl + '/tariffs'"
        [createMethod]="'put'"
        [accept]="'.csv'"
        [formDataParameters]="exportFormDataKeyValue"
        [object]="exportRate"
        (objectChange)="updateDynamicRate($event, false)"
        [idField]="'id'"
        [labelField]="'filename'"
      ></optima-uploader>
    </div>
  </div>
  <div
    data-automation="dynamic-rate-stored-section"
    class="tab-content"
    *ngIf="activeMode === 'stored'"
  >
    <div class="import-rate-select">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="selectedStoredImportRate">
          {{ 'scenario.tariff.stored.import_label' | translate }}
        </mat-label>
        <mat-label *ngIf="!selectedStoredImportRate">
          {{ 'scenario.form.stored_rate.no_select_import' | translate }}
        </mat-label>
        <mat-select
          panelClass="dynamic-rate-panel"
          [disableOptionCentering]="true"
          [(value)]="selectedStoredImportRate"
          (selectionChange)="selectRate($event.value, true)"
        >
          <div class="flex items-center mat-select-search-input-container">
            <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
            <input
              class="mat-select-search-input"
              (keyup)="filterImportOptions()"
              placeholder="{{ 'project.search' | translate }}"
              #searchImport
            />
          </div>
          <div class="deselect-border-style">
            <mat-option id="import-no-value" [value]="null">
              {{ 'scenario.form.select.deselect' | translate }}
            </mat-option>
          </div>
          <mat-option
            *ngFor="let importRate of filteredImportRates"
            [value]="importRate"
            id="{{ 'import-' + importRate }}"
          >
            {{ importRate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="export-rate-select">
      <mat-form-field appearance="outline">
        <mat-label *ngIf="selectedStoredExportRate">
          {{ 'scenario.tariff.stored.export_label' | translate }}
        </mat-label>
        <mat-label *ngIf="!selectedStoredExportRate">
          {{ 'scenario.form.stored_rate.no_select_export' | translate }}
        </mat-label>
        <mat-select
          panelClass="dynamic-rate-panel"
          [disableOptionCentering]="true"
          [(value)]="selectedStoredExportRate"
          (selectionChange)="selectRate($event.value, false)"
        >
          <div class="flex items-center mat-select-search-input-container">
            <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
            <input
              class="mat-select-search-input"
              (keyup)="filterExportOptions()"
              placeholder="{{ 'project.search' | translate }}"
              #searchExport
            />
          </div>
          <div class="deselect-border-style">
            <mat-option id="export-no-value" [value]="null">
              {{ 'scenario.form.select.deselect' | translate }}
            </mat-option>
          </div>
          <mat-option
            *ngFor="let exportRate of filteredExportRates"
            [value]="exportRate"
            id="{{ 'export-' + exportRate }}"
          >
            {{ exportRate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
