import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { UploaderModule } from '../../../global/uploader/uploader.module';
import { TariffTableModule } from '../../tariff-table/tariff-table.module';
import { DynamicRatesModule } from '../dynamic-rates/dynamic-rates.module';
import { TariffComponent } from './tariff.component';
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [TariffComponent],
  exports: [TariffComponent],
  imports: [
    CommonModule,
    DynamicRatesModule,
    TranslateModule,
    UploaderModule,
    TariffTableModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    MatTooltipModule,
    MatIconModule
  ]
})
export class TariffModule {}
