import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GridComponent } from './grid.component';
import { ProgramsListModule } from './programs-list/programs-list.module';
import { ProgramsMonteCarloModule } from './programs-monte-carlo/programs-monte-carlo.module';
import { ProgramsParametersModule } from './programs-parameters/programs-parameters.module';
import { ProgramsSearchModule } from './programs-search/programs-search.module';
import { ProgramsSelectedModule } from './programs-selected/programs-selected.module';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { UploaderModule } from "@component";
import { MatIconModule } from "@angular/material/icon";
import { LoaderModule } from "../../../global/loader/loader.module";

@NgModule({
  declarations: [GridComponent],
  exports: [GridComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ProgramsListModule,
    ProgramsSelectedModule,
    ProgramsMonteCarloModule,
    ProgramsSearchModule,
    ProgramsParametersModule,
    MatButtonToggleModule,
    UploaderModule,
    MatIconModule,
    LoaderModule
  ]
})
export class GridModule {}
