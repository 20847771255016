import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { GlobalAlertService } from './global-alert.service';
import { LoaderService } from './loader.service';
import { ResourceService } from './resource.service';
import { DialogComponent } from '@component';
import { sum } from '@helper';
import {
  DialogConfig,
  Project,
  Scenario,
  ScenarioOptions,
  ScenarioUpdateEnum,
} from '@model';

@Injectable()
export class ScenarioService {
  private _scenarios: Scenario[] = [];
  get scenarios(): Scenario[] {
    return this._scenarios;
  }

  private readonly scenariosChangeEmitter = new Subject<boolean | void>();
  scenariosChange$ = this.scenariosChangeEmitter.asObservable();

  private readonly deleteScenarioEmitter = new Subject<Scenario>();
  deleteScenarioStream$ = this.deleteScenarioEmitter.asObservable();

  apiUrl = ResourceService.API_LOCATION;
  runDisabledTranslationKeys: string[];
  private dialogRef: MatDialogRef<DialogComponent>;
  private scenarioToDelete: Scenario;
  scenarioDeleted: Scenario;

  constructor(
    private resourceService: ResourceService<Scenario>,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alertService: GlobalAlertService,
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  getScenarios$(projectId: number | string): Observable<Scenario[]> {
    return this.resourceService
      .getList(`scenarios?project_id=${projectId}`)
      .pipe(
        tap(scenarios => {
          this._scenarios = this._scenarios.map(
            scenario => scenarios.find(s => s.id === scenario.id) || scenario,
          );
          this.scenariosChangeEmitter.next();
        }),
      );
  }

  getAllScenarios$(): Observable<Scenario[]> {
    return this.resourceService.getList(`scenarios`).pipe(
      tap(scenarios => {
        this._scenarios = [...scenarios];
        this.scenariosChangeEmitter.next(true);
      }),
      catchError((error: unknown) => {
        this.alertService.setError(error.toString());
        this.scenariosChangeEmitter.error(error);
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  getScenario$(scenarioId: number | string): Observable<Scenario> {
    return this.resourceService.getById('scenarios', scenarioId).pipe(
      tap(scenario => {
        const scenarioIndex = this._scenarios.findIndex(
          c => c.id === scenario.id,
        );
        if (scenarioIndex === -1) {
          this._scenarios.unshift(scenario);
        } else {
          this._scenarios[scenarioIndex] = scenario;
        }
        this.scenariosChangeEmitter.next();
      }),
    );
  }

  createScenario$(
    scenario: Scenario | Partial<Scenario>,
  ): Observable<Scenario> {
    this.loaderService.changeLabel('');
    delete scenario.options;
    return this.resourceService
      .add(`scenarios?project_id=${scenario.parentId}`, scenario)
      .pipe(
        tap(scenario => {
          this._scenarios.unshift(scenario);
          this.scenariosChangeEmitter.next();
          this.loaderService.changeLabel(null);
        }),
      );
  }

  updateScenario$(
    id: string | number,
    scenario: Scenario | Partial<Scenario>,
    scenarioUpdateType?: ScenarioUpdateEnum,
  ): Observable<Scenario> {
    if (scenarioUpdateType === ScenarioUpdateEnum.RENAME) {
      this.loaderService.changeLabel('');
      this.loaderService.showLoader('global');
    }
    const scenarioClone: Partial<Scenario> = { ...scenario };
    delete scenarioClone.loadProfile;
    delete scenarioClone.pvloadProfile;
    delete scenarioClone.adjustedPvLoadProfile;
    delete scenarioClone.options;
    delete scenarioClone.currentTariff;
    delete scenarioClone.switchTariff;
    return this.resourceService.update('scenarios', id, scenarioClone).pipe(
      tap(scenario => {
        const scenarioIndex = this._scenarios.findIndex(
          s => s.id === scenario.id,
        );
        this._scenarios[scenarioIndex] = scenario;
        this.scenariosChangeEmitter.next();
        if (scenarioUpdateType === ScenarioUpdateEnum.RENAME) {
          this.loaderService.changeLabel(null);
          this.loaderService.resetPreviousLoaders();
        }
      }),
    );
  }

  updateScenarioOptions$(
    options: ScenarioOptions,
    scenarioId: string,
  ): Observable<Scenario> {
    const params = new HttpParams()
      .set('tariff_switch', options.tariffSwitch)
      .set('energy_storage', options.energyStorage)
      .set('solar_pv', options.solarPv)
      .set('grid_service', options.gridService)
      .set('forecast', options.forecast);
    return this.resourceService.update(
      'scenarios',
      `${scenarioId}/options`,
      null,
      params,
    );
  }

  scenarioChange(): void {
    this.scenariosChangeEmitter.next();
  }

  cloneScenario$(scenarioId: number | string): Observable<Scenario> {
    return this.resourceService.get(`scenarios/${scenarioId}/clone`);
  }

  deleteScenario$(id: string): Observable<Scenario> {
    return this.resourceService.delete('scenarios', id).pipe(
      tap(scenario => {
        const scenarioIndex = this._scenarios.findIndex(
          s => s.id === scenario.id,
        );
        this._scenarios.splice(scenarioIndex, 1);
        this.scenariosChangeEmitter.next();
      }),
    );
  }

  runScenario$(scenarioId: string): Observable<Scenario> {
    return this.resourceService.add(`scenarios/${scenarioId}/run`, null);
  }

  openDeleteProjectDialog(scenario: Scenario): void {
    this.scenarioToDelete = scenario;
    const config: DialogConfig = {
      title: this.translate.instant('dialog.scenario.delete.title'),
      contentText: this.translate.instant('dialog.scenario.delete.content'),
      firstButtonText: this.translate.instant(
        'dialog.scenario.delete.first_button',
      ),
      secondButtonText: this.translate.instant(
        'dialog.scenario.delete.second_button',
      ),
      firstButtonCallback: this.deleteScenarioCallback,
      secondButtonCallback: this.closeDialogCallback,
    };
    this.dialogRef = this.dialogService.openDialog(config, { width: '600px' });
  }

  deleteScenarioCallback = (): void => {
    this.deleteScenario$(this.scenarioToDelete.id).subscribe({
      error: () => {
        this.dialogRef.close();
        const message = this.translate.instant('scenario.delete.fail');
        this.alertService.setError(
          `${message} ${this.scenarioToDelete.displayLabel}`,
        );
        this.scenarioToDelete = undefined;
      },
      next: () => {
        this.dialogRef.close();
        const message = this.translate.instant('scenario.delete.success');
        this.alertService.setSuccess(
          `${message} ${this.scenarioToDelete.displayLabel}`,
        );
        this.router.navigateByUrl(
          `details/${this.scenarioToDelete.parentId}/view-project`,
        );
        this.deleteScenarioEmitter.next(this.scenarioToDelete);
        this.scenarioDeleted = this.scenarioToDelete;
        this.scenarioToDelete = undefined;
        this.scenariosChangeEmitter.next();
      },
    });
  };

  closeDialogCallback = (): void => {
    this.dialogRef.close();
  };

  runEnabled(scenario: Scenario, project: Project): boolean {
    this.runDisabledTranslationKeys = [];
    if (!project.location?.address) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.project',
      );
    }
    if (!scenario.loadprofileId) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.load_profile',
      );
    }
    const tswitch = scenario.options?.tariffSwitch;
    const ess = scenario.options?.energyStorage;
    const solarpv = scenario.options?.solarPv;
    const grid = scenario.options?.gridService;
    if (grid && !ess) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.grid_but_ess',
      );
    }
    if (grid && !scenario.programs?.standard.length && !scenario.programs?.custom.length) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.grid_but_programs',
      );
    }
    if (ess && !scenario.ders) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.grid_but_ess',
      );
    }
    if (ess && !scenario.ders?.length) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.energy_switch_but_size',
      );
    }
    if (tswitch && !scenario.switchTariff?.id) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.tariff_switch_but_file',
      );
    }
    if (!scenario.currentTariff?.id) {
      this.runDisabledTranslationKeys.push('scenario.results.run.error.tariff');
    }
    if (solarpv && !scenario.pvloadId) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.solar_switch_but_file',
      );
    }
    if (!ess && !tswitch && !solarpv) {
      this.runDisabledTranslationKeys.push(
        'scenario.results.run.error.tariff_energy_solar',
      );
    }
    return !!!this.runDisabledTranslationKeys.length;
  }

  getScenarioIdFromUrl(): string {
    const match = window.location.href.match(
      /\/details\/([a-zA-Z0-9_.-]+)\/(view|edit|create)-scenario/,
    );
    if (match) {
      return match[1];
    }
    return null;
  }

  hasDers(scenario: Scenario): boolean {
    return (
      sum(scenario.ders?.map(der => sum(der.ess.map(s => s.qty)))) > 0 ||
      scenario.ders.some(der => der.custom)
    );
  }
}
