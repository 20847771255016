import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { ScenarioService } from './scenario.service';
import { ForecastLoadProfile, Scenario } from '@model';

@Injectable()
export class ForecastService {
  constructor(
    private scenarioService: ScenarioService,
    private forecastDispatch: ResourceService<ForecastLoadProfile>,
    private forecastRun: ResourceService<void>,
  ) {}

  isForecastedStepDisabled(scenario: Scenario): boolean {
    return (
      !scenario?.options?.energyStorage ||
      !this.scenarioService.hasDers(scenario) ||
      !scenario?.loadprofileId ||
      (scenario?.options?.gridService &&
        scenario?.gridParameters?.montecarloIterations > 1)
    );
  }

  isThereForecasted(scenario: Scenario): boolean {
    return scenario?.options?.forecast && !!scenario?.forecastLoadId;
  }

  getEnergyForecasts(projectId: string): Observable<ForecastLoadProfile[]> {
    return this.forecastDispatch.getList(
      `projects/${projectId}/forecasts?type=METER`,
    );
  }

  getSolarForecasts(projectId: string): Observable<ForecastLoadProfile[]> {
    return this.forecastDispatch.getList(
      `projects/${projectId}/forecasts?type=PV`,
    );
  }

  forecastDispatch$(id: string): Observable<ForecastLoadProfile> {
    return this.forecastDispatch.get(`forecast-dispatch/${id}`);
  }

  runForecast$(id: string, idx: number): Observable<void> {
    return this.forecastRun.add(`scenarios/${id}/run-forecast?idx=${idx}`);
  }
}
