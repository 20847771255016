<div class="search-container">
  <input
    data-automation="grid-service-search-bar"
    matInput
    (keyup)="applyFilter($event)"
    type="search"
    placeholder="{{ 'scenario.grid.program.search' | translate }}"
    [formControl]="search"
  />
  <button hidden>
    <mat-icon svgIcon="filter"></mat-icon>
    <span>{{ 'scenario.form.grid.programs.filter' | translate }}</span>
  </button>
</div>
