<div class="flex flex-col mt-4 mb-4">
  <h5 class="mb-3">
    {{ 'scenario.site_constraint' | translate }}
  </h5>
  <p>{{ 'scenario.site_constraint.description' | translate }}</p>

  <div class="maximum-container">
    <h3>{{ 'scenario.site_constraint.max.title' | translate }}</h3>
    <mat-radio-group
      class="flex flex-col ml-6"
      (change)="changedMax($event)"
      [(ngModel)]="scenario.maxLoadSiteParameters.id"
    >
      <mat-radio-button
        *ngFor="let input of maxDynamicInputs"
        [value]="input.id"
      >
        <optima-dynamic-input
          #maxInputs
          [minWidth]="'230px'"
          [dynamicInputOptions]="input"
          [labelClasses]="
            scenario.maxLoadSiteParameters.id === input.id ? 'text-bold' : ''
          "
          [value]="scenario.maxLoadSiteParameters.value"
          (inputChange)="saveMaxValue($event)"
        ></optima-dynamic-input>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="minimum-container">
    <h3>{{ 'scenario.site_constraint.min.title' | translate }}</h3>
    <mat-radio-group
      class="flex flex-col ml-6"
      (change)="changedMin($event)"
      [(ngModel)]="scenario.minLoadSiteParameters.id"
    >
      <mat-radio-button
        *ngFor="let input of minDynamicInputs"
        [value]="input.id"
      >
        <optima-dynamic-input
          #minInputs
          [minWidth]="'230px'"
          [dynamicInputOptions]="input"
          [labelClasses]="
            scenario.minLoadSiteParameters.id === input.id ? 'text-bold' : ''
          "
          [value]="scenario.minLoadSiteParameters.value"
          (inputChange)="saveMinValue($event)"
        ></optima-dynamic-input>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
