import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicInputComponent } from './dynamic-input.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [DynamicInputComponent],
  exports: [DynamicInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PipeModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class DynamicInputModule {}
