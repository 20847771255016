import { Component, Input } from '@angular/core';
import { LoaderService } from '@service';

@Component({
  selector: 'optima-loader-no-http',
  templateUrl: './loader-no-http.component.html',
  styleUrls: ['./loader-no-http.component.scss'],
})
export class LoaderNoHttpComponent {
  @Input() opacity = 1;
  @Input() translateString = null;
  @Input() translateStringSubtext = null;

  constructor(public loaderService: LoaderService) {}
}
