import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { GridProgramService } from '@service';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'optima-programs-search',
  templateUrl: './programs-search.component.html',
  styleUrls: ['./programs-search.component.scss'],
})
export class ProgramsSearchComponent implements OnChanges {
  @Input() custom: boolean;
  search = new FormControl('');
  constructor(private gridProgramService: GridProgramService) {}

  ngOnChanges(change: SimpleChanges): void {
    if (change['custom']) {
      this.search.setValue('');
    }
  }

  applyFilter(event: Event): void {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();
    this.gridProgramService.tableFilter(filterValue);
  }
}
