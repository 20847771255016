<div class="full p-12 flex">
  <div class="full flex flex-col justify-end items-start left">
    <div class="optima-welcome flex flex-col">
      <span class="text-light-color" data-automation="welcome-title-1">
        {{ 'welcome.part1' | translate }}
      </span>
      <span class="text-primary-color optima" data-automation="welcome-title-2">
        {{ 'welcome.part2' | translate }}
      </span>
    </div>
    <div
      data-automation="info-container"
      class="background-primary flex justify-between mt-4 p-8 text-container"
    >
      <div class="mr-10 quick-links" data-automation="quick-links">
        <p class="sub-header">{{ 'welcome.quick_links' | translate }}</p>
        <p
          class="link"
          (click)="
            goTo(
              'https://confluence.springlab.enel.com/display/DERPM/What%27s+new+in+Optima'
            )
          "
        >
          <mat-icon>verified</mat-icon>
          <span class="link-text">{{ 'welcome.whats_new' | translate }}</span>
        </p>
        <p
          class="link"
          (click)="
            goTo(
              'https://confluence.springlab.enel.com/display/DERPM/Optima+User+Manual'
            )
          "
        >
          <mat-icon>menu_book</mat-icon>
          <span class="link-text">{{ 'welcome.user_manual' | translate }}</span>
        </p>
        <!-- <p data-automation="updates-title" class="sub-header">{{ "welcome.latest_updates" | translate }}</p>-->
        <!-- <div data-automation="updates" class="updates">-->
        <!--   <span *ngFor="let update of updates">{{ update }}</span>-->
        <!-- </div>-->
      </div>
      <div></div>
    </div>
  </div>
  <div data-automation="dashboard-image" class="cropped pr-20"></div>
</div>
