export interface GridParameters {
  existingLoadCurtailment: number;
  annualDiscountFactor: number;
  montecarloIterations: number;
}

export class GridParameters {
  constructor() {
    this.existingLoadCurtailment = 0;
    this.annualDiscountFactor = 6.4;
    this.montecarloIterations = 3;
  }
}
