import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { UploaderModule } from '../../../global/uploader/uploader.module';
import { LoadProfileTableModule } from '../../load-profile-table/load-profile-table.module';
import { MultiLoadProfileChartModule } from '../../multi-load-profile-chart/multi-load-profile-chart.module';
import { MultiLoadProfileStatsTableModule } from '../../multi-load-profile-stats-table/multi-load-profile-stats-table.module';
import { EnergyUseComponent } from './energy-use.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [EnergyUseComponent],
  exports: [EnergyUseComponent],
  imports: [
    CommonModule,
    LoadProfileTableModule,
    TranslateModule,
    NgxPopperjsModule,
    UploaderModule,
    PipeModule,
    MatIconModule,
    MultiLoadProfileChartModule,
    MultiLoadProfileStatsTableModule,
  ],
})
export class EnergyUseModule {}
