import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderNoHttpModule } from '../../../shared/component/global/loader-no-http/loader-no-http.module';
import { ResultTreemapModule } from '../../../shared/component/scenario/result-treemap/result-treemap.module';
import { ScenarioResultComponent } from './scenario-result.component';
import {
  GridServicesResultsTableModule,
  MultiLoadProfileChartModule,
  ResultBillingTableModule,
  ResultTableModule,
  SavingsRevenuesTableModule,
} from '@component';

@NgModule({
  declarations: [ScenarioResultComponent],
  exports: [ScenarioResultComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSortModule,
    MatRippleModule,
    MultiLoadProfileChartModule,
    ResultBillingTableModule,
    ResultTableModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    SavingsRevenuesTableModule,
    GridServicesResultsTableModule,
    ResultTreemapModule,
    LoaderNoHttpModule,
    MatButtonToggleModule,
    FormsModule,
  ],
})
export class ScenarioResultModule {}
