import { Component, Input, OnChanges } from '@angular/core';
import { monthsShort } from 'moment';
import { GridStats, Scenario } from '@model';
import { GridProgramService } from '@service';

@Component({
  selector: 'optima-grid-services-results-table',
  templateUrl: './grid-services-results-table.component.html',
  styleUrls: ['./grid-services-results-table.component.scss'],
})
export class GridServicesResultsTableComponent implements OnChanges {
  @Input() scenario: Scenario;
  @Input() idx: number;
  @Input() forecasted: boolean;
  showGrid = false;
  stats: GridStats[];

  constructor(private gridService: GridProgramService) {}

  ngOnChanges(): void {
    this.gridService
      .getGridStats$(this.scenario, this.idx, this.forecasted)
      .subscribe(stats => (this.stats = stats));
  }

  getProgramTypeColorClass(programType: string): string {
    return (
      {
        DR: 'blue',
        CP: 'orange',
        CPP: 'red',
      }[programType] || 'hide'
    );
  }

  monthName(month: number): string {
    return monthsShort(--month);
  }
}
