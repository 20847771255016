import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AxisLabelsFormatterContextObject,
  Point,
  Series,
  SeriesLegendItemClickEventObject,
  SeriesOptionsType,
  TooltipFormatterContextObject,
  XAxisPlotBandsOptions,
  YAxisOptions,
} from 'highcharts';
// eslint-disable-next-line import/named
import Highcharts, { StockChart } from 'highcharts/highstock';
highcharts(Highcharts);
import highcharts from 'highcharts/modules/map';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  check,
  isValidDate,
  programEventsCleaner,
  sleep,
  timeout,
} from '@helper';
import {
  CustomSeriesOptions,
  LoadProfileJson,
  LoadProfileStats,
  Scenario,
  ScenarioEvents,
} from '@model';
import {
  CookieService,
  ForecastService,
  LoadProfileService,
  LoaderService,
  ResultService,
  ScenarioEventsService,
  SidenavService,
  TimezonesService,
} from '@service';

enum DOWNLOAD_TYPES {
  'ONE_ITERATION' = 0,
  'ALL_ITERATIONS' = 1,
}

@Component({
  selector: 'optima-multi-load-profile-chart',
  templateUrl: './multi-load-profile-chart.component.html',
  styleUrls: ['./multi-load-profile-chart.component.scss'],
})
export class MultiLoadProfileChartComponent implements OnInit, AfterViewInit {
  @Input() projectId: string;
  @Input() scenario: Scenario;
  @Input() beforeJson: LoadProfileJson[];
  @Input() beforeStats: LoadProfileStats;
  @Input() afterSolarJson: LoadProfileJson[];
  @Input() stateOfChargeJson: LoadProfileJson[];
  @Input() afterSolarStats: LoadProfileStats;
  afterEssJson: LoadProfileJson[];
  @Input() beforeColor: string;
  @Input() afterEssColor: string;
  @Input() afterSolarColor: string;
  @Input() stateOfChargeColor: string;
  @Input() chartId = 'multi-load-profile-container';
  @Input() mode: 'result' | 'form' | 'single';
  @Input() projectLabel: string;
  @Input() scenarioLabel: string;
  @Input() forecasted = false;
  downloadDispatchProfileTypeForm: FormGroup;
  idx: string;
  adjustProfileId: string;
  chart: StockChart;
  series: CustomSeriesOptions[] = [];
  dateFrom: Date;
  dateTo: Date;
  minDate: Date;
  maxDate: Date;
  locale: string;
  timezone: string;
  events: ScenarioEvents[];
  dpCreated = false;
  @ViewChild('datepickers') private templateRef: ElementRef<unknown>;

  constructor(
    private route: ActivatedRoute,
    private resultService: ResultService,
    public timezoneService: TimezonesService,
    public translateService: TranslateService,
    private sidenavService: SidenavService,
    private scenarioEventsService: ScenarioEventsService,
    private loadProfileService: LoadProfileService,
    private cookieService: CookieService,
    private loaderService: LoaderService,
    private forecastService: ForecastService,
  ) {
    this.sidenavService.toggle$.subscribe(() =>
      setTimeout(() => this.chart.reflow()),
    );

    this.downloadDispatchProfileTypeForm = new FormGroup({
      downloadDispatchProfileType: new FormControl(),
    });

    this.downloadDispatchProfileTypeForm.setValue({
      downloadDispatchProfileType: DOWNLOAD_TYPES.ONE_ITERATION,
    });
  }

  async ngAfterViewInit(): Promise<void> {
    this.loaderService.showLoaderNoHttp(`${this.chartId}-loader`);
    setTimeout(async () => {
      if (this.mode !== 'result') {
        await this.setOptions();
      }
      this.datepickers();
      await this.coloredPlots();
      if (this?.chart?.xAxis) {
        setTimeout(() => {
          try {
            this.chart.redraw();
            this.loaderService.hideLoaderNoHttp(`${this.chartId}-loader`);
          } catch (e) {
            this.loaderService.hideLoaderNoHttp(`${this.chartId}-loader`);
          }
        });
      } else {
        this.loaderService.hideLoaderNoHttp(`${this.chartId}-loader`);
      }
    });
  }

  ngOnInit(): void {
    this.locale = this.timezoneService.adjustLocale(
      this.cookieService.getLocale(),
      false,
    );
    this.timezone = this.timezoneService.getTimezoneName(this.projectId);
    if (this.mode === 'result') {
      this.resultService.selectedResult$
        .pipe(
          switchMap(
            async (
              data: [
                Observable<LoadProfileJson[]>,
                string,
                Observable<LoadProfileJson[]>,
                boolean,
              ],
            ) => {
              while (!this.route.snapshot.queryParams['idx']) {
                await timeout(100);
              }
              this.idx = this.route.snapshot.queryParams['idx'];
              this.adjustProfileId = data[1];
              const loadProfileJson$ = data[0];
              const stateOfChargeJson$ = data[2];
              const events$ = this.scenarioEventsService.events$(
                this.scenario,
                this.idx,
                data[3],
              );
              return forkJoin([
                loadProfileJson$,
                events$,
                stateOfChargeJson$,
                of(data[3]),
              ]);
            },
          ),
        )
        .subscribe(
          // eslint-disable-next-line rxjs/no-async-subscribe
          async (
            data: Observable<
              [LoadProfileJson[], ScenarioEvents[], LoadProfileJson[], boolean]
            >,
          ) => {
            let toRedraw = false;
            const materializedData = await data.toPromise();
            if (
              JSON.stringify(this.stateOfChargeJson)?.trim() !==
              JSON.stringify(materializedData[2])?.trim()
            ) {
              toRedraw = true;
            }
            this.stateOfChargeJson = materializedData[2];
            if (
              JSON.stringify(this.events)?.trim() !==
              JSON.stringify(materializedData[1])?.trim()
            ) {
              toRedraw = true;
            }
            this.events = materializedData[1];
            if (
              !this.scenario.options.energyStorage &&
              this.scenario.options.solarPv
            ) {
              if (
                JSON.stringify(this.afterSolarJson)?.trim() !==
                JSON.stringify(materializedData[0])?.trim()
              ) {
                toRedraw = true;
              }
              this.afterSolarJson = materializedData[0];
            } else {
              if (
                JSON.stringify(this.afterEssJson)?.trim() !==
                JSON.stringify(materializedData[0])?.trim()
              ) {
                toRedraw = true;
              }
              this.afterEssJson = materializedData[0];
            }
            if (toRedraw || materializedData[3]) {
              this.loaderService.showLoaderNoHttp(`${this.chartId}-loader`);
              setTimeout(async () => {
                await this.setOptions();
                setTimeout(() => this.chart.redraw());
                this.loaderService.hideLoaderNoHttp(`${this.chartId}-loader`);
              });
            }
          },
        );
    }
  }

  private async setOptions(): Promise<void> {
    const series = await this.chartSeries();
    const plotBands = this.plotBands();
    const tooltipFormatter = this.tooltipFormatter;
    const redrawEvent = this.redraw;
    const displayDateTime = this.displayDateTime;

    // TODO:  Build is failing here with 11.4.4 highcharts.   Need to investigate these parameters.
    this.chart = Highcharts.chart(this.chartId, {
      boost: {
        useGPUTranslations: true,
        seriesThreshold: 1,
      },
      scrollbar: {
        barBorderRadius: 6,
        barBorderWidth: 0,
        buttonArrowColor: 'transparent',
        buttonBackgroundColor: 'transparent',
        buttonBorderColor: 'transparent',
        barBackgroundColor: '#cacaca',
        height: 6,
        minWidth: 6,
        trackBackgroundColor: 'transparent',
        barBorderColor: 'transparent',
        trackBorderColor: 'transparent',
        rifleColor: 'transparent',
      },
      chart: {
        height: 500,
        animation: false,
        type: 'line',
        style: {
          fontFamily: 'var(--fonts)',
        },
        zooming: {
          type: 'x',
        },
        events: {
          redraw: redrawEvent,
        },
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontFamily: 'var(--font)',
        },
      },
      mapNavigation: {
        enableMouseWheelZoom: true,
      },
      title: {
        text: '',
        align: 'left',
        margin: 25,
        style: {
          color: '#333333',
          fontSize: '14px',
        },
      },
      rangeSelector: {
        enabled: true,
        allButtonsEnabled: true,
        buttons: [
          {
            type: 'month',
            count: 1,
            text: this.translateService.instant(
              'chart.range_selector.buttons.1m',
            ),
          },
          {
            type: 'month',
            count: 3,
            text: this.translateService.instant(
              'chart.range_selector.buttons.3m',
            ),
          },
          {
            type: 'month',
            count: 6,
            text: this.translateService.instant(
              'chart.range_selector.buttons.6m',
            ),
          },
          {
            type: 'year',
            count: 1,
            text: this.translateService.instant(
              'chart.range_selector.buttons.1y',
            ),
          },
        ],
        selected: 4,
      },
      credits: {
        enabled: false,
      },
      yAxis: this.getYAxis(this.series),
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45,
          useHTML: true,
          style: {
            color: '#999999',
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px',
            textAlign: 'center',
          },
          allowOverlap: false,
          formatter: this.xAxisFormatter,
        },
        lineColor: '#ccd6eb',
        tickColor: '#ccd6eb',
        plotBands: plotBands,
        startOnTick: false,
        endOnTick: false,
        angle: 0,
        maxPadding: 0,
        minPadding: 0,
      },
      navigator: {
        adaptToUpdatedData: false,
        enabled: true,
        maskFill: 'rgba(102,133,194,0.2)',
        outlineColor: '#d9d9d9',
        handles: {
          borderColor: '#d9d9d9',
        },
        series: {
          dataGrouping: {
            enabled: false,
          },
          fillOpacity: 0.2,
        },
      },
      plotOptions: {
        line: {
          dataGrouping: {
            enabled: false,
          },
        },
        series: {
          pointInterval: 90000,
          boostThreshold: 1,
          states: {
            hover: {
              enabled: true,
            },
          },
          events: {
            legendItemClick: function (
              event: SeriesLegendItemClickEventObject,
            ): void {
              // eslint-disable-next-line @typescript-eslint/no-this-alias
              const that: Series = this;
              const visibleLegends = that.chart.legend.allItems.filter(
                legend => legend.visible,
              );
              if (
                visibleLegends.length === 1 &&
                visibleLegends[0].name === event.target.name
              ) {
                event.preventDefault();
              }
            },
          },
          turboThreshold: 0,
          lineWidth: 1,
          showInLegend: true,
          point: {
            events: {
              mouseOver: function () {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this;
                // @ts-expect-error halo is expected to exist
                if (that.series.halo) {
                  // @ts-expect-error halo is expected to exist
                  that.series.halo
                    .attr({
                      class: 'highcharts-tracker',
                    })
                    .toFront();
                }
              },
              click: function (): void {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const point: Point = this;
                if (
                  point.series.options.className?.indexOf('popup-on-click') !==
                  -1
                ) {
                  const chart = point.series.chart;
                  const date = displayDateTime(
                    point.x,
                    'Do MMMM YYYY h:mm:ss a z',
                  );
                  const text = `<b>${date}</b><br/>${point.y.toFixed(2)} kW | ${
                    point.series.name
                  }`;

                  const anchorX = this.plotX + point.series.xAxis['pos'];
                  const anchorY = this.plotY + point.series.yAxis['pos'];
                  const align =
                    anchorX < chart.chartWidth - 200 ? 'left' : 'right';
                  const x = align === 'left' ? anchorX + 10 : anchorX - 10;
                  const y = anchorY - 30;
                  if (!chart['sticky']) {
                    chart['sticky'] = chart.renderer
                      .label(text, x, y, 'callout', anchorX, anchorY)
                      .attr({
                        align,
                        fill: 'rgba(0, 0, 0, 0.75)',
                        padding: 10,
                        zIndex: 7, // Above series, below tooltip
                        style: { fontSize: '14px' },
                      })
                      .css({
                        color: 'white',
                        borderRadius: 10,
                      })
                      .on('click', function () {
                        chart['sticky'] = chart['sticky'].destroy();
                      })
                      .add();
                  } else {
                    chart['sticky']
                      .attr({ align, text })
                      .animate({ anchorX, anchorY, x, y }, { duration: 250 });
                  }
                }
              },
            },
          },
          marker: {
            radius: 1,
          },
        },
      },
      series: series,
      tooltip: {
        shared: true,
        useHTML: true,
        shadow: false,
        borderColor: '#979797',
        backgroundColor: '#fff',
        formatter: function (): string {
          return tooltipFormatter(this);
        },
      },
    });
  }

  private getYAxis(series: CustomSeriesOptions[]): YAxisOptions[] {
    const yAxis: YAxisOptions[] = [
      {
        title: {
          text: this.translateService.instant('chart.demand'),
          textAlign: 'center',
        },
        labels: {
          format: '{value} kW',
        },
        showLastLabel: true,
        showFirstLabel: true,
        visible: true,
        startOnTick: false,
        endOnTick: true,
        maxPadding: 0,
        minPadding: 0,
        opposite: false,
        alignTicks: false,
        softMin: 0,
      },
    ];
    const socSeries = series.find((serie: CustomSeriesOptions) => {
      return serie.name === 'State of Charge (SOC)';
    });
    if (socSeries) {
      yAxis.push({
        title: {
          text: this.translateService.instant('chart.soc_percent'),
          textAlign: 'center',
        },
        labels: {
          format: '{value} %',
        },
        showEmpty: false,
        showLastLabel: true,
        gridLineWidth: 0,
        visible: true,
        startOnTick: false,
        endOnTick: true,
        maxPadding: 0,
        minPadding: 0,
        opposite: true,
        tickInterval: 25,
        tickAmount: 5,
      });
    }
    return yAxis;
  }

  xAxisFormatter = (data: AxisLabelsFormatterContextObject): string => {
    return this.displayDateTime(data.value, 'Do MMMM YYYY h:mm a');
  };

  tooltipFormatter = (data: TooltipFormatterContextObject): string => {
    const dateHtml = this.displayDateTime(data.x, 'Do MMMM YYYY h:mm a z');
    const containerStyle = 'width: fit-content; background-color: #fff';
    const dateStyle = 'font-size: 13px; margin-bottom: 0.5rem; display: block';
    const h2Style = `font-size: 15px; margin-right: 0.5rem; display: inline-block;`;
    const kwStyle = `font-size: 15px; display: inline-block; font-weight: bold`;
    let tooltipContent = '';
    this.series.forEach(option => {
      const points = data.points.find(
        series => series.series.name === option.name,
      );
      const containerDisplay = points ? 'block' : 'none';
      const divStyle = `display: inline-block; width: 0.75rem; height: 0.75rem; background: ${option.color}; border-radius: 50%; margin-right: 0.25rem`;
      tooltipContent += `
        <div style="display: ${containerDisplay}">
          <div style="${divStyle}"></div>
          <span style="${h2Style}">
            ${this.translateService.instant(option.translationKey)} |
          </span>
          <span style="${kwStyle}">${points?.y.toFixed(2)} ${
            option.tooltipUnit
          }</span>
        </div>
      `;
    });
    return `
      <div style="${containerStyle}">
        <span style="${dateStyle}" data-automation="highchart-tooltip">${dateHtml}</span>
        ${tooltipContent}
      </div>
    `;
  };

  redraw = (): void => {
    if (!this.chart?.xAxis) {
      this.setDatepicker();
    }
    this.datepickers();
    setTimeout(async () => await this.coloredPlots());
  };

  private async chartSeries(): Promise<SeriesOptionsType[]> {
    let series: CustomSeriesOptions[] = [];
    if (this.mode === 'result') {
      if (this.afterSolarJson?.length > 0 && !this.afterEssJson) {
        const opt1TranslationKey = 'scenario.results.before_solar';
        const opt1: CustomSeriesOptions = {
          color: this.beforeColor,
          json: this.beforeJson,
          translationKey: opt1TranslationKey,
          tooltipUnit: 'kW',
          name: this.translateService.instant(opt1TranslationKey),
          visible: true,
        };
        const opt2TranslationKey = 'scenario.results.after_solar';
        const opt2: CustomSeriesOptions = {
          color: this.afterSolarColor,
          json: this.afterSolarJson,
          translationKey: opt2TranslationKey,
          tooltipUnit: 'kW',
          name: this.translateService.instant(opt2TranslationKey),
          visible: true,
        };
        series = [opt1, opt2];
      } else {
        const opt1TranslationKey = 'scenario.results.before_ess';
        const opt1: CustomSeriesOptions = {
          color: this.beforeColor,
          json: this.beforeJson,
          translationKey: opt1TranslationKey,
          tooltipUnit: 'kW',
          name: this.translateService.instant(opt1TranslationKey),
          visible: true,
        };
        const opt2TranslationKey = 'scenario.results.after_ess';
        const opt2: CustomSeriesOptions = {
          color: this.afterEssColor,
          json: this.afterEssJson,
          translationKey: opt2TranslationKey,
          tooltipUnit: 'kW',
          name: this.translateService.instant(opt2TranslationKey),
          visible: true,
        };

        series = [opt1, opt2];
      }

      if (
        this.beforeJson?.length > 0 &&
        this.afterEssJson?.length > 0 &&
        this.afterSolarJson?.length > 0
      ) {
        const opt1TranslationKey =
          'scenario.results.chart.before_solar_and_ess';
        const opt1: CustomSeriesOptions = {
          color: this.beforeColor,
          json: this.beforeJson,
          translationKey: opt1TranslationKey,
          name: this.translateService.instant(opt1TranslationKey),
          yAxis: 0,
          tooltipUnit: 'kW',
          getExtremesFromAll: true,
          visible: true,
        };
        const opt2TranslationKey =
          'scenario.results.chart.after_solar_before_ess';
        const opt2: CustomSeriesOptions = {
          color: this.afterSolarColor,
          json: this.afterSolarJson,
          translationKey: opt2TranslationKey,
          name: this.translateService.instant(opt2TranslationKey),
          yAxis: 0,
          tooltipUnit: 'kW',
          getExtremesFromAll: true,
          visible: true,
        };
        const opt3TranslationKey = 'scenario.results.chart.after_ess_and_solar';
        const opt3: CustomSeriesOptions = {
          color: this.afterEssColor,
          json: this.afterEssJson,
          translationKey: opt3TranslationKey,
          name: this.translateService.instant(opt3TranslationKey),
          yAxis: 0,
          tooltipUnit: 'kW',
          getExtremesFromAll: true,
          visible: true,
        };
        series = [opt1, opt2, opt3];
      }

      if (this.stateOfChargeJson?.length > 0) {
        const stateOfChargeOptionTranslationKey =
          'scenario.results.chart.state_of_charge';
        const stateOfChargeOption: CustomSeriesOptions = {
          color: this.stateOfChargeColor,
          json: this.stateOfChargeJson,
          translationKey: stateOfChargeOptionTranslationKey,
          name: this.translateService.instant(
            stateOfChargeOptionTranslationKey,
          ),
          yAxis: 1,
          tooltipUnit: '%',
          getExtremesFromAll: true,
          visible: false,
        };

        series.push(stateOfChargeOption);
      }

      return await this.renderChart(series);
    } else {
      const opt1TranslationKey = 'scenario.results.before_solar';
      const opt1: CustomSeriesOptions = {
        color: this.beforeColor,
        json: this.beforeJson,
        translationKey: opt1TranslationKey,
        tooltipUnit: 'kW',
        name: this.translateService.instant(opt1TranslationKey),
        visible: true,
      };
      const opt2TranslationKey = 'scenario.results.after_solar';
      const opt2: CustomSeriesOptions = {
        color: this.afterSolarColor,
        json: this.afterSolarJson,
        translationKey: opt2TranslationKey,
        tooltipUnit: 'kW',
        name: this.translateService.instant(opt2TranslationKey),
        visible: true,
      };
      series = [opt1];
      if (this.mode !== 'single') {
        series.push(opt2);
      }

      return await this.renderChart(series);
    }
  }

  private async renderChart(
    options: CustomSeriesOptions[],
  ): Promise<SeriesOptionsType[]> {
    const series: SeriesOptionsType[] = [];
    this.series = [];
    options = await this.adjustDateWithWorker(options);
    this.maxDate = this.adjustedDatepickerDate(this.maxDate, true);
    this.minDate = this.adjustedDatepickerDate(this.minDate, true);
    options.forEach(option => {
      option.active = true;
      this.series.push(option);
      const chartData = option.json?.map(point => {
        return [+new Date(point.timestamp), point.value];
      });
      series.push({
        name: option.name,
        type: 'line',
        showInLegend: true,
        data: chartData,
        color: option.color,
        showInNavigator: true,
        yAxis: option.yAxis,
        visible: option.visible,
      });
      const id = `chart-style-${option.name}`;
      const previousStyle = document.getElementById(id);
      if (previousStyle) {
        previousStyle.remove();
      }
      const style = document.createElement('style');
      style.id = id;
      const styleElem = document.head.appendChild(style);
      styleElem.innerHTML = `
      .checkbox-container .checkmark.${option.name}::after {
        border: solid ${option.color};
        border-width: 0 1px 1px 0;
      }`;
    });
    return series;
  }

  closedPicker(): void {
    this.changeGraphDates();
  }

  private createDatepicker(): void {
    document
      .getElementById(this.chartId)
      .appendChild(this.templateRef.nativeElement as HTMLDivElement);
  }

  private setDatepicker = (): void => {
    if (!this.chart?.xAxis) {
      setTimeout(() => this.setDatepicker(), 100);
      return;
    }
    this.dateFrom = new Date(this.chart.xAxis[0].min);
    this.dateTo = new Date(this.chart.xAxis[0].max);
  };

  private moveDatepickers(): void {
    const newParent = document
      .getElementById(this.chartId)
      .getElementsByClassName('highcharts-input-group')
      .item(0) as SVGGElement;
    const datePickers = document
      .getElementById(this.chartId)
      .querySelector('#datepickers') as HTMLDivElement;
    if (!datePickers) {
      this.datepickers();
      return;
    }
    if (!newParent) {
      setTimeout(() => this.moveDatepickers(), 100);
      return;
    }
    if (!newParent.firstChild) {
      return;
    }
    while (newParent.firstChild) {
      newParent.removeChild(newParent.firstChild);
    }
    const foreignObject = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'foreignObject',
    );
    foreignObject.setAttribute('transform', 'translate(0,0)');
    foreignObject.setAttribute('width', String(250));
    foreignObject.setAttribute('height', String(50));
    foreignObject.appendChild(datePickers);
    newParent.appendChild(foreignObject);
  }

  private datepickers(): void {
    this.dpCreated = false;
    this.createDatepicker();
    this.setDatepicker();
    this.moveDatepickers();
    this.dpCreated = true;
  }

  changeGraphDates(): void {
    const xAxis = this.chart.xAxis[0];
    xAxis.setExtremes(this.dateFrom.valueOf(), this.dateTo.valueOf());
    // setTimeout(async () => await this.coloredPlots(), 500);
  }

  adjustedDatepickerDate(date: Date, adjustForMinMax = false): Date {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const isTimezone = !!this.timezoneService.getTimezoneName(this.projectId);
    let offset: number;
    if (isTimezone) {
      offset = this.timezoneService.getDateTimezoneOffset(
        this.projectId,
        date.toISOString(),
      );
    } else {
      offset = -(date.getTimezoneOffset() * 60000);
    }
    if (adjustForMinMax) {
      return new Date(date.getTime() + offset + userTimezoneOffset);
    } else {
      return new Date(date.getTime() - offset - userTimezoneOffset);
    }
  }

  sanityCheckDate(date: Date): Date {
    if (!date) {
      return undefined;
    }
    return new Date(date.getTime());
  }

  private plotBands(): XAxisPlotBandsOptions[] {
    if (!this.events) {
      return [];
    }
    const allEvents = this.events;
    const uniqueEvents = allEvents.filter(
      (event, index, self) =>
        index ===
        self.findIndex(
          ev =>
            ev.programName === event.programName &&
            ev.startEvent === event.startEvent &&
            ev.endEvent === event.endEvent,
        ),
    );
    return uniqueEvents.map(event => {
      const id = programEventsCleaner(`${event.programName}${event.programId}`);
      const className = programEventsCleaner(`${event.programName}`);
      return {
        color: '#D9D9D980',
        id,
        from: +this.sanityCheckDate(new Date(event.startEvent)),
        to: +this.sanityCheckDate(new Date(event.endEvent)),
        className,
      };
    });
  }

  eventsProgramsDistinct(): string[] {
    const events = this.events;
    return [...new Set(events.map(event => event.programName))];
  }

  toggleAllEvents(): void {
    const plots = this.chart.xAxis[0]['plotLinesAndBands'];
    if (plots?.length) {
      plots.forEach(plot => {
        this.chart.xAxis[0].removePlotBand(plot.id);
      });
      if (plots.length) {
        this.toggleAllEvents();
      }
    } else {
      this.plotBands().forEach(plot => {
        this.chart.xAxis[0].addPlotBand(plot);
      });
    }
    setTimeout(async () => await this.coloredPlots());
  }

  toggleEvent(programName: string): void {
    programName = programEventsCleaner(programName);
    const plots = this.chart.xAxis[0]['plotLinesAndBands'];
    if (plots?.some(plot => plot.id.includes(programName))) {
      plots
        .filter(plot => plot.id.includes(programName))
        .forEach(plot => {
          this.chart.xAxis[0].removePlotBand(plot.id);
        });
    } else {
      this.plotBands()
        .filter(plot => plot.id.includes(programName))
        .forEach(plot => {
          this.chart.xAxis[0].addPlotBand(plot);
        });
    }
    setTimeout(async () => await this.coloredPlots());
  }

  isPlotShow(programName: string): boolean {
    programName = programEventsCleaner(programName);
    return this.chart.xAxis[0]['plotLinesAndBands'].some(plot =>
      plot.id.includes(programName),
    );
  }

  areAllProgramsHidden(): boolean {
    return !!!this.chart.xAxis[0]['plotLinesAndBands']?.length;
  }

  private coloredPlots = async (): Promise<void> => {
    if (this.mode !== 'result') {
      return;
    }
    const borderElements = Array.from(
      document.querySelectorAll('.colored-border'),
    ) as SVGPathElement[];
    borderElements.forEach(element => element.remove());
    if (!this.events) {
      await sleep(1000);
      await this.coloredPlots();
    }
    const allPaths = this.eventsProgramsDistinct().flatMap(eventName =>
      Array.from(
        document.querySelectorAll(`.${programEventsCleaner(eventName)}`),
      ),
    ) as SVGPathElement[];
    const style = getComputedStyle(document.body);
    allPaths.forEach(
      path => (path.id = (Math.random() * (1000000 - 1) + 1).toString()),
    );
    allPaths.forEach(path => {
      const eventIndex = this.eventsProgramsDistinct()
        .map(eventName => programEventsCleaner(eventName))
        .indexOf(path.classList[path.classList.length - 1]);
      const borderElement = path.cloneNode() as SVGPathElement;
      borderElement.setAttribute(
        'fill',
        style.getPropertyValue(`--color-${eventIndex + 1}`),
      );
      const d = borderElement.getAttribute('d');
      const splittedD = d.split(' ');
      const start = splittedD[1];
      const end = splittedD[7];
      const overlapping = allPaths.filter(spath => {
        if (path.id === spath.id || !spath.id) {
          return false;
        }
        const spSplitted = spath.getAttribute('d').split(' ');
        const spStart = spSplitted[1];
        const spEnd = spSplitted[7];
        return (
          (start > spStart && start < spEnd) ||
          (end > spStart && end < spEnd) ||
          (start <= spStart && end >= spEnd)
        );
      });
      splittedD[2] = (73 - overlapping.length * 5).toString();
      splittedD[5] = (68 - overlapping.length * 5).toString();
      splittedD[8] = (68 - overlapping.length * 5).toString();
      splittedD[11] = (73 - overlapping.length * 5).toString();
      borderElement.setAttribute('d', splittedD.join(' '));
      borderElement.setAttribute('class', 'colored-border');
      path.after(borderElement);
      path.removeAttribute('id');
    });
  };

  downloadDispatchProfile(): void {
    const forecastLabel = !this.forecastService.isThereForecasted(this.scenario)
      ? ''
      : this.forecasted
        ? 'forecasted_'
        : 'historical_';

    const downloadDispatchProfileType =
      this.downloadDispatchProfileTypeForm.get(
        'downloadDispatchProfileType',
      ).value;

    if (downloadDispatchProfileType === DOWNLOAD_TYPES.ONE_ITERATION) {
      this.loadProfileService
        .downloadDispatchProfileCsv(
          this.adjustProfileId,
          this.projectLabel,
          this.scenarioLabel,
          this.idx,
          this.timezoneService.getDateTimeDisplayLabel(
            this.projectId,
            Date(),
            'll',
          ),
          this.timezoneService.getTimezoneName(this.projectId),
          forecastLabel,
        )
        .subscribe(data => {
          const link = document.createElement('a');
          const blob = new Blob([data.csv], {
            type: 'text/csv;charset=UTF-8',
          });
          link.href = URL.createObjectURL(blob);
          link.download = data.filename;
          link.click();
        });
    } else if (downloadDispatchProfileType === DOWNLOAD_TYPES.ALL_ITERATIONS) {
      this.loadProfileService.downloadAllDispatchProfileCsv(
        this.projectLabel,
        this.scenario,
        this.idx,
        this.timezoneService.getDateTimeDisplayLabel(
          this.projectId,
          Date(),
          'll',
        ),
        this.timezoneService.getTimezoneName(this.projectId),
        forecastLabel,
        this.forecasted,
      );
    }
  }

  addCssForSelect(): void {
    const style = document.createElement('style');
    style.id = 'custom-style';
    style.innerText =
      '.mat-select-panel {' + 'max-width: unset !important' + '}';
    document.head.appendChild(style);
  }

  removeCssForSelect(): void {
    document.getElementById('custom-style').remove();
  }

  displayDateTime = (data: number | string, dateTimeFormat: string): string => {
    return this.timezoneService.getDateTimeDisplayLabel(
      this.projectId,
      data as string,
      dateTimeFormat,
      true,
      true,
    );
  };

  private async adjustDateWithWorker(
    options: CustomSeriesOptions[],
  ): Promise<CustomSeriesOptions[]> {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('./chart.worker', import.meta.url));
      const adjustDate = (
        options: CustomSeriesOptions[],
        timezone: string,
        locale: string,
      ): Promise<CustomSeriesOptions[]> =>
        new Promise(res => {
          worker.onmessage = ({ data }): void => {
            this.minDate = data['minDate'];
            this.maxDate = data['maxDate'];
            res(options);
          };
          worker.postMessage({
            options,
            timezone,
            locale,
          });
        });
      return await adjustDate(options, this.timezone, this.locale);
    } else {
      options.flatMap(opt => {
        if (opt.json) {
          opt.json?.map(json => {
            let adjustedDate: Date;
            adjustedDate = this.sanityCheckDate(
              new Date(
                new Date(json.timestamp).toLocaleString(this.locale, {
                  timeZone: this.timezone,
                }),
              ),
            );
            if (!isValidDate(adjustedDate)) {
              adjustedDate = this.sanityCheckDate(
                new Date(
                  new Date(json.timestamp).toLocaleString('en-US', {
                    timeZone: this.timezone,
                  }),
                ),
              );
            }
            if (this.minDate === undefined) {
              this.minDate = adjustedDate;
            }
            if (this.maxDate === undefined) {
              this.maxDate = adjustedDate;
            }
            this.minDate =
              this.minDate > adjustedDate ? adjustedDate : this.minDate;
            this.maxDate =
              this.maxDate < adjustedDate ? adjustedDate : this.maxDate;
          });
        }
      });
      return new Promise(res => res(options));
    }
  }

  protected readonly check = check;
  protected readonly DOWNLOAD_TYPES = DOWNLOAD_TYPES;
}
