import { Component, Input, OnChanges } from '@angular/core';
import { ProgramResult } from '../../../model/program-result.model';
import { detectBrowser, isNumber } from '@helper';
import { Result, RunState } from '@model';

@Component({
  selector: 'optima-savings-revenues-table',
  templateUrl: './savings-revenues-table.component.html',
  styleUrls: ['./savings-revenues-table.component.scss'],
})
export class SavingsRevenuesTableComponent implements OnChanges {
  @Input() result: Result;
  @Input() forecastResult: Result;
  @Input() showForecastResult: boolean;
  isFirefox: boolean;
  showForecastedSort = false;
  forecastedSort = 0;
  showHistoricalSort = false;
  historicalSort = 0;
  propertiesMap = new Map<string, string[]>();
  originalMap = new Map<string, string[]>();
  isNumber = isNumber;

  constructor() {
    this.isFirefox = detectBrowser().includes('Firefox');
  }

  // remove isNumber to remove values with 0 from the table
  ngOnChanges(): void {
    if (
      isNumber(this.result?.storageEnergyChargesSavings) ||
      isNumber(this.forecastResult?.storageEnergyChargesSavings)
    ) {
      this.propertiesMap.set('storageEnergyChargesSavings', [
        'blue',
        'scenario.results.savings.storage.energy',
        '',
        this.result?.storageEnergyChargesSavings?.toString(),
        this.forecastResult?.storageEnergyChargesSavings?.toString(),
        'results-storage-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.dynamicStorageSavings) ||
      isNumber(this.forecastResult?.dynamicStorageSavings)
    ) {
      this.propertiesMap.set('dynamicStorageSavings', [
        'blue',
        'scenario.results.savings.storage.dynamic_storage_savings',
        '',
        this.result?.dynamicStorageSavings?.toString(),
        this.forecastResult?.dynamicStorageSavings?.toString(),
        'results-storage-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.storageDemandChargesSavings) ||
      isNumber(this.forecastResult?.storageDemandChargesSavings)
    ) {
      this.propertiesMap.set('storageDemandChargesSavings', [
        'blue',
        'scenario.results.savings.storage.demand',
        '',
        this.result?.storageDemandChargesSavings?.toString(),
        this.forecastResult?.storageDemandChargesSavings?.toString(),
        'results-storage-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.auxLosses) ||
      isNumber(this.forecastResult?.auxLosses)
    ) {
      this.propertiesMap.set('auxLosses', [
        'blue',
        'scenario.results.savings.storage.aux_loss',
        '',
        this.result?.auxLosses?.toString(),
        this.forecastResult?.auxLosses?.toString(),
        'results-storage-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.solarEnergyChargesSavings) ||
      isNumber(this.forecastResult?.solarEnergyChargesSavings)
    ) {
      this.propertiesMap.set('solarEnergyChargesSavings', [
        'orange',
        'scenario.results.savings.solar.energy',
        '',
        this.result?.solarEnergyChargesSavings?.toString(),
        this.forecastResult?.solarEnergyChargesSavings?.toString(),
        'results-solar-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.dynamicSolarSavings) ||
      isNumber(this.forecastResult?.dynamicSolarSavings)
    ) {
      this.propertiesMap.set('dynamicSolarSavings', [
        'orange',
        'scenario.results.savings.solar.dynamic_solar_savings',
        '',
        this.result?.dynamicSolarSavings?.toString(),
        this.forecastResult?.dynamicSolarSavings?.toString(),
        'results-solar-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.solarDemandChargesSavings) ||
      isNumber(this.forecastResult?.solarDemandChargesSavings)
    ) {
      this.propertiesMap.set('solarDemandChargesSavings', [
        'orange',
        'scenario.results.savings.solar.demand',
        '',
        this.result?.solarDemandChargesSavings?.toString(),
        this.forecastResult?.solarDemandChargesSavings?.toString(),
        'results-solar-savings-tab',
      ]);
    }
    if (
      isNumber(this.result?.tariffSwitchSavings) ||
      isNumber(this.forecastResult?.tariffSwitchSavings)
    ) {
      this.propertiesMap.set('tariffSwitchSavings', [
        'green',
        'scenario.results.savings.tariff_switch',
        '',
        this.result?.tariffSwitchSavings?.toString(),
        this.forecastResult?.tariffSwitchSavings?.toString(),
        'results-tariff-switch-savings-tab',
      ]);
    }
    this.programIdDistinct().forEach(id => {
      let resValue: string;
      let forValue: string;
      if (isNumber(this.result?.gridServicesRevenuesFromPrograms)) {
        const program = this.result?.gridServicesRevenuesFromPrograms.find(
          p => p.id === id,
        );
        resValue = program?.revenue?.toString();
      }
      if (isNumber(this.forecastResult?.gridServicesRevenuesFromPrograms)) {
        const program =
          this.forecastResult?.gridServicesRevenuesFromPrograms.find(
            p => p.id === id,
          );
        forValue = program?.revenue?.toString();
      }
      this.propertiesMap.set(id, [
        'maroon',
        'scenario.results.revenue.grid_services',
        'true',
        resValue,
        forValue,
        'results-grid-revenue-tab',
      ]);
    });
    this.originalMap = new Map(this.propertiesMap);
  }

  programIdDistinct(): string[] {
    const resultPrograms = this.result?.gridServicesRevenuesFromPrograms ?? [];
    const forecastPrograms =
      this.forecastResult?.gridServicesRevenuesFromPrograms ?? [];
    return [
      ...new Set(
        [...resultPrograms, ...forecastPrograms].map(program => program.id),
      ),
    ];
  }

  getProgramById(programs: ProgramResult[], id: string): ProgramResult {
    return programs?.find(p => p.id === id);
  }

  getChange(resultVal: number, forecastVal: number): string {
    if (!resultVal || !forecastVal) {
      return '';
    }
    const percentageChange = (a: number, b: number): number =>
      ((b - a) / Math.abs(a)) * 100;
    let percentageString =
      percentageChange(resultVal, forecastVal).toFixed(0) + '%';
    if (!percentageString.includes('-')) {
      percentageString = `+${percentageString}`;
    }
    return percentageString;
  }

  sort(who: 'historical' | 'forecasted', value: number): void {
    if (value === 0) {
      this.propertiesMap = new Map(this.originalMap);
      return;
    }
    if (who === 'historical') {
      this.historicalSort = value;
      this.propertiesMap = this.sortMap(this.originalMap, value, 3);
    } else {
      this.forecastedSort = value;
      this.propertiesMap = this.sortMap(this.originalMap, value, 4);
    }
  }

  getResultProperties(): string[] {
    return Array.from(this.propertiesMap.keys());
  }

  private sortMap(
    map: Map<string, string[]>,
    value: number,
    index: number,
  ): Map<string, string[]> {
    return new Map(
      Array.from(map.entries()).sort(([k, v], [k2, v2]) => {
        if (Number(v[index]) > Number(v2[index])) {
          return value === 1 ? 1 : -1;
        }
        if (Number(v[index]) < Number(v2[index])) {
          return value === 1 ? -1 : 1;
        }
        return 0;
      }),
    );
  }

  protected readonly RunState = RunState;
}
