import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { setTariffs } from '@helper';
import { LoadProfile, Project, Scenario } from '@model';
import {
  EssService,
  GridProgramService,
  LoadProfileService,
  ProjectService,
  ScenarioService,
  TariffService,
} from '@service';

/**
 * @deprecated
 */
@Injectable()
export class ProjectResolver {
  constructor(
    private essService: EssService,
    private projectService: ProjectService,
    private scenarioService: ScenarioService,
    private loadProfileService: LoadProfileService,
    private tariffService: TariffService,
    private gridProgramService: GridProgramService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<[Scenario[], Project, LoadProfile[]]> {
    const projectId = route.paramMap.get('projectId');
    const projectScenarios$ = this.scenarioService
      .getScenarios$(projectId)
      .pipe(
        mergeMap(scenarios => {
          if (!scenarios.length) {
            return of([]);
          }
          return forkJoin(
            scenarios.map(scenario => {
              // For each duty, fetch the cleanerData, and append the result to the duty itself:
              const ders$ = this.essService.getDers$(scenario.id);
              const tariffs$ = this.tariffService.getTariffsNoBills$(
                scenario.id,
              );
              const programs$ = this.gridProgramService.getSelectedPrograms$(
                scenario.id,
              );
              return forkJoin([ders$, programs$, tariffs$]).pipe(
                take(1), // to complete the sub observable after getting the value from it.
                map(result => {
                  scenario.ders = result[0];
                  scenario.programs = result[1];
                  setTariffs(scenario, ...result[2]);
                  return scenario;
                }),
              );
            }),
          );
        }),
      );
    const loadProfiles$ = this.loadProfileService.loadProfiles$(projectId);
    const project = this.projectService.projects.find(p => p.id === projectId);
    const project$ = project
      ? of(project)
      : this.projectService.getProject$(projectId);
    return forkJoin([projectScenarios$, project$, loadProfiles$]);
  }
}
