import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoogleModuleOptions } from '@ng-maps/google';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';

@Injectable()
export class GoogleService implements GoogleModuleOptions {
  apiKey: string;
  libraries = ['places'];

  constructor(private resourceService: ResourceService<string>) {}

  load(): Observable<string> {
    return this.resourceService.get('google/api-key').pipe(
      tap(key => {
        const node = document.createElement('script');
        node.src = `https://maps.googleapis.com/maps/api/js?key=${key}&loading=async&libraries=places`;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
        this.apiKey = key;
      }),
    );
  }

  getTimeZone(lat: string, long: string, timestamp: string, apiKey: string) {
    const params = new HttpParams()
      .set('lat', lat)
      .set('long', long)
      .set('timestamp', timestamp)
      .set('apiKey', apiKey);
    return this.resourceService.get('getTimezoneFromLatLong', params);
  }
}
