import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { GridSummaryComponent } from './grid-summary.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [GridSummaryComponent],
  exports: [GridSummaryComponent],
  imports: [CommonModule, TranslateModule, PipeModule, MatTooltipModule],
})
export class GridSummaryModule {}
