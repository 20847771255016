import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LoadProfileStats, ScenarioOptions } from '@model';

interface LoadProfileTableStats extends LoadProfileStats {
  rowLabel: string;
}

@Component({
  selector: 'optima-multi-load-profile-stats-table',
  templateUrl: './multi-load-profile-stats-table.component.html',
  styleUrls: ['./multi-load-profile-stats-table.component.scss'],
})
export class MultiLoadProfileStatsTableComponent implements OnInit, OnChanges {
  @Input() beforeStats: LoadProfileStats;
  @Input() afterSolarStats: LoadProfileStats;
  @Input() mode: 'form' | 'single';
  @Input() scenarioOptions: ScenarioOptions;
  afterEssStats: LoadProfileStats;
  stats: LoadProfileTableStats[];
  dataSource: MatTableDataSource<LoadProfileStats>;
  columns = new Map<string, string>();
  displayedColumns: string[];

  ngOnInit(): void {
    this.initColumns();
    this.setDataSource();
  }

  private setDataSource(): void {
    if (this.beforeStats && this.afterSolarStats && this.afterEssStats) {
      const beforeSolarEss: LoadProfileTableStats = {
        rowLabel: 'scenario.results.before_solar_and_ess',
        ...this.beforeStats,
      };
      const afterSolarBeforeEss: LoadProfileTableStats = {
        rowLabel: 'scenario.results.after_solar_before_ess',
        ...this.afterSolarStats,
      };
      const afterEss: LoadProfileTableStats = {
        rowLabel: 'scenario.results.chart.after_ess_and_solar',
        ...this.afterEssStats,
      };
      this.stats = [beforeSolarEss, afterSolarBeforeEss, afterEss];
    } else if (this.afterSolarStats && !this.afterEssStats) {
      const beforeSolar: LoadProfileTableStats = {
        rowLabel: 'scenario.results.before_solar',
        ...this.beforeStats,
      };
      const afterSolar: LoadProfileTableStats = {
        rowLabel: 'scenario.results.after_solar',
        ...this.afterSolarStats,
      };
      this.stats = [beforeSolar, afterSolar];
    } else {
      const beforeEss: LoadProfileTableStats = {
        rowLabel: 'scenario.results.before_ess',
        ...this.beforeStats,
      };
      const afterEss: LoadProfileTableStats = {
        rowLabel: 'scenario.results.after_ess',
        ...this.afterEssStats,
      };
      this.stats = [beforeEss];
      if (this.mode !== 'single') {
        this.stats.push(afterEss);
      }
    }
    this.dataSource = new MatTableDataSource(this.stats);
  }

  ngOnChanges(): void {
    this.setDataSource();
  }

  private initColumns(): void {
    if (this.mode !== 'single') {
      this.columns.set('rowLabel', '');
    }
    this.columns.set('minKw', 'scenario.summary.energy_use.minimum_demand');
    this.columns.set('avgKw', 'scenario.summary.energy_use.average_demand');
    this.columns.set('maxKw', 'scenario.summary.energy_use.maximum_demand');
    this.columns.set('totalKwh', 'scenario.summary.energy_use.total_usage');
    this.columns.set('rows', 'scenario.summary.energy_use.number_of_intervals');
    this.columns.set(
      'interval',
      'scenario.summary.energy_use.interval_duration',
    );
    this.displayedColumns = Array.from(this.columns.keys());
  }
}
