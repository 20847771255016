import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgMapsCoreModule } from '@ng-maps/core';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { TranslateModule } from '@ngx-translate/core';
import { InlineEditComponent } from './inline-edit.component';
import { DirectiveModule } from '@directive';

@NgModule({
  declarations: [InlineEditComponent],
  exports: [InlineEditComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    TranslateModule,
    NgMapsGoogleModule,
    NgMapsCoreModule,
    DirectiveModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class InlineEditModule {}
