import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsSelectedComponent } from './programs-selected.component';

@NgModule({
  declarations: [ProgramsSelectedComponent],
  exports: [ProgramsSelectedComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
})
export class ProgramsSelectedModule {}
