import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderNoHttpModule } from '../../global/loader-no-http/loader-no-http.module';
import { MultiLoadProfileChartComponent } from './multi-load-profile-chart.component';

@NgModule({
  declarations: [MultiLoadProfileChartComponent],
  exports: [MultiLoadProfileChartComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    LoaderNoHttpModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  providers: [],
})
export class MultiLoadProfileChartModule {}
