import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UploaderComponent } from '@component';
import {
  ForecastChunk,
  ForecastChunkStatusEnum,
  ForecastLoadProfile,
  Scenario,
} from '@model';
import { ForecastService, ScenarioService } from '@service';

@Component({
  selector: 'optima-forecasted-solar',
  templateUrl: './forecasted-solar.component.html',
  styleUrls: ['./forecasted-solar.component.scss'],
})
export class ForecastedSolarComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() scenario: Scenario;
  @Input() solarForecasts: ForecastLoadProfile[];
  @Output() solarForecastChangedChunk = new EventEmitter<ForecastChunk>();
  @Output() solarForecastChanged = new EventEmitter<ForecastLoadProfile>();
  @ViewChild('searchInput') set content(content: ElementRef) {
    if (content) {
      this.input = content;
      this.initSearch();
    }
  }
  input: ElementRef;
  @ViewChild('uploaderSolarForecast')
  uploaderSolarForecast: UploaderComponent<ForecastLoadProfile>;

  filteredForecasts: ForecastLoadProfile[];
  isAdding = false;
  formDataParameters = new Map<string, string>();
  postForecastUrl: string;

  constructor(
    public scenarioService: ScenarioService,
    public forecastService: ForecastService,
  ) {}

  ngOnInit(): void {
    this.filteredForecasts = [...this.solarForecasts];
    this.filteredForecasts = this.filteredForecasts.filter(
      f => f.status === ForecastChunkStatusEnum.COMPLETED,
    );
    this.formDataParameters.set('type', 'PV');
    this.postForecastUrl = `/scenarios/${this.scenario.id}/forecast-dispatch`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['scenario'].firstChange) {
      setTimeout(() => this.initSearch());
    }
  }

  ngAfterViewInit(): void {
    this.initSearch();
  }

  async updateForecast(forecast: ForecastLoadProfile): Promise<void> {
    this.updateList();
    this.solarForecastChanged.emit(forecast);
  }

  async updateForecastChunk(forecast: ForecastChunk): Promise<void> {
    if (
      forecast?.chunkNumber &&
      forecast.chunkNumber === forecast.totalChunks
    ) {
      this.updateList();
    }
    this.solarForecastChangedChunk.emit(forecast);
  }

  private initSearch(): void {
    if (!this.input?.nativeElement) {
      return;
    }
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          const value = this.input.nativeElement.value;
          this.filteredForecasts = this.solarForecasts.filter(
            solarForecastProfile =>
              solarForecastProfile.filename
                .toLowerCase()
                .includes(value.toLowerCase()),
          );
        }),
      )
      .subscribe();
  }

  private updateList(): void {
    this.forecastService
      .getSolarForecasts(this.scenario.parentId)
      .subscribe(forecasts => {
        this.solarForecasts = forecasts;
        this.filteredForecasts = [...this.solarForecasts];
        this.filteredForecasts = this.filteredForecasts.filter(
          forecastProfile =>
            forecastProfile.status === ForecastChunkStatusEnum.COMPLETED,
        );
      });
  }
}
