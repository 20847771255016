export interface TariffParameters {
  dynamicAfter: boolean;
  dynamicBefore: boolean;
  dynamicStoredExportAfter: string;
  dynamicStoredExportBefore: string;
  dynamicStoredImportAfter: string;
  dynamicStoredImportBefore: string;
  nemNetting: boolean;
}

export class TariffParameters {
  constructor() {
    this.dynamicAfter = false;
    this.dynamicBefore = false;
    this.dynamicStoredExportAfter = null;
    this.dynamicStoredExportBefore = null;
    this.dynamicStoredImportAfter = null;
    this.dynamicStoredImportBefore = null;
    this.nemNetting = false;
  }
}
