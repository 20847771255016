import { Injectable } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { CssService } from './css.service';
import { timeout } from '@helper';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  private closeEmitter = new Subject();
  private toggleEmitter = new Subject<void>();
  close$ = this.closeEmitter.asObservable();
  toggle$ = this.toggleEmitter.asObservable();

  constructor(private cssService: CssService) {}

  setSidenav(sidenav: MatSidenav): void {
    this.sidenav = sidenav;
  }

  async closeIfMobile(): Promise<Promise<MatDrawerToggleResult> | void> {
    while (!this.sidenav) {
      await timeout(100);
    }
    if (this.sidenav.mode === 'over') {
      this.closeEmitter.next(null);
    }
  }

  sendToggleEvent(): void {
    this.sidenav._animationEnd.subscribe(() => {
      this.cssService.calculateSummaryWidth();
      this.toggleEmitter.next();
    });
  }
}
