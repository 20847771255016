<div class="w-auto">
  <div class="flex justify-start items-center" *ngIf="!isEditing">
    <div data-automation="editable-label" [innerHTML]="innerHtml"></div>
    <div
      *ngIf="inputType === 'map' && label"
      (click)="showMap = !showMap; moveMap()"
      class="flex items-center pointer mb2"
    >
      <mat-icon
        *ngIf="!showMap"
        svgIcon="map2"
        data-automation="map-icon"
        class="map-icon"
        id="map2"
        matTooltip="{{ 'project.location.map.tooltip' | translate }}"
        alt=""
      ></mat-icon>
      <mat-icon
          svgIcon="map3"
        *ngIf="showMap"
        data-automation="map-icon"
        class="map-icon"
        id="map3"
        alt=""
      ></mat-icon>
    </div>
    <mat-icon
      svgIcon="edit"
      class="pencil-clickable"
      data-automation="pencil-icon"
      class="icon"
      (click)="editMode()"
    ></mat-icon>
  </div>
</div>

<div id="map-detail" class="map-container" *ngIf="showMap">
  <map-view
    [zoom]="15"
    [usePanning]="true"
    [latitude]="latitude"
    [longitude]="longitude"
  >
    <map-marker [latitude]="latitude" [longitude]="longitude"></map-marker>
  </map-view>
</div>

<div *ngIf="isEditing">
  <mat-form-field
    [ngSwitch]="inputType"
    appearance="outline"
    [ngStyle]="{ visibility: inputType === 'date' ? 'hidden' : 'initial' }"
    [style.width.px]="formFieldWidth"
    [ngClass]="{ 'mb-5': formControl.invalid }"
  >
    <input
      optimaAutofocus
      matInput
      #inputText
      data-automation="input-edit-mode"
      *ngSwitchCase="'input'"
      [formControl]="formControl"
      (focusout)="editingEnd(formControl.value)"
      (keyup.enter)="editingEnd(formControl.value)"
      (keyup.escape)="escWithoutSaving()"
      (paste)="resizeInput(inputText.value)"
      (cut)="resizeInput(inputText.value)"
      (input)="resizeInput(inputText.value)"
      [errorStateMatcher]="matcher"
      required
    />

    <input
      optimaAutofocus
      matInput
      #mapSearch
      #inputText
      data-automation="map-edit-mode"
      *ngSwitchCase="'map'"
      [formControl]="formControl"
      [ngModel]="label"
      (keyup.escape)="escWithoutSaving()"
      (focusout)="editingEnd(formControl.value)"
      (paste)="resizeInput(inputText.value)"
      (cut)="resizeInput(inputText.value)"
      (input)="resizeInput(inputText.value)"
      [errorStateMatcher]="matcher"
      required
    />

    <div class="flex items-center" *ngSwitchCase="'date'">
      <input
        matInput
        [matDatepicker]="picker"
        [formControl]="formControl"
        (dateChange)="onDateChange($event.value)"
        style="display: none"
        readonly
      />
      <mat-datepicker
        (closed)="escWithoutSaving()"
        [startView]="'month'"
        [startAt]="defaultStartDate()"
        #picker
      ></mat-datepicker>
    </div>

    <mat-select
      #select
      data-automation="select-edit-mode"
      panelClass="select-panel-class"
      disableOptionCentering
      *ngSwitchCase="'select'"
      [formControl]="formControl"
      (selectionChange)="editingEnd(selectDisplayValue($event.value))"
      (openedChange)="openedChange($event)"
      (keyup.escape)="escWithoutSaving()"
      [errorStateMatcher]="matcher"
      required
    >
      <div
        *ngIf="selectEnableFilter"
        class="flex items-center mat-select-search-input-container"
      >
        <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
        <input
          data-automation="inline-edit-search-input"
          class="mat-select-search-input"
          (keyup)="filterSelectArray()"
          placeholder="{{ 'project.search' | translate }}"
          #searchSelectArray
        />
      </div>
      <mat-option
        data-automation="mat-option-select"
        *ngFor="let value of filteredSelectArray"
        [value]="value[selectValueField]"
        id="{{ selectIdPrefix + '-' + value[selectIdField] }}"
      >
        {{ value[selectDisplayField] }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{ 'project.edit.name.error.required' | translate }}
    </mat-error>
  </mat-form-field>

  <span #invisibleText class="invisible-text">{{ inputExample }}</span>
</div>
