import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Bess, LoadProfileEnum, Project, Scenario } from '@model';
import { ResultService, ScenarioService } from '@service';

@Component({
  selector: 'optima-scenario-summary',
  templateUrl: './scenario-summary.component.html',
  styleUrls: ['./scenario-summary.component.scss'],
})
export class ScenarioSummaryComponent implements OnInit {
  @Input() scenario: Scenario;
  @Input() project: Project;
  @Input() startRun: () => void;
  energyUseTableColumns: Map<string, string>;
  bess: Bess[];

  constructor(
    private route: ActivatedRoute,
    public resultService: ResultService,
    public scenarioService: ScenarioService,
  ) {}

  ngOnInit(): void {
    this.energyUseTableColumns = new Map<string, string>();
    this.energyUseTableColumns.set(
      'minKw',
      'scenario.summary.energy_use.minimum_demand',
    );
    this.energyUseTableColumns.set(
      'avgKw',
      'scenario.summary.energy_use.average_demand',
    );
    this.energyUseTableColumns.set(
      'maxKw',
      'scenario.summary.energy_use.maximum_demand',
    );
    this.energyUseTableColumns.set(
      'totalKwh',
      'scenario.summary.energy_use.total_usage',
    );
    this.energyUseTableColumns.set(
      'rows',
      'scenario.summary.energy_use.number_of_intervals',
    );
    this.energyUseTableColumns.set(
      'interval',
      'scenario.summary.energy_use.interval_duration',
    );
    this.bess = this.route.snapshot.data['scenario'][3];
  }

  checkPvGeneratedBy(): boolean {
    return (
      this.scenario.pvloadProfile?.isGeneratedBy ===
        LoadProfileEnum.PV_WATTS_API && this.scenario.options.solarPv
    );
  }
}
