<div class="ml-8 mb-4 flex items-center">
  <span class="bold mr-4">
    {{ 'scenario.site_constraint.max' | translate }}:
  </span>
  <div class="chips">
    <span class="mr-1">{{ showLabel(true) }}{{ showColumn(true) }}</span>
    <span>{{ showValue(true) }} {{ showUnits(true) }}</span>
  </div>
</div>

<div class="ml-8 flex items-center">
  <span class="bold mr-4">
    {{ 'scenario.site_constraint.min' | translate }}:
  </span>
  <div class="chips">
    <span class="mr-1">{{ showLabel() }}{{ showColumn() }}</span>
    <span>{{ showValue() }} {{ showUnits() }}</span>
  </div>
</div>
