import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EssSummaryTableComponent } from './ess-summary-table.component';

@NgModule({
  declarations: [EssSummaryTableComponent],
  exports: [EssSummaryTableComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class EssSummaryTableModule {}
