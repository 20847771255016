import { Injectable } from '@angular/core';

@Injectable()
export class CssService {
  calculateSummaryWidth(): void {
    const page = document.querySelector(
      '.page-container.scenario',
    ) as HTMLDivElement;
    const sidenavWidth = document.querySelector('mat-sidenav').clientWidth;
    if (page) {
      page.style.maxWidth = `${1440 - sidenavWidth}px`;
    }
  }
}
