import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ActivatedRoute } from '@angular/router';
import { concatMap, of } from 'rxjs';
import { check, inOut, isNot } from '@helper';
import {
  CustomErrorStateMatcher,
  LoadProfile,
  LoadProfileJson,
  PvWatts,
  PvWattsArrayType,
  PvWattsModuleType,
  Scenario,
  SolarLoadProfile,
} from '@model';
import { LoadProfileService, ScenarioService, SolarPvService } from '@service';

@Component({
  selector: 'optima-create-solar',
  templateUrl: './create-solar.component.html',
  styleUrls: ['./create-solar.component.scss'],
  animations: [inOut()],
})
export class CreateSolarComponent implements OnInit {
  @Input() scenario: Scenario;
  @Output() created = new EventEmitter<Scenario>();
  formGroup: FormGroup;
  monthFormGroup: FormGroup;
  showAdvancedParameters = false;
  showAllMonths = false;
  arrayType: PvWattsArrayType[];
  moduleType: PvWattsModuleType[];
  matcher = new CustomErrorStateMatcher();
  check = check;

  constructor(
    private fb: FormBuilder,
    private solarService: SolarPvService,
    private loadProfileService: LoadProfileService,
    private scenarioService: ScenarioService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.arrayType = this.route.snapshot.data['scenarioForm'][0];
    this.moduleType = this.route.snapshot.data['scenarioForm'][1];

    this.formGroup = this.fb.group({
      albedo: new FormControl(null, [
        Validators.min(0.001),
        Validators.max(0.999),
      ]),
      arrayType: new FormControl(0, [Validators.required]),
      azimuth: new FormControl(180, [
        Validators.required,
        Validators.min(0),
        Validators.max(359.9),
      ]),
      bifacial: new FormControl(false),
      coverage: new FormControl(0.4, [
        Validators.min(0.01),
        Validators.max(0.99),
      ]),
      dcac: new FormControl(1.2, [Validators.min(0.4), Validators.max(2)]),
      efficiency: new FormControl(96, [
        Validators.min(90),
        Validators.max(99.5),
      ]),
      loss: new FormControl(10, [
        Validators.required,
        Validators.min(0),
        Validators.max(99),
      ]),
      moduleType: new FormControl(0, [Validators.required]),
      monthlyLoss: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      solarPvSize: new FormControl(4, [
        Validators.required,
        Validators.min(0.05),
        Validators.max(500000),
      ]),
      tilt: new FormControl(20, [
        Validators.required,
        Validators.min(0),
        Validators.max(90),
      ]),
    });

    this.monthFormGroup = this.fb.group({
      jan: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      feb: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      mar: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      apr: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      may: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      jun: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      jul: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      aug: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      sep: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      oct: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      nov: new FormControl(0, [Validators.min(0), Validators.max(100)]),
      dec: new FormControl(0, [Validators.min(0), Validators.max(100)]),
    });

    this.onMonthChange();
  }

  /**
   * Submit button is clicked.
   *
   * If the form is invalid, wrong fields are forced to highlight the errors,
   * if an invalid field is in the advanced parameters section, this section is opened.
   *
   * If the form is valid, the PvWatts object is built merging the 2 forms values.
   *
   * If monthlyLoss field is empty monthlyLoss is set to null, else is an array of 12 numbers (months).
   *
   * After create solar, needs to downaload pvLoad and adjustedPvLoad
   */
  submit(): void {
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach(key => {
        if (this.formGroup.controls[key].status === 'INVALID') {
          const notAdvancedParameters = [
            'solarPvSize',
            'loss',
            'tilt',
            'azimuth',
          ];
          if (!notAdvancedParameters.includes(key)) {
            this.showAdvancedParameters = true;
          }
          this.formGroup.controls[key].markAsDirty();
          this.formGroup.controls[key].markAsTouched();
          this.formGroup.controls[key].markAsPending();
          this.formGroup.controls[key].updateValueAndValidity();
        }
      });
      return;
    }

    if (this.monthFormGroup.invalid) {
      Object.keys(this.monthFormGroup.controls).forEach(key => {
        if (this.monthFormGroup.controls[key].status === 'INVALID') {
          this.showAdvancedParameters = true;
          this.formGroup.controls[key].markAsDirty();
          this.formGroup.controls[key].markAsTouched();
          this.formGroup.controls[key].markAsPending();
          this.formGroup.controls[key].updateValueAndValidity();
        }
      });
      return;
    }

    let monthlyLoss: null | number[];
    if (isNot(this.formGroup.get('monthlyLoss').value)) {
      monthlyLoss = null;
    } else {
      monthlyLoss = Object.values(this.monthFormGroup.value) as number[];
    }
    const pvWatts = { ...this.formGroup.value, monthlyLoss } as PvWatts;
    const createSolar$ = this.solarService.createSolar(
      this.scenario.id,
      pvWatts,
    );
    const scenario$ = this.scenarioService.getScenario$(this.scenario.id);

    createSolar$
      .pipe(
        concatMap((pvLoad: SolarLoadProfile) => {
          this.scenario.pvloadProfile = pvLoad;
          this.scenario.pvloadId = pvLoad.id;
          return scenario$;
        }),
        concatMap((scenario: Scenario) => {
          this.scenario.adjustedPvLoadId = scenario.adjustedPvLoadId;
          return this.scenario.loadprofileId && this.scenario.adjustedPvLoadId
            ? this.loadProfileService.loadProfile$(
                this.scenario.adjustedPvLoadId,
              )
            : of(null);
        }),
        concatMap((adjustedPvLoad: LoadProfile) => {
          this.scenario.adjustedPvLoadProfile = adjustedPvLoad;
          return this.scenario.loadprofileId && this.scenario.adjustedPvLoadId
            ? this.loadProfileService.getLoadProfileJson(adjustedPvLoad.id)
            : of(null);
        }),
      )
      .subscribe((result: LoadProfileJson[]) => {
        if (result) {
          this.scenario.adjustedPvLoadProfile.json = result;
        }
        this.created.emit(this.scenario);
      });
  }

  /**
   * If monthly checkbox is disabled and the user change the month value,
   * the change is propagated to all months.
   * In this way the monthly form is already ready when the user submit the value.
   */
  private onMonthChange(): void {
    this.formGroup.get('monthlyLoss').valueChanges.subscribe(val => {
      this.monthFormGroup.patchValue({
        jan: val,
        feb: val,
        mar: val,
        apr: val,
        may: val,
        jun: val,
        jul: val,
        aug: val,
        sep: val,
        oct: val,
        nov: val,
        dec: val,
      });
    });
  }

  /**
   * If checkbox is checked application shows the 12 field for monthlyLoss disabling single monthlyLoss field.
   *
   * If checkbox is checked, are added required validators for monthlyForm.
   * @param change
   */
  disableSingleMonth(change: MatCheckboxChange): void {
    if (change.checked) {
      this.formGroup.get('monthlyLoss').disable();
      Object.keys(this.monthFormGroup.controls).forEach(key => {
        this.monthFormGroup.controls[key].addValidators(Validators.required);
      });
    } else {
      this.formGroup.get('monthlyLoss').enable();
      Object.keys(this.monthFormGroup.controls).forEach(key => {
        this.monthFormGroup.controls[key].removeValidators(Validators.required);
      });
    }
  }
}
