import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { GridServicesResultsTableComponent } from './grid-services-results-table.component';

@NgModule({
  declarations: [GridServicesResultsTableComponent],
  exports: [GridServicesResultsTableComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
})
export class GridServicesResultsTableModule {}
