import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[optimaDigitDecimalNumber]',
})
export class DigitDecimalNumberDirective {
  @Input() digit: number;
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: string[] = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const regex = new RegExp(`^\\d*\.?\\d{0,${this.digit}}$`);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    // TODO: manage better character allowed. Need a regex that allow only numbers with dot
    if (isNaN(Number(event.key)) && event.key !== '.' && event.key !== "'") {
      event.preventDefault();
    }
    const current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
}
