import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import { TimezonesService } from './timezones.service';
import { Project } from '@model';

@Injectable()
export class ProjectService {
  private _projects: Project[] = [];
  get projects(): Project[] {
    return this._projects;
  }

  private readonly projectsChangeEmitter = new Subject<void>();
  readonly projectsChange$ = this.projectsChangeEmitter.asObservable();

  private readonly searchFilterEmitter = new Subject<string>();
  readonly searchFilter$ = this.searchFilterEmitter.asObservable();

  private readonly selectProjectInListEmitter = new Subject<Project>();

  constructor(
    private resourceService: ResourceService<Project>,
    private timezoneService: TimezonesService,
    private alertService: GlobalAlertService,
  ) {}

  getProjects$(): Observable<Project[]> {
    return this.resourceService.getList(`projects`).pipe(
      tap(projects => {
        projects.forEach(p =>
          this.timezoneService.setTimezone(p.id, p.timezone),
        );
        this._projects = [...projects];
        this.projectsChangeEmitter.next();
      }),
      catchError((error: unknown) => {
        this.alertService.setError(error.toString());
        this.projectsChangeEmitter.error(error);
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  getProject$(projectId: number | string): Observable<Project> {
    return this.resourceService.getById('projects', projectId).pipe(
      tap(project => {
        const projectIndex = this._projects.findIndex(c => c.id === project.id);
        if (projectIndex === -1) {
          this._projects.unshift(project);
        } else {
          this._projects[projectIndex] = project;
        }
        this.timezoneService.setTimezone(project.id, project.timezone);
        this.projectsChangeEmitter.next();
      }),
    );
  }

  createProject$(project: Project | Partial<Project>): Observable<Project> {
    return this.resourceService.add(`projects`, project).pipe(
      tap(project => {
        this._projects.unshift(project);
        this.timezoneService.setTimezone(project.id, project.timezone);
        this.projectsChangeEmitter.next();
      }),
    );
  }

  updateProject$(
    id: string | number,
    project: Project | Partial<Project>,
  ): Observable<Project> {
    return this.resourceService.update('projects', id, project).pipe(
      tap(project => {
        const projectIndex = this._projects.findIndex(c => c.id === project.id);
        this._projects[projectIndex] = project;
        this.timezoneService.setTimezone(project.id, project.timezone);
        this.projectsChangeEmitter.next();
      }),
    );
  }

  deleteProject$(projectId: string): Observable<Project> {
    return this.resourceService.delete(`projects`, projectId).pipe(
      tap(() => {
        this._projects = this._projects.filter(
          project => project.id !== projectId,
        );
        this.projectsChangeEmitter.next();
      }),
      catchError((error: unknown) => {
        this.alertService.setError(error.toString());
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  sendValue(value: string): void {
    this.searchFilterEmitter.next(value);
  }

  selectProjectInList(project: Project): void {
    this.selectProjectInListEmitter.next(project);
  }
}
