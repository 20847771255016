import { Scenario, Tariff } from '@model';

export const setTariffs = (scenario: Scenario, ...tariffs: Tariff[]): void => {
  tariffs.forEach(tariff => {
    if (tariff.before) {
      if (tariff.dynamic) {
        if (tariff.importRate) {
          scenario.currentDynamicImportTariff = tariff;
        } else {
          scenario.currentDynamicExportTariff = tariff;
        }
      } else {
        scenario.currentTariff = tariff;
      }
    } else {
      if (tariff.dynamic) {
        if (tariff.importRate) {
          scenario.switchDynamicImportTariff = tariff;
        } else {
          scenario.switchDynamicExportTariff = tariff;
        }
      } else {
        scenario.switchTariff = tariff;
      }
    }
  });
};
