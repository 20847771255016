import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, HostListener } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Project, Scenario } from '@model';
import {
  CookieService,
  LoaderService,
  ProjectService,
  RouterService,
  ScenarioService,
  SidenavService,
  TreeService,
} from '@service';

interface TreeNode {
  label: string;
  id: string;
  children?: TreeNode[];
  type: 'PROJECT' | 'SCENARIO';
  parentId?: string;
}

@Component({
  selector: 'optima-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class TreeComponent {
  loaded = false;
  areProjects = false;
  selectedNode: TreeNode;
  expandedNodes = new Set<string>();
  projects: Project[] = [];
  scenarios: Scenario[] = [];
  treeData: TreeNode[] = [];
  treeHeight = '0px';
  filterValue = null;
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateHeight();
  }

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private sidenavService: SidenavService,
    private scenarioService: ScenarioService,
    private cookieService: CookieService,
    private routerService: RouterService,
    private loaderService: LoaderService,
    private treeService: TreeService,
  ) {
    this.projectService.projectsChange$.subscribe(() => {
      this.buildTree();
      this.areProjects = !!this.projectService.projects.length;
      if (!this.areProjects) {
        this.loaded = true;
      }
    });
    this.scenarioService.scenariosChange$.subscribe(loaded => {
      this.buildTree();
      this.loaded = this.loaded || !!loaded;
      if (this.loaded) {
        this.treeService.enableFilterInput();
      }
    });
    this.projectService.searchFilter$.subscribe(value => {
      if (!!!value) {
        this.dataSource.data = this.treeData;
        this.filterValue = null;
        this.autoSelectNodeInTree(true);
        return;
      }
      this.filterValue = value;
      this.dataSource.data = this.filterTreeByLabel(
        JSON.parse(JSON.stringify(this.treeData)),
        value,
      );
      this.expandOpenedNodes();
      this.autoSelectNodeInTree(true);
    });
    this.treeService.autoSelectInTree$.subscribe(() => {
      this.autoSelectNodeInTree();
    });
  }

  hasChild = (_: number, node: TreeNode): boolean =>
    !!node.children && node.children.length > 0;

  toggleButton(node: TreeNode): void {
    this.treeControl.isExpanded(node)
      ? this.expandedNodes.add(node.id)
      : this.expandedNodes.delete(node.id);
  }

  buildTree(): void {
    this.treeData = this.projectService.projects.map(project => {
      const filteredScenarios = this.scenarioService.scenarios.filter(
        scenario => scenario.parentId === project.id,
      );
      const scenariosForTree: TreeNode[] = filteredScenarios.map(s => {
        const scenarioTreeNode: TreeNode = {
          id: s.id,
          label: s.displayLabel,
          type: 'SCENARIO',
          parentId: project.id,
        };
        return scenarioTreeNode;
      });
      const projectForTree: TreeNode = {
        id: project.id,
        label: project.displayLabel,
        children: scenariosForTree,
        type: 'PROJECT',
      };
      return projectForTree;
    });
    this.dataSource.data = this.filterValue
      ? this.filterTreeByLabel(
          JSON.parse(JSON.stringify(this.treeData)),
          this.filterValue,
        )
      : this.treeData;
    if (this.selectedNode) {
      this.selectedNode = this.findNodeByIdAndType(
        this.treeData,
        this.selectedNode.id,
        this.selectedNode.type,
      );
    } else {
      this.autoSelectNodeInTree();
    }
    this.expandOpenedNodes();
    try {
      this.calculateHeight();
    } catch (e) {}
  }

  private expandOpenedNodes(): void {
    this.expandedNodes.forEach(nodeId => {
      const node = this.findNodeByIdAndType(
        this.dataSource.data,
        nodeId,
        'PROJECT',
      );
      this.treeControl.expand(node);
    });
  }

  createProject(): void {
    this.loaderService.showLoader('global');
    void this.sidenavService.closeIfMobile();

    const locale = this.cookieService.getLocale();
    this.projectService
      .createProject$({
        displayLabel: '',
        locale,
      })
      .pipe(
        switchMap(project => {
          const newProjectId = project.id;
          project.displayLabel = `Untitled Project ${newProjectId}`;
          return this.projectService.updateProject$(newProjectId, project);
        }),
      )
      .subscribe(project => {
        const flatTree = this.flatTree(this.treeData);
        const projectNode = flatTree.find(
          node => node.type === 'PROJECT' && node.id === project.id,
        );
        this.onProjectClicked(projectNode);
      });
  }

  onProjectClicked(project: TreeNode): void {
    this.loaderService.showLoader('global');
    void this.sidenavService.closeIfMobile();
    if (!this.routerService.isNavigating) {
      this.router
        .navigateByUrl(`/details/${project.id}/view-project`)
        .then(() => {
          this.selectedNode = project;
          this.autoSelectNodeInTree();
        });
    }
  }

  onScenarioClicked(scenario: TreeNode): void {
    this.loaderService.showLoader('global');
    void this.sidenavService.closeIfMobile();
    this.router
      .navigate([`/details/${scenario.id}/view-scenario`], {
        queryParams: { projectId: scenario.parentId },
      })
      .then(() => {
        this.selectedNode = scenario;
        this.autoSelectNodeInTree();
      });
  }

  calculateHeight(): void {
    const globalNavHeight = document.querySelector('global-nav').clientHeight;
    const sidenavHeaderHeight =
      document.querySelector('.sidenav-header').clientHeight;
    const createButtonHeight = document.querySelector(
      '#create-button-container',
    ).clientHeight;
    this.treeHeight = `${
      window.innerHeight -
      globalNavHeight -
      sidenavHeaderHeight -
      createButtonHeight
    }px`;
  }

  clicked(node: TreeNode): void {
    if (node.type === 'SCENARIO') {
      this.onScenarioClicked(node);
    } else {
      this.onProjectClicked(node);
    }
  }

  private flatTree(tree: TreeNode[]): TreeNode[] {
    const result = [];

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      result.push(node);
      if (node.children) {
        result.push(...node.children);
      }
    }

    return result;
  }

  private autoSelectNodeInTree(onlyScroll = false): void {
    if (
      window.location.href.includes('view-scenario') ||
      window.location.href.includes('create-scenario') ||
      window.location.href.includes('edit-scenario')
    ) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const projectId = params.get('projectId');
      const projectNode = this.treeData.find(node => node.id === projectId);
      if (!projectNode) {
        return;
      }
      const projectElement = document.getElementById(
        projectNode.id + 'PROJECT',
      );
      if (!projectElement) {
        setTimeout(() => this.autoSelectNodeInTree(onlyScroll), 100);
        return;
      }
      if (onlyScroll) {
        projectElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        return;
      }
      if (
        !(
          projectElement.attributes.getNamedItem('aria-expanded').value ===
          'true'
        )
      ) {
        const button = projectElement.querySelector('button');
        if (!button || !this.loaded) {
          setTimeout(() => this.autoSelectNodeInTree(onlyScroll), 100);
          return;
        }
        button.click();
        this.toggleButton(projectNode);
        projectElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
      let scenarioId: string;
      if (window.location.href.includes('create-scenario')) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        scenarioId = params.get('scenarioId');
      } else {
        scenarioId = this.scenarioService.getScenarioIdFromUrl();
      }
      this.selectedNode = this.findNodeByIdAndType(
        this.treeData,
        scenarioId,
        'SCENARIO',
      );
      if (!this.selectedNode) {
        setTimeout(() => this.autoSelectNodeInTree(onlyScroll), 100);
      }
    }
    if (window.location.href.includes('view-project')) {
      const projectId = this.getProjectIdFromUrl(window.location.href);
      this.selectedNode = this.treeData.find(node => node.id === projectId);
      const projectElement = document.getElementById(projectId + 'PROJECT');
      if (!projectElement) {
        return;
      }
      if (!this.loaded) {
        setTimeout(() => this.autoSelectNodeInTree(onlyScroll), 100);
      }
      projectElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  private getProjectIdFromUrl(url: string): string {
    const regex = /\/(\d+)\/view-project/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  private findNodeByIdAndType(
    nodeList: TreeNode[],
    id: string,
    type: 'PROJECT' | 'SCENARIO',
  ): TreeNode | null {
    for (const node of nodeList) {
      if (node.id === id && node.type === type) {
        return node;
      }
      if (node.children) {
        const foundNode = this.findNodeByIdAndType(node.children, id, type);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  }

  checkNode(node: TreeNode): boolean {
    if (!this.selectedNode) {
      return false;
    }
    return (
      this.selectedNode.id === node.id && this.selectedNode.type === node.type
    );
  }

  filterTreeByLabel(nodes: TreeNode[], label: string): TreeNode[] {
    function traverse(node: TreeNode): TreeNode | undefined {
      if (node.label?.toLowerCase().includes(label.toLowerCase())) {
        // If the node matches the label, return a new node with only its children
        return node;
      } else if (node.children) {
        // If the node does not match the label but has children, traverse them recursively
        const filteredChildren = node.children
          .map(traverse)
          .filter(n => n !== undefined);
        // If any of the children match the label, return a new node with only those children
        if (filteredChildren.length > 0) {
          return {
            ...node,
            children: filteredChildren,
          };
        }
      }
      // If the node and its children do not match the label, return undefined
      return undefined;
    }

    // Traverse the original tree and create a new tree with only the nodes that match the label
    return nodes.map(traverse).filter(n => n !== undefined);
  }
}
