import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { detectBrowser } from '@helper';
import { Bess, Ders, ScenarioOptions } from '@model';

interface EssTableRow {
  kw: number;
  kwh: number;
  essLabel: string;
  isCombo: boolean;
  isCustom: boolean;
  essItems: EssItem[];
}

interface EssItem {
  qty: number;
  essItemLabel?: string;
}

@Component({
  selector: 'optima-ess-summary-table',
  templateUrl: './ess-summary-table.component.html',
  styleUrls: ['./ess-summary-table.component.scss'],
})
export class EssSummaryTableComponent implements OnChanges {
  @Input() options: ScenarioOptions;
  @Input() ders: Ders[];
  @Input() bess: Bess[];
  bessMap: Map<string, Bess>;
  selectedEssList: EssTableRow[];
  isFirefox = detectBrowser().includes('Firefox');

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    this.selectedEssList = [];
    this.convertBessToMap();
    this.updateEssList();
  }

  updateEssList(): void {
    for (const der of this.ders) {
      const essItems: EssItem[] = [];
      if (der.custom === true) {
        let customLabel = 'Custom';
        this.translateService
          .get('scenario.form.energy_storage.list.custom')
          .subscribe((result: string) => {
            customLabel = result;
          });
        this.selectedEssList.push({
          kw: der.totalKw,
          kwh: der.totalKwh,
          essItems,
          essLabel: customLabel,
          isCombo: false,
          isCustom: true,
        });
      } else if (der.ess.length > 1) {
        let comboSizesLabel = '';
        let comboLabel = 'Combo';
        this.translateService
          .get('scenario.form.energy_storage.list.combo')
          .subscribe((result: string) => {
            comboLabel = result;
          });
        let count = 0;
        for (const ess of der.ess) {
          const matchedBess = this.bessMap.get(ess.id);
          comboSizesLabel = `${matchedBess.vendor} ${matchedBess.product} ${matchedBess.model}`;
          if (count < der.ess.length - 1) {
            comboSizesLabel = `${comboSizesLabel} + `;
          }

          essItems.push({ qty: ess.qty, essItemLabel: comboSizesLabel });
          count++;
        }

        this.selectedEssList.push({
          kw: der.totalKw,
          kwh: der.totalKwh,
          essItems,
          essLabel: comboLabel,
          isCombo: true,
          isCustom: false,
        });
      } else {
        const matchedBess = this.bessMap.get(der.ess[0].id);
        const essLabel = `${matchedBess.vendor} ${matchedBess.product}`;
        essItems.push({ qty: der.ess[0].qty });

        this.selectedEssList.push({
          essLabel,
          kw: der.totalKw,
          kwh: der.totalKwh,
          essItems,
          isCombo: false,
          isCustom: false,
        });
      }
    }
  }

  private convertBessToMap(): void {
    this.bessMap = new Map<string, Bess>();
    for (const bess of this.bess) {
      this.bessMap.set(bess.id, bess);
    }
  }
}
