import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { Project, Scenario } from '@model';
import {
  ProjectService,
  ScenarioService,
  TimezonesService,
  TreeService,
} from '@service';

@Component({
  selector: 'optima-project-scenario-table',
  templateUrl: './scenario-table.component.html',
  styleUrls: ['./scenario-table.component.scss'],
})
export class ScenarioTableComponent implements OnChanges, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() scenarios: Scenario[];
  @Input() project: Project;
  @Input() projectInvalid: boolean;
  @Output() projectInvalidChange: boolean;
  dataSource: MatTableDataSource<Scenario>;
  subscriptions = [];
  displayedColumns = [
    'status',
    'icons',
    'name',
    'updatedBy',
    'lastUpdatedDate',
    'actions',
  ];
  svgPath = '../../../../../assets/svg-icons/';
  imagesPath = '../../../../../assets/images/';
  scenariosLastSaved = new Map<string, Promise<string>>();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private scenarioService: ScenarioService,
    private treeService: TreeService,
    private projectService: ProjectService,
    public timezoneService: TimezonesService,
  ) {
    this.scenarioService.deleteScenarioStream$.subscribe(scenario => {
      const scenarioIndex = this.scenarios.findIndex(s => s.id === scenario.id);
      delete this.scenarios[scenarioIndex];
      this.scenarios = this.scenarios.filter(s => s);
      this.sort && (this.sort.active = null);
      this.initDataSource(this.scenarios);
    });
  }

  ngOnChanges(): void {
    this.scenarios.forEach(scenario => {
      this.scenariosLastSaved.set(
        scenario.id,
        this.timezoneService.getUserTimezoneLastUpdateDate(
          scenario.lastUpdatedDate,
        ),
      );
    });
    this.scenarios = this.scenarios.sort((a: Scenario, b: Scenario) => {
      return (
        new Date(b.lastUpdatedDate).valueOf() -
        new Date(a.lastUpdatedDate).valueOf()
      );
    });
    this.initDataSource(this.scenarios);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  createScenario(): void {
    this.treeService.doNotChangeRoute = true;
    this.router
      .navigate([`/details/${this.project.id}/create-scenario`], {
        queryParams: { projectId: this.project.id },
      })
      .then(() => {
        this.treeService.autoSelectInTree();
      });
  }

  editScenario(id: string): void {
    this.router
      .navigate([`/details/${id}/edit-scenario`], {
        queryParams: { projectId: this.project.id },
      })
      .then(() => {
        this.treeService.autoSelectInTree();
      });
  }

  cloneScenario(id: string): void {
    this.scenarioService
      .cloneScenario$(id)
      .pipe(
        switchMap((scenario: Scenario) => {
          scenario.displayLabel = scenario.displayLabel + ' (CLONE)';
          return this.scenarioService.updateScenario$(scenario.id, scenario);
        }),
      )
      .subscribe((scenario: Scenario) => {
        this.treeService.doNotChangeRoute = true;
        this.router
          .navigate([`/details/${scenario.id}/edit-scenario`], {
            queryParams: { projectId: scenario.parentId },
          })
          .then(() => {
            this.treeService.autoSelectInTree();
          });
      });
  }

  deleteScenario(scenario: Scenario): void {
    this.scenarioService.openDeleteProjectDialog(scenario);
  }

  sortData(sort: Sort): void {
    let data = this.scenarios.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(this.scenarios);
      return;
    }

    data = data.sort((a: Scenario, b) => {
      const isAscending = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return this.compare(a.runState ?? '', b.runState, isAscending);
        case 'name':
          return this.compare(a.displayLabel, b.displayLabel, isAscending);
        case 'createdBy':
          return this.compare(
            this.translate.instant('user.full.name', {
              first: a.createdBy?.firstName,
              last: a.createdBy?.lastSystemName,
            }),
            this.translate.instant('user.full.name', {
              first: b.createdBy?.firstName,
              last: b.createdBy?.lastSystemName,
            }),
            isAscending,
          );
        case 'dateTimeCreated':
          return this.compareDate(a.createdDate, b.createdDate, isAscending);
        case 'updatedBy':
          return this.compare(
            this.translate.instant('', {
              first: a.updatedBy?.firstName,
              last: a.updatedBy?.lastSystemName,
            }),
            this.translate.instant('', {
              first: b.updatedBy?.firstName,
              last: b.updatedBy?.lastSystemName,
            }),
            isAscending,
          );
        case 'lastUpdatedDate':
          return this.compareDate(
            a.lastUpdatedDate,
            b.lastUpdatedDate,
            isAscending,
          );
        default:
          return 0;
      }
    });

    this.initDataSource(data);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a: string, b: string, isAsc: boolean): number {
    const aa = new Date(a);
    const bb = new Date(b);
    return (aa < bb ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private initDataSource(scenarios: Scenario[]): void {
    if (!scenarios?.length) {
      return;
    }
    const initTable = (): void => {
      this.dataSource = new MatTableDataSource<Scenario>(scenarios);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    };
    setTimeout(initTable);
  }

  goToScenario(scenario: Scenario): void {
    this.router
      .navigate([`/details/${scenario.id}/view-scenario`], {
        queryParams: { projectId: this.project.id },
      })
      .then(() => {
        this.treeService.autoSelectInTree();
      });
  }

  showGridIcon(scenario: Scenario): boolean {
    return scenario.options.gridService
        && (!!scenario.programs.standard.length || !!scenario.programs.custom.length);
  }

  showTariffIcon(scenario: Scenario): boolean {
    return (
      scenario.options.tariffSwitch &&
      !!scenario.switchTariff?.id &&
      !!scenario.currentTariff?.id
    );
  }

  showSolarIcon(scenario: Scenario): boolean {
    return scenario.options.solarPv && !!scenario.pvloadId;
  }

  showEnergyStorageIcon(scenario: Scenario): boolean {
    const essToggle = scenario.options?.energyStorage;
    if (!essToggle) {
      return false;
    }
    if (essToggle && !scenario.ders) {
      return false;
    }
    return essToggle && this.scenarioService.hasDers(scenario);
  }
}
