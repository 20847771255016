import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, firstValueFrom, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CreateSolarComponent } from '../create-solar/create-solar.component';
import { UploaderComponent } from '@component';
import {
  LoadProfile,
  LoadProfileEnum,
  PvWattsArrayType,
  PvWattsModuleType,
  Scenario,
  SolarLoadProfile,
} from '@model';
import {
  LoadProfileService,
  LoaderService,
  ScenarioService,
  SolarPvService,
} from '@service';

@Component({
  selector: 'optima-solar-pv',
  templateUrl: './solar-pv.component.html',
  styleUrls: ['./solar-pv.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class SolarPvComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() scenario!: Scenario;
  @Input() loadProfiles!: SolarLoadProfile[];
  @Output() scenarioChange = new EventEmitter<[Scenario, Scenario, 'solar'?]>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(CreateSolarComponent) createSolarComponent!: CreateSolarComponent;
  @ViewChild(UploaderComponent)
  uploaderComponent: UploaderComponent<SolarLoadProfile>;
  radioChoice = 0;
  generatedByPvWatts: boolean;
  showCreate = false;
  formDataParameters: Map<string, string>;
  solarSystemSize: number;
  profileInHover: SolarLoadProfile;
  filteredProfiles: SolarLoadProfile[];
  solarProfileColumns: Map<string, string>;
  arrayType: PvWattsArrayType[];
  moduleType: PvWattsModuleType[];
  arrayTypeToDisplay: string;
  moduleTypeToDisplay: string;
  observer: ResizeObserver;
  tooltipMessage: string;

  constructor(
    public scenarioService: ScenarioService,
    private solarPvService: SolarPvService,
    private loadProfileService: LoadProfileService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
  ) {}

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnInit(): void {
    this.filteredProfiles = [...this.loadProfiles];
    this.formDataParameters = new Map<string, string>();
    this.solarSystemSize = null;
    this.solarProfileColumns = new Map<string, string>();
    this.solarProfileColumns.set('minKw', 'scenario.solar.min_power');
    this.solarProfileColumns.set('avgKw', 'scenario.solar.avg_power');
    this.solarProfileColumns.set('maxKw', 'scenario.solar.max_power');
    this.solarProfileColumns.set('totalKwh', 'scenario.solar.total_gen');
    this.tooltipMessage = this.translateService.instant(
      'scenario.solar.create.button.submit.error',
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['scenario'].firstChange) {
      setTimeout(() => this.initSearch());
    }
    this.checkPvGeneratedBy();
    this.arrayType = this.route.snapshot.data['scenarioForm'][0];
    this.moduleType = this.route.snapshot.data['scenarioForm'][1];
    this.arrayTypeToDisplay = this.arrayType.find(
      m => m.id === this.scenario.pvloadProfile?.pvParams?.arrayType,
    )?.displayLabel;
    this.moduleTypeToDisplay = this.moduleType.find(
      m => m.id === this.scenario.pvloadProfile?.pvParams?.moduleType,
    )?.displayLabel;
  }

  ngAfterViewInit(): void {
    this.initSearch();
    this.checkErrorContainerSize();
    this.startErrorContainerObserver();
  }

  setFormDataParameters(): void {
    if (this.solarSystemSize) {
      this.formDataParameters.set(
        'solarPvSize',
        this.solarSystemSize.toString(),
      );
    }
  }

  async updateLoadProfile(pvLoad: SolarLoadProfile): Promise<void> {
    const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
    this.scenario.pvloadId = pvLoad?.id;
    this.scenario.pvloadProfile = null;
    this.scenario.adjustedPvLoadProfile = null;
    this.scenarioChange.emit([this.scenario, scenarioBefore, 'solar']);
  }

  async uploaderCallback(pvLoadResponse: SolarLoadProfile): Promise<void> {
    if (pvLoadResponse !== null) {
      const scenario = await firstValueFrom(
        this.scenarioService.getScenario$(this.scenario.id),
      );
      this.scenario.adjustedPvLoadId = scenario.adjustedPvLoadId;
      pvLoadResponse.solarPvSize = this.solarSystemSize;
      this.scenario.pvloadId = pvLoadResponse.id;
      this.scenario.pvloadProfile = pvLoadResponse;
      const adjustedLoadProfile$ =
        this.scenario.loadprofileId && this.scenario.adjustedPvLoadId
          ? this.loadProfileService.loadProfile$(this.scenario.adjustedPvLoadId)
          : of(null);
      adjustedLoadProfile$
        .pipe(
          concatMap((adjustedLoadProfile: LoadProfile) => {
            this.scenario.adjustedPvLoadProfile = adjustedLoadProfile;
            return this.scenario.adjustedPvLoadProfile
              ? this.loadProfileService.getLoadProfileJson(
                  adjustedLoadProfile.id,
                )
              : of(null);
          }),
        )
        .subscribe({
          next: data => {
            if (this.scenario.adjustedPvLoadProfile?.id) {
              this.scenario.adjustedPvLoadProfile.json = data;
            }
            this.scenarioChange.emit([this.scenario, null]);
          },
          error: async () => {
            if (this.scenario.pvloadId) {
              this.uploaderComponent.remove(this.scenario.pvloadId);
            }
          },
        });
      this.solarPvService
        .pvLoadProfiles$(this.scenario.parentId)
        .subscribe(loadProfiles => {
          this.loadProfiles = loadProfiles;
          this.filteredProfiles = [...this.loadProfiles];
        });
    } else {
      this.loaderService.changeLabel('');
      this.radioChoice = 0;
      const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
      this.scenario.pvloadId = null;
      this.scenario.pvloadProfile = null;
      this.scenario.adjustedPvLoadProfile = null;
      this.scenarioChange.emit([this.scenario, scenarioBefore, 'solar']);
    }
  }

  private initSearch(): void {
    if (!this.searchInput?.nativeElement) {
      return;
    }
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          const value = this.searchInput.nativeElement.value;
          this.filteredProfiles = this.loadProfiles.filter(c =>
            c.filename.toLowerCase().includes(value.toLowerCase()),
          );
        }),
      )
      .subscribe();
  }

  showGraph(scenario: Scenario): void {
    this.arrayTypeToDisplay = this.arrayType.find(
      m => m.id === this.scenario.pvloadProfile?.pvParams?.arrayType,
    )?.displayLabel;
    this.moduleTypeToDisplay = this.moduleType.find(
      m => m.id === this.scenario.pvloadProfile?.pvParams?.moduleType,
    )?.displayLabel;
    this.showCreate = false;
    this.scenario = scenario;
    this.solarPvService
      .pvLoadProfiles$(scenario.parentId)
      .subscribe(loadProfiles => {
        this.loadProfiles = loadProfiles;
        this.filteredProfiles = [...this.loadProfiles];
        this.checkPvGeneratedBy();
      });
    this.scenarioChange.emit([this.scenario, null]);
  }

  radioChange(event: MatRadioChange): void {
    this.showCreate = event.value === 2;
  }

  private checkPvGeneratedBy(): void {
    this.generatedByPvWatts =
      this.scenario.pvloadProfile?.isGeneratedBy ===
      LoadProfileEnum.PV_WATTS_API;
  }

  // CSS SECTION

  private checkErrorContainerSize(): void {
    const errorContainer = document.getElementById(
      'solar-error-message-container',
    );
    const errorDivider = document.getElementById('solar-error-message-divider');
    if (!errorDivider) {
      return;
    }
    if (errorContainer.clientWidth < 684) {
      errorDivider.style.display = 'none';
    } else {
      errorDivider.style.display = 'inherit';
    }
  }

  private startErrorContainerObserver(): void {
    if (!document.getElementById('solar-error-message-container')) {
      return;
    }
    this.observer = new ResizeObserver(entries => {
      entries.forEach(() => {
        this.checkErrorContainerSize();
      });
    });

    this.observer.observe(
      document.getElementById('solar-error-message-container'),
    );
  }
}
