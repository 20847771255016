import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { ResultBillingTableComponent } from './result-billing-table.component';

@NgModule({
  declarations: [ResultBillingTableComponent],
  exports: [ResultBillingTableComponent],
  imports: [CommonModule, TranslateModule, MatTableModule, MatIconModule],
})
export class ResultBillingTableModule {}
