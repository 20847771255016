import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { getEnvironmentUrl } from "@helper";

const ICONSMAP = [
  'apply',
  'apply-white',
  'arrow',
  'arrow-red',
  'arrow-green',
  'arrow-down',
  'battery',
  'bolt',
  'building1',
  'calendar4',
  'capacity',
  'clear',
  'clear-pink',
  'clone',
  'copy',
  'delete',
  'delete-red',
  'deny-pink',
  'download-new',
  'edit',
  'edit-white',
  'electricity',
  'energy-storage',
  'ess-edit',
  'export-data',
  'export-data-white',
  'filter',
  'general-info',
  'hospital-white',
  'info',
  'info-circle',
  'grid',
  'grid-grey',
  'grid-white',
  'load-profile',
  'map2',
  'map-pin',
  'manage-white',
  'no-data',
  'oval-green',
  'oval-yellow',
  'oval-red',
  'pillar-white',
  'plus',
  'reset',
  'reset-white',
  'reset-pink',
  'run2',
  'search',
  'search-not',
  'search-new',
  'solar',
  'solar-grey',
  'solar-pv',
  'solar-pv-white',
  'spin',
  'spin-purple',
  'switch',
  'switch-grey',
  'tariff',
  'upload-new',
  'warning-red',
  'x',
  'x-white',
];

@Injectable()
export class IconsService {
  cdnUrl = getEnvironmentUrl();
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  load(): Promise<void> {
    return new Promise<void>(resolve => {
      ICONSMAP.forEach(ico => {
        this.iconRegistry.addSvgIcon(
          ico,
          this.sanitizer.bypassSecurityTrustResourceUrl(
            `${this.cdnUrl}/img/icons/${ico}.svg`,
          ),
        );
      });
      resolve();
    });
  }
}
