import { Component, Input } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { Scenario } from '@model';
import { GridProgramService } from '@service';

@Component({
  selector: 'optima-programs-monte-carlo',
  templateUrl: './programs-monte-carlo.component.html',
  styleUrls: ['./programs-monte-carlo.component.scss'],
})
export class ProgramsMonteCarloComponent {
  @Input() scenario: Scenario;

  constructor(
    private gridProgramService: GridProgramService,
    public translateService: TranslateService,
  ) {}

  selectionChange(event: MatSelectChange): void {
    const previousValue = JSON.parse(
      JSON.stringify(this.scenario.gridParameters.montecarloIterations),
    );
    this.scenario.gridParameters.montecarloIterations = event.value;
    this.gridProgramService
      .updateGridParameters$(this.scenario.id, this.scenario.gridParameters)
      .subscribe({
        error: () => {
          this.scenario.gridParameters.montecarloIterations = previousValue;
        },
      });
  }

  getTranslation(i: number): string {
    const key = `scenario.grid.monte_carlo.option${i + 1}`;
    const translations = this.translateService.instant(key);
    if (translations === key) {
      return `${i + 1}`;
    }
    return translations;
  }

  addCssForSelect(): void {
    const style = document.createElement('style');
    style.id = 'custom-style';
    style.innerText =
      '.mat-select-panel {' + 'max-width: unset !important' + '}';
    document.head.appendChild(style);
  }

  removeCssForSelect(): void {
    document.getElementById('custom-style').remove();
  }
}
