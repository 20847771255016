import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsListComponent } from './programs-list.component';

@NgModule({
  declarations: [ProgramsListComponent],
  exports: [ProgramsListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
  ],
})
export class ProgramsListModule {}
