<div class="container">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let column of valuesColumns; let row = index"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ columns.get(column) | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let stat"
        [class]="row === 0 && detailsShown ? 'purple-bordered-column' : ''"
      >
        <span *ngIf="column === 'billingPeriod'; else otherColumns">
          {{ stat[column] }}
        </span>
        <ng-template #otherColumns>
          <span>
            {{
              stat[column]
                | number: '1.0-0'
                | noComma
                | currency
                | removeDoubleDecimalZero
            }}
          </span>
        </ng-template>
      </td>
      <td
        mat-footer-cell
        colspan="5"
        *matFooterCellDef
        [hidden]="row > 0"
        class="pointer"
        (click)="showDetails()"
      >
        <div *ngIf="!detailsShown" class="flex justify-center">
          <div class="text-primary-color">
            {{ 'scenario.summary.show_details' | translate }}
          </div>
        </div>
        <div *ngIf="detailsShown" class="flex justify-center">
          <div class="text-primary-color">
            {{ 'scenario.summary.hide_details' | translate }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef class="arrow-header"></th>
      <td
        mat-cell
        *matCellDef="let stat; let row = index"
        class="arrow-container"
      >
        <img
          *ngIf="row === 0"
          [src]="imagesPath + 'arrow-forward.png'"
          [ngClass]="{ rotate: detailsShown }"
          class="arrow"
          alt=""
        />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let stat; columns: displayedColumns; let row = index"
      [@rowsAnimation]
      [style.font-weight]="row === 0 && detailsShown ? '700' : '400'"
      [class]="getRowClass(row)"
      (click)="row === 0 ? showDetails() : '#'"
    ></tr>
  </table>
</div>
