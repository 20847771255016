import { ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderComponent } from '../../shared/component/global/loader/loader.component';
import { NonEmptyString } from '@model';

@Injectable()
export class LoaderService {
  #_excludedUiUrls: string[] = [];
  #_regexEndpoints: RegExp[] = [];
  #_ignoreNext: string;
  #_ignoreAll = false;
  private _isLoading: boolean;
  private callInProgress = 0;
  private actualTranslatedString: string;
  readonly existingLoaders: ElementRef<LoaderComponent>[] = [];
  keptLoaders: HTMLElement[];
  private noHttp: string[] = [];

  constructor(private translationService: TranslateService) {}

  get isLoading(): boolean {
    return this._isLoading;
  }

  get excludedUiUrls(): string[] {
    return this.#_excludedUiUrls;
  }

  get ignoreAll(): boolean {
    return this.#_ignoreAll;
  }

  get ignoreNext(): string {
    return this.#_ignoreNext;
  }

  set regexEndpoints(patterns: string[]) {
    this.#_regexEndpoints = patterns.map(p => new RegExp(p));
  }

  set ignoreAll(ignore: boolean) {
    this.#_ignoreAll = ignore;
  }

  getRegexEndpoints(): RegExp[] {
    return this.#_regexEndpoints;
  }

  setExcludedUiUrls(...excluded: string[]): void {
    this.#_excludedUiUrls.push(...excluded);
  }

  start(): void {
    this._isLoading = true;
    this.callInProgress++;
  }

  end(): void {
    if (this.callInProgress === 0) {
      return;
    }
    this.callInProgress--;
    if (this.callInProgress === 0) {
      this._isLoading = false;
    }
  }

  terminate(): void {
    if (this.callInProgress === 0) {
      return;
    }
    this.callInProgress--;
    this._isLoading = false;
  }

  /**
   * Registers a new loader to the existing loaders array
   * @param newLoader the component to register
   */
  registerLoader(newLoader: ElementRef<LoaderComponent>): void {
    this.existingLoaders.forEach(loader => {
      loader.nativeElement['style']['display'] = 'none';
    });
    this.existingLoaders.push(newLoader);
  }

  /**
   * Called when a component that contains a loader is destroyed
   * @param loader to unregister
   */
  unregisterLoader(loader: ElementRef<LoaderComponent>): void {
    this.existingLoaders.splice(this.existingLoaders.indexOf(loader), 1);
    if (this.existingLoaders.length) {
      this.existingLoaders[this.existingLoaders.length - 1].nativeElement[
        'style'
      ]['display'] = 'inherit';
    }
  }

  setIgnoreNext(endpoint: string): void {
    this.#_ignoreNext = endpoint;
  }

  showLoaders(...elements: Element[]): void {
    this.hideAllLoaders();
    elements.forEach(element => {
      (<HTMLElement>element).style.display = 'inherit';
    });
    this.changeLabel();
  }

  showLoader<T extends string>(
    loaderId: NonEmptyString<T>,
    hideOthers = true,
  ): void {
    if (hideOthers) {
      this.hideAllLoaders();
    }
    const loader = document.querySelector(`optima-loader#${loaderId}`);
    (<HTMLElement>loader).style.display = 'inherit';
    this.changeLabel();
  }

  showLoaderNoHttp<T extends string>(
    loaderId: NonEmptyString<T>,
    maxOnce = false,
  ): void {
    const loader = document.querySelector(`optima-loader-no-http#${loaderId}`);
    (<HTMLElement>loader).style.display = 'inherit';
    const index = this.noHttp.indexOf(loaderId);
    if (maxOnce && index === -1) {
      this.noHttp.push(loaderId);
    }
    if (!maxOnce) {
      this.noHttp.push(loaderId);
    }
  }

  hideLoaderNoHttp<T extends string>(loaderId: NonEmptyString<T>): void {
    const indexBefore = this.noHttp.indexOf(loaderId);
    if (indexBefore === -1) {
      return;
    }
    this.noHttp.splice(indexBefore, 1);
    const indexAfter = this.noHttp.indexOf(loaderId);
    if (indexAfter === -1) {
      const loader = document.querySelector(
        `optima-loader-no-http#${loaderId}`,
      );
      (<HTMLElement>loader).style.display = 'none';
    }
  }

  resetPreviousLoaders(): void {
    this.hideAllLoaders(false);
    this.keptLoaders.forEach(loader => (loader.style.display = 'inherit'));
  }

  private hideAllLoaders(keep = true): void {
    const allLoaders = document.querySelectorAll('optima-loader');
    if (keep) {
      this.keptLoaders = (<HTMLElement[]>Array.from(allLoaders)).filter(
        loader => loader.style.display !== 'none',
      );
    }
    allLoaders.forEach(loader => {
      (<HTMLElement>loader).style.display = 'none';
    });
  }

  /**
   * Use null to reset the previous value
   * @param translationLabel
   */
  changeLabel(translationLabel?: string): void {
    if (translationLabel !== undefined) {
      this.actualTranslatedString = translationLabel;
    }
    const visibleLoaders = (<HTMLElement[]>(
      Array.from(document.querySelectorAll('optima-loader'))
    )).filter(loaders => loaders.style.display !== 'none');
    visibleLoaders.forEach(loader => {
      try {
        const oldLabel = loader.querySelector('h2').innerText;
        let label: string;
        if (
          this.actualTranslatedString === null ||
          this.actualTranslatedString === undefined
        ) {
          label = oldLabel;
        } else if (this.actualTranslatedString === '') {
          label = '';
        } else {
          label = this.translationService.instant(this.actualTranslatedString);
          if (label === this.actualTranslatedString) {
            throw new Error();
          }
        }
        loader.querySelector('h2').innerText = label;
      } catch (e) {
        setTimeout(() => this.changeLabel(), 50);
      }
    });
  }
}
