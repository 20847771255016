import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { LoadProfileTableComponent } from './load-profile-table.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [LoadProfileTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    PipeModule,
  ],
  exports: [LoadProfileTableComponent],
})
export class ProjectLoadProfileTableModule {}
