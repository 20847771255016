import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toCamel, toSnake } from 'convert-keys';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import { Bess, CustomDers, Ders, EssParameters } from '@model';

const CACHE_SIZE = 1;

@Injectable()
export class EssService {
  private dersCache = new Map<string, Observable<Ders[]>>();

  constructor(
    private bessService: ResourceService<Bess>,
    private httpClient: HttpClient,
    private dersService: ResourceService<Ders>,
    private parametersService: ResourceService<EssParameters>,
    private alertService: GlobalAlertService,
    private translateService: TranslateService,
  ) {}

  bess$(): Observable<Bess[]> {
    return this.bessService.getList(`bess`);
  }

  getDers$(scenarioId: string): Observable<Ders[]> {
    if (!this.dersCache.get(scenarioId)) {
      this.dersCache.set(
        scenarioId,
        this.dersService
          .getList(`scenarios/${scenarioId}/ders`)
          .pipe(shareReplay({ bufferSize: CACHE_SIZE, refCount: false })),
      );
    }
    return this.dersCache.get(scenarioId);
  }

  updateDers$(scenarioId: string, ders: Partial<Ders[]>): Observable<Ders[]> {
    this.dersCache.set(scenarioId, null);
    return this.dersService.addList(`scenarios/${scenarioId}/ders`, ders).pipe(
      catchError((error: unknown) => {
        this.alertService.setError(
          this.translateService.instant(
            'scenario.form.energy_storage.table.error',
          ),
        );
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  addCustomDers$(scenarioId: string, ders: CustomDers[]): Observable<Ders[]> {
    return this.httpClient
      .post<unknown>(
        `${ResourceService.API_LOCATION}/scenarios/${scenarioId}/ders-custom`,
        toSnake(ders),
      )
      .pipe(map(data => toCamel(data['data'])));
  }

  deleteDer$(scenarioId: string, derId: string): Observable<Ders> {
    this.dersCache.set(scenarioId, null);
    return this.dersService.delete(`scenarios/${scenarioId}/ders`, derId).pipe(
      catchError((error: unknown) => {
        this.alertService.setError(
          this.translateService.instant(
            'scenario.form.energy_storage.table.error',
          ),
        );
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  deleteAllDers$(scenarioId: string): Observable<Ders> {
    this.dersCache.set(scenarioId, null);
    return this.dersService.deletePath(`scenarios/${scenarioId}/ders`).pipe(
      catchError((error: unknown) => {
        this.alertService.setError(
          this.translateService.instant(
            'scenario.form.energy_storage.table.error',
          ),
        );
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  getParameters$(scenarioId: string): Observable<EssParameters> {
    return this.parametersService.get(`scenarios/${scenarioId}/ess-parameters`);
  }

  updateParameters$(
    scenarioId: string,
    parameters: EssParameters,
  ): Observable<EssParameters> {
    return this.parametersService.update(
      'scenarios',
      `${scenarioId}/ess-parameters`,
      parameters,
    );
  }
}
