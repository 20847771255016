import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CustomErrorStateMatcher, DynamicInput, ValidatorEnum } from '@model';

@Component({
  selector: 'optima-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent<T> implements OnChanges, AfterViewInit {
  @Input() dynamicInputOptions: DynamicInput;
  @Input() labelClasses: string;
  @Input() value: T;
  @Input() showPreviousValueError = true;
  @Output() inputChange = new EventEmitter<T>();
  @ViewChild('matInput') elementInput: ElementRef;
  @Input() minWidth? = 'auto';
  formControl: FormControl;
  hideInputField = true;
  previousValue: T;
  error: string;
  matcher = new CustomErrorStateMatcher();

  constructor(private translator: TranslateService) {}

  ngOnChanges(): void {
    this.formControl = new FormControl(this.value);
    this.previousValue = this.value;
    if (this.dynamicInputOptions.input) {
      Object.entries(this.dynamicInputOptions.input.validators).forEach(
        inputValidator => {
          const validator = this.getValidator(inputValidator);
          this.formControl.addValidators(validator);
        },
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.elementInput) {
      this.setError();
      fromEvent(this.elementInput.nativeElement, 'keyup')
        .pipe(
          tap(async () => {
            this.setError();
          }),
        )
        .subscribe();
      fromEvent(this.elementInput.nativeElement, 'keyup')
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          tap(async () => {
            if (!this.formControl.invalid) {
              this.inputChange.emit(this.elementInput.nativeElement.value);
              this.previousValue = this.elementInput.nativeElement.value;
            }
          }),
        )
        .subscribe();
    }
  }

  getValidator(
    validator: [string, number | string | boolean | null],
  ): (control: AbstractControl) => ValidationErrors | null {
    switch (validator[0]) {
      case ValidatorEnum.EMAIL:
        return Validators.email;
      case ValidatorEnum.REQUIRED:
        return Validators.required;
      case ValidatorEnum.MAX:
        return Validators.max(validator[1] as number);
      case ValidatorEnum.MIN:
        return Validators.min(validator[1] as number);
      case ValidatorEnum.MAX_LENGTH:
        return Validators.maxLength(validator[1] as number);
      case ValidatorEnum.MIN_LENGTH:
        return Validators.minLength(validator[1] as number);
      case ValidatorEnum.MAX_DECIMAL: {
        const numRegex = /^-?\d*[.,]?\d{0,var}$/;
        const regStr = numRegex.source;
        const newReg = regStr.replace('var', validator[1].toString());
        return Validators.pattern(new RegExp(newReg));
      }
      default:
        return null;
    }
  }

  stopClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  setError(): void {
    this.error = null;
    if (!this.formControl.errors) {
      return undefined;
    }
    if (this.formControl.errors['required']) {
      this.error = this.translator.instant('dynamic_input.error.required');
    }
    if (this.formControl.errors['pattern']) {
      const digit =
        this.dynamicInputOptions.input.validators[
          ValidatorEnum.MAX_DECIMAL
        ].toString();
      this.error = this.translator.instant('dynamic_input.error.maxDecimal', {
        digit,
      });
    }
    if (this.formControl.errors['min']) {
      const min =
        this.dynamicInputOptions.input.validators[ValidatorEnum.MIN].toString();
      this.error = this.translator.instant('dynamic_input.error.min', { min });
    }
  }
}
