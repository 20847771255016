import { Injectable } from '@angular/core';
import { locale, updateLocale } from 'moment';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from './cookie.service';
import { ResourceService } from './resource.service';
import { timeout } from '@helper';
import { Timezone } from '@model';

@Injectable()
export class TimezonesService {
  timezones: Timezone[];
  userTimezone: string;
  private projectTimezones = new Map<string, string>();

  constructor(
    private cookieService: CookieService,
    private resourceService: ResourceService<Timezone>,
  ) {
    this.timezones = [];
    const userLanguage = this.cookieService.getLocale().split('-')[0];
    locale(userLanguage);
  }

  loadTimezones(): Observable<Timezone[]> {
    this.updateLongDateFormat();
    return this.resourceService.getList('reference/timezones').pipe(
      tap(rawResp => {
        if (this.timezones && Array.isArray(this.timezones)) {
          while (this.timezones.length > 0) {
            this.timezones.pop();
          }
        }
        if (
          typeof rawResp !== undefined &&
          Array.isArray(rawResp) &&
          rawResp.length > 0
        ) {
          rawResp.sort(
            (a, b) => Number(a.displayLabel) - Number(b.displayLabel),
          );
          this.timezones.push(...rawResp);
          const sortedTimezones = this.timezones.sort(
            (t1, t2) => +t1.id - +t2.id,
          );
          const australian = sortedTimezones.pop();
          const coordinated = sortedTimezones.shift();
          this.timezones = [
            australian,
            coordinated,
            ...sortedTimezones.sort((t1, t2) =>
              t1.displayLabel.localeCompare(t2.displayLabel),
            ),
          ];
        }
      }),
    );
  }

  setTimezone(projectId: string, timezone: string): void {
    this.projectTimezones.set(projectId, timezone);
  }

  getTimezoneName(projectId: string): string {
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezoneName === projectTimezone,
    );
    return timezone?.timezoneName;
  }

  getTimezoneDisplayLabel(projectId: string): string {
    if (!this.timezones?.length) {
      return '-';
    }
    const projectTimezone = this.projectTimezones.get(projectId);
    const timezone = this.timezones.find(
      timezone => timezone.timezoneName === projectTimezone,
    );
    return timezone?.displayLabel ?? '-';
  }

  getDateTimeDisplayLabel(
    projectId: string,
    date: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format: string,
    timezone = true,
    keepLocalTime = false,
  ): string {
    if (!date) {
      return '';
    }
    const timezoneName = this.getTimezoneName(projectId);
    let momentDate = moment(date);
    // If there is no timezone for any reason, stick with GMT Time
    if (timezoneName && timezone) {
      momentDate = momentDate.tz(timezoneName, keepLocalTime);
    }
    return momentDate.format(format);
  }

  async getUserTimezoneLastUpdateDate(
    date: string,
    format = 'llll',
  ): Promise<string> {
    if (!date) {
      return '';
    }
    let momentDate = moment(date);
    while (!this.userTimezone) {
      await timeout(100);
    }
    momentDate = momentDate.tz(this.userTimezone, false);
    return momentDate.format(format);
  }

  getDateTimeDisplayLabelForChart(
    date: string,
    format: string,
    timezoneName: string,
  ): string {
    if (!date) {
      return '';
    }
    let momentDate = moment(date);
    momentDate = momentDate.tz(timezoneName, true);
    return momentDate.format(format);
  }

  getDateTimezoneOffset(projectId: string, date: string): number {
    const timezoneName = this.getTimezoneName(projectId);
    if (!timezoneName) {
      return -(new Date(date).getTimezoneOffset() * 60000);
    }
    const momentDate = moment(date);
    return momentDate.tz(timezoneName).utcOffset() * 60000;
  }

  adjustLocale(locale: string, lowerCase = true): string {
    if (lowerCase) {
      return locale.toLowerCase().replace('_', '-');
    }
    return locale.replace('_', '-');
  }

  private updateLongDateFormat(): void {
    const userLocale = this.adjustLocale(this.cookieService.getLocale());
    updateLocale(userLocale, {
      longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'MM/DD/YYYY',
        l: 'M/D/YYYY',
        LL: 'MMMM Do YYYY',
        ll: 'MMM D, YYYY',
        LLL: 'MMMM Do YYYY LT',
        lll: 'MMM D YYYY',
        LLLL: 'D MMMM YYYY LTS',
        llll: 'MMM D, YYYY LT',
      },
    });
  }
}
