import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { detectBrowser } from '@helper';
import {
  BillCharges,
  LoadProfile,
  LoadProfileJson,
  Project,
  Result,
  RunState,
  Scenario,
} from '@model';
import {
  ForecastService,
  LoadProfileService,
  LoaderService,
  ResultService,
  ScenarioService,
  SolarPvService,
  TimezonesService,
} from '@service';

@Component({
  selector: 'optima-scenario-result',
  templateUrl: './scenario-result.component.html',
  styleUrls: ['./scenario-result.component.scss'],
})
export class ScenarioResultComponent implements OnChanges, OnInit, OnDestroy {
  @Input() scenario: Scenario;
  @Input() project: Project;
  @Input() results: Result[];
  activeMode: 'historical' | 'forecasted' = 'historical';
  selectedConfigIndex: number;
  idx: number;
  lastCompleted: string;
  lastForecastCompleted: string;
  isFirefox: boolean;
  copiedData: string;
  queryParamSubscription: Subscription;
  afterSolarCharges: BillCharges;
  resultsCharges: BillCharges;
  resultsChargesForecasted: BillCharges;
  forecastResult: Result;
  showForecastResults = false;
  forecastAdjusted: LoadProfile;
  private interval: NodeJS.Timeout;
  private readonly FORECAST_POLLING_MINS = 1;
  protected readonly RunState = RunState;

  constructor(
    private cd: ChangeDetectorRef,
    public resultService: ResultService,
    private route: ActivatedRoute,
    public timezoneService: TimezonesService,
    private translateService: TranslateService,
    public solarPvService: SolarPvService,
    private scenarioService: ScenarioService,
    public forecastedService: ForecastService,
    public loaderService: LoaderService,
    private loadProfileService: LoadProfileService,
    private _snackBar: MatSnackBar,
  ) {
    this.isFirefox = detectBrowser().includes('Firefox');
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        switchMap(params => {
          return this.billAndResults(params);
        }),
      )
      .subscribe(([pvCharges, rezCharges, result]) => {
        this.setBillChargesResults(pvCharges, rezCharges, result);
      });
  }

  ngOnDestroy(): void {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
    if (this.forecastResult?.runState === RunState.STARTED) {
      this.loaderService.end();
    }
    this.stopInterval();
  }

  async ngOnChanges(change: SimpleChanges): Promise<void> {
    this.activeMode = 'historical';

    if (change['results']?.currentValue?.length === 0) {
      this.showForecastResults = false;
    }

    if (
      change['results']?.currentValue?.find(
        (config: Result) => config.runState === 'COMPLETED',
      )
    ) {
      const params: Params = {
        idx: this.route.snapshot.queryParams['idx'],
        tab: this.route.snapshot.queryParams['tab'],
      };
      this.billAndResults(params).subscribe(
        ([pvCharges, rezCharges, result]) => {
          this.setBillChargesResults(pvCharges, rezCharges, result);
        },
      );
    }
    await this.getLastSave();
    await this.getLastForecastSave();
  }

  async getLastSave(): Promise<void> {
    this.lastCompleted =
      await this.timezoneService.getUserTimezoneLastUpdateDate(
        this.scenario.runCompletedOn,
      );
    if (this.lastCompleted === '') {
      this.lastCompleted = this.translateService.instant(
        'scenario.results.last_completed.never',
      );
    }
  }

  async getLastForecastSave(): Promise<void> {
    this.lastForecastCompleted =
      await this.timezoneService.getUserTimezoneLastUpdateDate(
        this.forecastResult?.runCompletedOn
          ? this.forecastResult.runCompletedOn
          : '',
      );
    if (this.lastForecastCompleted === '') {
      this.showForecastResults = false;
    }
  }

  openSnackBar(): void {
    this._snackBar.open(
      this.translateService.instant('scenario.result.copied'),
      '',
      {
        duration: 3000,
      },
    );
  }

  areChanges(): boolean {
    if (this.scenario.results.length > 0) {
      return (
        new Date(this.scenario.lastUpdatedDate) >
        new Date(this.scenario?.runStartedOn)
      );
    }
    return false;
  }

  exportResults(): void {
    const scenarioId =
      this.route.snapshot.queryParamMap.get('scenarioId') ??
      this.route.snapshot.params['scenarioId'];
    this.resultService
      .getResultExport$(
        scenarioId,
        this.project.displayLabel,
        this.scenario.displayLabel,
        this.timezoneService.getDateTimeDisplayLabel(
          this.project.id,
          Date(),
          'YYYY-MMM-DD',
        ),
        this.timezoneService.getTimezoneName(this.project.id),
        this.forecastedService.isThereForecasted(this.scenario),
      )
      .subscribe(data => {
        const link = document.createElement('a');
        const blob = new Blob([data.buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        link.href = URL.createObjectURL(blob);
        link.download = data.filename + '.xlsx';
        link.click();
      });
  }

  setDataForCopy(data: string): void {
    this.activeMode = 'historical'; // A new config was clicked, so need to reset activeMode.
    this.copiedData = data;
    this.cd.detectChanges();
  }

  private billAndResults(
    params: Params,
  ): Observable<[BillCharges[], BillCharges[], Result]> {
    if (
      this.idx === Number(params['idx']) ||
      params['tab'] !== 'results' ||
      !this.results?.length ||
      this.scenario.id !== this.scenarioService.getScenarioIdFromUrl()
    ) {
      return EMPTY;
    }
    let idxParam = 1;
    if (params['idx'] && Number(params['idx']) <= this.results.length) {
      idxParam = Number(params['idx']);
    }
    this.stopInterval();
    this.idx = idxParam;
    this.selectedConfigIndex = this.results.findIndex(
      result => result.idx === idxParam,
    );
    const solarCharges$ = this.solarPvService.getSolarCharges$(this.scenario);
    const resultsCharges$ = this.resultService.getResultsCharges$(
      this.scenario,
      this.idx,
    );
    return forkJoin([
      solarCharges$,
      resultsCharges$,
      this.forecastedService.isThereForecasted(this.scenario)
        ? this.forecastResult$()
        : of(null),
    ]);
  }

  private setBillChargesResults(
    pvCharges: BillCharges[],
    rezCharges: BillCharges[],
    result: Result,
  ): void {
    this.setForecastData(result);
    this.afterSolarCharges =
      pvCharges.length > 0 ? pvCharges[pvCharges.length - 1] : null;
    this.resultsCharges =
      rezCharges?.length > 0 ? rezCharges[rezCharges.length - 1] : null;
  }

  runWithForecasted(): void {
    if (this.forecastResult.runState === RunState.STARTED) {
      return;
    }
    this.resultService.showForecastedRunningSpinner = true;
    const previousRunState = this.forecastResult.runState;
    this.forecastResult = {
      runState: RunState.STARTED,
    } as Result;
    this.loaderService.showLoaderNoHttp('forecast-run-loader', true);
    const run$ = this.forecastedService.runForecast$(
      this.scenario.id,
      this.idx,
    );
    run$.pipe(switchMap(() => this.forecastResult$())).subscribe({
      error: () => {
        this.forecastResult.runState = previousRunState;
        this.loaderService.hideLoaderNoHttp('forecast-run-loader');
        this.stopInterval();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (result: Result) => {
        this.setForecastData(result);
      },
    });
  }

  startInterval = (): void => {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(
      () => {
        this.forecastResult$().subscribe((result: Result) => {
          this.setForecastData(result);
        });
      },
      this.FORECAST_POLLING_MINS * 60 * 1000,
    );
  };

  stopInterval = (): void => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  };

  forecastResult$(): Observable<Result> {
    return this.resultService
      .getForecastResults$(this.scenario.id, this.idx)
      .pipe(
        tap((forecastResult: Result) => {
          if (!forecastResult?.runState) {
            this.showForecastResults = false;
            this.resultService.showForecastedRunningSpinner = false;
          } else {
            // If the forecasted results are stale, hide them
            this.showForecastResults =
              new Date(this.scenario.lastUpdatedDate) <
                new Date(forecastResult.runStartedOn) &&
              new Date(this.scenario.runStartedOn) <
                new Date(forecastResult.runStartedOn) &&
              forecastResult.runState === RunState.COMPLETED;

            this.resultService.showForecastedRunningSpinner =
              forecastResult.runState === RunState.STARTED;
          }
          this.forecastResult = forecastResult;
        }),
      );
  }

  private setForecastData(result: Result): void {
    this.forecastResult = result;
    if (this.forecastResult?.runState === RunState.STARTED) {
      this.loaderService.showLoaderNoHttp('forecast-run-loader', true);
      this.startInterval();
    } else {
      this.loaderService.hideLoaderNoHttp('forecast-run-loader');
      this.stopInterval();
    }
    if (this.forecastResult?.runState === RunState.COMPLETED) {
      this.resultService.showForecastedRunningSpinner = false;
      const adjustedLoadProfile$ = result.adjustedLoadProfileId
        ? this.loadProfileService.loadProfile$(result.adjustedLoadProfileId)
        : of(null);
      const adjustedJson$ = result.adjustedLoadProfileId
        ? this.loadProfileService.getLoadProfileJson(
            result.adjustedLoadProfileId,
          )
        : of(null);
      const resultsChargesForecasted$ = this.resultService.getResultsCharges$(
        this.scenario,
        this.idx,
        true,
      );
      forkJoin([
        adjustedLoadProfile$,
        adjustedJson$,
        resultsChargesForecasted$,
      ]).subscribe((data: [LoadProfile, LoadProfileJson[], BillCharges[]]) => {
        this.forecastAdjusted = data[0];
        if (this.forecastAdjusted) {
          this.forecastAdjusted.json = data[1];
        }
        this.resultsChargesForecasted =
          data[2]?.length > 0 ? data[2][data[2].length - 1] : null;
      });
    }
  }

  buttonChange(): void {
    if (this.activeMode === 'forecasted') {
      this.resultService.selectResult(this.forecastAdjusted.id, true);
    } else {
      this.resultService.selectResult(
        this.results.find(r => r.idx === this.idx).adjustedLoadProfileId,
      );
    }
  }

  showForecastError(): boolean {
    return this.forecastResult?.runState === RunState.ERROR &&
      ((this.scenario.runStartedOn < this.forecastResult.runCompletedOn) ||
      (this.scenario.runCompletedOn < this.forecastResult.runCompletedOn))
  }
}
