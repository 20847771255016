<form [formGroup]="formGroup" class="mt-4 mb-8">
  <div class="flex">
    <mat-form-field appearance="outline" class="mr-12">
      <mat-label>
        {{ 'scenario.grid.parameters.curtailment' | translate }}
        <mat-icon
          svgIcon="info"
          [matTooltip]="
            translateService.instant(
              'scenario.grid.parameters.curtailment.tooltip'
            )
          "
        ></mat-icon>
      </mat-label>
      <input
        id="curtailment"
        data-automation="grid-service-curtailment-input"
        matInput
        formControlName="curtailment"
        [errorStateMatcher]="matcher"
        optimaDigitDecimalNumber
        [digit]="1"
        required
      />
      <mat-error *ngIf="check('curtailment', 'required', formGroup)">
        {{ 'scenario.grid.parameters.error.curtailment.required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          check('curtailment', 'min', formGroup) ||
          check('curtailment', 'max', formGroup)
        "
      >
        {{ 'scenario.grid.parameters.error.curtailment.min_max' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="mr-12">
      <mat-label>
        {{ 'scenario.grid.parameters.discount' | translate }}
        <mat-icon
          svgIcon="info"
          [matTooltip]="
            translateService.instant(
              'scenario.grid.parameters.discount.tooltip'
            )
          "
        ></mat-icon>
      </mat-label>
      <input
        id="discount"
        data-automation="grid-service-discount-input"
        matInput
        formControlName="discount"
        [errorStateMatcher]="matcher"
        optimaDigitDecimalNumber
        [digit]="1"
        required
      />
      <mat-error *ngIf="check('discount', 'required', formGroup)">
        {{ 'scenario.grid.parameters.error.discount.required' | translate }}
      </mat-error>
      <mat-error
        *ngIf="
          check('discount', 'min', formGroup) ||
          check('discount', 'max', formGroup)
        "
      >
        {{ 'scenario.grid.parameters.error.discount.min_max' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
