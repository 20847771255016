import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Program, Scenario } from '@model';
import { GridProgramService } from '@service';

@Component({
  selector: 'optima-programs-selected',
  templateUrl: './programs-selected.component.html',
  styleUrls: ['./programs-selected.component.scss'],
})
export class ProgramsSelectedComponent implements OnInit, OnChanges {
  @Input() scenario: Scenario;
  @Input() customPrograms: Program[];
  @Output() scenarioEmitter = new EventEmitter<Scenario>();
  allPrograms: Program[];
  selectedPrograms: Program[];
  months = new Map([
    [1, 'Jan'],
    [2, 'Feb'],
    [3, 'Mar'],
    [4, 'Apr'],
    [5, 'May'],
    [6, 'Jun'],
    [7, 'Jul'],
    [8, 'Aug'],
    [9, 'Sep'],
    [10, 'Oct'],
    [11, 'Nov'],
    [12, 'Dec']
  ])


  constructor(
    private gridProgramService: GridProgramService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.updatePrograms()
  }

  ngOnChanges(): void {
    this.updatePrograms()
  }

  updatePrograms() {
    this.allPrograms = [...this.route.snapshot.data['scenarioForm'][2], ...this.customPrograms];
    this.selectedPrograms = [...this.scenario.programs.standard, ...this.scenario.programs.custom].map(programId => {
      const program = this.allPrograms.find(program => program.id === programId);
      program.toMonthName = this.months.get(program.toMonth);
      program.fromMonthName = this.months.get(program.fromMonth);
      return { ...program }
    });
  }

  delete(program: Program): void {
    this.scenario.programs.standard = this.scenario.programs.standard.filter(
      id => id !== program.id,
    );
    this.scenario.programs.custom = this.scenario.programs.custom.filter(
      id => id !== program.id,
    );
    this.selectedPrograms = this.selectedPrograms.filter((prog) => prog.id !== program.id );

    this.scenarioEmitter.emit(this.scenario);
    this.gridProgramService
      .deleteSelectedProgram$(program.id, this.scenario.id)
      .subscribe({
        error: () => {
          this.scenario.programs.standard.push(program.id);
        },
      });
  }
}
