import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { TreeComponent } from './tree.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [TreeComponent],
  exports: [TreeComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatRippleModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    PipeModule,
  ],
})
export class TreeModule {}
