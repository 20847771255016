<div class="flex flex-col justify-start items-center">
  <div class="w-full flex justify-center items-center title-heading">
    <div
      class="flex justify-start items-center justify-self-start margin-right-auto icon-header"
    >
      <div class="icon-container">
        <mat-icon svgIcon="manage-white" class="header-icon"></mat-icon>
      </div>
      <div [ngClass]="{ 'mb-4': scenarioNameFormControl.invalid }">
        <optima-inline-edit
          #scenarioEdit
          ngDefaultControl
          htmlTag="h1"
          inputType="input"
          [(formControl)]="scenarioNameFormControl"
          [label]="scenario.displayLabel"
          [fieldToUpdate]="'displayLabel'"
          [callback]="updateScenario"
        ></optima-inline-edit>
        <h2
          id="parent-title"
          class="parent-title"
          data-automation="createTitle"
          [ngClass]="{ 'mt-3': scenarioEdit.isEditing }"
        >
          {{ project?.displayLabel || project?.id }}
        </h2>
      </div>

      <div class="summary-result">
        <div class="flex items-center">
          <h3
            [ngClass]="{ 'active-heading': mode === 'summary' }"
            id="summary"
            (click)="changeTab('summary')"
            class="tab"
          >
            {{ 'scenario.summary' | translate }}
          </h3>
          <h3
            [ngClass]="{ 'active-heading': mode === 'results' }"
            id="results"
            (click)="changeTab('results')"
            class="tab"
          >
            {{ 'scenario.result' | translate }}
            <mat-icon
              class="tab-warning-icon text-error-red"
              matTooltip="{{ 'scenario.results.warning' | translate }}"
              *ngIf="hasChanges()"
              data-automation="results-tab-warning"
            >
              warning
            </mat-icon>
          </h3>
        </div>
      </div>
    </div>
    <div
      class="flex justify-start items-center justify-self-end margin-left-auto"
    >
      <div
        *ngIf="mode === 'summary'"
        class="flex items-center justify-end button-container ml-6"
      >
        <button
          id="delete-scenario-button"
          data-automation="delete-scenario-button"
          (click)="openDeleteProjectDialog()"
          class="x-btn x-btn-primary delete-scenario-button flex items-center"
          matRipple
        >
          <mat-icon svgIcon="delete"></mat-icon>
          <span class="ml-2">{{ 'project.button.delete' | translate }}</span>
        </button>

        <button
          class="x-btn x-btn-secondary flex justify-start items-center"
          (click)="editScenario(scenario.id)"
          mat-ripple
          aria-label="Edit"
          data-automation="edit"
        >
          <mat-icon svgIcon="edit" class="edit-icon"></mat-icon>
          <span class="button-label">
            {{ 'scenario.button.edit' | translate }}
          </span>
        </button>
      </div>

      <div
        [popper]="myPopover"
        [popperApplyClass]="'run-popover'"
        [popperDisabled]="resultService.runEnable"
        [popperTrigger]="'hover'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="true"
        [popperHideOnMouseLeave]="true"
        [popperStyles]="{ width: 'fit-content' }"
      >
        <button
          *ngIf="mode === 'results'"
          class="flex justify-start items-center x-btn x-btn-primary w-inherit x-btn-icon"
          mat-ripple
          aria-label="run"
          data-automation="run-button"
          (click)="startRun()"
          [disabled]="!resultService.runEnable"
        >
          <mat-icon
            svgIcon="spin"
            [ngClass]="{ rotating: resultService.showRunningSpinner }"
          ></mat-icon>
          <span class="ml-3">
            {{ 'scenario.button.run_results' | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div [ngSwitch]="mode" class="page-container scenario">
  <optima-scenario-summary
    *ngSwitchCase="'summary'"
    [scenario]="scenario"
    [startRun]="startRun"
    [project]="project"
  ></optima-scenario-summary>
  <optima-scenario-result
    *ngSwitchCase="'results'"
    [scenario]="scenario"
    [results]="scenario.results"
    [project]="project"
  ></optima-scenario-result>
</div>

<popper-content #myPopover>
  <ul>
    <li
      *ngFor="let key of scenarioService.runDisabledTranslationKeys"
      class="list-item"
    >
      <span>{{ key | translate }}</span>
    </li>
  </ul>
</popper-content>
