<div *ngIf="scenario.tariffParameters" class="flex options-container">
  <mat-checkbox
    [(ngModel)]="scenario.tariffParameters.nemNetting"
    (ngModelChange)="update()"
  >
    {{ 'scenario.tariff.nem' | translate }}
  </mat-checkbox>
  <mat-icon
    class="info-circle"
    svgIcon="info-circle"
    matTooltip="{{ 'scenario.form.tariff.nem_netting' | translate }}"
    alt="info-circle"
    height="14"
    width="14"
  ></mat-icon>
</div>
