import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toSnake } from 'convert-keys';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { GridParameters, GridStats, Program, Scenario, ScenarioPrograms } from '@model';
import { CustomGridUpload } from "../../shared/model/custom-grid-upload.model";

interface ArrayResponse {
  data: string[];
  status: string;
  code: number;
}

interface ComboResponse {
  data: ScenarioPrograms;
  status: string;
  code: number;
}


@Injectable()
export class GridProgramService {
  private readonly filterValueEmitter = new Subject<string>();
  readonly filterValue$ = this.filterValueEmitter.asObservable();
  private gridStatsCache = new Map<string, Observable<GridStats[]>>();

  constructor(
    private programResourceService: ResourceService<Program>,
    private gridProgramServiceResourceService: ResourceService<GridParameters>,
    private gridStatsResourceService: ResourceService<GridStats>,
    private customGridUploadResourceService: ResourceService<CustomGridUpload>,
    private http: HttpClient,
  ) {}

  programs$(): Observable<Program[]> {
    return this.programResourceService.getList(`programs`);
  }

  customPrograms$(scenarioId: string): Observable<Program[]> {
    return this.programResourceService.getList(`scenarios/${scenarioId}/programs/custom`);
  }

  deleteCustomPrograms$(scenarioId: string): Observable<CustomGridUpload> {
    return this.customGridUploadResourceService.deletePath(`scenarios/${scenarioId}/files/programs/custom-file`);
  }

  programsDetail$(scenarioId: string): Observable<Program[]> {
    return this.programResourceService.getList(
      `scenarios/${scenarioId}/programs/details`,
    );
  }

  updateSelectedProgram$(
    programId: string,
    scenarioId: string,
    isCustom = false
  ): Observable<void[]> {
    let url = `api/v1/scenarios/${scenarioId}/programs/${programId}`
    if (isCustom) {
      url = url + '?is_custom=true'
    }
    return this.http
      .put<ArrayResponse>(url, {})
      .pipe(map(() => null));
  }

  deleteSelectedProgram$(
    programId: string,
    scenarioId: string,
  ): Observable<void> {
    return this.http
      .delete<ArrayResponse>(`api/v1/scenarios/${scenarioId}/programs/${programId}`)
      .pipe(map(() => null));
  }

  gridParameters$(scenarioId: string): Observable<GridParameters> {
    return this.gridProgramServiceResourceService.get(
      `scenarios/${scenarioId}/grid-services-parameters`,
    );
  }

  updateGridParameters$(
    scenarioId: string,
    parameters: GridParameters,
  ): Observable<GridParameters> {
    return this.http.put<GridParameters>(
      `api/v1/scenarios/${scenarioId}/grid-services-parameters`,
      toSnake(parameters),
    );
  }

  getSelectedPrograms$(scenarioId: string): Observable<ScenarioPrograms> {
    return this.http
      .get<ComboResponse>(`api/v1/scenarios/${scenarioId}/programs`)
      .pipe(map(response => response.data));
  }

  getGridStats$(
    scenario: Scenario,
    idx: number,
    forecasted: boolean,
  ): Observable<GridStats[]> {
    let KEY = `${scenario.id}${idx}${scenario.lastUpdatedDate}${scenario.runCompletedOn}`;
    if (forecasted) {
      KEY += 'forecasted';
    }
    if (!this.gridStatsCache.has(KEY)) {
      this.gridStatsCache.set(
        KEY,
        this.gridStats$(scenario.id, idx, forecasted).pipe(shareReplay(1)),
      );
    }
    return this.gridStatsCache.get(KEY);
  }

  private gridStats$(
    scenarioId: string,
    idx: number,
    forecasted: boolean,
  ): Observable<GridStats[]> {
    let path = `scenarios/${scenarioId}/results/${idx}/grid-services-stats`;
    if (forecasted) {
      path += '?is_forecast=true';
    }
    return this.gridStatsResourceService.getList(path);
  }

  tableFilter(filterValue: string): void {
    this.filterValueEmitter.next(filterValue);
  }
}
