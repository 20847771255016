<div
  class="programs-table-container"
  [ngStyle]="{ 'overflow-y': isFirefox ? 'auto' : 'overlay' }"
  *ngIf="dataSource.data.length && dataSource.filteredData.length"
>
  <table
    data-automation="grid-service-programs-table"
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
  >
    <ng-container matColumnDef="style">
      <th
        [ngStyle]="{ display: 'none' }"
        mat-header-cell
        *matHeaderCellDef
        [attr.rowspan]="2"
      ></th>
      <td
        mat-cell
        *matCellDef="let program"
        [attr.rowspan]="2"
        [ngClass]="{ 'background-primary': selection.isSelected(program.id) }"
      ></td>
    </ng-container>

    <ng-container matColumnDef="checkbox">
      <th
        [ngStyle]="{ display: 'none' }"
        mat-header-cell
        *matHeaderCellDef
        [attr.rowspan]="2"
      ></th>
      <td mat-cell *matCellDef="let program" [attr.rowspan]="2">
        <mat-checkbox
          (click)="checkProgram($event, program.id)"
          (change)="$event ? selection.toggle(program.id) : null"
          [checked]="selection.isSelected(program.id)"
          data-automation="grid-service-table-program-checkbox"
          color="primary"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="operator">
      <th
        data-automation="grid-program-table-column-label"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ 'scenario.grid.program.operator' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let program"
        data-automation="grid-service-table-operator"
      >
        {{ program.operator }}
      </td>
    </ng-container>

    <ng-container matColumnDef="zone">
      <th
        data-automation="grid-program-table-column-label"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ 'scenario.grid.program.zone' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let program"
        data-automation="grid-service-table-zone"
      >
        {{ program.zone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="meterType">
      <th
        data-automation="grid-program-table-column-label"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ 'scenario.grid.program.meter' | translate }}
      </th>
      <td mat-cell *matCellDef="let program">{{ program.meterType }}</td>
    </ng-container>

    <ng-container matColumnDef="programMonths">
      <th
        data-automation="grid-program-table-column-label"
        mat-header-cell
        *matHeaderCellDef
      >
        {{ 'scenario.grid.program.months' | translate }}
      </th>
      <td mat-cell *matCellDef="let program" [attr.rowspan]="2">
        <div class="flex flex-col">
          <div class="flex">
            <div
              class="program-type"
              [class]="getProgramTypeColorClass(program.programType)"
            >
              {{ program.programType }}
            </div>
            <div class="ml-2 program-revenue">
              <span
                *ngFor="let item of [].constructor(4); let i = index"
                [ngClass]="{ green: program.revenue > i }"
              >
                $
              </span>
            </div>
          </div>
          <div class="flex months-margins">
            {{ monthName(program.fromMonth) }} -
            {{ monthName(program.toMonth) }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="header-row-first-group">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"></th>
    </ng-container>
    <ng-container matColumnDef="header-row-second-group">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        <!--        <mat-checkbox (change)="$event ? masterToggle() : null"-->
        <!--                      [checked]="selection.hasValue() && isAllSelected()"-->
        <!--                      [indeterminate]="selection.hasValue() && !isAllSelected()">-->
        <!--        </mat-checkbox>-->
      </th>
    </ng-container>
    <ng-container matColumnDef="header-row-third-group">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="4">
        {{ 'scenario.grid.program.available' | translate }} /
        {{ 'scenario.grid.program.type' | translate }} /
        {{ 'scenario.grid.program.revenue' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let program"
        data-automation="grid-service-table-program-name"
        [attr.colspan]="3"
      >
        {{ program.programName }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="
        [
          'header-row-first-group',
          'header-row-second-group',
          'header-row-third-group'
        ];
        sticky: true
      "
    ></tr>
    <tr
      mat-header-row
      *matHeaderRowDef="
        ['style', 'checkbox', 'operator', 'zone', 'meterType', 'programMonths'];
        sticky: true
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let program;
        columns: [
          'style',
          'checkbox',
          'header-row-third-group',
          'programMonths'
        ]
      "
      [ngClass]="{ 'background-grey': selection.isSelected(program.id) }"
    ></tr>
    <tr
      mat-row
      *matRowDef="let program; columns: ['operator', 'zone', 'meterType']"
      [ngClass]="{ 'background-grey': selection.isSelected(program.id) }"
    ></tr>
  </table>
</div>

<div
  class="flex w-full justify-start items-center"
  *ngIf="!dataSource.data.length || !dataSource.filteredData.length"
>
  <mat-icon svgIcon="search-not" class="search-not-icon"></mat-icon>
  <div class="flex flex-col justify-center items-start">
    <span class="no-programs" data-automation="grid-programs-not-found">
      {{ 'scenario.form.grid.programs.not_found' | translate }}
    </span>
    <span class="no-programs detail">
      {{ 'scenario.form.grid.programs.not_found.detail' | translate }}
    </span>
  </div>
</div>
