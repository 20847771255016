export interface Ders {
  auxExtreme: number;
  auxModerate: number;
  id: string;
  ess: Ess[];
  rte: number;
  totalKw: number;
  totalKwh: number;
  custom?: boolean;
}

export interface Ess {
  id: string;
  qty: number;
}

export interface CustomDers {
  aux: number;
  rte: number;
  totalKw: number;
  totalKwh: number;
}

export class Ders {
  constructor(id: string, qty: number) {
    this.ess = [{ id, qty }];
  }
}
