import { DOCUMENT } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import {
  GlobalAlertService,
  LoaderService,
  LoginRedirectService,
} from '@service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private loginRedirect: LoginRedirectService,
    private globalAlertService: GlobalAlertService,
    private loaderService: LoaderService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let lastResponse;
    let error;

    return next.handle(request).pipe(
      map(event => {
        lastResponse = event;
        return event;
      }),
      catchError((err: unknown) => {
        error = err;
        this.loaderService.terminate();
        if (err['message'].toString().includes('i18n')) {
          return EMPTY;
        }
        if (!(err instanceof HttpErrorResponse)) {
          return throwError(() => new Error(err.toString()));
        }
        switch (err.status) {
          case 401:
            if (!this.loginRedirect.urlToNavigate) {
              this.loginRedirect.setLogout();
            } else {
              this.document.location.href = this.loginRedirect.urlToNavigate;
            }
            break;
          case 403:
            this.redirectToForbidden();
            break;
        }

        const errorMessage = `${err.error?.code}: ${err.error?.message} - ${err.error?.detail}`;
        this.globalAlertService.setError(errorMessage);
        return throwError(() => new Error(errorMessage));
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          // last response type was 0, and we haven't received an error
          this.loaderService.end();
        }
      }),
    );
  }

  redirectToForbidden(): void {
    const forbiddenPath = 'forbidden/index.html';
    this.document.location.href = `${document.location.origin}/${forbiddenPath}?type=forbidden`;
  }
}
