import { DatePipe } from '@angular/common';
import {
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { DialogComponent } from '@component';
import { DialogConfig, LoadProfile, Project, Scenario } from '@model';
import {
  DialogService,
  ProjectService,
  ScenarioService,
  TimezonesService,
} from '@service';
import { noWhitespaceValidator } from '@validator';

@Component({
  selector: 'optima-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: [DatePipe],
})
export class ProjectComponent implements OnInit, OnDestroy, OnChanges {
  dialogRef: MatDialogRef<DialogComponent>;
  subscriptions = [];
  projectNameFormControl: UntypedFormControl;
  project: Project;
  dialog: MatDialog;
  scenarios: Scenario[];
  loadProfiles: LoadProfile[];
  projectInvalid = false;
  projectLastSaved: Promise<string>;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent): void {
    if (
      (<Window>event.currentTarget).location.pathname.includes(
        `details/${this.scenarioService.scenarioDeleted.id}`,
      )
    ) {
      history.forward();
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    event.preventDefault();
  }

  constructor(
    private timezoneService: TimezonesService,
    private datePipe: DatePipe,
    private projectService: ProjectService,
    private scenarioService: ScenarioService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const paramSubscription = this.route.data.subscribe(data => {
      this.project = data['project'][1];
      this.setUpForm();
      this.projectInvalid =
        !this.project?.timezone || !this.project?.location?.address;
      this.projectService.selectProjectInList(this.project);

      this.scenarios = data['project'][0];
      this.loadProfiles = data['project'][2];
    });
    this.projectLastSaved = this.timezoneService.getUserTimezoneLastUpdateDate(
      this.project.lastUpdatedDate,
      'lll',
    );
    this.subscriptions.push(paramSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(): void {
    this.projectLastSaved = this.timezoneService.getUserTimezoneLastUpdateDate(
      this.project.lastUpdatedDate,
      'lll',
    );
  }

  openDeleteProjectDialog(): void {
    const config: DialogConfig = {
      title: this.translate.instant('dialog.project.delete.title'),
      contentText: this.translate.instant('dialog.project.delete.content'),
      firstButtonText: this.translate.instant(
        'dialog.project.delete.first_button',
      ),
      secondButtonText: this.translate.instant(
        'dialog.project.delete.second_button',
      ),
      firstButtonCallback: this.deleteProjectCallback,
      secondButtonCallback: this.closeDialogCallback,
    };
    this.dialogRef = this.dialogService.openDialog(config, { width: '600px' });
  }

  deleteProjectCallback = (): void => {
    this.projectService
      .deleteProject$(this.project.id)
      .pipe(switchMap(() => this.projectService.getProjects$()))
      .subscribe({
        error: () => this.dialogRef.close(),
        complete: () => {
          this.dialogRef.close();
          void this.router.navigateByUrl('/');
        },
      });
  };

  closeDialogCallback = (): void => {
    this.dialogRef.close();
  };

  updateProject = (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof Project,
  ): Observable<Project> => {
    if (fieldToUpdate.valueOf() === 'startDate') {
      this.project.startDate = this.datePipe.transform(
        formControl.value,
        'MM-dd-yyyy',
      );
    } else {
      this.project[fieldToUpdate.valueOf()] = formControl.value;
    }
    this.projectInvalid = !(
      this.project?.timezone?.length > 0 &&
      this.project.location?.address?.length > 0
    );
    return this.projectService
      .updateProject$(this.project.id, this.project)
      .pipe(
        take(1),
        tap(project => {
          this.project = project;
        }),
      );
  };

  private setUpForm(): void {
    this.projectNameFormControl = new UntypedFormControl(
      this.project.displayLabel || '',
      [Validators.required, noWhitespaceValidator()],
    );
  }
}
