import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { LoaderModule } from '../../../shared/component/global/loader/loader.module';
import { GridSummaryModule } from '../../../shared/component/scenario/form/grid-summary/grid-summary.module';
import { ResultTreemapModule } from '../../../shared/component/scenario/result-treemap/result-treemap.module';
import { SiteConstraintModule } from '../../../shared/component/scenario/site-constraint/site-constraint.module';
import { ScenarioSummaryComponent } from './scenario-summary.component';
import {
  EssSummaryTableModule,
  MultiLoadProfileChartModule,
  MultiLoadProfileStatsTableModule,
  TariffTableModule,
} from '@component';

@NgModule({
  declarations: [ScenarioSummaryComponent],
  exports: [ScenarioSummaryComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TariffTableModule,
    EssSummaryTableModule,
    MatIconModule,
    SiteConstraintModule,
    MultiLoadProfileChartModule,
    MultiLoadProfileStatsTableModule,
    MatTooltipModule,
    MatRippleModule,
    GridSummaryModule,
    NgxPopperjsModule,
    ResultTreemapModule,
    LoaderModule,
  ],
})
export class ScenarioSummaryModule {}
