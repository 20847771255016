import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LoadProfileStats } from '@model';

@Component({
  selector: 'optima-load-profile-table',
  templateUrl: './load-profile-table.component.html',
  styleUrls: ['./load-profile-table.component.scss'],
})
export class LoadProfileTableComponent implements OnChanges {
  @Input() stats: LoadProfileStats;
  @Input() columns: Map<string, string>;
  @Input() onHover = false;
  statsArray: LoadProfileStats[];

  dataSource: MatTableDataSource<LoadProfileStats>;
  displayedColumns: string[];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.statsArray = [this.stats];
    this.displayedColumns = Array.from(this.columns.keys());
    this.dataSource = new MatTableDataSource(this.statsArray);
  }
}
