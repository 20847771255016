<div
  class="flex flex-row justify-start"
  [ngClass]="scenario.loadprofileId ? 'items-start' : 'items-stretch'"
>
  <div
    class="flex flex-col existing-profile-container"
    *ngIf="!scenario.loadprofileId"
  >
    <p class="load-profiles-title">
      {{ 'scenario.energy_use.search_profile' | translate }}
    </p>
    <div class="flex items-center search-container">
      <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
      <input
        class="search-input"
        placeholder="{{ 'project.search' | translate }}"
        [disabled]="!loadProfiles?.length"
        #searchInput
      />
    </div>
    <div class="load-profiles-container" *ngIf="loadProfiles?.length">
      <div
        class="flex items-center"
        *ngFor="let profile of filteredProfiles"
        (click)="updateLoadProfile(profile)"
        (mouseenter)="profileInHover = profile"
      >
        <input type="radio" name="profile" />
        <label class="load-profile-text">
          {{ profile.filename | trimFileExtension | stringTruncate }}
        </label>
        <mat-icon
          svgIcon="general-info"
          class="pop-icon"
          [popper]="myPopover"
          [popperTrigger]="'hover'"
          [popperPlacement]="'right'"
          [popperDisableStyle]="true"
          [popperDisableAnimation]="true"
          [popperHideOnMouseLeave]="true"
          [popperStyles]="{ width: 'min-content' }"
          alt="general-info"
        ></mat-icon>
      </div>
    </div>
    <div class="load-profiles-container" *ngIf="!loadProfiles?.length">
      <p class="text-light-color">
        {{ 'scenario.energy_use.no_files_found' | translate }}
      </p>
    </div>
  </div>
  <div
    class="uploader-container"
    [ngClass]="{ 'uploader-container-width': !loadProfile }"
  >
    <optima-uploader
      #uploaderLoadProfile
      title="{{ 'uploader.load_profile.title' | translate }}"
      fileLabel="{{ 'uploader.load_profile.file_label' | translate }}"
      [url]="scenarioService.apiUrl + '/load-profiles'"
      [createMethod]="'post'"
      [accept]="'.csv'"
      [formDataParameters]="loadProfileFormDataKeyValue"
      [object]="loadProfile"
      (objectChange)="updateLoadProfile($event)"
      [idField]="'id'"
      [labelField]="'filename'"
    ></optima-uploader>
    <div
      class="flex flex-col"
      *ngIf="loadProfile?.stats && loadProfile?.json && scenario.loadprofileId"
    >
      <optima-multi-load-profile-chart
        [projectId]="scenario.parentId"
        [beforeJson]="loadProfile.json"
        [mode]="'single'"
        [beforeColor]="'#C2185B'"
        [chartId]="'chart-id-1'"
        [scenario]="scenario"
      ></optima-multi-load-profile-chart>
      <optima-multi-load-profile-stats-table
        [beforeStats]="loadProfile.stats"
        [mode]="'single'"
      ></optima-multi-load-profile-stats-table>
    </div>
  </div>
</div>

<popper-content #myPopover>
  <optima-load-profile-table
    *ngIf="profileInHover"
    [columns]="energyUseTableColumns"
    [stats]="profileInHover?.stats"
    [onHover]="true"
  ></optima-load-profile-table>
</popper-content>
