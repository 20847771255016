import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { TranslateModule } from '@ngx-translate/core';
import { LoadProfileTableComponent } from './load-profile-table.component';

@NgModule({
  declarations: [LoadProfileTableComponent],
  exports: [LoadProfileTableComponent],
  imports: [CommonModule, TranslateModule, MatTableModule],
})
export class LoadProfileTableModule {}
