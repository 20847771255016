import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guard';
import { PermissionEnum } from '@model';
import {
  DashboardResolver,
  ProjectResolver,
  ScenarioFormResolver,
  ScenarioResolver,
  ScenarioViewResolver,
} from '@resolver';
import {
  DashboardComponent,
  DashboardModule,
  ProjectComponent,
  ProjectModule,
  ScenarioComponent,
  ScenarioFormComponent,
  ScenarioFormModule,
  ScenarioModule,
} from '@view';

enum Routing {
  doc = 'doc',
  list = 'list',
  configured = 'configured',
  history = 'history',
  root = '',
  viewProject = 'details/:projectId/view-project',
  createScenario = 'details/:projectId/create-scenario',
  viewScenario = 'details/:scenarioId/view-scenario',
  editScenario = 'details/:scenarioId/edit-scenario',
}

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  enableTracing: false,
};

const routes: Routes = [
  {
    path: Routing.root,
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.READ] },
    resolve: { updates: DashboardResolver },
  },
  {
    path: Routing.viewProject,
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.READ] },
    resolve: { project: ProjectResolver },
    title: 'Optima - Project',
  },
  {
    path: Routing.createScenario,
    component: ScenarioFormComponent,
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.CREATE] },
    resolve: { scenario: ScenarioResolver, scenarioForm: ScenarioFormResolver },
  },
  {
    path: Routing.viewScenario,
    component: ScenarioComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.READ] },
    resolve: { scenario: ScenarioResolver, scenarioView: ScenarioViewResolver },
    title: 'Optima - Scenario',
  },
  {
    path: Routing.editScenario,
    component: ScenarioFormComponent,
    canActivate: [AuthGuard],
    data: { requiredPermissions: [PermissionEnum.UPDATE] },
    resolve: { scenario: ScenarioResolver, scenarioForm: ScenarioFormResolver, scenarioView: ScenarioViewResolver },
  },
  {
    path: Routing.doc,
    redirectTo: '/doc/index.html',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    DashboardModule,
    ProjectModule,
    RouterModule.forRoot(routes, routerOptions),
    ScenarioModule,
    ScenarioFormModule,
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    DashboardResolver,
    ProjectResolver,
    ScenarioResolver,
    ScenarioFormResolver,
    ScenarioViewResolver,
  ],
})
export class CoreRoutingModule {}
