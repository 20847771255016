import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Wrapping the translateService for Enel purposes, including fallback rules, branding, etc
 */
@Injectable()
export class CookieService {
  cookies: object;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.cookies = {};
    this.document.cookie.split(';').map(variable => {
      const variableParts = variable.split('=');
      this.cookies[variableParts[0].trim()] = variableParts[1];
    });
  }

  // Need to import the angular cookie service
  getCookie(cname: string | number, defaulter: unknown = null): string {
    return this.cookies[cname] || defaulter;
  }

  getEnocSession(): string {
    return this.getCookie('enoc_session');
  }

  getLocale(): string {
    return this.getCookie('locale') || 'en_US';
  }
}
