<div class="container">
  <table
    data-automation="load-profile-table"
    mat-table
    [dataSource]="dataSource"
    [ngClass]="{ shadow: onHover }"
  >
    <ng-container
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ columns.get(column) | translate }}
      </th>
      <td mat-cell *matCellDef="let stat">
        <span *ngIf="column === 'months'; else otherColumns">
          {{ stat[column] | number: '1.0-1' || '-' }}
        </span>
        <ng-template #otherColumns>
          <span>{{ stat[column] | number: '1.0-0' || '-' }}</span>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let stat; columns: displayedColumns"></tr>
  </table>
</div>
