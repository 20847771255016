<div
  data-automation="grid-service-selected-program-panel"
  class="programs-selected-container"
>
  <div class="header">
    {{ scenario?.programs?.standard?.length + scenario?.programs?.custom?.length }}
    {{ 'scenario.grid.program.selected' | translate }}
  </div>
  <div class="selected-programs-scroll">
      <div *ngFor="let program of selectedPrograms" class="program-container">
          <div
              data-automation="grid-service-selected-program"
              class="flex justify-between"
          >
              <span data-automation="selected-program-name" class="program-name">{{ program.programName }}</span>
              <mat-icon
                  data-automation="remove-program-button"
                  svgIcon="delete-red"
                  class="pointer"
                  (click)="delete(program)"
              ></mat-icon>
          </div>
          <div class="flex justify-start items-center zone">
            <div class="truncate" *ngIf="program.zone" title="{{ program.zone }}">{{ program.zone }}</div>
            <div *ngIf="program.zone">&nbsp;|&nbsp;</div>
            <div>
                {{program.fromMonthName}} - {{ program.toMonthName }}
            </div>
          </div>
      </div>
  </div>
</div>
