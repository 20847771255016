import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { detectBrowser } from '@helper';
import { LoadProfile, Scenario } from '@model';
import { LoadProfileService } from '@service';

@Component({
  selector: 'optima-project-load-profile-table',
  templateUrl: './load-profile-table.component.html',
  styleUrls: ['./load-profile-table.component.scss'],
})
export class LoadProfileTableComponent implements OnChanges {
  @Input() loadProfiles: LoadProfile[];
  @Input() scenarios: Scenario[];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<LoadProfile>;
  columns = new Map<string, string>();
  displayedColumns: string[];
  columnsToIterate: string[];
  isFirefox: boolean;

  constructor(private loadProfileService: LoadProfileService) {
    this.isFirefox = detectBrowser().includes('Firefox');
    this.loadProfileService.deleteLoadProfileStream$.subscribe(loadProfile => {
      const loadProfileIndex = this.loadProfiles.findIndex(
        s => s.id === loadProfile.id,
      );
      delete this.loadProfiles[loadProfileIndex];
      this.loadProfiles = this.loadProfiles.filter(s => s);
      this.sort.active = null;
      this.initDataSource(this.loadProfiles);
    });
    this.columns.set('filename', 'load_profile.table.header.filename');
    this.columns.set('minKw', 'scenario.summary.energy_use.minimum_demand');
    this.columns.set('avgKw', 'scenario.summary.energy_use.average_demand');
    this.columns.set('maxKw', 'scenario.summary.energy_use.maximum_demand');
    this.columns.set('totalKwh', 'scenario.summary.energy_use.total_usage');
    this.columns.set('rows', 'scenario.summary.energy_use.number_of_intervals');
    this.columns.set(
      'interval',
      'scenario.summary.energy_use.interval_duration',
    );
    this.displayedColumns = [...Array.from(this.columns.keys()), 'actions'];
    this.columnsToIterate = [...this.displayedColumns].slice(1, -1);
  }

  ngOnChanges(): void {
    this.initDataSource(this.loadProfiles);
  }

  deleteLoadProfile(loadProfile: LoadProfile): void {
    if (this.isUsed(loadProfile.id)) {
      return;
    }
    this.loadProfileService.openDeleteLoadProfileDialog(loadProfile);
  }

  isUsed(loadProfileId: string): boolean {
    return this.scenarios.some(s => s.loadprofileId === loadProfileId);
  }

  sortData(sort: Sort): void {
    let data = this.loadProfiles.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(this.loadProfiles);
      return;
    }

    data = data.sort((a: LoadProfile, b: LoadProfile) => {
      const isAscending = sort.direction === 'asc';
      if (sort.active === 'filename') {
        return this.compare(a[sort.active] ?? '', b[sort.active], isAscending);
      }
      return this.compare(
        a.stats[sort.active] ?? '',
        b.stats[sort.active],
        isAscending,
      );
    });

    this.initDataSource(data);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private initDataSource(loadProfiles: LoadProfile[]): void {
    if (!loadProfiles?.length) {
      return;
    }
    const initTable = (): void => {
      this.dataSource = new MatTableDataSource<LoadProfile>(loadProfiles);
      this.dataSource.sort = this.sort;
    };
    setTimeout(initTable);
  }
}
