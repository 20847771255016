import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { Program } from '@model';
import { GridProgramService } from '@service';

/**
 * @deprecated
 */
@Injectable()
export class ScenarioViewResolver {
  constructor(private gridProgramService: GridProgramService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line prettier/prettier
  ): Observable<[Program[], Program[]]> {
    const scenarioId =
      route.queryParamMap.get('scenarioId') || route.paramMap.get('scenarioId');
    let programsDetail$ = of([]);
    let customPrograms$ = of([]);
    if (scenarioId) {
      programsDetail$ = this.gridProgramService.programsDetail$(scenarioId);
      customPrograms$ = this.gridProgramService.customPrograms$(scenarioId);
    }
    return forkJoin([programsDetail$, customPrograms$]);
  }
}
