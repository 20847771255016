import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { monthsShort } from 'moment';
import { detectBrowser } from '@helper';
import { Program, ProgramTypeEnum, Scenario } from '@model';
import { GridProgramService } from '@service';

@Component({
  selector: 'optima-programs-list',
  templateUrl: './programs-list.component.html',
  styleUrls: ['./programs-list.component.scss'],
})
export class ProgramsListComponent implements OnChanges {
  @Input() scenario: Scenario;
  @Input() custom: boolean;
  @Input() customPrograms: Program[];
  @Output() scenarioEmitter = new EventEmitter<Scenario>();
  programs: Program[];
  allPrograms: Program[];
  dataSource: MatTableDataSource<Program>;
  isFirefox = detectBrowser().includes('Firefox');
  selection = new SelectionModel<string>(true, []);

  constructor(
    private gridProgramService: GridProgramService,
    private route: ActivatedRoute,
  ) {
    this.gridProgramService.filterValue$.subscribe(filterValue => {
      this.dataSource.filter = filterValue;
    });

    this.allPrograms = this.route.snapshot.data['scenarioForm'][2];
    this.refreshDatasource(this.allPrograms);
  }

  ngOnChanges(change: SimpleChanges): void {

    this.selection.clear();

    if (change['custom']) {
      this.dataSource.filter = '';

      if (this.custom) {
        this.refreshDatasource(this.customPrograms);
      } else {
        this.refreshDatasource(this.allPrograms);
      }
    }

    if (this.custom) {
      this.selection.select(...change['scenario'].currentValue['programs']['custom']);
    } else {
      this.selection.select(...change['scenario'].currentValue['programs']['standard']);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(program =>
          this.selection.select(program.id),
        );
  }

  private refreshDatasource(programs: Program[]): void {
    this.programs = programs;
    this.dataSource = new MatTableDataSource<Program>(this.programs);
    this.dataSource.filterPredicate = (data, filter): boolean =>
      data.programName?.toLowerCase().includes(filter) ||
      data.operator?.toLowerCase().includes(filter) ||
      data.zone?.toLowerCase().includes(filter);
  }

  getProgramTypeColorClass(programType: ProgramTypeEnum): string {
    return (
      {
        DR: 'blue',
        CP: 'orange',
        CPP: 'red',
      }[programType] || 'hide'
    );
  }

  checkProgram(event: MouseEvent, programId: string): void {
    const type = this.selection.selected.includes(programId) ? 'remove' : 'add';
    event.stopPropagation();
    setTimeout(() => {
      if (this.custom) {
        this.checkCustomProgram(programId, type)
      } else {
        this.checkStandardProgram(programId, type)
      }
    });
  }

  monthName(month: number): string {
    return monthsShort(--month);
  }

  private checkCustomProgram(programId: string, type: string) {
    if (type === 'add') {
      this.scenario.programs.custom.push(programId);
      this.selection.clear();
      this.selection.select(...this.scenario.programs.custom);
      this.scenarioEmitter.emit(this.scenario);
      this.gridProgramService
        .updateSelectedProgram$(programId, this.scenario.id, true)
        .subscribe({
          error: () => {
            this.selection.deselect(programId);
            this.scenario.programs.custom = this.scenario.programs.custom.filter(
              id => id !== programId,
            );
          },
        });
    } else {
      this.scenario.programs.custom = this.scenario.programs.custom.filter(
        id => id !== programId,
      );
      this.selection.clear();
      this.selection.select(...this.scenario.programs.custom);
      this.scenarioEmitter.emit(this.scenario);
      this.gridProgramService
        .deleteSelectedProgram$(programId, this.scenario.id)
        .subscribe({
          error: () => {
            this.selection.select(programId);
            this.scenario.programs.custom.push(programId);
          },
        });
    }
  }

  private checkStandardProgram(programId: string, type: string) {
    if (type === 'add') {
      this.scenario.programs.standard.push(programId);
      this.selection.clear();
      this.selection.select(...this.scenario.programs.standard);
      this.scenarioEmitter.emit(this.scenario);
      this.gridProgramService
        .updateSelectedProgram$(programId, this.scenario.id)
        .subscribe({
          error: () => {
            this.selection.deselect(programId);
            this.scenario.programs.standard = this.scenario.programs.standard.filter(
              id => id !== programId,
            );
          },
        });
    } else {
      this.scenario.programs.standard = this.scenario.programs.standard.filter(
        id => id !== programId,
      );
      this.selection.clear();
      this.selection.select(...this.scenario.programs.standard);
      this.scenarioEmitter.emit(this.scenario);
      this.gridProgramService
        .deleteSelectedProgram$(programId, this.scenario.id)
        .subscribe({
          error: () => {
            this.selection.select(programId);
            this.scenario.programs.standard.push(programId);
          },
        });
    }
  }
}
