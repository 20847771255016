<div class="flex options-container">
  <div class="flex flex-col justify-start mr-20 half-column">
    <div class="flex mb-4 checkboxes-container">
      <mat-checkbox
        [disabled]="disableExport()"
        [(ngModel)]="scenario.essParameters.export"
        (ngModelChange)="update()"
        [matTooltipDisabled]="!disableExport()"
        matTooltip="{{ 'scenario.ess.export.tooltip.disabled' | translate }}"
      >
        {{ 'scenario.ess.export' | translate }}
      </mat-checkbox>
    </div>
    <div class="flex flex-col mb-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <label>{{ 'scenario.ess.reserve' | translate }}</label>
          <mat-icon
            class="tooltip-icon"
            matTooltip="{{ 'scenario.ess.reserve.tooltip' | translate }}"
            svgIcon="info-circle"
          >
          </mat-icon>
        </div>
        <label>{{ scenario.essParameters.reserve * 100 | number }}%</label>
      </div>
      <mat-slider
        #reserveSlider
        [max]="100"
        [min]="0"
        [step]="5"
        [thumbLabel]="true"
        [ngModel]="scenario.essParameters.reserve * 100 | number"
        (change)="sliderOnChange('reserve', reserveSlider.value / 100)"
      ></mat-slider>
      <div class="flex justify-between slider-info">
        <label>0%</label>
        <label>100%</label>
      </div>
    </div>
    <mat-form-field
      appearance="outline"
      class="mb-6"
      [ngClass]="{ 'mb-9': costCycleError }"
    >
      <mat-label>
        <span class="form-field-label-and-icon">
          {{ 'scenario.ess.cost_cycle' | translate }}
          <mat-icon
            class="form-field-tooltip-icon"
            matTooltip="{{ 'scenario.ess.cost_cycle.tooltip' | translate }}"
            svgIcon="info-circle"
          >
          </mat-icon>
        </span>
      </mat-label>
      <input
        matInput
        #costCycleElement
        [formControl]="costCycleControl"
        (keyup)="costCycleCheck()"
      />
      <mat-error *ngIf="costCycleError">
        {{ 'scenario.ess.two_decimal_and_range.error' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="mt-2"
      [ngClass]="{ 'mb-10': costSocError }"
    >
      <mat-label>
        <span class="form-field-label-and-icon">
          {{ 'scenario.ess.cost_soc' | translate }}
          <mat-icon
            class="form-field-tooltip-icon"
            matTooltip="{{ 'scenario.ess.cost_soc.tooltip' | translate }}"
            svgIcon="info-circle"
          >
          </mat-icon>
        </span>
      </mat-label>
      <input
        matInput
        #costSocElement
        [formControl]="costSocControl"
        (keyup)="costSocCheck()"
      />
      <mat-error *ngIf="costSocError">
        {{ 'scenario.ess.five_decimal_and_range.error' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex flex-col justify-evenly half-column">
    <div
      class="flex mb-4 checkboxes-container"
      *ngIf="scenarioService.hasDers(scenario)"
    >
      <mat-checkbox
        [disabled]="pvChargeDisabled"
        [(ngModel)]="scenario.essParameters.pvCharge"
        (ngModelChange)="update()"
        [matTooltipDisabled]="!pvChargeDisabled"
        matTooltip="{{
          'scenario.form.tariff.nem_netting.disabled' | translate
        }}"
        data-automation="pv-charge-restriction"
      >
        {{ 'scenario.ess.charging' | translate }}
      </mat-checkbox>
    </div>
    <div class="flex flex-col mb-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <label>{{ 'scenario.ess.dod' | translate }}</label>
          <mat-icon
            class="tooltip-icon"
            matTooltip="{{ 'scenario.ess.dod.tooltip' | translate }}"
            svgIcon="info-circle"
          >
          </mat-icon>
        </div>
        <label>{{ scenario.essParameters.dod * 100 | number }}%</label>
      </div>
      <mat-slider
        #dodSlider
        [max]="100"
        [min]="50"
        [step]="5"
        [thumbLabel]="true"
        [ngModel]="scenario.essParameters.dod * 100 | number"
        (change)="sliderOnChange('dod', dodSlider.value / 100)"
      ></mat-slider>
      <div class="flex justify-between slider-info">
        <label>50%</label>
        <label>100%</label>
      </div>
    </div>
    <mat-form-field
      appearance="outline"
      class="mb-6"
      [ngClass]="{ 'mb-9': essDistanceError }"
    >
      <mat-label>
        <span class="form-field-label-and-icon">
          {{ 'scenario.ess.distance' | translate }}
          <mat-icon
            class="form-field-tooltip-icon"
            matTooltip="{{ 'scenario.ess.distance.tooltip' | translate }}"
            svgIcon="info-circle"
          >
          </mat-icon>
        </span>
      </mat-label>
      <input
        matInput
        #essDistanceElement
        (keydown)="keyPressIntegerControl($event)"
        [formControl]="essDistanceControl"
        (keyup)="essDistanceCheck()"
      />
      <mat-error *ngIf="essDistanceError">
        {{ 'scenario.ess.distance.min_max.error' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="mt-2">
      <div class="flex items-center">
        <label>{{ 'scenario.ess.climate' | translate }}</label>
        <mat-icon
          class="tooltip-icon"
          matTooltip="{{ 'scenario.ess.climate.tooltip' | translate }}"
          svgIcon="info-circle"
        >
        </mat-icon>
      </div>
      <mat-radio-group
        class="flex flex-col mt-4"
        [(ngModel)]="scenario.essParameters.climate"
        (ngModelChange)="update()"
      >
        <mat-radio-button value="MODERATE" class="mb-2">
          {{ 'scenario.ess.climate.moderate' | translate }}
        </mat-radio-button>
        <mat-radio-button value="EXTREME">
          {{ 'scenario.ess.climate.extreme' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
