import { Injectable } from '@angular/core';
import { toCamel } from 'convert-keys';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { BillCharges, Tariff, TariffParameters } from '@model';

@Injectable()
export class TariffService {
  constructor(
    private tariffResourceService: ResourceService<Tariff>,
    private billsResourceService: ResourceService<BillCharges>,
    private parametersService: ResourceService<TariffParameters>,
    private storedRateResourceService: ResourceService<string>,
  ) {}

  getTariffs$(scenarioId: string): Observable<Tariff[]> {
    return this.tariffResourceService
      .getList(`scenarios/${scenarioId}/tariffs`)
      .pipe(
        switchMap(tariffs => {
          const beforeCharges = this.getBillCharges$(scenarioId, true);
          const afterTariffCharges = this.getBillCharges$(scenarioId, false);
          return forkJoin([of(tariffs), beforeCharges, afterTariffCharges]);
        }),
        map(t => {
          const tariffs = t[0];
          const billsTrue = t[1];
          const billsFalse = t[2];
          tariffs.forEach(tariff => {
            if (tariff.before) {
              tariff.stats = billsTrue;
            } else {
              tariff.stats = billsFalse;
            }
          });
          return tariffs;
        }),
      );
  }

  getTariffsNoBills$(scenarioId: string): Observable<Tariff[]> {
    return this.tariffResourceService
      .getList(`scenarios/${scenarioId}/tariffs`)
      .pipe(
        switchMap(tariffs => {
          return forkJoin([of(tariffs)]);
        }),
        map(t => {
          const tariffs = t[0];
          tariffs.forEach(tariff => {
            tariff.stats = [];
          });
          return tariffs;
        }),
      );
  }

  deleteTariff$(id: string): Observable<Tariff> {
    return this.tariffResourceService.delete(`tariffs`, id);
  }

  getBillCharges$(
    scenarioId: string,
    before: boolean,
  ): Observable<BillCharges[]> {
    return this.billsResourceService.getList(
      `scenarios/${scenarioId}/bills?before=${before}`,
      undefined,
      this.mapBillsResponse,
    );
  }

  getParameters$(scenarioId: string): Observable<TariffParameters> {
    return this.parametersService.get(
      `scenarios/${scenarioId}/tariff-parameters`,
    );
  }

  getStoredRates$(): Observable<string[]> {
    return this.storedRateResourceService.get(
      `stored-dynamic-rates`,
    ) as unknown as Observable<string[]>;
  }

  updateParameters$(
    scenarioId: string,
    parameters: TariffParameters,
  ): Observable<TariffParameters> {
    return this.parametersService.update(
      'scenarios',
      `${scenarioId}/tariff-parameters`,
      parameters,
    );
  }

  mapBillsResponse(bills: unknown): BillCharges {
    if (!bills) {
      return <BillCharges>bills;
    }
    const o = {
      billing_start: bills['billing_period']['start'],
      billing_stop: bills['billing_period']['stop'],
      ...(<Partial<BillCharges>>bills),
    };
    delete o['billing_period'];
    return toCamel<BillCharges>(o);
  }
}
