import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsMonteCarloComponent } from './programs-monte-carlo.component';

@NgModule({
  declarations: [ProgramsMonteCarloComponent],
  exports: [ProgramsMonteCarloComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ProgramsMonteCarloModule {}
