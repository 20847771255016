import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SavingsRevenuesTableComponent } from './savings-revenues-table.component';

@NgModule({
  declarations: [SavingsRevenuesTableComponent],
  exports: [SavingsRevenuesTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatIconModule,
    MatLegacyTooltipModule,
    MatLegacyRadioModule,
    FormsModule,
  ],
})
export class SavingsRevenuesTableModule {}
