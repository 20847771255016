import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { LoadProfileTableModule } from '../../load-profile-table/load-profile-table.module';
import { CreateSolarModule } from '../create-solar/create-solar.module';
import { SolarPvComponent } from './solar-pv.component';
import {
  MultiLoadProfileChartModule,
  MultiLoadProfileStatsTableModule,
  UploaderModule,
} from '@component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [SolarPvComponent],
  exports: [SolarPvComponent],
  imports: [
    CommonModule,
    LoadProfileTableModule,
    TranslateModule,
    NgxPopperjsModule,
    UploaderModule,
    PipeModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MultiLoadProfileChartModule,
    MultiLoadProfileStatsTableModule,
    MatRippleModule,
    CreateSolarModule,
    MatRadioModule,
    MatTooltipModule,
  ],
})
export class SolarPvModule {}
