import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { Scenario, ScenarioEvents } from '@model';

@Injectable()
export class ScenarioEventsService {
  constructor(private resourceService: ResourceService<ScenarioEvents>) {}
  private eventsCache = new Map<string, Observable<ScenarioEvents[]>>();

  events$(
    scenario: Scenario,
    idx: string,
    forecasted = false,
  ): Observable<ScenarioEvents[]> {
    let KEY = `${scenario.id}${idx}${scenario.lastUpdatedDate}${scenario.runCompletedOn}`;
    if (forecasted) {
      KEY += 'forecasted';
    }
    if (!this.eventsCache.has(KEY)) {
      this.eventsCache.set(
        KEY,
        this.getEvents$(scenario.id, idx, forecasted).pipe(shareReplay(1)),
      );
    }
    return this.eventsCache.get(KEY);
  }

  private getEvents$(
    scenarioId: string,
    idx: string,
    forecasted: boolean,
  ): Observable<ScenarioEvents[]> {
    let path = `scenarios/${scenarioId}/events?idx=${idx}`;
    if (forecasted) {
      path += '&is_forecast=true';
    }
    return this.resourceService.getList(path);
  }
}
