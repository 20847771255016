import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTruncate',
})
export class StringTruncatePipe implements PipeTransform {
  transform(filename: string, characters?: number): string {
    if (!filename) {
      return undefined;
    }
    return filename.length > characters ?? 40
      ? filename.substring(0, characters ?? 40).trim() + '...'
      : filename;
  }
}
