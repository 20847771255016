import {
  AnimationTriggerMetadata,
  animate,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

interface InOutAnimationModel {
  height?: number;
  triggerName?: string;
  time?: number;
}

export const inOut = (opt?: InOutAnimationModel): AnimationTriggerMetadata => {
  const height = opt?.height ?? 180;
  const time = opt?.time ?? 0.3;
  const triggerName = opt?.triggerName ?? 'inOut';
  return trigger(triggerName, [
    transition(':enter', [
      style({ height: 0, opacity: 0 }),
      animate(`${time}s ease-out`, style({ height, opacity: 1 })),
    ]),
    transition(':leave', [
      style({ height, opacity: 1 }),
      animate(`${time}s ease-in`, style({ height: 0, opacity: 0 })),
    ]),
  ]);
};

export const rowsAnimation = trigger('rowsAnimation', [
  transition('void => *', [
    style({
      height: '*',
      opacity: '0',
      transform: 'translateY(-550px)',
      'box-shadow': 'none',
    }),
    sequence([
      animate(
        '.22s ease',
        style({
          height: '*',
          opacity: '.2',
          transform: 'translateY(0)',
          'box-shadow': 'none',
        }),
      ),
      animate(
        '.22s ease',
        style({ height: '*', opacity: 1, transform: 'translateY(0)' }),
      ),
    ]),
  ]),
]);
