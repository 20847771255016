import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { rowsAnimation } from '@helper';
import { BillCharges } from '@model';
import { TimezonesService } from '@service';

@Component({
  selector: 'optima-tariff-table',
  templateUrl: './tariff-table.component.html',
  styleUrls: ['./tariff-table.component.scss'],
  animations: [rowsAnimation],
})
export class TariffTableComponent implements OnChanges {
  @Input() stats: BillCharges[];
  @Input() projectId: string;
  shownStats: BillCharges[];
  dataSource: MatTableDataSource<BillCharges>;
  columns = new Map<string, string>();
  displayedColumns: string[];
  valuesColumns: string[];
  detailsShown = false;
  imagesPath = '../../../../../assets/images/';

  constructor(private timezoneService: TimezonesService) {
    this.columns.set('billingPeriod', 'scenario.summary.tariff.billing_period');
    this.columns.set('fixedCharges', 'scenario.summary.tariff.fixed_charges');
    this.columns.set('energyCharges', 'scenario.summary.tariff.energy_charges');
    this.columns.set('demandCharges', 'scenario.summary.tariff.demand_charges');
    this.columns.set('totalCharges', 'scenario.summary.tariff.total_charges');
    this.valuesColumns = Array.from(this.columns.keys());
    this.displayedColumns = [...this.valuesColumns, 'icon'];
  }

  ngOnChanges(): void {
    this.stats = this.stats.map(stat => {
      const start = this.timezoneService.getDateTimeDisplayLabel(
        this.projectId,
        stat.billingStart,
        'lll z',
      );
      const stop = this.timezoneService.getDateTimeDisplayLabel(
        this.projectId,
        stat.billingStop,
        'lll',
      );
      const billingPeriod = `${start} - ${stop}`;
      return { billingPeriod, ...stat };
    });
    this.renderTable();
  }

  showDetails(): void {
    this.detailsShown = !this.detailsShown;
    this.renderTable();
  }

  private renderTable(): void {
    if (this.detailsShown) {
      const clonedStats = [...this.stats];
      this.shownStats = [clonedStats.pop(), ...clonedStats];
    } else {
      this.shownStats = [[...this.stats].pop()];
    }
    this.dataSource = new MatTableDataSource(this.shownStats);
  }

  getRowClass(row: number): string {
    let classString = '';
    if (row === 0) {
      classString += ' pointer';
      classString += this.detailsShown
        ? ' background-primary-super-lighter'
        : ' background-white';
    } else {
      classString += ' background-grey-light';
    }
    return classString.trim();
  }
}
