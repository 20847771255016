import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResultTreemapComponent } from './result-treemap.component';

@NgModule({
  declarations: [ResultTreemapComponent],
  exports: [ResultTreemapComponent],
  imports: [CommonModule],
})
export class ResultTreemapModule {}
