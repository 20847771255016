import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ForecastedEnergyComponent } from './forecasted-energy/forecasted-energy.component';
import { ForecastedSolarComponent } from './forecasted-solar/forecasted-solar.component';
import {
  ForecastChunk,
  ForecastChunkStatusEnum,
  ForecastLoadProfile,
  Scenario,
} from '@model';
import { ForecastService, GlobalAlertService, LoaderService } from '@service';

@Component({
  selector: 'optima-forecasted',
  templateUrl: './forecasted.component.html',
  styleUrls: ['./forecasted.component.scss'],
})
export class ForecastedComponent implements OnInit, OnDestroy {
  @Input() scenario: Scenario;
  @Input() energyForecasts: ForecastLoadProfile[];
  @Input() solarForecasts: ForecastLoadProfile[];
  @Output() forecastChange = new EventEmitter<
    [ForecastLoadProfile, ForecastLoadProfile, 'energy-forecast' | 'solar-forecast']
  >();
  @Output() forecastRemoved = new EventEmitter<
    ['energy-forecast' | 'solar-forecast']
  >();
  @ViewChild(ForecastedEnergyComponent)
  forecastedEnergyComponent: ForecastedEnergyComponent;
  @ViewChild(ForecastedSolarComponent)
  forecastedSolarComponent: ForecastedSolarComponent;
  private interval: NodeJS.Timeout;
  chunkLoaderTranslateString = 'chunk.zipping';
  chunkLoaderTranslateStringSubtext = 'chunk.zipping.sub';

  constructor(
    private forecastService: ForecastService,
    private loaderService: LoaderService,
    private alertService: GlobalAlertService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (
      this.scenario.forecastLoadProfile?.status ===
      ForecastChunkStatusEnum.STARTED
    ) {
      const id = this.scenario.forecastLoadId;
      this.scenario.forecastLoadId = null;
      this.scenario.forecastLoadProfile = null;
      this.startInterval(id);
    }
    if (
      this.scenario.forecastLoadProfile?.status ===
      ForecastChunkStatusEnum.ERROR
    ) {
      this.emitEmpty('energy-forecast');
    }
    if (
      this.scenario.forecastPvLoadProfile?.status ===
      ForecastChunkStatusEnum.STARTED
    ) {
      this.startInterval(this.scenario.forecastPvId);
    }
    if (
      this.scenario.forecastPvLoadProfile?.status ===
      ForecastChunkStatusEnum.ERROR
    ) {
      this.emitEmpty('solar-forecast');
    }
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  forecastChangedChunk(forecast: ForecastChunk): void {
    if (forecast?.forecastId) {
      this.startInterval(forecast.forecastId);
    } else {
      this.emitEmpty('energy-forecast');
    }
  }

  forecastChanged(forecast: ForecastLoadProfile): void {
    const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
    this.scenario.forecastLoadId = forecast.id;
    this.scenario.forecastLoadProfile = forecast;
    this.forecastChange.emit([
      forecast,
      scenarioBefore.forecastLoadProfile,
      'energy-forecast',
    ]);
  }

  solarForecastChangedChunk(forecast: ForecastChunk): void {
    if (!forecast) {
      const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
      this.emitEmpty('solar-forecast');
    } else
    if (forecast?.forecastId) {
      this.startInterval(forecast.forecastId);
    } else {
      // this is likely a progress update from the uploader we do not want to do anything
    }
  }

  solarForecastChanged(forecast: ForecastLoadProfile): void {
    const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
    this.scenario.forecastPvId = forecast?.id;
    this.scenario.forecastPvLoadProfile = forecast;
    if (!forecast) {
      this.forecastRemoved.emit(['solar-forecast']);
    } else {
      this.forecastChange.emit([this.scenario.forecastPvLoadProfile, scenarioBefore.forecastPvLoadProfile, 'solar-forecast']);
    }
  }

  startInterval = (id: string): void => {
    if (this.interval) {
      return;
    }
    const showLoader = (): void => {
      if (document.querySelector(`optima-loader-no-http#chunk-loader`)) {
        this.loaderService.showLoaderNoHttp(`chunk-loader`, true);
      } else {
        setTimeout(() => showLoader(), 500);
      }
    };
    this.chunkLoaderTranslateString = 'chunk.waiting';
    this.chunkLoaderTranslateStringSubtext = undefined;
    showLoader();
    this.interval = setInterval(() => {
      this.forecastService.forecastDispatch$(id).subscribe({
        next: (forecast) => {
          if (forecast.status !== ForecastChunkStatusEnum.STARTED) {
            this.stopInterval();
          }
          if (forecast.status === ForecastChunkStatusEnum.COMPLETED) {
            if (forecast.type === 'METER') {
              this.scenario.forecastLoadId = forecast.id;
              this.scenario.forecastLoadProfile = forecast;
            } else {
              this.scenario.forecastPvId = forecast.id;
              this.scenario.forecastPvLoadProfile = forecast;
            }
          }
          if (forecast.status === ForecastChunkStatusEnum.ERROR) {
            const message = this.translateService.instant('chunk.error');
            this.alertService.setError(message);

            if (forecast.type === 'METER') {
              this.emitEmpty('energy-forecast');
            } else {
              this.emitEmpty('solar-forecast');
            }
          }
        },
        error: (err) => {
          console.log('err call in interval', err);
        },
        complete: () => {},
      });
    }, 5000);
  };

  stopInterval = (): void => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
      this.loaderService.hideLoaderNoHttp(`chunk-loader`);
    }
  };

  private emitEmpty(
    who: 'energy-forecast' | 'solar-forecast',
  ): void {
    this.forecastRemoved.emit([
      who,
    ]);
  }
}
