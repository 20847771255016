import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { cloneDeep } from 'lodash';
import { check } from '@helper';
import { CustomDers, CustomErrorStateMatcher } from '@model';
import { EssService, LoaderService } from '@service';

@Component({
  selector: 'optima-ess-dialog',
  templateUrl: './ess-dialog.component.html',
  styleUrls: ['./ess-dialog.component.scss'],
})
export class EssDialogComponent implements OnInit, AfterViewInit {
  formGroupArray: FormGroup[] = [];
  defaultForm: FormGroup;
  matcher = new CustomErrorStateMatcher();
  check = check;
  customAlreadyPresents: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { customAlreadyPresents: number; scenarioId: string },
    public essService: EssService,
    public loaderService: LoaderService,
    public dialogRef: MatDialogRef<EssDialogComponent>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.customAlreadyPresents = this.data.customAlreadyPresents;
    this.defaultForm = this.fb.group({
      totalKw: new FormControl(null, [
        Validators.min(0.1),
        Validators.required,
      ]),
      totalKwh: new FormControl(null, [
        Validators.min(0.1),
        Validators.required,
      ]),
      rte: new FormControl(90, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
      aux: new FormControl(4, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ]),
    });
    this.addForm();
  }

  ngAfterViewInit(): void {
    this.loaderService.showLoader('ess-dialog-loader');
  }

  addForm(): void {
    const clone = cloneDeep(this.defaultForm);
    this.formGroupArray.push(clone);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  checkInvalid(): boolean {
    return this.formGroupArray.some(form => form.invalid);
  }

  submit(): void {
    const values: CustomDers[] = this.formGroupArray.map(form => form.value);
    this.essService
      .addCustomDers$(
        this.data.scenarioId,
        values.map(der => {
          return {
            aux: der.aux,
            rte: der.rte,
            totalKw: +der.totalKw,
            totalKwh: +der.totalKwh,
          };
        }),
      )
      .subscribe(ders => {
        this.dialogRef.close(ders);
      });
  }
}
