import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { EssDialogComponent } from './ess-dialog/ess-dialog.component';
import { detectBrowser } from '@helper';
import { Bess, Ders, Ess, Scenario } from '@model';
import { EssService, LoaderService } from '@service';

@Component({
  selector: 'optima-ess-table',
  templateUrl: './ess-table.component.html',
  styleUrls: ['./ess-table.component.scss'],
})
export class EssTableComponent implements OnInit, AfterViewInit {
  @Input() scenario!: Scenario;
  @Output() scenarioChange = new EventEmitter<Scenario>();
  @Input() bess: Bess[];
  @Output() completedEmitter = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchInput') input: ElementRef;
  dataClone: Bess[];
  essDataSource: MatTableDataSource<Bess>;
  essDisplayedColumns = ['action', 'vendor', 'kw', 'kwh', 'duration'];
  configToOrder = new Map<string, number>();
  isFirefox: boolean;
  selectedDers: Ess[] = [];

  constructor(
    private essService: EssService,
    private loaderService: LoaderService,
    public dialog: MatDialog,
  ) {
    this.isFirefox = detectBrowser().includes('Firefox');
  }

  ngOnInit(): void {
    this.dataClone = [...this.bess];
    this.bess.forEach(v => {
      this.configToOrder.set(v.id, 0);
    });
    if (!this.scenario.ders) {
      this.scenario.ders = [];
    }
    this.completedEmitter.emit(this.completed());
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.loaderService.showLoader('ess-loader'), 1000);
    this.initEssDataSource(this.bess);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          let value = this.input.nativeElement.value;
          value = value.trim().toLowerCase();
          this.essDataSource.filter = value;
        }),
      )
      .subscribe();
  }

  private initEssDataSource(ess: Bess[]): void {
    if (!ess?.length) {
      return;
    }
    const initTable = (): void => {
      this.essDataSource = new MatTableDataSource<Bess>(ess);
      this.essDataSource.sort = this.sort;
      this.essDataSource.filterPredicate = (
        data: Bess,
        filter: string,
      ): boolean => {
        return (
          data.model.toLocaleLowerCase().includes(filter) ||
          data.vendor.toLocaleLowerCase().includes(filter) ||
          data.product.toLocaleLowerCase().includes(filter) ||
          data.kw.toString().includes(filter) ||
          data.kwh.toString().includes(filter) ||
          data.duration.toString().includes(filter)
        );
      };
    };
    setTimeout(initTable);
  }

  addEss(id: string): void {
    let qty = this.configToOrder.get(id);
    qty++;
    this.configToOrder.set(id, qty);
    this.selectedDers = this.selectedDers.filter(der => der.id !== id);
    this.selectedDers.push({ id, qty });
  }

  removeEss(id: string): void {
    let qty = this.configToOrder.get(id);
    qty--;
    this.selectedDers = this.selectedDers.filter(der => der.id !== id);
    if (qty >= 0) {
      this.configToOrder.set(id, qty);
    }
    if (qty > 0) {
      this.selectedDers.push({ id, qty });
    }
  }

  incrementAll(): void {
    this.configToOrder.forEach((qty, key, entity) => {
      if (this.essDataSource.filteredData.find(der => der.id === key)) {
        entity.set(key, qty + 1);
        if (this.selectedDers.find(der => der.id === key)) {
          this.selectedDers = this.selectedDers.filter(der => der.id !== key);
        }

        this.selectedDers.push({ qty: qty + 1, id: key });
      }
    });
  }

  decrementAll(): void {
    this.configToOrder.forEach((qty, key, entity) => {
      const newQty = qty - 1;

      if (this.essDataSource.filteredData.find(der => der.id === key)) {
        this.selectedDers = this.selectedDers.filter(der => der.id !== key);
        if (newQty > 0) {
          this.selectedDers.push({ qty: newQty, id: key });
        }

        if (qty > 0) {
          entity.set(key, newQty);
        }
      }
    });
  }

  clearAll(): void {
    this.configToOrder.forEach((qty, key, entity) => {
      entity.set(key, 0);
    });
    this.selectedDers = [];
  }

  getEssValue(id: string): number {
    return this.configToOrder.get(id);
  }

  getAllZero(): boolean {
    return [...this.configToOrder.keys()].every(
      k => this.configToOrder.get(k) < 1,
    );
  }

  private completed(): boolean {
    return this.scenario.ders.length > 0;
  }

  combineSelections(): void {
    const selectedDers: Ess[] = [];
    this.configToOrder.forEach((value, key, entity) => {
      if (value > 0) {
        selectedDers.push({ id: key, qty: value });
      }
      entity.set(key, 0);
    });

    const ders: any[] = selectedDers.reduce((acc: any, step: Ess) => {
      if (!acc.ess) {
        acc.ess = [];
      }

      acc.ess.push(step);
      return acc;
    }, {});

    const result = [];
    result.push(ders);

    this.essService.updateDers$(this.scenario.id, result).subscribe(ders => {
      this.scenario.ders = ders;
      this.scenarioChange.emit(this.scenario);
      this.clearAll();
      this.selectedDers = [];
      this.completedEmitter.emit(this.completed());
    });
  }

  addToList(): void {
    const selectedDers: any[] = [];
    this.configToOrder.forEach((value, key) => {
      if (value > 0) {
        selectedDers.push({ id: key, qty: value });
      }
    });

    const ders: any[] = selectedDers.reduce((acc: any[], step: Ess) => {
      const ess: Ess[] = [];
      ess.push(step);
      acc.push({ ess: ess });
      return acc;
    }, []);

    this.essService.updateDers$(this.scenario.id, ders).subscribe(ders => {
      this.scenario.ders = ders;
      this.scenarioChange.emit(this.scenario);
      this.clearAll();
      this.selectedDers = [];
      this.completedEmitter.emit(this.completed());
    });
  }

  deleteDer(derId: string): void {
    this.essService.deleteDer$(this.scenario.id, derId).subscribe(ders => {
      this.scenario.ders = this.scenario.ders.filter(der => der.id !== derId);
      this.scenarioChange.emit(this.scenario);
      this.completedEmitter.emit(this.completed());
    });
  }

  getBessLabel(der: Ess): string {
    const theBess = this.bess.find(bessItem => bessItem.id === der.id);
    return `${theBess?.vendor}/${theBess?.product}`;
  }

  getBessComboLabel(der: Ess): string {
    const theBess = this.bess.find(bessItem => bessItem.id === der.id);
    return `${theBess?.product} ${theBess?.model}`;
  }

  deleteAllDers() {
    this.essService.deleteAllDers$(this.scenario.id).subscribe(ders => {
      this.scenario.ders = [];
      this.scenarioChange.emit(this.scenario);
      this.completedEmitter.emit(this.completed());
    });
  }

  checkDerCount(applyLimit = false) {
    if (applyLimit) {
      return this.selectedDers.length > 3 || this.selectedDers.length < 2;
    }

    return this.selectedDers.length === 0;
  }

  isDerSelected(essId: string) {
    return this.selectedDers.find(der => der.id === essId);
  }

  customDers(isCustom: boolean): Ders[] {
    return this.scenario.ders.filter(der => der?.custom === isCustom);
  }

  openDialog(): void {
    const customAlreadyPresents = this.scenario.ders.filter(
      der => der.custom,
    ).length;
    this.dialog
      .open(EssDialogComponent, {
        width: '860px',
        data: { customAlreadyPresents, scenarioId: this.scenario.id },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((ders: Ders[]) => {
        this.loaderService.showLoader('ess-loader');
        if (ders) {
          this.scenario.ders = ders;
        }
      });
  }
}
