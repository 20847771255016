import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Scenario, Tariff } from '@model';
import { ScenarioService, TariffService } from '@service';

@Component({
  selector: 'optima-dynamic-rates',
  templateUrl: './dynamic-rates.component.html',
  styleUrls: ['./dynamic-rates.component.scss'],
})
export class DynamicRatesComponent implements OnInit, AfterViewInit {
  activeMode: 'upload' | 'stored';
  @Input() tariffType: 'current' | 'switch';
  @Input() scenario: Scenario;
  @Input() storedRates: string[];
  @Output() tariffChange = new EventEmitter<Scenario>();
  @ViewChild('searchImport') filterImport: ElementRef;
  @ViewChild('searchExport') filterExport: ElementRef;
  importRate: Tariff;
  exportRate: Tariff;
  filteredImportRates: string[];
  filteredExportRates: string[];
  selectedStoredImportRate: string;
  selectedStoredExportRate: string;
  importFormDataKeyValue = new Map<string, string>();
  exportFormDataKeyValue = new Map<string, string>();
  disabledButtonUploadTooltipMessage: string;
  disabledButtonStoredTooltipMessage: string;
  observer: ResizeObserver;

  constructor(
    public scenarioService: ScenarioService,
    private tariffService: TariffService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const before = this.tariffType === 'current';
    this.activeMode = 'upload';
    this.filteredImportRates = this.storedRates;
    this.filteredExportRates = this.storedRates;
    if (before) {
      this.importRate = this.scenario.currentDynamicImportTariff;
      this.exportRate = this.scenario.currentDynamicExportTariff;
      if (
        this.scenario.tariffParameters?.dynamicStoredImportBefore ||
        this.scenario.tariffParameters?.dynamicStoredExportBefore
      ) {
        this.activeMode = 'stored';
        this.selectedStoredImportRate =
          this.scenario.tariffParameters?.dynamicStoredImportBefore;
        this.selectedStoredExportRate =
          this.scenario.tariffParameters?.dynamicStoredExportBefore;
      }
    } else {
      this.importRate = this.scenario.switchDynamicImportTariff;
      this.exportRate = this.scenario.switchDynamicExportTariff;
      if (
        this.scenario.tariffParameters?.dynamicStoredImportAfter ||
        this.scenario.tariffParameters?.dynamicStoredExportAfter
      ) {
        this.activeMode = 'stored';
        this.selectedStoredImportRate =
          this.scenario.tariffParameters?.dynamicStoredImportAfter;
        this.selectedStoredExportRate =
          this.scenario.tariffParameters?.dynamicStoredExportAfter;
      }
    }
    this.importFormDataKeyValue.set('scenario_id', this.scenario.id);
    this.importFormDataKeyValue.set('before', String(before));
    this.importFormDataKeyValue.set('dynamic', 'true');
    this.importFormDataKeyValue.set('import_rate', 'true');
    this.exportFormDataKeyValue.set('scenario_id', this.scenario.id);
    this.exportFormDataKeyValue.set('before', String(before));
    this.exportFormDataKeyValue.set('dynamic', 'true');
    this.exportFormDataKeyValue.set('import_rate', 'false');
    this.disabledButtonUploadTooltipMessage = this.translateService.instant(
      'scenario.form.dynamic_rate_upload.disabled.tooltip',
    );
    this.disabledButtonStoredTooltipMessage = this.translateService.instant(
      'scenario.form.dynamic_rate_stored.disabled.tooltip',
    );
  }

  ngAfterViewInit(): void {
    this.checkErrorContainerSize();
    this.startErrorContainerObserver();
  }

  async updateDynamicRate(tariff: Tariff, importRate: boolean): Promise<void> {
    let tariffId: string;
    if (this.tariffType === 'current') {
      if (importRate) {
        tariffId = this.scenario.currentDynamicImportTariff?.id;
        this.scenario.currentDynamicImportTariff = tariff;
        this.importRate = tariff;
      } else {
        tariffId = this.scenario.currentDynamicExportTariff?.id;
        this.scenario.currentDynamicExportTariff = tariff;
        this.exportRate = tariff;
      }
    } else {
      if (importRate) {
        tariffId = this.scenario.switchDynamicImportTariff?.id;
        this.scenario.switchDynamicImportTariff = tariff;
        this.importRate = tariff;
      } else {
        tariffId = this.scenario.switchDynamicExportTariff?.id;
        this.scenario.switchDynamicExportTariff = tariff;
        this.exportRate = tariff;
      }
    }
    if (!tariff) {
      await firstValueFrom(this.tariffService.deleteTariff$(tariffId));
    }
    this.tariffChange.emit(this.scenario);
  }

  selectRate(storedRate: string, importRate: boolean): void {
    if (this.tariffType === 'current') {
      if (importRate) {
        this.scenario.tariffParameters.dynamicStoredImportBefore = storedRate;
      } else {
        this.scenario.tariffParameters.dynamicStoredExportBefore = storedRate;
      }
    } else {
      if (importRate) {
        this.scenario.tariffParameters.dynamicStoredImportAfter = storedRate;
      } else {
        this.scenario.tariffParameters.dynamicStoredExportAfter = storedRate;
      }
    }
    this.tariffService
      .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
      .subscribe(() => {
        this.tariffChange.emit(this.scenario);
      });
  }

  filterImportOptions(): void {
    const searchKey = this.filterImport.nativeElement.value;
    this.filteredImportRates = this.storedRates.filter(rate =>
      rate.includes(searchKey),
    );
  }

  filterExportOptions(): void {
    const searchKey = this.filterExport.nativeElement.value;
    this.filteredExportRates = this.storedRates.filter(rate =>
      rate.includes(searchKey),
    );
  }

  hasStoredRate(): boolean {
    const currentTariff = this.tariffType === 'current';
    if (currentTariff) {
      return !!(
        this.scenario.tariffParameters.dynamicStoredImportBefore ||
        this.scenario.tariffParameters.dynamicStoredExportBefore
      );
    } else {
      return !!(
        this.scenario.tariffParameters.dynamicStoredImportAfter ||
        this.scenario.tariffParameters.dynamicStoredExportAfter
      );
    }
  }

  hasDynamicUploadFile(): boolean {
    const currentTariff = this.tariffType === 'current';
    if (currentTariff) {
      return !!(
        this.scenario.currentDynamicImportTariff ||
        this.scenario.currentDynamicExportTariff
      );
    } else {
      return !!(
        this.scenario.switchDynamicImportTariff ||
        this.scenario.switchDynamicExportTariff
      );
    }
  }

  // CSS SECTION

  private checkErrorContainerSize(): void {
    const dynamicUploadSection = document.getElementById(
      'dynamic-upload-section',
    );
    if (!dynamicUploadSection) {
      return;
    }
    const leftSection = document.querySelector(
      '.import-rate-upload',
    ) as HTMLElement;
    if (!leftSection) {
      return;
    }
    if (dynamicUploadSection.clientWidth < 267) {
      leftSection.style.borderRight = 'none';
    } else {
      leftSection.style.borderRight = '1px solid #D4D4D4';
    }
  }

  private startErrorContainerObserver(): void {
    if (!document.getElementById('dynamic-upload-section')) {
      return;
    }
    this.observer = new ResizeObserver(entries => {
      entries.forEach(() => {
        this.checkErrorContainerSize();
      });
    });

    this.observer.observe(document.getElementById('dynamic-upload-section'));
  }
}
