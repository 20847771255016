import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { setTariffs } from '@helper';
import {
  Bess,
  Ders,
  DynamicInput,
  EssParameters,
  ForecastLoadProfile,
  GridParameters,
  LoadProfile,
  LoadProfileJson,
  MaxSiteParameters,
  MinSiteParameters,
  Project,
  Result,
  Scenario,
  ScenarioPrograms,
  SolarLoadProfile,
  Tariff,
  TariffParameters,
} from '@model';
import {
  CookieService,
  EssService,
  ForecastService,
  GlobalAlertService,
  GridProgramService,
  LoadProfileService,
  LoaderService,
  ProjectService,
  ResultService,
  ScenarioService,
  SiteConstraintService,
  SolarPvService,
  TariffService,
} from '@service';

/**
 * @deprecated
 */
@Injectable()
export class ScenarioResolver {
  constructor(
    private scenarioService: ScenarioService,
    private cookieService: CookieService,
    private essService: EssService,
    private projectService: ProjectService,
    private loadProfileService: LoadProfileService,
    private forecastService: ForecastService,
    private solarPvService: SolarPvService,
    private resultService: ResultService,
    private tariffService: TariffService,
    private siteConstraintService: SiteConstraintService,
    private globalAlertService: GlobalAlertService,
    private loaderService: LoaderService,
    private gridProgramService: GridProgramService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<
    [
      Scenario,
      Project,
      LoadProfile[],
      Bess[],
      SolarLoadProfile[],
      DynamicInput[],
      DynamicInput[],
      string[],
      ForecastLoadProfile[],
      ForecastLoadProfile[],
    ]
  > {
    const projectId =
      route.queryParamMap.get('projectId') || route.paramMap.get('projectId');
    const scenarioId =
      route.queryParamMap.get('scenarioId') || route.paramMap.get('scenarioId');
    const locale = this.cookieService.getLocale();

    const createScenario$ = this.scenarioService.createScenario$({
      displayLabel: '',
      parentId: projectId,
      locale,
    });

    const scenario$ = createScenario$.pipe(
      switchMap((scenario: Scenario) => {
        const scenarioId = scenario.id;
        scenario.displayLabel = `Untitled Scenario ${scenarioId}`;
        const scenarioUpdate$ = this.scenarioService.updateScenario$(
          scenarioId,
          scenario,
        );
        return forkJoin([scenarioUpdate$]);
      }),
    );

    const updateScenario$ = scenario$.pipe(
      concatMap((p: [Scenario]) => {
        const scenario = p[0];
        scenario.programs = new ScenarioPrograms();
        return of(scenario);
      }),
    );

    const project$ = this.projectService.getProject$(projectId);
    const loadProfiles$ = this.loadProfileService.loadProfiles$(projectId);
    const pvLoadProfiles$ = this.solarPvService.pvLoadProfiles$(projectId);
    const bess$ = this.essService.bess$();
    const minLoadInputs$ =
      this.siteConstraintService.getDynamicInput$('site-constraint');
    const maxLoadInputs$ = this.siteConstraintService.getDynamicInput$(
      'max-load-site-constraint',
    );
    const storedRates$ = this.tariffService.getStoredRates$();
    const gridParameters$ = scenarioId
      ? this.gridProgramService.gridParameters$(scenarioId)
      : of(new GridParameters());
    const essParameters$ = scenarioId
      ? this.essService.getParameters$(scenarioId)
      : of(new EssParameters());
    const tariffParameters$ = scenarioId
      ? this.tariffService.getParameters$(scenarioId)
      : of(new TariffParameters());
    const minSiteParameters$ = scenarioId
      ? this.siteConstraintService.getSiteConstraint$(scenarioId)
      : of(new MinSiteParameters());
    const maxSiteParameters$ = scenarioId
      ? this.siteConstraintService.getSiteConstraint$(scenarioId, true)
      : of(new MaxSiteParameters());
    const energyForecasts$ = this.forecastService.getEnergyForecasts(projectId);
    const solarForecasts$ = this.forecastService.getSolarForecasts(projectId);

    if (scenarioId) {
      this.loaderService.changeLabel('loader.message.loading_summary');
      const scenario$ = this.scenarioService.getScenario$(scenarioId);
      const result$ = this.resultService.getResults$(scenarioId);
      const tariff$ = this.tariffService.getTariffs$(scenarioId);
      const ders$ = this.essService.getDers$(scenarioId);
      const selectedPrograms$ = scenarioId
        ? this.gridProgramService.getSelectedPrograms$(scenarioId)
        : of(new ScenarioPrograms());
      const firstResult$ = forkJoin([
        scenario$,
        result$,
        tariff$,
        ders$,
        essParameters$,
        tariffParameters$,
        minSiteParameters$,
        maxSiteParameters$,
        gridParameters$,
        selectedPrograms$,
      ]);
      const secondResult$ = firstResult$.pipe(
        concatMap(
          (
            p: [
              Scenario,
              Result[],
              Tariff[],
              Ders[],
              EssParameters,
              TariffParameters,
              MinSiteParameters,
              MaxSiteParameters,
              GridParameters,
              ScenarioPrograms,
            ],
          ) => {
            const scenario = p[0];
            scenario.results = p[1];
            scenario.ders = p[3];
            scenario.essParameters = p[4];
            scenario.tariffParameters = p[5];
            scenario.minLoadSiteParameters = p[6];
            scenario.maxLoadSiteParameters = p[7];
            scenario.gridParameters = p[8];
            scenario.programs = p[9];
            setTariffs(scenario, ...p[2]);
            const adjustedLoadProfile$ = scenario.adjustedPvLoadId
              ? this.loadProfileService.loadProfile$(scenario.adjustedPvLoadId)
              : of(null);
            const adjustedJson$ = scenario.adjustedPvLoadId
              ? this.loadProfileService.getLoadProfileJson(
                  scenario.adjustedPvLoadId,
                )
              : of(null);
            const loadProfile$ = scenario.loadprofileId
              ? this.loadProfileService.loadProfile$(scenario.loadprofileId)
              : of(null);
            const loadProfileJson$ = scenario.loadprofileId
              ? this.loadProfileService.getLoadProfileJson(
                  scenario.loadprofileId,
                )
              : of(null);
            const pvLoadProfile$ = scenario.pvloadId
              ? this.solarPvService.pvLoadProfile$(scenario.pvloadId)
              : of(null);
            const project$ = this.projectService.getProject$(projectId);
            const forecastedLoadProfile$ = scenario.forecastLoadId
              ? this.forecastService.forecastDispatch$(scenario.forecastLoadId)
              : of(null);
            const forecastedPvLoadProfile$ = scenario.forecastPvId
              ? this.forecastService.forecastDispatch$(scenario.forecastPvId)
              : of(null);
            return forkJoin([
              of(scenario),
              project$,
              loadProfile$,
              loadProfileJson$,
              pvLoadProfile$,
              scenario.loadprofileId && scenario.pvloadId
                ? adjustedLoadProfile$
                : of(null),
              scenario.loadprofileId && scenario.pvloadId
                ? adjustedJson$
                : of(null),
              scenario.loadprofileId ? forecastedLoadProfile$ : of(null),
              scenario.pvloadId ? forecastedPvLoadProfile$ : of(null),
            ]);
          },
        ),
        catchError((err: unknown) => {
          this.globalAlertService.setError(err.toString());
          return throwError(() => new Error(err.toString()));
        }),
      );
      const thirdResult$ = secondResult$.pipe(
        concatMap(
          (
            p: [
              Scenario,
              Project,
              LoadProfile,
              LoadProfileJson[],
              SolarLoadProfile,
              LoadProfile,
              LoadProfileJson[],
              ForecastLoadProfile,
              ForecastLoadProfile,
            ],
          ) => {
            const scenario = p[0] as Scenario;
            const project = p[1] as Project;
            scenario.loadProfile = p[2];
            if (scenario.loadProfile) {
              scenario.loadProfile.json = p[3];
            }
            scenario.pvloadProfile = p[4];
            scenario.adjustedPvLoadProfile = p[5];
            if (scenario.adjustedPvLoadProfile) {
              scenario.adjustedPvLoadProfile.json = p[6];
            }
            scenario.forecastLoadProfile = p[7];
            scenario.forecastPvLoadProfile = p[8];
            return forkJoin([
              of(scenario),
              of(project),
              loadProfiles$,
              bess$,
              pvLoadProfiles$,
              minLoadInputs$,
              maxLoadInputs$,
              storedRates$,
              energyForecasts$,
              solarForecasts$,
            ]);
          },
        ),
        catchError((err: unknown) => {
          this.globalAlertService.setError(err.toString());
          return throwError(() => new Error(err.toString()));
        }),
      );
      return thirdResult$;
    } else {
      this.loaderService.changeLabel('');
      return forkJoin([
        updateScenario$,
        project$,
        loadProfiles$,
        bess$,
        pvLoadProfiles$,
        minLoadInputs$,
        maxLoadInputs$,
        storedRates$,
        energyForecasts$,
        solarForecasts$,
      ]);
    }
  }
}
