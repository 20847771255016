import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UploaderComponent } from '@component';
import { LoadProfile, Scenario } from '@model';
import {
  LoadProfileService,
  LoaderService,
  ScenarioService,
  TariffService,
} from '@service';

@Component({
  selector: 'optima-energy-use',
  templateUrl: './energy-use.component.html',
  styleUrls: ['./energy-use.component.scss'],
})
export class EnergyUseComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() scenario!: Scenario;
  @Input() loadProfile: LoadProfile;
  @Input() loadProfiles!: LoadProfile[];
  @Output() scenarioChange = new EventEmitter<
    [Scenario, Scenario, 'energy-use']
  >();
  @ViewChild('searchInput') input: ElementRef;
  @ViewChild('uploaderLoadProfile')
  uploaderLoadProfile: UploaderComponent<LoadProfile>;
  profileInHover: LoadProfile;
  filteredProfiles: LoadProfile[];
  energyUseTableColumns: Map<string, string>;
  loadProfileFormDataKeyValue = new Map<string, string>();

  constructor(
    public scenarioService: ScenarioService,
    private loadProfileService: LoadProfileService,
    private tariffService: TariffService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.filteredProfiles = [...this.loadProfiles];
    this.energyUseTableColumns = new Map<string, string>();
    this.energyUseTableColumns.set(
      'minKw',
      'scenario.summary.energy_use.minimum_demand',
    );
    this.energyUseTableColumns.set(
      'avgKw',
      'scenario.summary.energy_use.average_demand',
    );
    this.energyUseTableColumns.set(
      'maxKw',
      'scenario.summary.energy_use.maximum_demand',
    );
    this.energyUseTableColumns.set(
      'totalKwh',
      'scenario.summary.energy_use.total_usage',
    );
    this.energyUseTableColumns.set(
      'months',
      'scenario.summary.energy_use.number_of_months',
    );
    this.energyUseTableColumns.set(
      'interval',
      'scenario.summary.energy_use.interval_duration',
    );
    this.loadProfileFormDataKeyValue.set('project_id', this.scenario.parentId);
    this.loadProfileFormDataKeyValue.set('scenario_id', this.scenario.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['scenario'].firstChange) {
      setTimeout(() => this.initSearch());
    }
  }

  ngAfterViewInit(): void {
    this.loaderService.showLoader('energy-use-loader');
    this.initSearch();
  }

  async updateLoadProfile(loadProfile: LoadProfile): Promise<void> {
    this.loaderService.showLoader('energy-use-loader');
    if (!loadProfile) {
      this.scenario.adjustedPvLoadProfile = null;
      this.loaderService.changeLabel('');
    }
    const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
    this.scenario.loadprofileId = loadProfile?.id;
    if (!scenarioBefore.loadprofileId && this.scenario.loadprofileId) {
      let hasDynamic = false;
      if (
        this.scenario.currentDynamicImportTariff ||
        this.scenario.currentDynamicExportTariff ||
        this.scenario.tariffParameters?.dynamicStoredExportBefore ||
        this.scenario.tariffParameters?.dynamicStoredImportBefore
      ) {
        this.scenario.tariffParameters.dynamicBefore = true;
        hasDynamic = true;
      }

      if (
        this.scenario.switchDynamicImportTariff ||
        this.scenario.switchDynamicExportTariff ||
        this.scenario.tariffParameters?.dynamicStoredExportAfter ||
        this.scenario.tariffParameters?.dynamicStoredImportAfter
      ) {
        this.scenario.tariffParameters.dynamicAfter = true;
        hasDynamic = true;
      }

      if (hasDynamic) {
        this.tariffService
          .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
          .subscribe();
      }
    }
    if (!loadProfile?.id) {
      this.scenario.tariffParameters.dynamicBefore = false;
      this.scenario.tariffParameters.dynamicAfter = false;
      this.tariffService
        .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
        .subscribe();
    }
    this.loadProfileService
      .loadProfiles$(this.scenario.parentId)
      .subscribe(lp => {
        this.loadProfiles = lp;
        this.filteredProfiles = [...this.loadProfiles];
      });
    this.scenarioChange.emit([this.scenario, scenarioBefore, 'energy-use']);
  }

  private initSearch(): void {
    if (!this.input?.nativeElement) {
      return;
    }
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          const value = this.input.nativeElement.value;
          this.filteredProfiles = this.loadProfiles.filter(c =>
            c.filename.toLowerCase().includes(value.toLowerCase()),
          );
        }),
      )
      .subscribe();
  }
}
