import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toCamel, toSnake } from 'convert-keys';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from './cookie.service';

@Injectable()
export class ResourceService<T> {
  static API_LOCATION = '/api/v1';

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {}

  static handleError(error: string): Observable<never> {
    return throwError(() => new Error(`${error}`));
  }

  get(
    path: string,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .get<T>(`${ResourceService.API_LOCATION}/${path}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  getById(
    path: string,
    id: string | number,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .get<T>(`${ResourceService.API_LOCATION}/${path}/${id}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  getList(
    path: string,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${ResourceService.API_LOCATION}/${path}`, { params })
      .pipe(
        map(items => {
          const data = items['data'];
          if (!mappingCallback) {
            return <T[]>data.map(p => toCamel<T>(p));
          }
          return <T[]>data.map(p => toCamel<T>(mappingCallback(p)));
        }),
        catchError(ResourceService.handleError),
      );
  }

  getPaginated(
    path: string,
    index: number,
    page: number,
    params?: HttpParams,
  ): Observable<T[]> {
    const listParams = new HttpParams()
      .set('limit', index.toString())
      .set('offset', page.toString());

    return this.httpClient
      .get<T[]>(
        `${ResourceService.API_LOCATION}/${path}?${listParams.toString()}`,
        {
          params,
        },
      )
      .pipe(
        map(list => list['data'].map(p => toCamel<T>(p))),
        catchError(ResourceService.handleError),
      );
  }

  add(
    path: string,
    resource: T | Partial<T>[] | Partial<T> = undefined,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .post<T>(`${ResourceService.API_LOCATION}/${path}`, toSnake(resource), {
        params,
      })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  addList(
    path: string,
    resource: T[] | Partial<T[]> = undefined,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T[]> {
    return this.httpClient
      .post<T[]>(`${ResourceService.API_LOCATION}/${path}`, toSnake(resource), {
        params,
      })
      .pipe(
        map(items => {
          const data = items['data'];
          if (!mappingCallback) {
            return <T[]>data.map(p => toCamel<T>(p));
          }
          return <T[]>data.map(p => toCamel<T>(mappingCallback(p)));
        }),
        catchError(ResourceService.handleError),
      );
  }

  update(
    path: string,
    id: string | number,
    resource: T | Partial<T> | T[] | Partial<T>[],
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .put<T>(
        `${ResourceService.API_LOCATION}/${path}/${id}`,
        toSnake(resource),
        {
          params,
        },
      )
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  delete(
    path: string,
    id: string | number,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .delete<T>(`${ResourceService.API_LOCATION}/${path}/${id}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  deletePath(
    path: string,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .delete<T>(`${ResourceService.API_LOCATION}/${path}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  PUT(
    path: string,
    body?: T,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .put<T>(`${ResourceService.API_LOCATION}/${path}`, toSnake(body))
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  POST(
    path: string,
    body?: T,
    mappingCallback?: (response: unknown) => T,
  ): Observable<T> {
    return this.httpClient
      .post<T>(`${ResourceService.API_LOCATION}/${path}`, toSnake(body))
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError),
      );
  }

  private mapResponse(item, mappingCallback): T {
    const data = item['data'];
    if (!mappingCallback) {
      if (typeof data !== 'object') {
        return data;
      }
      return toCamel<T>(data);
    }
    return toCamel<T>(mappingCallback(data));
  }
}
