import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'optima-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  updates: string[];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.updates = this.route.snapshot.data['updates'];
  }

  goTo(url: string): void {
    window.open(url, '_blank');
  }
}
