<div *ngIf="stats?.length" class="mt-10">
  <div
    class="flex flex-start items-center border-bottom-header pb-3 mb-4 pointer"
    (click)="showGrid = !showGrid"
  >
    <mat-icon
      class="ml-2 text-primary-color"
      [ngStyle]="{ transform: showGrid ? 'rotate(180deg)' : 'rotate(0)' }"
    >
      arrow_drop_down
    </mat-icon>
    <h2 class="font-weight-300 ml-3">
      {{ 'scenario.form.grid_services' | translate }}
    </h2>
  </div>
  <div *ngIf="showGrid">
    <div class="mc-iterations-container mt-5 flex items-center">
      <span class="bold">
        {{ 'scenario.grid.monte_carlo.title' | translate }}:
      </span>
      <span class="ml-3 monte-carlo-number">
        {{ scenario.gridParameters?.montecarloIterations }}
      </span>
    </div>

    <table class="mt-5">
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th>{{ 'scenario.results.grid.table.minimum' | translate }}</th>
        <th>{{ 'scenario.results.grid.table.average' | translate }}</th>
        <th>{{ 'scenario.results.grid.table.maximum' | translate }}</th>
      </tr>
      <tr *ngFor="let stat of stats">
        <td>
          <div class="flex flex-col">
            <span class="program-name">{{ stat.programName }}</span>
            <span class="months">
              {{ monthName(stat.fromMonth) }}. - {{ monthName(stat.toMonth) }}.
            </span>
            <span class="type-revenue">
              <div
                class="program-type"
                [class]="getProgramTypeColorClass(stat.programType)"
              >
                {{ stat.programType }}
              </div>
              <div class="ml-2 program-revenue">
                <span
                  *ngFor="let item of [].constructor(4); let i = index"
                  [ngClass]="{ green: stat.revenue > i }"
                >
                  $
                </span>
              </div>
            </span>
          </div>
        </td>
        <td>
          <div class="separator"></div>
        </td>
        <td>
          <div class="text-primary-color flex flex-col">
            <span>{{ 'scenario.grid.program.revenue' | translate }}</span>
            <span>{{ 'scenario.grid.program.nomination' | translate }}</span>
            <span>{{ 'scenario.grid.program.utilization' | translate }}</span>
          </div>
        </td>
        <td>
          <div class="flex flex-col">
            <span>${{ stat.min.revenue | number: '1.2-2' }}</span>
            <span>{{ stat.min.nomination }}</span>
            <ng-container
              *ngTemplateOutlet="utilizationMin; context: stat.min"
            ></ng-container>
          </div>
        </td>
        <td>
          <div class="flex flex-col">
            <span>${{ stat.avg.revenue | number: '1.2-2' }}</span>
            <span>{{ stat.avg.nomination }}</span>
            <ng-container
              *ngTemplateOutlet="utilization; context: stat.avg"
            ></ng-container>
          </div>
        </td>
        <td>
          <div class="flex flex-col">
            <span>${{ stat.max.revenue | number: '1.2-2' }}</span>
            <span>{{ stat.max.nomination }}</span>
            <ng-container
              *ngTemplateOutlet="utilization; context: stat.max"
            ></ng-container>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #utilization let-stat="utilization">
  <span class="flex items-center">
    <span>{{ stat * 100 | number: '1.0-0' }}%</span>
  </span>
</ng-template>

<ng-template #utilizationMin let-stat="utilization">
  <span class="flex items-center" [ngClass]="{ red: stat < 0.25 }">
    <mat-icon
      class="warning-icon"
      svgIcon="warning-red"
      *ngIf="stat < 0.25"
    ></mat-icon>
    <span>{{ stat * 100 | number: '1.0-0' }}%</span>
  </span>
</ng-template>
