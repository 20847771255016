import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicInputModule } from '../../../global/dynamic-input/dynamic-input.module';
import { SiteConstraintFormComponent } from './site-constraint-form.component';

@NgModule({
  declarations: [SiteConstraintFormComponent],
  exports: [SiteConstraintFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatRadioModule,
    DynamicInputModule,
    FormsModule,
  ],
})
export class SiteConstraintFormModule {}
