<div>
  <h4 class="mb-6 mt-12 header">
    {{ 'scenario.form.grid_services' | translate }}
  </h4>
</div>
<div class="flex flex-col ml-8">
  <div class="flex flex-col">
    <h5 class="mb-6">{{ 'scenario.grid.selected.programs' | translate }}</h5>
    <div
      class="flex items-center"
      *ngIf="
        (scenario?.programs?.standard?.length || scenario?.programs?.custom?.length)
        && scenario?.options?.gridService;
        else elseBlock
      "
    >
      <span class="bold mr-4 monte-carlo-label">
        {{ 'scenario.grid.monte_carlo.title' | translate }}:
      </span>
      <span class="monte-carlo-number">
        {{ scenario.gridParameters?.montecarloIterations }}
      </span>
    </div>
  </div>
  <div
    class="list-background"
    *ngIf="(scenario?.programs?.standard?.length || scenario?.programs?.custom?.length) && scenario?.options?.gridService"
  >
    <div class="row selected-header">
      {{ scenario.programs.standard.length + scenario.programs.custom.length }}
      {{ 'scenario.grid.program.selected' | translate }}
    </div>
    <div class="row program" *ngFor="let program of programs">
      <span class="name mr-1">{{ program.programName }}</span>
      <span class="operator mr-1">{{ program.operator }}</span>
      <span
        class="zone mr-1 pl-2 pr-2"
        [matTooltip]="program.zone"
        [matTooltipDisabled]="checkEllipses(program.zone)"
      >
        {{ program.zone | stringTruncate: zoneLength }}
      </span>
      <span class="meter-type mr-2">{{ program.meterType }}</span>
      <span class="months mr-2">
        {{ monthName(program.fromMonth) }} - {{ monthName(program.toMonth) }}
      </span>
      <div class="type-revenue">
        <div
          class="program-type"
          [class]="getProgramTypeColorClass(program.programType)"
        >
          {{ program.programType }}
        </div>
        <div class="ml-2 program-revenue">
          <span
            *ngFor="let item of [].constructor(4); let i = index"
            [ngClass]="{ green: program.revenue > i }"
          >
            $
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <div class="ml-8">
    {{ 'scenario.summary.grid.no_program' | translate }}
  </div>
</ng-template>
