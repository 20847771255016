<div class="dialog-container" data-automation="custom-ess-dialog">
  <optima-loader id="ess-dialog-loader"></optima-loader>
  <div class="text-center">
    <h1
      mat-dialog-title
      class="flex items-center"
      data-automation="custom-ess-dialog-title"
    >
      <div class="purple-rect flex items-center justify-center mr-3">
        <mat-icon svgIcon="ess-edit"></mat-icon>
      </div>
      {{ 'dialog.ess_custom.title' | translate }}
    </h1>
    <div mat-dialog-content class="mt-2 pb-2">
      <div
        *ngFor="let formGroup of formGroupArray; index as i"
        [ngClass]="{ 'mt-10': i !== 0 }"
      >
        <div class="flex items-end justify-start text-left">
          <div
            class="custom-product flex flex-col"
            data-automation="custom-ess-dialog-product-custom"
          >
            <span class="field-label">
              {{ 'dialog.ess_custom.form.model' | translate }}
            </span>
            <span class="field-value">
              {{ 'dialog.ess_custom.form.model.custom' | translate }}
              {{ customAlreadyPresents + i + 1 }}
            </span>
          </div>
          <div
            class="flex flex-col"
            data-automation="custom-ess-dialog-product-oem"
          >
            <span class="field-label">
              {{ 'dialog.ess_custom.form.oem' | translate }}
            </span>
            <span class="field-value">
              {{ 'dialog.ess_custom.form.oem.custom' | translate }}
            </span>
          </div>
        </div>
        <form
          [formGroup]="formGroup"
          class="form-gap"
          data-automation="custom-ess-dialog-form"
        >
          <mat-form-field appearance="outline">
            <mat-label data-automation="custom-ess-power-label">
              {{ 'dialog.ess_custom.form.power' | translate }}
            </mat-label>
            <input
              data-automation="custom-ess-power-value"
              matInput
              formControlName="totalKw"
              placeholder="{{
                'dialog.ess_custom.form.power.enter' | translate
              }}"
              optimaDigitDecimalNumber
              [digit]="1"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error *ngIf="check('totalKw', 'required', formGroup)">
              {{ 'dialog.ess_custom.form.power.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="check('totalKw', 'min', formGroup)">
              {{ 'dialog.ess_custom.form.power.error.min_max' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label data-automation="custom-ess-capacity-label">
              {{ 'dialog.ess_custom.form.capacity' | translate }}
            </mat-label>
            <input
              data-automation="custom-ess-capacity-value"
              matInput
              formControlName="totalKwh"
              placeholder="{{
                'dialog.ess_custom.form.capacity.enter' | translate
              }}"
              optimaDigitDecimalNumber
              [digit]="1"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error *ngIf="check('totalKwh', 'required', formGroup)">
              {{ 'dialog.ess_custom.form.capacity.error.required' | translate }}
            </mat-error>
            <mat-error *ngIf="check('totalKwh', 'min', formGroup)">
              {{ 'dialog.ess_custom.form.capacity.error.min_max' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="rte-field">
            <mat-label data-automation="custom-ess-rte-label">
              {{ 'dialog.ess_custom.form.rte' | translate }}
            </mat-label>
            <input
              data-automation="custom-ess-rte-value"
              matInput
              formControlName="rte"
              placeholder="{{ 'dialog.ess_custom.form.rte.enter' | translate }}"
              optimaDigitDecimalNumber
              [digit]="1"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-icon
              matSuffix
              svgIcon="info-circle"
              class="input-icon"
              [matTooltip]="'dialog.ess_custom.form.rte.tooltip' | translate"
              matTooltipClass="line-broken-tooltip"
            ></mat-icon>
            <mat-error *ngIf="check('rte', 'required', formGroup)">
              {{ 'dialog.ess_custom.form.rte.error.required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                check('rte', 'min', formGroup) || check('rte', 'max', formGroup)
              "
            >
              {{ 'dialog.ess_custom.form.rte.error.min_max' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="aux-field">
            <mat-label data-automation="custom-ess-aux-label">
              {{ 'dialog.ess_custom.form.aux' | translate }}
            </mat-label>
            <input
              data-automation="custom-ess-aux-value"
              matInput
              formControlName="aux"
              placeholder="{{ 'dialog.ess_custom.form.aux.enter' | translate }}"
              optimaDigitDecimalNumber
              [digit]="2"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-icon
              matSuffix
              svgIcon="info-circle"
              class="input-icon"
              [matTooltip]="'dialog.ess_custom.form.aux.tooltip' | translate"
              matTooltipClass="line-broken-tooltip"
            ></mat-icon>
            <mat-error *ngIf="check('aux', 'required', formGroup)">
              {{ 'dialog.ess_custom.form.aux.error.required' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                check('aux', 'min', formGroup) || check('aux', 'max', formGroup)
              "
            >
              {{ 'dialog.ess_custom.form.aux.error.min_max' | translate }}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div
        class="mt-9 text-primary-color pointer flex items-center w-fit"
        (click)="addForm()"
        data-automation="custom-ess-dialog-add-another"
      >
        <mat-icon svgIcon="plus" class="mr-2 plus-icon"></mat-icon>
        {{ 'dialog.ess_custom.add_another' | translate }}
      </div>
    </div>
    <div
      mat-dialog-actions
      class="flex items-center justify-between"
      data-automation="custom-ess-dialog-actions"
    >
      <button
        class="icon-btn primary-btn"
        data-automation="dialog-second-button"
        matRipple
        (click)="cancel()"
      >
        <span>{{ 'dialog.ess_custom.cancel' | translate }}</span>
      </button>

      <button
        class="icon-btn primary-btn first-button"
        data-automation="dialog-first-button"
        matRipple
        [disabled]="checkInvalid()"
        (click)="submit()"
      >
        <span>{{ 'dialog.ess_custom.add_custom' | translate }}</span>
      </button>
    </div>
  </div>
</div>
