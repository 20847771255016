import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { ResultTableComponent } from './result-table.component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [ResultTableComponent],
  exports: [ResultTableComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatTooltipModule,
    ClipboardModule,
    MatRippleModule,
    MatSortModule,
    PipeModule,
  ],
})
export class ResultTableModule {}
