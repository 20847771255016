import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Scenario } from '@model';

@Injectable()
export class TreeService {
  private _doNotChangeRoute = false;
  get doNotChangeRoute(): boolean {
    return this._doNotChangeRoute;
  }
  set doNotChangeRoute(value: boolean) {
    this._doNotChangeRoute = value;
  }

  private readonly enableFilterInputEmitter = new Subject<boolean>();
  enableFilterInput$ = this.enableFilterInputEmitter.asObservable();

  private readonly autoSelectInTreeEmitter = new Subject<void>();
  autoSelectInTree$ = this.autoSelectInTreeEmitter.asObservable();

  enableFilterInput(): void {
    this.enableFilterInputEmitter.next(true);
  }

  autoSelectInTree(): void {
    this.autoSelectInTreeEmitter.next();
  }
}
