import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ResourceService } from './resource.service';
import { DynamicInput, MinSiteParameters, Scenario } from '@model';

@Injectable()
export class SiteConstraintService {
  private readonly uncheckEssExportEmitter = new Subject<void>();
  uncheckEssExport$ = this.uncheckEssExportEmitter.asObservable();

  constructor(
    private siteConstraintResourceService: ResourceService<MinSiteParameters>,
    private dynamicInputResourceService: ResourceService<DynamicInput>,
  ) {}

  getSiteConstraint$(
    scenarioId: string,
    max = false,
  ): Observable<MinSiteParameters> {
    return this.siteConstraintResourceService.get(
      max
        ? `scenarios/${scenarioId}/max-load-site-parameters`
        : `scenarios/${scenarioId}/site-parameters`,
    );
  }

  getDynamicInput$(topic: string): Observable<DynamicInput[]> {
    return this.dynamicInputResourceService.getList(
      `reference/dynamic-inputs?topic=${topic}`,
    );
  }

  updateSiteConstraint$(
    scenarioId: string,
    siteConstraint: MinSiteParameters,
    max = false,
  ): Observable<MinSiteParameters> {
    const path = max
      ? `${scenarioId}/max-load-site-parameters`
      : `${scenarioId}/site-parameters`;
    return this.siteConstraintResourceService.update(
      'scenarios',
      path,
      siteConstraint,
    );
  }

  uncheckEssExport(): void {
    this.uncheckEssExportEmitter.next();
  }
}
