<div class="flex flex-col justify-center items-center h-full">
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'scenario.grid.monte_carlo.title' | translate }}
      <mat-icon
        svgIcon="info"
        [matTooltip]="
          translateService.instant('scenario.grid.monte_carlo.tooltip')
        "
      ></mat-icon>
    </mat-label>
    <mat-select
      [ngModel]="scenario.gridParameters?.montecarloIterations"
      (selectionChange)="selectionChange($event)"
      (opened)="addCssForSelect()"
      (closed)="removeCssForSelect()"
    >
      <mat-option
        *ngFor="let item of [].constructor(50); let i = index"
        [value]="i + 1"
      >
        {{ getTranslation(i) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <span class="explanation">
    {{ 'scenario.grid.monte_carlo.explanation' | translate }}
  </span>
</div>
