<div
  class="ess-summary-container"
  *ngIf="ders?.length && options?.energyStorage; else emptyStorage"
>
  <div
    class="flex flex-col card-container"
    [ngStyle]="{ 'overflow-y': isFirefox ? 'auto' : 'overlay' }"
  >
    <mat-card class="summary-card" *ngFor="let ess of selectedEssList">
      <div class="flex flex-row justify-between">
        <span class="flex justify-start">
          <span
            class="mr-3 text-16 text-bold gray-text"
            *ngIf="!ess.isCombo && !ess.isCustom"
          >
            {{ ess.essItems[0].qty }}
          </span>
          <span class="mr-3 text-16 text-bold gray-text" *ngIf="ess.isCombo">
            1
          </span>
          <span class="mr-3 text-16 text-bold">{{ ess.essLabel }}</span>
        </span>
        <span class="flex items-center">
          <span class="mr-6">
            <mat-icon
              svgIcon="bolt"
              matTooltip="{{ 'scenario.ess_summary_table.power' | translate }}"
              id="lightning"
              alt="lightning bolt"
            ></mat-icon>
            {{ ess.kw | number: '1.0-0' }}
          </span>
          <span>
            <mat-icon
              svgIcon="capacity"
              matTooltip="{{
                'scenario.ess_summary_table.capacity' | translate
              }}"
              id="battery"
              alt="battery capacity"
            ></mat-icon>
            {{ ess.kwh | number: '1.0-0' }}
          </span>
        </span>
      </div>
      <div *ngIf="ess.isCombo">
        <ng-container *ngFor="let modelSizes of ess.essItems">
          <span class="gray-text">({{ modelSizes.qty }})</span>
          <span>{{ modelSizes.essItemLabel }}</span>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>

<ng-template #emptyStorage>
  <span class="ml-8" data-automation="no-ess-label">
    {{ 'scenario.no_ess' | translate }}
  </span>
</ng-template>
