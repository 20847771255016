import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { keypressIntegerControl } from '@helper';
import { Scenario } from '@model';
import { EssService, ScenarioService, SiteConstraintService } from '@service';

@Component({
  selector: 'optima-ess-advanced-options',
  templateUrl: './ess-advanced-options.component.html',
  styleUrls: ['./ess-advanced-options.component.scss'],
})
export class EssAdvancedOptionsComponent implements OnChanges {
  @Input() scenario: Scenario;
  @ViewChild('costCycleElement') costCycleElement: ElementRef;
  @ViewChild('costSocElement') costSocElement: ElementRef;
  @ViewChild('essDistanceElement') essDistanceElement: ElementRef;
  pvChargeDisabled = false;
  costCycleControl: UntypedFormControl;
  costSocControl: UntypedFormControl;
  essDistanceControl: UntypedFormControl;
  costCycleError = false;
  costSocError = false;
  essDistanceError = false;
  costCycleValue: number;
  costSocValue: number;
  essDistanceValue: number;
  lastTimeout: NodeJS.Timeout;
  keyPressIntegerControl = keypressIntegerControl;

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    event.preventDefault();
  }

  constructor(
    private essService: EssService,
    public scenarioService: ScenarioService,
    private siteConstraintService: SiteConstraintService,
  ) {
    this.siteConstraintService.uncheckEssExport$.subscribe(() => {
      if (this.scenario.essParameters.export) {
        this.scenario.essParameters.export = false;
        this.update();
      }
    });
    this.scenarioService.scenariosChange$.subscribe(() => {
      this.checkSolar();
    });
  }

  ngOnChanges(): void {
    this.checkSolar();
    this.costCycleValue = this.scenario.essParameters.costCycle;
    this.costSocValue = this.scenario.essParameters.costSoc;
    this.essDistanceValue = this.scenario.essParameters.distance;

    this.costCycleControl = new UntypedFormControl(this.costCycleValue);
    this.costSocControl = new UntypedFormControl(this.costSocValue);
    this.essDistanceControl = new UntypedFormControl(this.essDistanceValue);

    this.costCycleControl.valueChanges.subscribe((value: number) => {
      this.costCycleValue = Number(parseFloat(String(value)).toFixed(2));
    });

    this.costSocControl.valueChanges.subscribe((value: number) => {
      this.costSocValue = Number(parseFloat(String(value)).toFixed(5));
    });

    this.essDistanceControl.valueChanges.subscribe((value: number) => {
      this.essDistanceValue = parseInt(String(value));
    });
  }

  update(): void {
    this.essService
      .updateParameters$(this.scenario.id, this.scenario.essParameters)
      .subscribe();
  }

  sliderOnChange(propertyName: string, value: number): void {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }
    if (this.scenario.essParameters[propertyName] !== value) {
      this.scenario.essParameters[propertyName] = Number(
        parseFloat(String(value)).toFixed(2),
      );
      this.lastTimeout = setTimeout(() => this.update(), 500);
    }
  }

  saveInput(property: string, value: number): void {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }
    this.lastTimeout = setTimeout(() => {
      this.scenario.essParameters[property] = value;
      this.update();
    }, 500);
  }

  costCycleCheck(): void {
    if (
      !/^\s*-?\d*(\.\d{1,2})?\s*$/.test(
        this.costCycleElement.nativeElement.value,
      ) ||
      this.costCycleElement.nativeElement.value < -1000000 ||
      this.costCycleElement.nativeElement.value > 1000000
    ) {
      if (this.lastTimeout) {
        clearTimeout(this.lastTimeout);
      }
      this.costCycleElement.nativeElement.blur();
      this.costCycleElement.nativeElement.focus();
      this.costCycleError = true;
      this.costCycleControl.setErrors({ notMatched: true });
    } else {
      this.costCycleError = false;
      this.costCycleControl.setErrors(null);
      this.saveInput('costCycle', this.costCycleValue);
    }
  }

  costSocCheck(): void {
    if (
      !/^\s*-?\d*(\.\d{1,5})?\s*$/.test(
        this.costSocElement.nativeElement.value,
      ) ||
      this.costSocElement.nativeElement.value < -1000000 ||
      this.costSocElement.nativeElement.value > 1000000
    ) {
      if (this.lastTimeout) {
        clearTimeout(this.lastTimeout);
      }
      this.costSocElement.nativeElement.blur();
      this.costSocElement.nativeElement.focus();
      this.costSocError = true;
      this.costSocControl.setErrors({ notMatched: true });
    } else {
      this.costSocError = false;
      this.costSocControl.setErrors(null);
      this.saveInput('costSoc', this.costSocValue);
    }
  }

  essDistanceCheck(): void {
    if (
      this.essDistanceElement.nativeElement.value < 0 ||
      this.essDistanceElement.nativeElement.value > 50000
    ) {
      if (this.lastTimeout) {
        clearTimeout(this.lastTimeout);
      }
      this.essDistanceElement.nativeElement.blur();
      this.essDistanceElement.nativeElement.focus();
      this.essDistanceError = true;
      this.essDistanceControl.setErrors({ notMatched: true });
    } else {
      this.essDistanceError = false;
      this.essDistanceControl.setErrors(null);
      this.saveInput('distance', this.essDistanceValue);
    }
  }

  disableExport(): boolean {
    const id = this.scenario.minLoadSiteParameters.id;
    return id === 2 || id === 3;
  }

  private checkSolar(): void {
    if (!this.scenario.pvloadId || !this.scenario.options.solarPv) {
      if (this.scenario.essParameters.pvCharge) {
        this.scenario.essParameters.pvCharge = false;
        this.update();
      }
      this.pvChargeDisabled = true;
    } else {
      this.pvChargeDisabled = false;
    }
  }
}
