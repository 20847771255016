<div class="container mt-6">
  <div class="section-header-container download-link-container mt-0 mb-0 pb-1">
    <div class="flex flex-col">
      <div class="icon-container">
        <a
          class="pointer"
          data-automation="download-profile-hyperlink"
          (click)="downloadBillResults()"
        >
          <mat-icon svgIcon="download-new" class=""></mat-icon>
          <span>
            {{ 'scenario.results.download_bill_summary' | translate }}
          </span>
        </a>
      </div>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    data-automation="multi-load-profile-table"
  >
    <ng-container
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ columns.get(column) | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let stat"
        [ngClass]="{ 'row-label': column === 'rowLabel' }"
      >
        <ng-container *ngIf="column === 'rowLabel'; else otherColumns">
          <span data-automation="multi-load-profile-table-row-label">
            {{ stat['rowLabel'] | translate }}
          </span>
        </ng-container>
        <ng-template #otherColumns>
          <span *ngIf="!units.get(column)">
            {{ stat[column] | currency: 'USD' : 'symbol' : '1.0-0' }}
          </span>
          <span *ngIf="units.get(column)">
            {{ stat[column] | number: '1.0-0' || '-' }}
          </span>
          <span class="ml-1">{{ units.get(column) | translate }}</span>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let stat; columns: displayedColumns"></tr>
  </table>
</div>
