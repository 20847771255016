import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../../../shared/component/global/loader/loader.module';
import { ForecastedModule } from '../../../shared/component/scenario/form/forecasted/forecasted.module';
import { GridModule } from '../../../shared/component/scenario/form/grid/grid.module';
import { SiteConstraintFormModule } from '../../../shared/component/scenario/form/site-constraint-form/site-constraint-form.module';
import { SolarPvModule } from '../../../shared/component/scenario/form/solar-pv/solar-pv.module';
import { TariffAdvancedOptionsModule } from '../../../shared/component/scenario/form/tariff-advanced-options/tariff-advanced-options.module';
import { ScenarioFormComponent } from './scenario-form.component';
import {
  EnergyUseModule,
  EssAdvancedOptionsModule,
  EssTableModule,
  InlineEditModule,
  TariffModule,
} from '@component';
import { PipeModule } from '@pipe';

@NgModule({
  declarations: [ScenarioFormComponent],
  imports: [
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    MatStepperModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    InlineEditModule,
    MatRippleModule,
    EssTableModule,
    EnergyUseModule,
    TariffModule,
    EssAdvancedOptionsModule,
    MatSlideToggleModule,
    PipeModule,
    SolarPvModule,
    TariffAdvancedOptionsModule,
    SiteConstraintFormModule,
    MatTooltipModule,
    LoaderModule,
    GridModule,
    ForecastedModule,
  ],
  exports: [ScenarioFormComponent],
})
export class ScenarioFormModule {}
