import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UploaderComponent } from '@component';
import {
  ForecastChunk,
  ForecastChunkStatusEnum,
  ForecastLoadProfile,
  Scenario,
} from '@model';
import { ForecastService, ScenarioService } from '@service';

@Component({
  selector: 'optima-forecasted-energy',
  templateUrl: './forecasted-energy.component.html',
  styleUrls: ['./forecasted-energy.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ForecastedEnergyComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() scenario: Scenario;
  @Input() energyForecasts: ForecastLoadProfile[];
  @Output() forecastChangedChunk = new EventEmitter<ForecastChunk>();
  @Output() forecastChanged = new EventEmitter<ForecastLoadProfile>();
  @ViewChild('searchInput') input: ElementRef;
  @ViewChild('uploaderEnergyForecast')
  uploaderEnergyForecast: UploaderComponent<ForecastLoadProfile>;
  filteredForecasts: ForecastLoadProfile[];
  formDataParameters = new Map<string, string>();
  postForecastUrl: string;

  constructor(
    public scenarioService: ScenarioService,
    private forecastService: ForecastService,
  ) {}

  ngOnInit(): void {
    this.filteredForecasts = [...this.energyForecasts];
    this.filteredForecasts = this.filteredForecasts.filter(
      forecastLoadProfile =>
        forecastLoadProfile.status === ForecastChunkStatusEnum.COMPLETED,
    );
    this.formDataParameters.set('type', 'METER');
    this.postForecastUrl = `/scenarios/${this.scenario.id}/forecast-dispatch`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['scenario'].firstChange) {
      setTimeout(() => this.initSearch());
    }
  }

  ngAfterViewInit(): void {
    this.initSearch();
  }

  async updateForecast(forecast: ForecastLoadProfile): Promise<void> {
    this.updateList();
    this.forecastChanged.emit(forecast);
  }

  async updateForecastChunk(forecast: ForecastChunk): Promise<void> {
    if (forecast && forecast.chunkNumber === forecast.totalChunks) {
      this.updateList();
    }
    this.forecastChangedChunk.emit(forecast);
  }

  private initSearch(): void {
    if (!this.input?.nativeElement) {
      return;
    }
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          const value = this.input.nativeElement.value;
          this.filteredForecasts = this.energyForecasts.filter(c =>
            c.filename.toLowerCase().includes(value.toLowerCase()),
          );
        }),
      )
      .subscribe();
  }

  private updateList(): void {
    this.forecastService
      .getEnergyForecasts(this.scenario.parentId)
      .subscribe(forecasts => {
        this.energyForecasts = forecasts;
        this.filteredForecasts = [...this.energyForecasts];
        this.filteredForecasts = this.filteredForecasts.filter(
          forecastLoadProfile =>
            forecastLoadProfile.status === ForecastChunkStatusEnum.COMPLETED,
        );
      });
  }
}
