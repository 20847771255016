import { DecimalPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicInput, Scenario } from '@model';

@Component({
  selector: 'optima-site-constraint',
  templateUrl: './site-constraint.component.html',
})
export class SiteConstraintComponent {
  @Input() scenario: Scenario;
  minInputs: DynamicInput[];
  maxInputs: DynamicInput[];

  constructor(
    private route: ActivatedRoute,
    private decimalPipe: DecimalPipe,
  ) {
    this.minInputs = this.route.snapshot.data['scenario'][5];
    this.maxInputs = this.route.snapshot.data['scenario'][6];
  }

  showColumn(max = false): string {
    const id = max
      ? this.scenario.maxLoadSiteParameters.id
      : this.scenario.minLoadSiteParameters.id;
    if (id === 0 || id === 2) {
      return '';
    }
    return ':';
  }

  showUnits(max = false): string {
    const id = max
      ? this.scenario.maxLoadSiteParameters.id
      : this.scenario.minLoadSiteParameters.id;
    if (id === 0 || id === 2) {
      return '';
    }
    return 'kW';
  }

  showValue(max = false): string {
    const id = max
      ? this.scenario.maxLoadSiteParameters.id
      : this.scenario.minLoadSiteParameters.id;
    if (id === 0 || id === 2) {
      return '';
    }
    const decimal = max
      ? this.decimalPipe.transform(
          this.scenario.maxLoadSiteParameters.value,
          '1.0-1',
        )
      : this.decimalPipe.transform(
          this.scenario.minLoadSiteParameters.value,
          '1.0-1',
        );
    return decimal.replaceAll(',', '');
  }

  showLabel(max = false): string {
    const label = max
      ? this.maxInputs.find(
          input =>
            input.displayOrder === this.scenario.maxLoadSiteParameters.id,
        ).displayLabel
      : this.minInputs.find(
          input =>
            input.displayOrder === this.scenario.minLoadSiteParameters.id,
        ).displayLabel;
    return label.replace(/ *\([^)]*\) */g, '');
  }
}
