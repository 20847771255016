import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toCamel } from 'convert-keys';
import { gzip } from 'pako';
import { Observable, Subject, forkJoin } from 'rxjs';
import { LoaderService } from '@service';

@Injectable()
export class UploaderService<T> {
  object: T | T[];
  chunkMB = 3.5;

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
  ) {}

  // create a http-post request and pass the form
  // tell it to report the upload progress
  req = (
    method: string,
    url: string,
    listParams: HttpParams,
    data: FormData | ArrayBufferLike,
    headers?: HttpHeaders,
  ): HttpRequest<FormData | ArrayBufferLike> =>
    new HttpRequest(
      method.toUpperCase(),
      `${url}?${listParams.toString()}`,
      data,
      {
        headers: headers,
        reportProgress: true,
      },
    );

  upload = async (
    file: File,
    url: string,
    method: 'post' | 'put',
    chunk: boolean,
    formDataParameters?: Map<string, string>,
  ): Promise<Observable<number>> => {
    // this will be the resulting map
    // create a new progress-subject for every file
    const progress = new Subject<number>();

    let listParams = new HttpParams();
    listParams = listParams.set('filename', file.name);
    if (formDataParameters) {
      formDataParameters.forEach((value: string, key: string) => {
        listParams = listParams.append(key, value);
      });
    }
    if (chunk) {
      this.loaderService.showLoaderNoHttp(`chunk-loader`);
      const zipped = await this.zippingAsync(file);
      const chunkSize = 1024 * 1024 * this.chunkMB;
      const totalChunks = Math.ceil(zipped.byteLength / chunkSize);
      const chunkObservables = [];
      let chunkNumber: number;

      // write the first chunk synchronously to initialize in the Service
      const firstChunk$ = this.writeChunk(
        zipped,
        listParams,
        method,
        url,
        totalChunks,
        1,
        chunkSize,
      );

      for (chunkNumber = 2; chunkNumber < totalChunks + 1; chunkNumber++) {
        const chunkObservable = this.writeChunk(
          zipped,
          listParams,
          method,
          url,
          totalChunks,
          chunkNumber,
          chunkSize,
        );
        chunkObservables.push(chunkObservable);
      }

      this.loaderService.hideLoaderNoHttp(`chunk-loader`);

      // write the first chunk synchronously to initialize in the Service
      firstChunk$.subscribe({
        next: value => {
          this.object = value as T;
          progress.next(0);
        },
        error: (err: unknown) => {
          progress.error(err);
        },
        complete: () => {
          // if more than 1 chunk, write all the remaining chunks
          if (chunkObservables.length > 0) {
            // eslint-disable-next-line rxjs/no-nested-subscribe
            forkJoin([...chunkObservables]).subscribe(
              (allResponses: HttpResponse<T>[]) => {
                const response = allResponses[0];
                if (response?.body?.hasOwnProperty('data')) {
                  this.object = response.body['data'] as T;
                  this.object = toCamel(this.object);
                }
                progress.complete();
              },
            );
          } else {
            if (
              this.object?.hasOwnProperty('body') &&
              this.object['body'].hasOwnProperty('data')
            ) {
              this.object = this.object['body']['data'] as T;
              this.object = toCamel(this.object);
            }
            progress.complete();
          }
        },
      });
    } else {
      const formData = new FormData();
      formData.append('csvfile', file, file.name);
      this.http
        .request<FormData>(this.req(method, url, listParams, formData))
        .subscribe({
          next: (value) => {
            this.object = value as T;
            progress.next(0);
          },
          error: (err) => {
            console.log(`error uploading ${file.name}`, err);
            progress.error(err);
          },
          complete: () => {
            if (
              this.object?.hasOwnProperty('body') &&
              this.object['body'].hasOwnProperty('data')
            ) {
              this.object = this.object['body']['data'] as T;
              this.object = toCamel(this.object);
            }
            console.log(`uploaded the ${file.name} successfully!`);
            progress.complete();
          }
        });
    }

    return progress.asObservable();
  };

  zippingAsync = async (file: File): Promise<Uint8Array> => {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(new URL('./uploader.worker', import.meta.url));
      const zipping = (file: File): Promise<Uint8Array> =>
        new Promise(res => {
          worker.onmessage = ({ data }): void => {
            const zipped = data['zipped'];
            res(zipped);
          };
          worker.postMessage(file);
        });
      return await zipping(file);
    } else {
      const arr = await file.arrayBuffer();
      const zipped = gzip(arr);
      return new Promise(res => res(zipped));
    }
  };

  writeChunk = (
    allData: Uint8Array,
    listParams: HttpParams,
    method: 'post' | 'put',
    url: string,
    totalChunks: number,
    chunkNumber: number,
    chunkSize: number,
  ): Observable<HttpEvent<ArrayBufferLike>> => {
    const start = (chunkNumber - 1) * chunkSize;
    const end = Math.min(start + chunkSize, allData.byteLength);
    const chunk = allData.slice(start, end);
    const newHeaders = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });

    listParams = listParams.set('totalChunks', totalChunks);
    listParams = listParams.set('chunkNumber', chunkNumber);
    listParams = listParams.set('chunkSize', chunkSize);

    const req = this.req(method, url, listParams, chunk.buffer, newHeaders);
    return this.http.request<ArrayBufferLike>(req);
  };
}
