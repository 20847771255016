import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsParametersComponent } from './programs-parameters.component';
import { DirectiveModule } from '@directive';

@NgModule({
  declarations: [ProgramsParametersComponent],
  exports: [ProgramsParametersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DirectiveModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class ProgramsParametersModule {}
