<div class="flex justify-center items-center table-container" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="{{ column }}"
        style="text-align: center"
      >
        {{
          getTranslateKey(column)
            | translate
            | removeUnit: getPrintInfo(column) : hasColumnValues(column)
        }}
      </th>
      <td
        mat-cell
        *matCellDef="let el"
        [ngStyle]="{ 'text-align': el[column] ? 'center' : 'center' }"
      >
        <ng-container *ngIf="column === 'runState'; else otherColumns">
          <div></div>
          <ng-container [ngSwitch]="el['runState']">
            <span class="adjust-center" *ngSwitchCase="'STARTED'">
              <img
                src="../../../../../assets/images/oval-yellow-hollow.svg"
                alt="yellow"
                matTooltip="{{ 'scenario.results.status.yellow' | translate }}"
              />
            </span>
            <span class="adjust-center" *ngSwitchCase="'COMPLETED'">
              <img
                src="../../../../../assets/images/oval-green.svg"
                alt="green"
                matTooltip="{{ 'scenario.results.status.green' | translate }}"
              />
            </span>
            <span class="adjust-center" *ngSwitchCase="'ERROR'">
              <img
                src="../../../../../assets/images/oval-red.svg" alt="red"
                matTooltip="{{ 'scenario.results.status.red' | translate }}"
              />
            </span>
          </ng-container>
        </ng-container>
        <ng-template #otherColumns>
          <span class="adjust-center">
            {{
              el[column]
                | number: '1.0-0'
                | printUnit: getTranslateKey(column) : getPrintInfo(column)
            }}
          </span>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="pointer purple-hover"
      (click)="toggleSelectedRow(result)"
      [ngClass]="{ 'selected-row': result.idx === selectedIdx }"
      *matRowDef="let result; columns: displayedColumns"
    ></tr>
  </table>
</div>
