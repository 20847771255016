export interface GlobalAlertOptions {
  type: 'success' | 'error' | 'warning';
  message: string;
  timeout: number;
}

export class GlobalAlertOptions {
  constructor(
    type: 'success' | 'error' | 'warning',
    message: string,
    timeout: number,
  ) {
    this.type = type;
    this.message = message;
    this.timeout = timeout;
  }
}
