import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, from, of } from 'rxjs';
import { EnergyUseComponent } from '../../../shared/component/scenario/form/energy-use/energy-use.component';
import { ForecastedComponent } from '../../../shared/component/scenario/form/forecasted/forecasted.component';
import { SolarPvComponent } from '../../../shared/component/scenario/form/solar-pv/solar-pv.component';
import { inOut } from '@helper';
import {
  Bess,
  DynamicInput,
  ForecastLoadProfile,
  LoadProfile,
  Project,
  Scenario,
  ScenarioUpdateEnum,
  SolarLoadProfile,
} from '@model';
import {
  EssService,
  ForecastService,
  LoadProfileService,
  LoaderService,
  ScenarioService,
  SolarPvService,
  TariffService,
  TreeService,
} from '@service';
import { noWhitespaceValidator } from '@validator';

@Component({
  selector: 'optima-scenario-form',
  templateUrl: './scenario-form.component.html',
  styleUrls: ['./scenario-form.component.scss'],
  animations: [inOut({ triggerName: 'inOut2', height: 10, time: 0.1 })],
})
// eslint-disable-next-line prettier/prettier
export class ScenarioFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  isLinear = false;
  isEssSectionCompleted = false;
  showEssOptions = false;
  showTariffOptions = false;
  energyUseFormGroup: UntypedFormGroup;
  tariffDataFormGroup: UntypedFormGroup;
  addEnergyStorageFormGroup: UntypedFormGroup;
  addSolarPVFormGroup: UntypedFormGroup;
  addGridServicesFormGroup: UntypedFormGroup;
  addForecastedFormGroup: UntypedFormGroup;
  parentProject: Project;
  scenario: Scenario;
  bess: Bess[];
  scenarioNameFormControl: UntypedFormControl;
  loadProfiles: LoadProfile[];
  pvLoadProfiles: SolarLoadProfile[];
  minLoadSiteConstraintInputs: DynamicInput[];
  maxLoadSiteConstraintInputs: DynamicInput[];
  @ViewChild(EnergyUseComponent) energyUseComponent: EnergyUseComponent;
  @ViewChild(SolarPvComponent) solarPvComponent: SolarPvComponent;
  @ViewChild(ForecastedComponent) forecastComponent: ForecastedComponent;
  storedDynamicRateList: string[];
  energyForecasts: ForecastLoadProfile[];
  solarForecasts: ForecastLoadProfile[];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public scenarioService: ScenarioService,
    public listService: TreeService,
    public tariffService: TariffService,
    public loadProfileService: LoadProfileService,
    public loaderService: LoaderService,
    public solarPvService: SolarPvService,
    public essService: EssService,
    public forecastService: ForecastService,
    public translateService: TranslateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loaderService.changeLabel(null);
    this.isEssSectionCompleted = !!this.scenario?.options?.energyStorage;
    this.listService.doNotChangeRoute = true;
    this.scenario = this.route.snapshot.data['scenario'][0];
    this.parentProject = this.route.snapshot.data['scenario'][1];
    this.loadProfiles = this.route.snapshot.data['scenario'][2];
    this.bess = this.route.snapshot.data['scenario'][3];
    this.pvLoadProfiles = this.route.snapshot.data['scenario'][4];
    this.minLoadSiteConstraintInputs = this.route.snapshot.data['scenario'][5];
    this.maxLoadSiteConstraintInputs = this.route.snapshot.data['scenario'][6];
    this.storedDynamicRateList = this.route.snapshot.data['scenario'][7];
    this.energyForecasts = this.route.snapshot.data['scenario'][8];
    this.solarForecasts = this.route.snapshot.data['scenario'][9];
    const scenarioIdQuery = this.route.snapshot.queryParamMap.get('scenarioId');
    if (!scenarioIdQuery) {
      const prevSetting = this.router.routeReuseStrategy.shouldReuseRoute;
      this.router.routeReuseStrategy.shouldReuseRoute = (): boolean => false;
      this.router
        .navigate([], {
          relativeTo: this.route,
          queryParams: { scenarioId: this.scenario.id },
          queryParamsHandling: 'merge',
        })
        .then(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prevSetting;
        });
    }
    this.setupForm();
  }

  ngOnChanges(change: SimpleChanges): void {
    this.scenario = change['scenario'].currentValue;
  }

  /**
   * Removes all scenario-form loaders and set again the global loader
   */
  ngOnDestroy(): void {
    this.loaderService.showLoader('global');
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();

    // Check the loaders in the other sections, removes them and set the loader in the first section (if there is, otherwise the global)
    this.setLoaderInStepOrGlobal(0);
  }

  setupForm(): void {
    this.scenarioNameFormControl = new UntypedFormControl(
      this.scenario.displayLabel || '',
      [Validators.required, noWhitespaceValidator()],
    );
    this.energyUseFormGroup = this._formBuilder.group({});
    this.tariffDataFormGroup = this._formBuilder.group({});
    this.addEnergyStorageFormGroup = this._formBuilder.group({});
    this.addSolarPVFormGroup = this._formBuilder.group({});
    this.addGridServicesFormGroup = this._formBuilder.group({});
    this.addForecastedFormGroup = this._formBuilder.group({});
  }

  openDeleteProjectDialog(): void {
    this.scenarioService.openDeleteProjectDialog(this.scenario);
  }

  viewScenario(id: string): void {
    this.loaderService.showLoader('global');
    this.router.navigate([`details/${id}/view-scenario`], {
      queryParams: { projectId: this.scenario.parentId },
    });
  }

  updateScenarioByForm = (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof Scenario,
  ): Observable<void> => {
    const scenarioBefore = JSON.parse(JSON.stringify(this.scenario));
    this.scenario[fieldToUpdate.valueOf()] = formControl.value;
    return from(
      this.updateScenario(
        false,
        scenarioBefore,
        null,
        ScenarioUpdateEnum.RENAME,
      ),
    );
  };

  loadTariffs(): void {
    this.tariffService.getTariffs$(this.scenario.id).subscribe(tariffs => {
      tariffs.forEach(tariff => {
        if (tariff.before) {
          if (tariff.dynamic) {
            if (tariff.importRate) {
              this.scenario.currentDynamicImportTariff = tariff;
            } else {
              this.scenario.currentDynamicExportTariff = tariff;
            }
          } else {
            this.scenario.currentTariff = tariff;
          }
        } else {
          if (tariff.dynamic) {
            if (tariff.importRate) {
              this.scenario.switchDynamicImportTariff = tariff;
            } else {
              this.scenario.switchDynamicExportTariff = tariff;
            }
          } else {
            this.scenario.switchTariff = tariff;
          }
        }
      });
    });
  }

  async scenarioChange(
    callbackEvent: [
      Scenario,
      Scenario,
      'solar' | 'energy-use' | 'energy-forecast' | 'solar-forecast',
    ],
  ): Promise<void> {
    this.scenario = callbackEvent[0];
    const reloadTariffsAndProfiles =
      callbackEvent[2] !== 'energy-forecast' &&
      callbackEvent[2] !== 'solar-forecast';
    if (callbackEvent[1]) {
      await this.updateScenario(
        reloadTariffsAndProfiles,
        callbackEvent[1],
        callbackEvent[2],
      );
    }
    this.scenarioService.scenarioChange();
    this.loaderService.changeLabel(null);
  }

  async forecastChange(
    callbackEvent: [
      ForecastLoadProfile,
      ForecastLoadProfile,
      'energy-forecast' | 'solar-forecast',
    ],
  ): Promise<void> {
    switch (callbackEvent[2]) {
      case 'energy-forecast':
        this.scenario.forecastLoadProfile = callbackEvent[0];
        break;
      case 'solar-forecast':
        this.scenario.forecastPvLoadProfile = callbackEvent[0];
        break;
      default:
        console.error(
          'forecastChange event received with unsupported type. Was the Forecast model updated with a new type?',
        );
    }
    await this.updateScenario(true, this.scenario, callbackEvent[2]);
    this.scenarioService.scenarioChange();
    this.loaderService.changeLabel(null);
  }

  async forecastRemoved(
    callbackEvent: ['energy-forecast' | 'solar-forecast'],
  ): Promise<void> {
    switch (callbackEvent[0]) {
      case 'energy-forecast':
        this.scenario.forecastLoadProfile = null;
        this.scenario.forecastLoadId = null;
        break;
      case 'solar-forecast':
        this.scenario.forecastPvLoadProfile = null;
        this.scenario.forecastPvId = null;
        break;
      default:
        console.error(
          'forecastChange event received with unsupported type. Was the Forecast model updated with a new type?',
        );
    }
    await this.updateScenario(true, this.scenario, callbackEvent[0]);
    this.scenarioService.scenarioChange();
    this.loaderService.changeLabel(null);
  }

  tariffChange(scenario: Scenario): void {
    this.scenario = scenario;
    this.loadTariffs();
  }

  // TODO: improve this part. ScenarioDto only with things from the prez,
  //  and then Scenario that implements ScenarioDto.
  //  In this way we don't need to save values and re assign them,
  //  with spread operator is a one line code.
  //  Backup strategy if option call goes into error like programs
  async toggleSlide(
    toggle: MatSlideToggleChange,
    type: 'ess' | 'solar' | 'grid' | 'forecast',
  ): Promise<void> {
    const currentTariff = this.scenario.currentTariff;
    const switchTariff = this.scenario.switchTariff;
    const loadProfile = this.scenario.loadProfile;
    const results = this.scenario.results;
    const programs = this.scenario.programs;
    if (type === 'ess') {
      const pvLoad = this.scenario.pvloadProfile;
      await this.toggleEssSlide(toggle);
      this.scenario.pvloadProfile = pvLoad;
    }
    if (type === 'solar') {
      const essParameters = this.scenario.essParameters;
      const ders = this.scenario.ders;
      await this.togglePvSlide(toggle);
      this.scenario.essParameters = essParameters;
      this.scenario.ders = ders;
    }
    if (type === 'grid') {
      this.scenario.options.gridService = toggle.checked;
      await firstValueFrom(
        this.scenarioService.updateScenarioOptions$(
          this.scenario.options,
          this.scenario.id,
        ),
      );
      this.scenarioService.scenarioChange();
      return;
    }
    if (type === 'forecast') {
      await this.disableForecast(toggle.checked);
      return;
    }
    this.scenario.programs = programs;
    this.scenario.currentTariff = currentTariff;
    this.scenario.switchTariff = switchTariff;
    this.scenario.loadProfile = loadProfile;
    this.scenario.results = results;
    this.scenarioService.scenarioChange();
  }

  private async toggleEssSlide(toggle: MatSlideToggleChange): Promise<void> {
    this.scenario.options.energyStorage = toggle.checked;
    if (toggle.checked) {
      const scenarioPromise = firstValueFrom(
        this.scenarioService.updateScenarioOptions$(
          this.scenario.options,
          this.scenario.id,
        ),
      );
      const essPromise = firstValueFrom(
        this.essService.getParameters$(this.scenario.id),
      );
      const dersPromise = firstValueFrom(
        this.essService.getDers$(this.scenario.id),
      );
      await Promise.all([scenarioPromise, essPromise, dersPromise]).then(
        values => {
          this.scenario.essParameters = values[1];
          this.scenario.ders = values[2];
        },
      );
    } else {
      await firstValueFrom(
        this.scenarioService.updateScenarioOptions$(
          this.scenario.options,
          this.scenario.id,
        ),
      );
    }
  }

  private async togglePvSlide(toggle: MatSlideToggleChange): Promise<void> {
    this.scenario.options.solarPv = toggle.checked;
    if (toggle.checked) {
      const scenarioPromise = firstValueFrom(
        this.scenarioService.updateScenarioOptions$(
          this.scenario.options,
          this.scenario.id,
        ),
      );
      const pvLoadPromise: Promise<SolarLoadProfile> = firstValueFrom(
        this.scenario.pvloadId
          ? this.solarPvService.pvLoadProfile$(this.scenario.pvloadId)
          : of(null),
      );
      const adjPvLoadPromise: Promise<LoadProfile> = firstValueFrom(
        this.scenario.loadprofileId &&
          this.scenario.pvloadId &&
          this.scenario.adjustedPvLoadId
          ? this.loadProfileService.loadProfile$(this.scenario.adjustedPvLoadId)
          : of(null),
      );
      await Promise.all([
        scenarioPromise,
        pvLoadPromise,
        adjPvLoadPromise,
      ]).then(async values => {
        this.scenario.pvloadProfile = values[1];
        this.scenario.adjustedPvLoadProfile = values[2];
        if (this.scenario.adjustedPvLoadProfile) {
          this.scenario.adjustedPvLoadProfile.json = await firstValueFrom(
            this.loadProfileService.getLoadProfileJson(
              this.scenario.adjustedPvLoadId,
            ),
          );
        }
      });
    } else {
      await firstValueFrom(
        this.scenarioService.updateScenarioOptions$(
          this.scenario.options,
          this.scenario.id,
        ),
      );
    }
  }

  private async updateScenario(
    reloadTariffsAndProfiles = false,
    scenarioBefore: Scenario,
    component?: 'solar' | 'energy-use' | 'energy-forecast' | 'solar-forecast',
    scenarioUpdateType?: ScenarioUpdateEnum,
  ): Promise<void> {
    const scenario$ = this.scenarioService.updateScenario$(
      this.scenario.id,
      this.scenario,
      scenarioUpdateType,
    );
    let newScenario: Scenario;
    try {
      newScenario = await firstValueFrom(scenario$);
      this.scenario = { ...this.scenario, ...newScenario };
    } catch (e) {
      this.scenario = scenarioBefore;
      return;
    }
    if (reloadTariffsAndProfiles) {
      this.loadTariffs();
      const loadProfilePromise = firstValueFrom(
        this.scenario.loadprofileId
          ? this.loadProfileService.loadProfile$(this.scenario.loadprofileId)
          : of(null),
      );
      const pvLoadProfilePromise = firstValueFrom(
        this.scenario.pvloadId
          ? this.solarPvService.pvLoadProfile$(this.scenario.pvloadId)
          : of(null),
      );
      const adjustedPvLoadProfilePromise = firstValueFrom(
        this.scenario.pvloadId &&
          this.scenario.loadprofileId &&
          this.scenario.adjustedPvLoadId
          ? this.loadProfileService.loadProfile$(this.scenario.adjustedPvLoadId)
          : of(null),
      );
      const loadProfileJsonPromise = firstValueFrom(
        this.scenario.loadprofileId
          ? this.loadProfileService.getLoadProfileJson(
              this.scenario.loadprofileId,
            )
          : of(null),
      );
      await Promise.all([
        loadProfilePromise,
        pvLoadProfilePromise,
        adjustedPvLoadProfilePromise,
        loadProfileJsonPromise,
      ])
        .then(async results => {
          this.scenario.loadProfile = results[0];
          this.scenario.pvloadProfile = results[1];
          this.scenario.adjustedPvLoadProfile = results[2];
          if (this.scenario.loadProfile) {
            this.scenario.loadProfile.json = results[3];
          }
          if (this.scenario.adjustedPvLoadProfile) {
            this.scenario.adjustedPvLoadProfile.json = await firstValueFrom(
              this.loadProfileService.getLoadProfileJson(
                this.scenario.adjustedPvLoadId,
              ),
            );
          }
          // To trigger ngOnChanges
          this.scenario = JSON.parse(JSON.stringify(this.scenario));
        })
        .catch(() => {
          if (component === 'energy-use' && this.scenario.loadprofileId) {
            this.energyUseComponent.uploaderLoadProfile.remove(
              this.scenario.loadprofileId,
            );
          }
          if (component === 'solar' && this.scenario.pvloadId) {
            this.solarPvComponent.uploaderComponent.remove(
              this.scenario.pvloadId,
            );
          }
          if (component === 'energy-forecast' && this.scenario.forecastLoadId) {
            this.forecastComponent.forecastedEnergyComponent.uploaderEnergyForecast.remove(
              this.scenario.forecastLoadId,
            );
          }
          if (component === 'solar-forecast' && this.scenario.forecastPvId) {
            this.forecastComponent.forecastedSolarComponent.uploaderSolarForecast.remove(
              this.scenario.forecastPvId,
            );
          }
        });
    }
  }

  stepChange(event: StepperSelectionEvent): void {
    this.setLoaderInStepOrGlobal(event.selectedIndex);
  }

  private setLoaderInStepOrGlobal(index: number): void {
    const currentStep = document.querySelectorAll('.mat-step').item(index);
    const currentLoaders = currentStep.querySelectorAll('optima-loader');
    if (currentLoaders.length > 0) {
      const lastLoader = currentLoaders.item(currentLoaders.length - 1);
      this.loaderService.showLoaders(lastLoader);
    } else {
      this.loaderService.showLoader('global');
    }
  }

  essCompleted(completed: boolean): void {
    this.isEssSectionCompleted = completed;
    this.scenarioService.scenarioChange();
  }

  getForecastDisabledTooltip(): string {
    if (!this.scenario.loadprofileId) {
      return this.translateService.instant(
        'scenario.form.forecast.disabled.load_profile',
      );
    }
    if (
      !this.scenario.options.energyStorage ||
      !this.scenarioService.hasDers(this.scenario)
    ) {
      return this.translateService.instant(
        'scenario.form.forecast.disabled.energy_storage',
      );
    }
    return this.translateService.instant(
      'scenario.form.forecast.disabled.monte_carlo',
    );
  }

  isForecastDisabled(): boolean {
    const isDisabled = this.forecastService.isForecastedStepDisabled(
      this.scenario,
    );
    if (isDisabled && this.scenario.options.forecast) {
      void this.disableForecast(false);
    }
    return isDisabled;
  }

  async disableForecast(flag: boolean): Promise<void> {
    this.scenario.options.forecast = flag;
    await firstValueFrom(
      this.scenarioService.updateScenarioOptions$(
        this.scenario.options,
        this.scenario.id,
      ),
    );
    this.scenarioService.scenarioChange();
  }
}
