<form [formGroup]="formGroup" class="mt-8">
  <div class="flex flex-col">
    <div class="form">
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-system-size-label">
          {{ 'scenario.solar.create.form.solarPvSize.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-create-system-size-value"
          matInput
          formControlName="solarPvSize"
          placeholder="{{
            'scenario.solar.create.form.solarPvSize.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="2"
          required
        />
        <mat-error *ngIf="check('solarPvSize', 'required', formGroup)">
          {{
            'scenario.solar.create.form.solarPvSize.error.required' | translate
          }}
        </mat-error>
        <mat-error
          *ngIf="
            check('solarPvSize', 'min', formGroup) ||
            check('solarPvSize', 'max', formGroup)
          "
        >
          {{
            'scenario.solar.create.form.solarPvSize.error.min_max' | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-module-type-label">
          {{ 'scenario.solar.create.form.moduleType.title' | translate }}
        </mat-label>
        <mat-select
          data-automation="solar-create-module-type-select"
          placeholder="{{
            'scenario.solar.create.form.moduleType.placeholder' | translate
          }}"
          formControlName="moduleType"
        >
          <mat-option *ngFor="let value of moduleType" [value]="value.id">
            {{ value.displayLabel }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="check('moduleType', 'required', formGroup)">
          {{
            'scenario.solar.create.form.moduleType.error.required' | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-array-type-label">
          {{ 'scenario.solar.create.form.arrayType.title' | translate }}
        </mat-label>
        <mat-select
          data-automation="solar-create-array-type-select"
          placeholder="{{
            'scenario.solar.create.form.arrayType.placeholder' | translate
          }}"
          formControlName="arrayType"
        >
          <mat-option *ngFor="let value of arrayType" [value]="value.id">
            {{ value.displayLabel }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="check('arrayType', 'required', formGroup)">
          {{
            'scenario.solar.create.form.arrayType.error.required' | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-system-loss-label">
          {{ 'scenario.solar.create.form.loss.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-create-system-loss-value"
          matInput
          formControlName="loss"
          placeholder="{{
            'scenario.solar.create.form.loss.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="1"
          required
        />
        <mat-error *ngIf="check('loss', 'required', formGroup)">
          {{ 'scenario.solar.create.form.loss.error.required' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            check('loss', 'min', formGroup) || check('loss', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.loss.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-tilt-label">
          {{ 'scenario.solar.create.form.tilt.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-create-tilt-value"
          matInput
          formControlName="tilt"
          placeholder="{{
            'scenario.solar.create.form.tilt.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="1"
          required
        />
        <mat-error *ngIf="check('tilt', 'required', formGroup)">
          {{ 'scenario.solar.create.form.tilt.error.required' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            check('tilt', 'min', formGroup) || check('tilt', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.tilt.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-create-azimuth-label">
          {{ 'scenario.solar.create.form.azimuth.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-create-azimuth-value"
          matInput
          formControlName="azimuth"
          placeholder="{{
            'scenario.solar.create.form.azimuth.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="1"
          required
        />
        <mat-error *ngIf="check('azimuth', 'required', formGroup)">
          {{ 'scenario.solar.create.form.azimuth.error.required' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            check('azimuth', 'min', formGroup) ||
            check('azimuth', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.azimuth.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div
    data-automation="solar-create-advanced-parameters"
    class="flex items-center mt-8 mb-8 pointer advanced-parameters-header"
    (click)="showAdvancedParameters = !showAdvancedParameters"
  >
    <img
      src="../../../../../../assets/svg-icons/arrow.svg"
      alt="arrow"
      class="mr-4"
      [class]="
        !showAdvancedParameters
          ? 'advanced-parameters-arrow-90'
          : 'advanced-parameters-arrow-180'
      "
    />
    <h5>{{ 'scenario.solar.create.advanced_parameters' | translate }}</h5>
  </div>

  <div class="flex flex-col mb-10" *ngIf="showAdvancedParameters" [@inOut]>
    <div class="advanced-parameters" [ngClass]="{ 'mb-12': showAllMonths }">
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-dc-ac-ratio-label">
          {{ 'scenario.solar.create.form.dcac.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-dc-ac-ratio-value"
          matInput
          formControlName="dcac"
          placeholder="{{
            'scenario.solar.create.form.dcac.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="2"
        />
        <mat-error
          *ngIf="
            check('dcac', 'min', formGroup) || check('dcac', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.dcac.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-inverter-efficiency-label">
          {{ 'scenario.solar.create.form.efficiency.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-inverter-efficiency-value"
          matInput
          formControlName="efficiency"
          placeholder="{{
            'scenario.solar.create.form.efficiency.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="2"
        />
        <mat-error
          *ngIf="
            check('efficiency', 'min', formGroup) ||
            check('efficiency', 'max', formGroup)
          "
        >
          {{
            'scenario.solar.create.form.efficiency.error.min_max' | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-ground-coverage-label">
          {{ 'scenario.solar.create.form.coverage.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-ground-coverage-value"
          matInput
          formControlName="coverage"
          placeholder="{{
            'scenario.solar.create.form.coverage.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="2"
        />
        <mat-error
          *ngIf="
            check('coverage', 'min', formGroup) ||
            check('coverage', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.coverage.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label data-automation="solar-monthly-irradiance-loss-label">
          {{ 'scenario.solar.create.form.monthlyLoss.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-monthly-irradiance-loss-value"
          matInput
          formControlName="monthlyLoss"
          placeholder="{{
            'scenario.solar.create.form.monthlyLoss.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="1"
        />
        <mat-error
          *ngIf="
            check('monthlyLoss', 'min', formGroup) ||
            check('monthlyLoss', 'max', formGroup)
          "
        >
          {{
            'scenario.solar.create.form.monthlyLoss.error.min_max' | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-bifacial-label">
          {{ 'scenario.solar.create.form.bifacial.title' | translate }}
        </mat-label>
        <mat-select
          placeholder="{{
            'scenario.solar.create.form.bifacial.placeholder' | translate
          }}"
          formControlName="bifacial"
        >
          <mat-option [value]="true">
            {{ 'scenario.solar.create.form.bifacial.value.yes' | translate }}
          </mat-option>
          <mat-option [value]="false">
            {{ 'scenario.solar.create.form.bifacial.value.no' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="mr-12">
        <mat-label data-automation="solar-albedo-label">
          {{ 'scenario.solar.create.form.albedo.title' | translate }}
        </mat-label>
        <input
          data-automation="solar-albedo-value"
          matInput
          formControlName="albedo"
          placeholder="{{
            'scenario.solar.create.form.albedo.placeholder' | translate
          }}"
          [errorStateMatcher]="matcher"
          optimaDigitDecimalNumber
          [digit]="3"
        />
        <mat-error
          *ngIf="
            check('albedo', 'min', formGroup) ||
            check('albedo', 'max', formGroup)
          "
        >
          {{ 'scenario.solar.create.form.albedo.error.min_max' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-checkbox
        data-automation="solar-create-monthly-loss-checkbox"
        [(ngModel)]="showAllMonths"
        (change)="disableSingleMonth($event)"
        [ngModelOptions]="{ standalone: true }"
      >
        {{ 'scenario.solar.create.form.monthlyLoss.checkbox' | translate }}
      </mat-checkbox>
    </div>

    <form [formGroup]="monthFormGroup" class="months-form">
      <div
        data-automation="solar-monthly-loss-form"
        class="flex flex-col months-container"
        [hidden]="!showAllMonths"
        [@inOut]
      >
        <div class="month">
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.january'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="jan"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('jan', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.january.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('jan', 'min', monthFormGroup) ||
                check('jan', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.february'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="feb"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('feb', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.february.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('feb', 'min', monthFormGroup) ||
                check('feb', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.march' | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="mar"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('mar', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.march.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('mar', 'min', monthFormGroup) ||
                check('mar', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.april' | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="apr"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('apr', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.april.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('apr', 'min', monthFormGroup) ||
                check('apr', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.may' | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="may"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('may', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.may.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('may', 'min', monthFormGroup) ||
                check('may', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.june' | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="jun"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('jun', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.june.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('jun', 'min', monthFormGroup) ||
                check('jun', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.july' | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="jul"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('jul', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.july.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('jul', 'min', monthFormGroup) ||
                check('jul', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.august'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="aug"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('aug', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.august.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('aug', 'min', monthFormGroup) ||
                check('aug', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.september'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="sep"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('sep', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.september.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('sep', 'min', monthFormGroup) ||
                check('sep', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.october'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="oct"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('oct', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.october.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('oct', 'min', monthFormGroup) ||
                check('oct', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.november'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="nov"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('nov', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.november.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('nov', 'min', monthFormGroup) ||
                check('nov', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mr-12">
            <mat-label data-automation="solar-month-form-label">
              {{
                'scenario.solar.create.form.monthlyLoss.month.december'
                  | translate
              }}
            </mat-label>
            <input
              data-automation="solar-month-form-value"
              matInput
              formControlName="dec"
              [errorStateMatcher]="matcher"
              optimaDigitDecimalNumber
              [digit]="1"
            />
            <mat-error *ngIf="check('dec', 'required', monthFormGroup)">
              {{
                'scenario.solar.create.form.monthlyLoss.month.december.error'
                  | translate
              }}
            </mat-error>
            <mat-error
              *ngIf="
                check('dec', 'min', monthFormGroup) ||
                check('dec', 'max', monthFormGroup)
              "
            >
              {{
                'scenario.solar.create.form.monthlyLoss.error.min_max'
                  | translate
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</form>
