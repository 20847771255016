import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noComma',
})
export class NoCommaPipe implements PipeTransform {
  transform(val: number): number {
    if (val !== undefined && val !== null) {
      // here we just remove the commas from value
      const numberWithoutComma = val.toString().replace(/,/g, '');
      return Number.parseInt(numberWithoutComma);
    } else {
      return Number.parseInt('');
    }
  }
}
