<input
  type="file"
  #file
  style="display: none"
  (change)="onFilesAdded()"
  [multiple]="multiple"
  [accept]="accept"
  [disabled]="disabled"
/>

<div
  #uploader
  *ngIf="!showFiles && !showLoader"
  class="flex flex-col justify-evenly items-stretch"
  data-automation="optima-uploader-box"
>
  <div
    class="uploader flex flex-col justify-center items-center"
    data-automation="optima-file-upload"
    [ngClass]="{ 'uploader-disabled': disabled }"
    (click)="addFiles()"
    (dragover)="dragOver($event)"
    (dragenter)="dragEnter($event)"
    (dragleave)="dragLeave($event)"
    (drop)="filesDrop($event)"
  >
    <mat-icon svgIcon="upload-new" class="icon-upload"></mat-icon>
    <h1 class="text-center">{{ title }}</h1>
    <span class="text-center">
      {{ subTitle || ('uploader.subtitle' | translate) }}
    </span>
  </div>
</div>

<mat-list *ngIf="!showError && uploading && !showLoader" class="full">
  <mat-list-item>
    <span class="label-full">{{ file.name }}</span>
  </mat-list-item>
</mat-list>

<div *ngIf="showFiles && !showLoader" class="full">
  <ng-container *ngIf="multiple">
    <div *ngFor="let file of $any(object)">
      <div class="flex items-center file-container" [ngClass]="fileJustify">
        <span class="file-label">{{ file[labelField] }}</span>
        <mat-icon
          svgIcon="clear-pink"
          class="pointer"
          (click)="remove(file[idField])"
        ></mat-icon>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!multiple">
    <div class="flex items-center file-container" [ngClass]="fileJustify">
      <span data-automation="optima-uploader-file-label" class="file-label">
        {{ fileLabel ? fileLabel + ': ' : '' }}{{ object[labelField] }}
      </span>
      <mat-icon
        data-automation="optima-uploader-remove-file"
        svgIcon="clear-pink"
        class="pointer"
        (click)="remove(file[idField])"
      ></mat-icon>
    </div>
  </ng-container>
</div>

<div *ngIf="showError" class="full flex justify-start items-center">
  <span class="text-error">There was an error with upload. Try again</span>
  <button mat-ripple class="button" (click)="clear()">
    <mat-icon svgIcon="reset" class="icon-reset"></mat-icon>
  </button>
</div>

<!--<div *ngIf="showLoader" class="full flex justify-start items-center">-->
<!--    <mat-spinner></mat-spinner>-->
<!--</div>-->
