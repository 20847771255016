<div class="external-ess-table-container">
  <div class="flex items-center mt-8 search-container">
    <mat-icon svgIcon="search-new" class="icon-search"></mat-icon>
    <input
      class="search-input"
      placeholder="{{ 'project.search' | translate }}"
      matInput
      #searchInput
    />
  </div>
  <div class="ess-table-list-container">
    <div class="flex items-stretch justify-between">
      <div class="table-section-container">
        <div
          class="table-container"
          [ngStyle]="{ 'overflow-y': isFirefox ? 'auto' : 'overlay' }"
        >
          <table mat-table [dataSource]="essDataSource" matSort>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <div class="flex justify-center items-center">
                  <p
                    class="action-button no-select"
                    (click)="decrementAll()"
                    [ngClass]="{ disabled: getAllZero() }"
                  >
                    -
                  </p>
                  <div
                    class="flex items-center justify-center ess-value hidden"
                  >
                    <p></p>
                  </div>
                  <p class="action-button no-select" (click)="incrementAll()">
                    +
                  </p>
                </div>
              </th>
              <td mat-cell *matCellDef="let ess">
                <div class="flex justify-center items-center">
                  <p
                    class="action-button no-select"
                    (click)="removeEss(ess.id)"
                    [ngClass]="{ disabled: getEssValue(ess.id) < 1 }"
                  >
                    -
                  </p>
                  <div
                    class="flex items-center justify-center ess-value"
                    [ngClass]="{ disabled: getEssValue(ess.id) < 1 }"
                  >
                    <p>{{ getEssValue(ess.id) }}</p>
                  </div>
                  <p class="action-button no-select" (click)="addEss(ess.id)">
                    +
                  </p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="vendor">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="vendor"
                class="text-light"
              >
                <div class="purple-text text-16 bold">
                  {{
                    'scenario.form.energy_storage.table.vendor_product_name'
                      | translate
                  }}
                </div>
                <div>
                  {{ 'scenario.form.energy_storage.table.model' | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let ess"
                data-automation="ess-table-vendor"
                class="text-light"
              >
                <div>
                  <div class="flex items-center justify-start">
                    <span>
                      <ng-container *ngIf="ess.status === 'Active'">
                        <mat-icon class="active status-indicator">
                          circle
                        </mat-icon>
                      </ng-container>
                      <ng-container *ngIf="ess.status === 'Retired'">
                        <mat-icon class="retired status-indicator">
                          circle
                        </mat-icon>
                      </ng-container>
                    </span>
                    <span class="text-16 purple-text">
                      {{ ess.vendor }} / {{ ess.product }}
                    </span>
                  </div>
                </div>
                <div>{{ ess.model }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="kw">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="kw"
                class="text-light"
              >
                {{ 'scenario.form.energy_storage.table.kW' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let ess"
                data-automation="ess-table-nameplate-power"
              >
                <p>{{ ess.kw }} kW</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="kwh">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="kwh"
                class="text-light"
              >
                {{ 'scenario.form.energy_storage.table.kWh' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let ess"
                data-automation="ess-table-usable-capacity"
              >
                <p>{{ ess.kwh }} kWh</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="duration">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="duration"
                class="text-light"
              >
                {{ 'scenario.form.energy_storage.table.duration' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let ess"
                data-automation="ess-table-duration"
              >
                <p>{{ ess.duration | number: '1.0-1' }} hrs</p>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="essDisplayedColumns; sticky: true"
              class="item-row-header"
            ></tr>
            <tr
              mat-row
              *matRowDef="let ess; columns: essDisplayedColumns"
              id="{{ 'ess-table-' + ess.id }}"
              [ngClass]="{ active: isDerSelected(ess.id), 'item-row': true }"
            ></tr>
          </table>
        </div>
        <div class="table-actions flex justify-between items-center">
          <div
            data-automation="custom-ess-button"
            class="text-primary-color pointer flex items-center"
            (click)="openDialog()"
          >
            <mat-icon svgIcon="plus" class="mr-2 plus-icon"></mat-icon>
            {{
              'scenario.form.energy_storage.table.add_custom_ess' | translate
            }}
          </div>
          <div class="flex">
            <button
              class="x-btn-secondary flex justify-between items-center"
              (click)="combineSelections()"
              [disabled]="checkDerCount(true) || selectedDers.length === 0"
            >
              <span>
                {{
                  'scenario.form.energy_storage.table.combine_selection'
                    | translate
                }}
              </span>
              <mat-icon
                matTooltip="{{
                  'scenario.form.energy_storage.list.combine_tooltip'
                    | translate
                }}"
                class="text-18 combine-tooltip"
              >
                info_circle
              </mat-icon>
            </button>
            <button
              class="x-btn-primary ml-2"
              (click)="addToList()"
              [disabled]="checkDerCount()"
            >
              {{ 'scenario.form.energy_storage.table.add_to_list' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="list-section-container">
        <div class="ess-list-container">
          <div class="ess-list-header">
            <h3>
              {{ 'scenario.form.energy_storage.list.header' | translate }} ({{
                this.scenario.ders.length
              }})
            </h3>
          </div>
          <hr />
          <div class="list-selections" data-automation="ess-list-container">
            <ng-container *ngFor="let der of customDers(false)">
              <ng-container *ngIf="der.ess.length > 1">
                <div class="ess-list-item-container">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center w-1/2 justify-start">
                      <span class="qty text-12">(1x)</span>
                      <h3 class="text-14">
                        {{
                          'scenario.form.energy_storage.list.combo' | translate
                        }}
                      </h3>
                    </div>
                    <div class="flex items-center w-1/2 justify-end">
                      <span class="text-10">
                        {{ der.totalKw | number: '1.0-1' }} kW |
                        {{ der.totalKwh | number: '1.0-1' }} kWh
                      </span>
                      <mat-icon
                        data-automation="optima-delete-der"
                        svgIcon="delete-red"
                        class="pointer"
                        (click)="deleteDer(der.id)"
                      ></mat-icon>
                    </div>
                  </div>
                  <hr class="dotted-break" />
                  <div class="flex flex-wrap">
                    <ng-container *ngFor="let essItem of der.ess; index as i">
                      <span class="text-10">
                        ({{ essItem.qty }}) {{ getBessComboLabel(der.ess[i]) }}
                      </span>
                      <span *ngIf="i !== der.ess.length - 1" class="combo-item">
                        &nbsp;+&nbsp;
                      </span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="der.ess.length === 1">
                <div class="flex items-center justify-between items-center">
                  <div class="flex items-center w-1/2 justify-start">
                    <span class="qty text-12">({{ der.ess[0].qty }}x)</span>
                    <h3 class="text-14">{{ this.getBessLabel(der.ess[0]) }}</h3>
                  </div>
                  <div class="flex items-center w-1/2 justify-end">
                    <span class="text-10">
                      {{ der.totalKw | number: '1.0-1' }} kW |
                      {{ der.totalKwh | number: '1.0-1' }} kWh
                    </span>
                    <mat-icon
                      data-automation="optima-delete-der"
                      svgIcon="delete-red"
                      class="pointer"
                      (click)="deleteDer(der.id)"
                    ></mat-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let der of customDers(true); index as i">
              <div class="flex items-center justify-between items-center">
                <div class="flex items-center w-1/2 justify-start">
                  <h3 class="text-14">
                    {{ 'dialog.ess_custom.label' | translate }} {{ i + 1 }}
                  </h3>
                </div>
                <div class="flex items-center w-1/2 justify-end">
                  <span class="text-11">
                    {{ der.totalKw | number: '1.0-1' }} kW |
                    {{ der.totalKwh | number: '1.0-1' }} kWh
                  </span>
                  <mat-icon
                    data-automation="optima-delete-der"
                    svgIcon="delete-red"
                    class="pointer"
                    (click)="deleteDer(der.id)"
                  ></mat-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="list-actions">
          <div
            class="text-center flex justify-center items-center pointer"
            (click)="deleteAllDers()"
          >
            <mat-icon
              data-automation="optima-delete-der"
              svgIcon="delete-red"
            ></mat-icon>
            <h3 class="text-light">
              {{ 'scenario.form.energy_storage.list.unselect_all' | translate }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p class="clear-button" (click)="clearAll()">Clear all</p>
</div>
