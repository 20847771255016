import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColumnOptions, Result, RunState } from '@model';
import { ResultService } from '@service';

@Component({
  selector: 'optima-result-table',
  templateUrl: './result-table.component.html',
  styleUrls: ['./result-table.component.scss'],
})
export class ResultTableComponent implements OnChanges, AfterViewInit {
  @Input() results: Result[];
  @Input() selectedIdx: number;
  @Output() copiedDataEmitter = new EventEmitter<string>();
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Result>;
  columns: ColumnOptions[] = [];
  displayedColumns: string[];
  copiedData: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private resultService: ResultService,
    private translateService: TranslateService,
  ) {
    this.columns.push(
      new ColumnOptions('runState', 'scenario.results.run_state', false),
    );
    this.columns.push(
      new ColumnOptions('idx', 'scenario.results.config_number', false),
    );
    this.columns.push(
      new ColumnOptions(
        'solarPvNameplate',
        'scenario.results.solar_pv_nameplate',
        true,
      ),
    );
    this.columns.push(
      new ColumnOptions('storagePower', 'scenario.results.storage_power', true),
    );
    this.columns.push(
      new ColumnOptions(
        'storageCapacity',
        'scenario.results.storage_capacity',
        true,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'solarSavings',
        'scenario.results.solar_savings',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'storageSavings',
        'scenario.results.storage_savings',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'tariffSwitchSavings',
        'scenario.results.tariff_switch_savings',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'gridServiceRevenues',
        'scenario.results.grid_services_revenues',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'totalSavingsAndRevenues',
        'scenario.results.total_savings_and_revenues',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'storageCycles',
        'scenario.results.storage_cycles',
        false,
      ),
    );
    this.columns.push(
      new ColumnOptions(
        'storageSavingsPerKwh',
        'scenario.results.total_storage_savings',
        false,
      ),
    );
    this.displayedColumns = Array.from(this.columns.map(k => k.id));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.initDataSource(this.results);
    this.selectedIdx = this.selectedIdx ?? this.dataSource.data[0].idx;
    await this.selectResult();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  async toggleSelectedRow(result: Result): Promise<void> {
    if (result.runState === RunState.STARTED) {
      return;
    }
    this.selectedIdx = result.idx;
    if (this.selectedIdx !== null && this.selectedIdx !== undefined) {
      await this.selectResult();
    }
  }

  initDataSource(data: Result[]): void {
    this.dataSource = new MatTableDataSource(data);
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }

    this.copiedData = [
      Array.from(this.columns.map(column => column.translatedKey)).map(key =>
        this.translateService.instant(key),
      ),
      ...this.dataSource.data.map(item =>
        Array.from(this.columns.map(column => column.id)).map(id => item[id]),
      ),
    ]
      .map(data => data.join(','))
      .join('\n');

    this.copiedDataEmitter.emit(this.copiedData);
  }

  private async selectResult(): Promise<void> {
    const selectedResult = this.dataSource.data.find(
      c => c.idx === this.selectedIdx,
    );
    if (selectedResult && selectedResult.runState !== RunState.STARTED) {
      await this.appendIdxToQueryParam(selectedResult.idx);
      this.resultService.selectResult(selectedResult.adjustedLoadProfileId);
    }
  }

  private async appendIdxToQueryParam(idx: number): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        idx: idx,
      },
      queryParamsHandling: 'merge',
    });
  }

  getTranslateKey(columnId: string): string {
    return this.columns.find(k => k.id === columnId).translatedKey;
  }

  getPrintInfo(columnId: string): boolean {
    return this.columns.find(k => k.id === columnId).showUnitInBody;
  }

  hasColumnValues(column: string): boolean {
    return this.dataSource.data.map(k => k[column]).filter(c => c).length > 0;
  }
}
