import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalNavComponent } from 'ngx-global-nav';
import { CookieService } from 'ngx-shared-services';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { getEnvironmentUrl, timeout } from "@helper";
import {
  I18nService,
  LoaderService,
  ProjectService,
  ResourceService,
  ScenarioService,
  SidenavService,
  TimezonesService,
  TreeService,
  UserService,
} from '@service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'optima-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('nav') globalNav: GlobalNavComponent;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('searchInput') input: ElementRef;
  cdnUrl = getEnvironmentUrl();
  sidenavOpen = true;
  disabledInput = true;
  navUrl: string;
  images = [
    `${this.cdnUrl}/img/icons/arrow.svg`,
    `${this.cdnUrl}/img/icons/general-info.svg`,
    `${this.cdnUrl}/img/icons/arrow-forward.png`,
    `${this.cdnUrl}/img/icons/info-circle.svg`,
    `${this.cdnUrl}/img/icons/info.svg`,
    `${this.cdnUrl}/img/icons/grid-white.svg`,
    `${this.cdnUrl}/img/icons/delete-red.svg`,
    `${this.cdnUrl}/img/icons/search.svg`,
    `${this.cdnUrl}/img/icons/search-not.svg`,
    `${this.cdnUrl}/img/icons/filter.svg`,
    `${this.cdnUrl}/img/icons/map-pin.svg`,
    `${this.cdnUrl}/img/icons/manage-white.svg`,
    `${this.cdnUrl}/img/icons/edit-white.svg`,
    `${this.cdnUrl}/img/icons/delete.svg`,
    `${this.cdnUrl}/img/icons/spin.svg`,
    `${this.cdnUrl}/img/icons/edit.svg`,
  ];
  loaded = 0;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private cookieService: CookieService,
    private sidenavService: SidenavService,
    private projectService: ProjectService,
    private treeService: TreeService,
    private scenarioService: ScenarioService,
    public loaderService: LoaderService,
    private resourceService: ResourceService<string>,
    private timezoneService: TimezonesService,
    private userService: UserService,
  ) {
    // this.loaderService.setExcludedUiUrls('tab=results');
    this.loaderService.regexEndpoints = [
      'ess-parameters',
      'permissions',
      'run',
      'site-parameters',
      'grid-services-parameters',
      'programs',
    ];
    this.getNavUrl();
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = 'CALIBRANT'
    this.translateService.setDefaultLang(
      `${defaults.locale}.${brand.toUpperCase()}`,
    );

    this.i18nService.setBrand(brand);
    this.i18nService.setLocale(locale);
    this.i18nService.loadLocale();

    this.translateService.use(`${locale}.${brand}`).subscribe(() => {
      document.title = this.translateService.instant('APP.TITLE');
    });

    this.sidenavService.close$.subscribe(() => (this.sidenavOpen = false));

    this.projectService.getProjects$().subscribe();
    this.scenarioService.getAllScenarios$().subscribe();

    this.treeService.enableFilterInput$.subscribe(
      enable => (this.disabledInput = !enable),
    );
  }

  async ngAfterViewInit(): Promise<void> {
    // Awaits for the user object in global nav to have the user timezone
    while (!Object.keys(this?.globalNav?.user).length) {
      await timeout(100);
    }
    this.timezoneService.userTimezone =
      this.globalNav.user.preferences?.default_time_zone;
    this.userService.id = this.globalNav.user.user_id;

    const logo = document.querySelector('.logo') as HTMLElement;
    logo.style.cursor = 'pointer';
    logo.addEventListener('click', () => {
      this.router.navigateByUrl('/');
    });

    this.sidenavService.setSidenav(this.sidenav);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          this.projectService.sendValue(this.input.nativeElement.value);
        }),
      )
      .subscribe();
  }

  private getNavUrl(): void {
    this.resourceService
      .get('links/nav')
      .subscribe(navUrl => (this.navUrl = navUrl));
  }

  isLargeScreen(): boolean {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return width > 1280;
  }

  toggleSidenav(): void {
    this.sidenavOpen = !this.sidenavOpen;
    this.sidenavService.sendToggleEvent();
  }

  load(): void {
    this.loaded++;
    if (this.images.length == this.loaded) {
      const imagesToLoad = Array.from(
        document.querySelectorAll('.image-to-load'),
      );
      imagesToLoad.forEach(image => {
        image.remove();
      });
    }
  }
}
