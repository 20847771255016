import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges
} from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { monthsShort } from 'moment';
import { Program, Scenario } from '@model';

@Component({
  selector: 'optima-grid-summary',
  templateUrl: './grid-summary.component.html',
  styleUrls: ['./grid-summary.component.scss'],
})
export class GridSummaryComponent implements OnChanges, AfterViewInit {
  @Input() scenario: Scenario;
  programs: Program[];
  zoneLength = 50;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculateZoneWidth();
  }

  constructor(private route: ActivatedRoute) {}

  ngOnChanges(): void {
    const allPrograms = [...this.route.snapshot.data['scenarioView'][0], ...this.route.snapshot.data['scenarioView'][1]];
    this.programs = allPrograms.filter(program => this.scenario.programs.custom.includes(program.id) || this.scenario.programs.standard.includes(program.id));
    this.programs = this.programs.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0,
    );
  }

  ngAfterViewInit(): void {
    this.calculateZoneWidth();
  }

  getProgramTypeColorClass(programType: string): string {
    return (
      {
        DR: 'blue',
        CP: 'orange',
        CPP: 'red',
      }[programType] || 'hide'
    );
  }

  monthName(month: number): string {
    return monthsShort(--month);
  }

  checkEllipses(zone: string): boolean {
    if (!zone) {
      return true;
    }
    return zone.length < this.zoneLength;
  }

  calculateZoneWidth(): void {
    const zones = Array.from(
      document.querySelectorAll('.zone'),
    ) as HTMLSpanElement[];
    zones.forEach(zone => {
      zone.style.removeProperty('width');
    });
    const widths = zones.map(zone => zone.clientWidth);
    const maxWidth = Math.max(...widths);
    zones.forEach(zone => {
      zone.style.width = `${maxWidth}px`;
    });
  }
}
