import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import {
  Project,
  Result,
  RunState,
  Scenario,
  ScenarioUpdateEnum,
} from '@model';
import {
  CssService,
  GlobalAlertService,
  LoaderService,
  ResultService,
  ScenarioService,
} from '@service';
import { noWhitespaceValidator } from '@validator';

@Component({
  selector: 'optima-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss'],
})
export class ScenarioComponent implements OnInit, AfterViewInit, OnDestroy {
  mode: 'summary' | 'results' = 'summary';
  scenario: Scenario;
  project: Project;
  scenarioNameFormControl: UntypedFormControl;
  private subscriptions: Subscription[] = [];
  private interval: NodeJS.Timeout;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.cssService.calculateSummaryWidth();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public scenarioService: ScenarioService,
    private loaderService: LoaderService,
    public resultService: ResultService,
    private globalAlertService: GlobalAlertService,
    private cssService: CssService,
  ) {}

  ngOnInit(): void {
    this.cssService.calculateSummaryWidth();
    this.loaderService.changeLabel(null);
    this.subscriptions.push(
      this.route.data.subscribe(data => {
        this.stopInterval();
        const scenario: Scenario = data['scenario'][0];
        this.project = data['scenario'][1];
        this.refreshScenario(scenario);
      }),
    );
    const tab = this.route.snapshot.queryParamMap.get('tab');
    this.mode = tab === 'results' ? 'results' : 'summary';
    setTimeout(() => this.appendModeToQueryParam(this.mode), 500);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.appendModeToQueryParam(this.mode), 500);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stopInterval();
  }

  editScenario(id: string): void {
    this.loaderService.showLoader('global');
    this.router.navigate([`/details/${id}/edit-scenario`], {
      queryParams: { projectId: this.scenario.parentId },
    });
  }

  updateScenario = (
    formControl: UntypedFormControl,
    fieldToUpdate: keyof Scenario,
  ): Observable<Scenario> => {
    this.scenario[fieldToUpdate.valueOf()] = formControl.value;
    const scenarioId =
      this.route.snapshot.queryParamMap.get('scenarioId') ??
      this.route.snapshot.params['scenarioId'];
    return this.scenarioService
      .updateScenario$(scenarioId, this.scenario, ScenarioUpdateEnum.RENAME)
      .pipe(
        take(1),
        tap(scenario => {
          const newScenario = { ...this.scenario, ...scenario };
          this.refreshScenario(newScenario);
        }),
      );
  };

  startRun = (): void => {
    if (this.scenario.runState === RunState.STARTED) {
      return;
    }
    this.resultService.showRerunningSpinner = true;
    this.scenario.results = [];
    this.resultService.showRunningSpinner = true;
    const scenarioId =
      this.route.snapshot.queryParamMap.get('scenarioId') ??
      this.route.snapshot.params['scenarioId'];
    const run$ = this.scenarioService.runScenario$(scenarioId);
    run$.pipe(switchMap(() => this.resultsAndScenario())).subscribe({
      error: (err: unknown) => {
        this.globalAlertService.setError(err.toString());
        this.resultService.showRunningSpinner = false;
        this.resultService.showRerunningSpinner = false;
        this.stopInterval();
      },
      next: (data: [Result[], Scenario]) => {
        this.updateResults(data);
        this.startInterval();
      },
    });
  };

  startInterval = (): void => {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(
      () =>
        this.resultsAndScenario().subscribe({
          next: (data: [Result[], Scenario]) => {
            this.updateResults(data);
          },
          error: (err: unknown) => {
            this.globalAlertService.setError(err.toString());
          },
        }),
      5000,
    );
  };

  changeTab = (mode: 'summary' | 'results'): void => {
    this.stopInterval();
    this.mode = mode;
    this.appendModeToQueryParam(mode);
  };

  setUpForm = (): void => {
    this.scenarioNameFormControl = new UntypedFormControl(
      this.scenario.displayLabel || '',
      [Validators.required, noWhitespaceValidator()],
    );
  };

  refreshScenario = (scenario: Scenario): void => {
    this.scenario = scenario;
    this.setUpForm();
    this.appendModeToQueryParam(this.mode);
    this.resultService.showRunningSpinner =
      this.scenario.runState === RunState.STARTED;
    if (this.resultService.showRunningSpinner) {
      this.startInterval();
    }
    this.resultService.runEnable = this.scenarioService.runEnabled(
      this.scenario,
      this.project,
    );
  };

  appendModeToQueryParam = (mode: 'summary' | 'results'): void => {
    const queryParams =
      mode === 'results' ? { tab: mode } : { tab: mode, idx: null }; // force remove of idx if not on results tab
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true,
      // do not trigger navigation
    });
  };

  updateResults = (data: [Result[], Scenario]): void => {
    const scenario = data[1];
    const results = data[0];
    const scenarioId =
      this.route.snapshot.queryParamMap.get('scenarioId') ??
      this.route.snapshot.params['scenarioId'];
    if (scenario.id !== scenarioId) {
      return;
    }
    this.scenario.runState = scenario.runState;
    this.scenario.runStartedOn = scenario.runStartedOn;
    this.scenario.runCompletedOn = scenario.runCompletedOn;
    if (results?.length) {
      this.scenario.results = results;
      this.resultService.showRerunningSpinner = false;
      this.resultService.showRunningSpinner =
        this.scenario.runState === RunState.STARTED;
      if (!this.resultService.showRunningSpinner) {
        this.stopInterval();
      }
    }
  };

  resultsAndScenario = (): Observable<[Result[], Scenario]> => {
    const result$ = (scenarioId: string): Observable<Result[]> =>
      this.resultService.getResults$(scenarioId).pipe(delay(3000));
    const scenario$ = (scenarioId: string): Observable<Scenario> =>
      this.scenarioService.getScenario$(scenarioId).pipe(delay(3000));
    const scenarioId =
      this.route.snapshot.queryParamMap.get('scenarioId') ??
      this.route.snapshot.params['scenarioId'];
    this.loaderService.setIgnoreNext('scenario');
    return forkJoin([result$(scenarioId), scenario$(scenarioId)]);
  };

  stopInterval = (): void => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  };

  openDeleteProjectDialog(): void {
    this.scenarioService.openDeleteProjectDialog(this.scenario);
  }

  hasChanges(): boolean {
    if (this.scenario.results.length > 0) {
      return (
        new Date(this.scenario.lastUpdatedDate) >
        new Date(this.scenario?.runStartedOn)
      );
    }
    return false;
  }
}
