import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BillCharges, LoadProfileStats, Scenario } from '@model';
import { ForecastService, ResultService, TimezonesService } from '@service';

interface LoadProfileTableStats {
  rowLabel: string;
  minKw: number;
  avgKw: number;
  maxKw: number;
  totalKwh: number;
  fixedCharges: number;
  energyCharges: number;
  demandCharges: number;
  totalCharges: number;
}

enum ScenarioType {
  STORAGE_ONLY,
  SOLAR_STORAGE,
  SOLAR_ONLY,
}

@Component({
  selector: 'optima-result-billing-table',
  templateUrl: './result-billing-table.component.html',
  styleUrls: ['./result-billing-table.component.scss'],
})
export class ResultBillingTableComponent implements OnInit, OnChanges {
  @Input() projectId: string;
  @Input() projectLabel: string;
  @Input() scenarioLabel: string;
  @Input() beforeStats: LoadProfileStats;
  @Input() afterSolarStats: LoadProfileStats;
  @Input() scenario: Scenario;
  @Input() beforeCharges: BillCharges;
  @Input() afterTariffCharges: BillCharges;
  @Input() afterSolarCharges: BillCharges;
  @Input() resultsCharges: BillCharges;
  @Input() forecasted: boolean;
  afterEssStats: LoadProfileStats;
  stats: LoadProfileTableStats[];
  dataSource: MatTableDataSource<LoadProfileTableStats>;
  columns = new Map<string, string>();
  displayedColumns: string[];
  units = new Map<string, string>();

  constructor(
    private route: ActivatedRoute,
    private resultService: ResultService,
    private translateService: TranslateService,
    private timezoneService: TimezonesService,
    private forecastService: ForecastService,
  ) {}

  ngOnInit(): void {
    this.initColumns();
    this.initUnits();
    this.resultService.selectedResultStats$
      .pipe(
        switchMap((data: [Observable<LoadProfileStats>, string]) => {
          const adjustProfileId = data[1];
          const loadProfileStats$ = data[0];
          return forkJoin([loadProfileStats$, of(adjustProfileId)]);
        }),
      )
      .subscribe((data: [LoadProfileStats, string]) => {
        if (
          !this.scenario.options.energyStorage &&
          this.scenario.options.solarPv
        ) {
          this.afterSolarStats = data[0];
        } else {
          this.afterEssStats = data[0];
        }
        this.setDataSource();
      });
  }

  ngOnChanges(): void {
    this.setDataSource();
  }

  private setDataSource(): void {
    this.stats = this.buildRows();
    this.dataSource = new MatTableDataSource(this.stats);
  }

  private initColumns(): void {
    this.columns.set('rowLabel', '');
    this.columns.set('minKw', 'scenario.results.billing.min_demand');
    this.columns.set('avgKw', 'scenario.results.billing.avg_demand');
    this.columns.set('maxKw', 'scenario.results.billing.max_demand');
    this.columns.set('totalKwh', 'scenario.results.billing.total_usage');
    this.columns.set('fixedCharges', 'scenario.results.billing.fixed_charges');
    this.columns.set(
      'energyCharges',
      'scenario.results.billing.energy_charges',
    );
    this.columns.set(
      'demandCharges',
      'scenario.results.billing.demand_charges',
    );
    this.columns.set('totalCharges', 'scenario.results.billing.total_charges');
    this.displayedColumns = Array.from(this.columns.keys());
  }

  private initUnits(): void {
    this.units.set('minKw', 'units.kw');
    this.units.set('avgKw', 'units.kw');
    this.units.set('maxKw', 'units.kw');
    this.units.set('totalKwh', 'units.kwh');
  }

  downloadBillResults(): void {
    const csv = this.createCsvContents();

    const link = document.createElement('a');
    const blob = new Blob([csv], {
      type: 'text/csv;charset=UTF-8',
    });
    link.href = URL.createObjectURL(blob);
    link.download = this.createDownloadFilename();
    link.click();
  }

  private buildRows(includeEmpty: boolean = false): LoadProfileTableStats[] {
    const scenarioType: ScenarioType =
      this.beforeStats && this.afterSolarStats && this.afterEssStats
        ? ScenarioType.SOLAR_STORAGE
        : this.afterSolarStats && !this.afterEssStats
          ? ScenarioType.SOLAR_ONLY
          : ScenarioType.STORAGE_ONLY;

    let beforeAnythingRowLabel: string;
    let afterEverythingRowLabel: string;
    if (includeEmpty === true) {
      beforeAnythingRowLabel = 'scenario.results.before_solar_and_ess';
      afterEverythingRowLabel = 'scenario.results.chart.after_ess_and_solar';
    } else {
      switch (scenarioType) {
        case ScenarioType.SOLAR_STORAGE:
          beforeAnythingRowLabel = 'scenario.results.before_solar_and_ess';
          afterEverythingRowLabel =
            'scenario.results.chart.after_ess_and_solar';
          break;
        case ScenarioType.STORAGE_ONLY:
          beforeAnythingRowLabel = 'scenario.results.before_ess';
          afterEverythingRowLabel = 'scenario.results.after_ess';
          break;
        case ScenarioType.SOLAR_ONLY:
          beforeAnythingRowLabel = 'scenario.results.before_solar';
          afterEverythingRowLabel = 'scenario.results.after_solar';
          break;
      }
    }

    const beforeAnything: LoadProfileTableStats = {
      rowLabel: beforeAnythingRowLabel,
      ...ResultBillingTableComponent.pickStats(this.beforeStats),
      ...ResultBillingTableComponent.pickCharges(this.beforeCharges),
    };
    const afterTariffSwitch: LoadProfileTableStats = {
      rowLabel: 'scenario.results.after_tariff_switch',
      ...ResultBillingTableComponent.pickStats(
        this.afterTariffCharges ? this.beforeStats : null,
      ),
      ...ResultBillingTableComponent.pickCharges(this.afterTariffCharges),
    };
    const afterSolar: LoadProfileTableStats = {
      rowLabel: this.afterEssStats
        ? 'scenario.results.after_solar_before_ess'
        : afterEverythingRowLabel,
      ...ResultBillingTableComponent.pickStats(this.afterSolarStats),
      ...ResultBillingTableComponent.pickCharges(this.afterSolarCharges),
    };
    const afterEverything: LoadProfileTableStats = {
      rowLabel: afterEverythingRowLabel,
      ...ResultBillingTableComponent.pickStats(
        scenarioType === ScenarioType.SOLAR_ONLY ? null : this.afterEssStats,
      ),
      ...ResultBillingTableComponent.pickCharges(this.resultsCharges),
    };

    let stats: LoadProfileTableStats[];
    if (includeEmpty === true) {
      if (scenarioType === ScenarioType.SOLAR_ONLY) {
        afterSolar.rowLabel = 'scenario.results.after_solar_before_ess';
      }
      stats = [beforeAnything, afterTariffSwitch, afterSolar, afterEverything];
    } else {
      switch (scenarioType) {
        case ScenarioType.SOLAR_STORAGE:
          stats = this.afterTariffCharges
            ? [beforeAnything, afterTariffSwitch, afterSolar, afterEverything]
            : [beforeAnything, afterSolar, afterEverything];
          break;
        case ScenarioType.STORAGE_ONLY:
          stats = this.afterTariffCharges
            ? [beforeAnything, afterTariffSwitch, afterEverything]
            : [beforeAnything, afterEverything];
          break;
        case ScenarioType.SOLAR_ONLY:
          stats = this.afterTariffCharges
            ? [beforeAnything, afterTariffSwitch, afterSolar]
            : [beforeAnything, afterSolar];
          break;
      }
    }
    return stats;
  }

  private createCsvContents(): string {
    const rows: LoadProfileTableStats[] = this.buildRows(true);
    if (!rows || !rows.length) {
      return '';
    }
    let csv = '';
    this.columns.forEach((value, key) => {
      const u = this.units.get(key);
      const unitSuffix = u
        ? ` (${this.translateService.instant(this.units.get(key))})`
        : ' ($)';
      csv +=
        value !== null && value.length > 0
          ? this.translateService.instant(value) + unitSuffix + ','
          : ',';
    });
    csv += '\n';

    const keys = Array.from(this.columns.keys());
    csv += rows
      .map(row => {
        return keys
          .map(key => {
            if (key === 'rowLabel')
              return this.translateService.instant(row[key]);
            else
              return row[key] === null || row[key] === undefined
                ? 'NA'
                : row[key];
          })
          .join(',');
      })
      .join('\n');
    return csv;
  }

  private createDownloadFilename(): string {
    const date = this.timezoneService.getDateTimeDisplayLabel(
      this.projectId,
      Date(),
      'YYYY-MMM-DD',
    );
    const configIdx = this.route.snapshot.queryParams['idx'];
    const forecastLabel = !this.forecastService.isThereForecasted(this.scenario)
      ? ''
      : this.forecasted
        ? 'forecasted_'
        : 'historical_';
    return `OPT_${this.projectLabel}_${this.scenarioLabel}_Config${configIdx}_BillSummary_${forecastLabel}${date}.csv`
      .replaceAll(' ', '_')
      .replaceAll(/[\[\]\\\/?*:+,]/g, '');
  }

  private static pickStats(
    stats: LoadProfileStats,
  ): Pick<LoadProfileStats, 'avgKw' | 'maxKw' | 'minKw' | 'totalKwh'> {
    if (stats) {
      const { months, rows, interval, ...subset } = stats;
      return subset;
    } else {
      return null;
    }
  }

  private static pickCharges(
    charges: BillCharges,
  ): Pick<
    BillCharges,
    'fixedCharges' | 'energyCharges' | 'demandCharges' | 'totalCharges'
  > {
    if (charges) {
      const { billingStart, billingStop, ...subset } = charges;
      return subset;
    } else {
      return null;
    }
  }
}
