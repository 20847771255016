import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
// eslint-disable-next-line import/named
import { firstValueFrom } from 'rxjs';
import { UploaderComponent } from '@component';
import { Scenario, Tariff } from '@model';
import { LoaderService, ScenarioService, TariffService } from '@service';

@Component({
  selector: 'optima-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss'],
})
export class TariffComponent implements OnInit {
  @Input() scenario: Scenario;
  @Input() storedDynamicRates: string[];
  @Output() tariffChange = new EventEmitter<Scenario>();
  @ViewChild('uploaderTariff') uploaderTariff: UploaderComponent<Tariff>;
  @ViewChild('uploaderTariffSwitch')
  uploaderTariffSwitch: UploaderComponent<Tariff>;
  currentTariffFormDataKeyValue = new Map<string, string>();
  switchTariffFormDataKeyValue = new Map<string, string>();

  constructor(
    public scenarioService: ScenarioService,
    private tariffService: TariffService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.currentTariffFormDataKeyValue.set('scenario_id', this.scenario.id);
    this.currentTariffFormDataKeyValue.set('before', 'true');
    this.switchTariffFormDataKeyValue.set('scenario_id', this.scenario.id);
    this.switchTariffFormDataKeyValue.set('before', 'false');
  }

  async updateCurrentTariff(tariff: Tariff): Promise<void> {
    const id = this.scenario.currentTariff?.id;
    this.scenario.currentTariff = tariff;
    if (!tariff) {
      this.loaderService.changeLabel('');
      await firstValueFrom(this.tariffService.deleteTariff$(id));
      if (this.scenario.tariffParameters.dynamicBefore) {
        this.scenario.tariffParameters.dynamicBefore = false;
        this.tariffService
          .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
          .subscribe();
      }
    } else {
      tariff.before = true;
    }
    this.tariffChange.emit(this.scenario);
  }

  async updateSwitchTariff(tariff: Tariff): Promise<void> {
    const id = this.scenario.switchTariff?.id;
    this.scenario.switchTariff = tariff;
    if (!tariff) {
      this.loaderService.changeLabel('');
      await firstValueFrom(this.tariffService.deleteTariff$(id));
      if (this.scenario.tariffParameters.dynamicAfter) {
        this.scenario.tariffParameters.dynamicAfter = false;
        this.tariffService
          .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
          .subscribe();
      }
    }
    this.tariffChange.emit(this.scenario);
  }

  async toggleSlide(toggle: MatSlideToggleChange): Promise<void> {
    this.scenario.options.tariffSwitch = toggle.checked;
    await firstValueFrom(
      this.scenarioService.updateScenarioOptions$(
        this.scenario.options,
        this.scenario.id,
      ),
    );
    this.tariffChange.emit(this.scenario);
  }

  dynamicRateChange(scenario: Scenario): void {
    this.tariffChange.emit(scenario);
  }

  updateTariffParam(): void {
    this.tariffService
      .updateParameters$(this.scenario.id, this.scenario.tariffParameters)
      .subscribe();
    this.tariffChange.emit(this.scenario);
  }
}
