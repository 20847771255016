import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../../../global/loader/loader.module';
import { LoaderNoHttpModule } from '../../../global/loader-no-http/loader-no-http.module';
import { ForecastedEnergyModule } from './forecasted-energy/forecasted-energy.module';
import { ForecastedSolarModule } from './forecasted-solar/forecasted-solar.module';
import { ForecastedComponent } from './forecasted.component';

@NgModule({
  declarations: [ForecastedComponent],
  exports: [ForecastedComponent],
  imports: [
    CommonModule,
    LoaderModule,
    TranslateModule,
    ForecastedEnergyModule,
    ForecastedSolarModule,
    LoaderNoHttpModule,
  ],
})
export class ForecastedModule {}
