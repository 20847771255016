import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Program, PvWattsArrayType, PvWattsModuleType } from '@model';
import { GridProgramService, SolarPvService } from '@service';

/**
 * @deprecated
 */
@Injectable()
export class ScenarioFormResolver {
  constructor(
    private gridProgramService: GridProgramService,
    private solarPvService: SolarPvService,
  ) {}

  resolve(): Observable<[PvWattsArrayType[], PvWattsModuleType[], Program[]]> {
    const allPrograms$ = this.gridProgramService.programs$();
    const arrayType$ = this.solarPvService.getPvWattsArrayType$();
    const moduleType$ = this.solarPvService.getPvWattsModuleType$();
    return forkJoin([arrayType$, moduleType$, allPrograms$]);
  }
}
