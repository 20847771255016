<div
  class="section-header-container"
  data-automation="load-profile-header-container"
>
  <h4 class="section-header" data-automation="load-profile-header">
    {{ 'project.load_profiles' | translate }}
  </h4>
</div>

<div class="container">
  <div
    class="scenario-not-found"
    id="scenario-not-found"
    *ngIf="!loadProfiles?.length"
  >
    {{ 'load_profile.not_found' | translate }}
  </div>

  <div
    *ngIf="loadProfiles?.length"
    class="table-container"
    [ngStyle]="{ overflow: isFirefox ? 'auto' : 'overlay' }"
  >
    <table
      data-automation="project-load-profile-table"
      mat-table
      matSort
      [dataSource]="dataSource"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="filename">
          {{ 'load_profile.table.header.filename' | translate }}
        </th>
        <td mat-cell *matCellDef="let loadProfile">
          <p>{{ loadProfile.filename | trimFileExtension | stringTruncate }}</p>
        </td>
      </ng-container>

      <ng-container
        *ngFor="let column of columnsToIterate"
        [matColumnDef]="column"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column }}">
          {{ columns.get(column) | translate }}
        </th>
        <td mat-cell *matCellDef="let el">
          <span *ngIf="column === 'rows'; else otherColumns">
            {{ el.stats[column] | number: '1.0-1' || '-' }}
          </span>
          <ng-template #otherColumns>
            <span>{{ el.stats[column] | number: '1.0-0' || '-' }}</span>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'load_profile.table.header.actions' | translate }}
        </th>
        <td mat-cell *matCellDef="let loadProfile">
          <img
            alt="{{ 'load_profile.table.delete.alt' | translate }}"
            [ngClass]="{ disabled: isUsed(loadProfile.id) }"
            (click)="deleteLoadProfile(loadProfile)"
            class="images"
            [src]="
              isUsed(loadProfile.id)
                ? '../../../../../assets/images/delete-grey.svg'
                : '../../../../../assets/images/delete.svg'
            "
          />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let scenario; columns: displayedColumns"
        id="{{ 'scenarioTable-' + scenario.id }}"
      ></tr>
    </table>
  </div>
</div>
