import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { toCamel } from 'convert-keys';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import { DialogComponent } from '@component';
import {
  DialogConfig,
  GridStats,
  LoadProfile,
  LoadProfileJson,
  ResultMonteCarlo,
  Scenario,
} from '@model';

interface Response {
  data: string;
  status: string;
  code: number;
}

@Injectable()
export class LoadProfileService {
  private readonly deleteLoadProfileEmitter = new Subject<LoadProfile>();
  deleteLoadProfileStream$ = this.deleteLoadProfileEmitter.asObservable();
  private loadProfileCache = new Map<string, Observable<LoadProfile>>();
  private loadProfileJsonCache = new Map<
    string,
    Observable<LoadProfileJson[]>
  >();
  private socCache = new Map<string, Observable<LoadProfileJson[]>>();

  private dialogRef: MatDialogRef<DialogComponent>;
  private loadProfileToDelete: LoadProfile;

  constructor(
    private alertService: GlobalAlertService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private resourceLoadProfileService: ResourceService<LoadProfile>,
    private resourceLoadProfileJsonService: ResourceService<LoadProfileJson[]>,
    private http: HttpClient,
  ) {}

  loadProfiles$(projectId: number | string): Observable<LoadProfile[]> {
    return this.resourceLoadProfileService.getList(
      `projects/${projectId}/load-profiles`,
    );
  }

  loadProfile$(loadProfileId: string): Observable<LoadProfile> {
    if (!this.loadProfileCache.has(loadProfileId)) {
      this.loadProfileCache.set(
        loadProfileId,
        this.getLoadProfile$(loadProfileId).pipe(shareReplay(1)),
      );
    }
    return this.loadProfileCache.get(loadProfileId);
  }

  private getLoadProfile$(loadProfileId: string): Observable<LoadProfile> {
    return this.resourceLoadProfileService.getById(
      'load-profiles',
      loadProfileId,
    );
  }

  loadSOC$(loadProfileId: string): Observable<LoadProfileJson[]> {
    if (!this.socCache.has(loadProfileId)) {
      this.socCache.set(
        loadProfileId,
        this.getLoadSOC$(loadProfileId).pipe(shareReplay(1)),
      );
    }
    return this.socCache.get(loadProfileId);
  }

  private getLoadSOC$(loadProfileId: string): Observable<LoadProfileJson[]> {
    return this.resourceLoadProfileJsonService.get(
      `load-profiles/${loadProfileId}/state-of-charge`,
    );
  }

  deleteLoadProfile$(id: string): Observable<LoadProfile> {
    return this.resourceLoadProfileService.delete('load-profiles', id);
  }

  getLoadProfileJson(id: string): Observable<LoadProfileJson[]> {
    if (!this.loadProfileJsonCache.has(id)) {
      this.loadProfileJsonCache.set(
        id,
        this.loadProfileJson$(id).pipe(shareReplay(1)),
      );
    }
    return this.loadProfileJsonCache.get(id);
  }

  private loadProfileJson$(id: string): Observable<LoadProfileJson[]> {
    return id
      ? this.resourceLoadProfileJsonService.get(`load-profiles/${id}/json`)
      : of(null);
  }

  downloadDispatchProfileCsv(
    id: string,
    projectLabel: string,
    scenarioLabel: string,
    configIdx: string,
    date: string,
    timezone: string,
    forecastLabel: string,
    suppliedFilename?: string,
  ): Observable<{ filename: string; csv: string }> {
    const dateWithTimezone = date + '_' + timezone;
    const filename =
      suppliedFilename ||
      `OPT_${projectLabel}_${scenarioLabel}_Config${configIdx}_Profile_${forecastLabel}${dateWithTimezone}.csv`
        .replaceAll(' ', '_')
        .replaceAll(/[\[\]\\\/?*:+,]/g, '');
    return this.http
      .get<Response>(`api/v1/load-profiles/${id}/download-csv`)
      .pipe(
        map(response => {
          return {
            filename: filename,
            csv: response.data,
          };
        }),
      );
  }

  downloadAllDispatchProfileCsv(
    projectLabel: string,
    scenario: Scenario,
    configIdx: string,
    date: string,
    timezone: string,
    forecastLabel: string,
    isForecast = false,
  ): Subscription {
    const dateWithTimezone = date + '_' + timezone;
    return this.http
      .get<unknown>(
        `api/v1/scenarios/${scenario.id}/results/${configIdx}/iters?is_forecast=${isForecast}`,
      )
      .subscribe((response: unknown) => {
        const allIterationObservables = [];
        let iterations: ResultMonteCarlo[] = [];
        if (response?.hasOwnProperty('data')) {
          // @ts-expect-error need to parse Monte Carlo
          iterations = toCamel(response.data);
        }
        for (const iteration of iterations) {
          const filename =
            `OPT_${projectLabel}_${scenario.displayLabel}_Config${configIdx}_Iter${iteration.iter + 1}_Profile_${forecastLabel}${dateWithTimezone}.csv`
              .replaceAll(' ', '_')
              .replaceAll(/[\[\]\\\/?*:+,]/g, '');
          allIterationObservables.push(
            this.downloadDispatchProfileCsv(
              iteration.adjustedLoadProfileId,
              projectLabel,
              scenario.displayLabel,
              configIdx,
              date,
              timezone,
              forecastLabel,
              filename,
              // eslint-disable-next-line rxjs/no-nested-subscribe
            ).subscribe(resp => {
              const link = document.createElement('a');
              const blob = new Blob([resp.csv], {
                type: 'text/csv;charset=UTF-8',
              });
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            }),
          );
        }
        return allIterationObservables;
      });
  }

  openDeleteLoadProfileDialog(loadProfile: LoadProfile): void {
    this.loadProfileToDelete = loadProfile;
    const config: DialogConfig = {
      title: this.translateService.instant('dialog.load_profile.delete.title'),
      contentText: this.translateService.instant(
        'dialog.load_profile.delete.content',
      ),
      firstButtonText: this.translateService.instant(
        'dialog.load_profile.delete.first_button',
      ),
      secondButtonText: this.translateService.instant(
        'dialog.load_profile.delete.second_button',
      ),
      firstButtonCallback: this.deleteLoadProfileCallback,
      secondButtonCallback: this.closeDialogCallback,
    };
    this.dialogRef = this.dialogService.openDialog(config, { width: '600px' });
  }

  deleteLoadProfileCallback = (): void => {
    this.deleteLoadProfile$(this.loadProfileToDelete.id).subscribe({
      error: () => {
        this.dialogRef.close();
        const message = this.translateService.instant(
          'load_profile.delete.fail',
        );
        this.alertService.setError(
          `${message} ${this.loadProfileToDelete.filename}`,
        );
        this.loadProfileToDelete = undefined;
      },
      next: () => {
        this.dialogRef.close();
        const message = this.translateService.instant(
          'load_profile.delete.success',
        );
        this.alertService.setSuccess(
          `${message} ${this.loadProfileToDelete.filename}`,
        );
        this.deleteLoadProfileEmitter.next(this.loadProfileToDelete);
        this.loadProfileToDelete = undefined;
      },
    });
  };

  closeDialogCallback = (): void => {
    this.dialogRef.close();
  };
}
