import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { check } from '@helper';
import { CustomErrorStateMatcher, GridParameters, Scenario } from '@model';
import { GridProgramService } from '@service';

@Component({
  selector: 'optima-programs-parameters',
  templateUrl: './programs-parameters.component.html',
  styleUrls: ['./programs-parameters.component.scss'],
})
export class ProgramsParametersComponent implements OnInit {
  @Input() scenario: Scenario;
  formGroup: FormGroup;
  check = check;
  matcher = new CustomErrorStateMatcher();

  constructor(
    private gridProgramService: GridProgramService,
    public translateService: TranslateService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      curtailment: new FormControl(
        this.scenario.gridParameters.existingLoadCurtailment,
        [Validators.min(0), Validators.max(999999.0), Validators.required],
      ),
      discount: new FormControl(
        this.scenario.gridParameters.annualDiscountFactor,
        [Validators.min(0), Validators.max(20), Validators.required],
      ),
    });

    this.formGroup.valueChanges
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        tap(() => {
          if (this.formGroup.valid) {
            this.update();
          }
        }),
      )
      .subscribe();
  }

  update(): void {
    const previousValue: GridParameters = JSON.parse(
      JSON.stringify(this.scenario.gridParameters),
    );
    let curtailmentValue;
    let discountValue;
    const cfv = +(<HTMLInputElement>document.getElementById('curtailment'))
      .value;
    const dfv = +(<HTMLInputElement>document.getElementById('discount')).value;
    if (isNaN(cfv) || cfv === null || cfv === undefined) {
      curtailmentValue = previousValue.existingLoadCurtailment;
    } else {
      curtailmentValue = cfv;
    }
    if (isNaN(dfv) || dfv === null || dfv === undefined) {
      discountValue = previousValue.annualDiscountFactor;
    } else {
      discountValue = dfv;
    }
    this.scenario.gridParameters.existingLoadCurtailment = curtailmentValue;
    this.scenario.gridParameters.annualDiscountFactor = discountValue;
    this.formGroup
      .get('curtailment')
      .setValue(this.scenario.gridParameters.existingLoadCurtailment);
    this.formGroup
      .get('discount')
      .setValue(this.scenario.gridParameters.annualDiscountFactor);
    if (
      curtailmentValue === previousValue.existingLoadCurtailment &&
      discountValue === previousValue.annualDiscountFactor
    ) {
      return;
    }
    this.gridProgramService
      .updateGridParameters$(this.scenario.id, this.scenario.gridParameters)
      .subscribe({
        error: () => {
          this.scenario.gridParameters = previousValue;
          this.formGroup
            .get('curtailment')
            .setValue(previousValue.existingLoadCurtailment);
          this.formGroup
            .get('discount')
            .setValue(previousValue.annualDiscountFactor);
        },
      });
  }
}
