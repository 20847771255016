import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsSearchComponent } from './programs-search.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ProgramsSearchComponent],
  exports: [ProgramsSearchComponent],
  imports: [CommonModule, TranslateModule, MatInputModule, MatIconModule, FormsModule, ReactiveFormsModule]
})
export class ProgramsSearchModule {}
