import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
} from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { DynamicInputComponent } from '../../../global/dynamic-input/dynamic-input.component';
import { DynamicInput, Scenario } from '@model';
import { SiteConstraintService } from '@service';

@Component({
  selector: 'optima-site-constraint-form',
  templateUrl: './site-constraint-form.component.html',
  styleUrls: ['./site-constraint-form.component.scss'],
})
export class SiteConstraintFormComponent implements AfterViewInit {
  @Input() scenario: Scenario;
  @Output() scenarioChange = new EventEmitter<Scenario>();
  @Input() minDynamicInputs: DynamicInput[];
  @Input() maxDynamicInputs: DynamicInput[];
  @ViewChildren('minInputs')
  minInputComponents: DynamicInputComponent<unknown>[];
  @ViewChildren('maxInputs')
  maxInputComponents: DynamicInputComponent<unknown>[];

  constructor(
    private siteConstraintService: SiteConstraintService,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.minInputComponents.forEach(inputComponent => {
      if (
        inputComponent.dynamicInputOptions.id ===
          this.scenario.minLoadSiteParameters.id &&
        inputComponent.dynamicInputOptions.input
      ) {
        inputComponent.hideInputField = false;
      }
    });
    this.maxInputComponents.forEach(inputComponent => {
      if (
        inputComponent.dynamicInputOptions.id ===
          this.scenario.maxLoadSiteParameters.id &&
        inputComponent.dynamicInputOptions.input
      ) {
        inputComponent.hideInputField = false;
      }
    });
  }

  changedMin(event: MatRadioChange): void {
    this.siteConstraintService.uncheckEssExport();
    if (!event.value && event.value !== 0) {
      return;
    }
    if (event.value === 2 || event.value === 3) {
      this.scenario.essParameters.export = false;
    }
    this.minInputComponents.forEach(input => {
      input.hideInputField = input.dynamicInputOptions.id !== event.value;
      this.cd.detectChanges();
    });
    this.scenario.minLoadSiteParameters.id = event.value;
    this.scenario.minLoadSiteParameters.value = null;
    const relatedInput = this.minDynamicInputs.find(
      input => input.id === event.value,
    );
    // Save the radio change only if it doesn't need the value
    if (!relatedInput.input?.type) {
      this.siteConstraintService
        .updateSiteConstraint$(
          this.scenario.id,
          this.scenario.minLoadSiteParameters,
        )
        .subscribe(() => this.scenarioChange.emit(this.scenario));
    } else {
      if (this.scenario.minLoadSiteParameters.id === 1) {
        this.saveMinValue(0.1);
      }
      if (this.scenario.minLoadSiteParameters.id === 3) {
        this.saveMinValue(0);
      }
    }
  }

  changedMax(event: MatRadioChange): void {
    if (!event.value && event.value !== 0) {
      return;
    }
    this.maxInputComponents.forEach(input => {
      input.hideInputField = input.dynamicInputOptions.id !== event.value;
      this.cd.detectChanges();
    });
    this.scenario.maxLoadSiteParameters.id = event.value;
    this.scenario.maxLoadSiteParameters.value = null;
    const relatedInput = this.maxDynamicInputs.find(
      input => input.id === event.value,
    );
    // Save the radio change only if it doesn't need the value
    if (!relatedInput.input?.type) {
      this.siteConstraintService
        .updateSiteConstraint$(
          this.scenario.id,
          this.scenario.maxLoadSiteParameters,
          true,
        )
        .subscribe(() => this.scenarioChange.emit(this.scenario));
    } else {
      this.saveMaxValue(1);
    }
  }

  saveMinValue(value: number): void {
    this.scenario.minLoadSiteParameters.value = value;
    this.siteConstraintService
      .updateSiteConstraint$(
        this.scenario.id,
        this.scenario.minLoadSiteParameters,
      )
      .subscribe(() => this.scenarioChange.emit(this.scenario));
  }

  saveMaxValue(value: number): void {
    this.scenario.maxLoadSiteParameters.value = value;
    this.siteConstraintService
      .updateSiteConstraint$(
        this.scenario.id,
        this.scenario.maxLoadSiteParameters,
        true,
      )
      .subscribe(() => this.scenarioChange.emit(this.scenario));
  }
}
